import { Component, OnInit, Input } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import * as $ from 'jquery';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();
@Component({
  selector: 'app-general-comment-of-sd',
  templateUrl: './general-comment-of-sd.component.html',
  styleUrls: ['./general-comment-of-sd.component.css'],
  providers: [NgxSpinnerService]
})
export class GeneralCommentOfSDComponent implements OnInit {

  reqData: any;
  generalCommentForm: FormGroup;
  resData: AppResponse;
  refId :any;
  userprofileInfoId : any;
  generalCommentList: any[] = [];
  updategeneralCommentForm: FormGroup;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService, 
    private formBuilder: FormBuilder, 
    private router: Router,
    private commonService: CommonService, 
    private tokenService: TokenStorageService, 
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
   
    this.userprofileInfoId = this.tokenService.getuserProfileId();
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
        this.refId = params.id;
    });

    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['SD', [Validators.required]],
      'comment': ['', [Validators.required]],
      });
      this.getComment();
  }

  getComment(){
    this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.GET_GENERAL_COMMENT).subscribe(res => {

      if (res == undefined) {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        // console.log(JSON.stringify(res));
       
        if (res.status == 200) {
          this.spinner.hide();
          this.resData = new AppResponse(res); 
          this.generalCommentList = this.resData.data;
         } else if (res.status == 401) {
          this.spinner.hide();
          this.router.navigate(['logout']);
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      }
    });
    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['SD', [Validators.required]],
      'comment': ['', [Validators.required]],
      'generalCommentId':["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() { return this.generalCommentForm.controls; }

  onSubmit() {
    this.spinner.show();
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      // console.log(JSON.stringify(this.generalCommentForm.value));
     
      this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.ADD_GENERAL_COMMENT).subscribe(res => {
        if (res == undefined) {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
        } else {
          // console.log(JSON.stringify(res));
         
          if (res.status == 200) {
           // this.resData = new AppResponse(res);
           this.getComment();
          } else if (res.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }
      });
    }

  }

  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editcomment = 1;
      }
    }

  }


  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){
    
      this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
      // console.log(JSON.stringify(this.generalCommentForm.value));
      if(confirm("Are you sure you want to delete comment")){
        this.httpService
        .update(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.DELETE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });

      }
  }
}
