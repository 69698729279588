import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { FormBuilder } from '@angular/forms';

import { Router } from '@angular/router';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Component({
  selector: 'app-archived-mc-notification-list',
  templateUrl: './archived-mc-notification-list.component.html',
  styleUrls: ['./archived-mc-notification-list.component.css']
})
export class ArchivedMcNotificationListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  userProfileInfoId : string;
  editReqData: AppResponse;
  deleteResData: AppResponse;

    
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
   
  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) {
   
  }
  
 
  ngOnInit() {

       
      if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
  


  //   // this.userProfileInfoId = this.tokenService.getuserProfileId();
  //   // console.log("Ashish231"+ this.userProfileInfoId);
  

  }

  
  ngAfterViewInit(): void {

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.ARCHIVED_NOTIFICATION_LIST_BY_MC).subscribe(res => {
      console.log(res)
      this.resData = new AppResponse(res);
     
      setTimeout(() => {
        $('#notificationListDataTable1').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
            
            stateSave: true,
           
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
         
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
          
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 3,
            orderable: false
          },
        ]
        });
      }, 200);
      if (res.status === 200) {
        

      }
        else if (res.status === 401) {
       
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['dashboardmc']);
      }
    });
  }

}
