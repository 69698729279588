
export class ApplicationConstants {

     //PORTAL
     static readonly USER_PORTAL = 'USER_PORTAL';
     static readonly SYSTEM_PORTAL = 'SYSTEM_PORTAL';
     static readonly GROUP_PORTAL = 'GROUP_PORTAL';

     // BASE Href
     static readonly APP_BASE_HREF_USER_PORTAL = '/user-portal/';
     static readonly APP_BASE_HREF_SYSTEM_PORTAL = '/system-portal/';
     static readonly APP_BASE_HREF_GROUP_PORTAL = '/group-portal/';


     // Token & Session
     static readonly TOKEN_KEY = 'AuthToken';
     static readonly TOKEN_TYPE = "Bearer ";
     static readonly AUTHORIZATION = "Authorization";
     static readonly TOKEN_HEADER_KEY = 'Authorization';
     static readonly AUTHORITIES_KEY = 'AuthAuthorities';

     static readonly USER_PROFILE_INFO_ID_KEY = 'AuthUserProfileId';
     static readonly EMAIL_KEY = "email";
     static readonly FULL_NAME_KEY = "fullName";
     static readonly GROUP_ID_KEY = "groupId";
     static readonly URL_TOKENS_LIST_KEY = "urlTokens";
     static readonly ROLES = "roles";
     static readonly GROUP_NAME = "groupName";
     static readonly ADDRESS = "address";

     // Success Or Error Label
     static readonly SUCCESS_LABLE = "success";
     static readonly ERROR_LABLE = "error";
     static readonly DANGER_LABLE = "danger";
     static readonly MSG_BOX_LABEL = "#messageBox";

     // Error msg
     static readonly INTERNAL_SEREVR_ERROR = "Internal server error.";
     static readonly MAXIMUM_FILE_SIZE ="Maximum file size 10MB";

     // Content type
     static readonly APPLICATION_JSON = "application/json";

    // Custome Message
    static readonly GROUP_CONFIGS_KEY = "group-configs";

    static readonly DASHBOARD_URL_LABEL = "dashboardUrl";

    static readonly SERVER_CONNECTION_PROBLEM ="Server connection refused";
    static readonly IS_PHONE_VERIFIED ="isPhoneVerified";

}
