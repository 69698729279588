import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as FileSaver from 'file-saver';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRegExConstants } from '../../utils/AppRegExConstants';

@Component({
  selector: 'app-crreview-byho',
  templateUrl: './crreview-byho.component.html',
  styleUrls: ['./crreview-byho.component.css'],
  providers: [NgxSpinnerService]
})
export class CrreviewByhoComponent implements OnInit {

  complianceRequestId: any;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  reviewComplianceRequestForm: FormGroup;
  isStatusSelected: boolean;
  isStatusClosed: boolean;
  isStatusResolved: boolean;
  isEditable: boolean;
  btnUpdateDisabled: boolean;
  fileUploadList: string[] = [];

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService, 
    private formBuilder: FormBuilder, 
    private commonService: CommonService, 
    private router: Router, 
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    this.spinner.show();
    this.isStatusClosed = false;
    this.isEditable = false;
    this.activatedRoute.params.subscribe(params => {
      this.complianceRequestId = params.id;
    });

    this.reviewComplianceRequestForm = this.formBuilder.group({
      'reviewOutCome': ['' ,[Validators.required]],
      'reviewComments': ['',[Validators.required]],               
      'byLawsTopic': ['', [Validators.required]],              
      'complianceRequestId': [this.complianceRequestId, [Validators.required]],
      'createdBy':[''],
      'images': ['', Validators.pattern(AppRegExConstants.DOCUMENT_PATTERN_FOR_COMPLIANCE)], 
      'compliancerequestnumber':['',[Validators.required]]          
    });    
    
    
  }

  ngAfterViewInit(){

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS + "/" + this.complianceRequestId).subscribe(data => {

      this.appResponse = new AppResponse(data);
      // console.log(JSON.stringify(this.appResponse));
      if (data.status === 200) {

        if( this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_PENDING ){
          this.isEditable = true;
        }else{
          if (this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_CLOSED) {
            this.isStatusClosed = true;
          }else if(this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_RESOLVED){
            this.isStatusResolved = true;
          }
          if( !this.isStatusClosed ){
            if (this.appResponse.data.reviewCrList != null) {
              for (let index = 0; index < this.appResponse.data.reviewCrList.length; index++) {
                if (this.appResponse.data.reviewCrList[index].assignedTo == this.tokenService.getuserProfileId()) {
                    this.isEditable = true;
                }
              }
            }
          }
        }
        
        setTimeout(() => {
          $('#reviewCommentTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
            lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 4,
              orderable: false
            },
            ]
          });

          this.spinner.hide();
        }, 2000);  //2s
        this.reviewComplianceRequestForm = this.formBuilder.group({
          'reviewOutCome': [this.appResponse.data.complianceRequest.status, [Validators.required]],
          'reviewComments': ['',[Validators.required]],               
          'byLawsTopic': [this.appResponse.data.complianceRequest.byLawsTopic, [Validators.required]],              
          'complianceRequestId': [this.complianceRequestId, [Validators.required]],
          'createdBy': [this.appResponse.data.complianceRequest.createdBy] ,
          'images' : [''],
          'compliancerequestnumber':[this.appResponse.data.complianceRequest.compliancerequestnumber,[Validators.required]]        
        });
        if(this.appResponse.data.complianceRequest.status=='ACKNOWLEDGED'){
          this.isStatusSelected=true;
        }
        // this.btnUpdateDisabled = true;
        
      } else if (data.status === 401) {
        //401 token related issue
        this.spinner.hide();
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.spinner.hide();
        //this.router.navigate(['home']);
      }
    });
  }

  // convenience getter for easy access to form fields
  get compliance() { return this.reviewComplianceRequestForm.controls; }

  onSubmit() {
    this.spinner.show();
    // console.log(JSON.stringify(this.reviewComplianceRequestForm.value));
    if (false) {
      // console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      // added by aishwaryaa for hb-825 2/2022
      if(this.reviewComplianceRequestForm.controls['reviewOutCome'].value == 'PENDING'){
        // console.log(this.reviewComplianceRequestForm.controls['reviewOutCome'].value)
        // console.log(this.appResponse.data.complianceRequest.status);
       alert("please change the  status");
       this.spinner.hide();
        
        
      }else{
        var formData = new FormData();
        // Add multiple file in formData
        for (var i = 0; i < this.fileUploadList.length; i++) {
          formData.append('images', this.fileUploadList[i]);
        }
  
        formData.append('reviewOutCome', this.reviewComplianceRequestForm.controls['reviewOutCome'].value);
        formData.append('reviewComments', this.reviewComplianceRequestForm.controls['reviewComments'].value);
        formData.append('byLawsTopic', this.reviewComplianceRequestForm.controls['byLawsTopic'].value);
        formData.append('complianceRequestId', this.reviewComplianceRequestForm.controls['complianceRequestId'].value);
        formData.append('createdBy', this.reviewComplianceRequestForm.controls['createdBy'].value);
  
        this.httpService.save(formData, environment.BASE_URL + ApplicationURIConstants.UPDATE_CR_REVIEW_BY_HO)

          .subscribe((res) => {
            if (res != undefined) {
              this.resData = new AppResponse(res);
              this.spinner.hide();
              // console.log("Response ->>" + JSON.stringify(res));
              if (res.status == 200) {
                
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
                $('#reviewCommentTable').dataTable().fnDestroy(); 
                setTimeout(() => {
                  this.ngAfterViewInit();
                }, 2000);  //3s
  
              } else if (res.status == 401) {
                this.router.navigate(['logout']);
              } else {
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
              }
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
            }
          });
      }

     
    }
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  changeStatus(e) {
   
    if (e.target.value == "RESOLVED") {
      this.isStatusSelected = true;
    }else if (e.target.value == "ACKNOWLEDGED") {
      this.isStatusSelected = true;
    } else {
      this.isStatusSelected = false;
    }  
    // if (e.target.value == this.appResponse.data.complianceRequest.status) {
    //   this.btnUpdateDisabled = true;
    //  //$('#btnUpdate').prop('disabled', true);
    // }else{
    //   this.btnUpdateDisabled = false;
    //   //$('#btnUpdate').prop('disabled', false);
    // }
  }
  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    this.reviewComplianceRequestForm.setErrors(null);
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      var size = Math.round((event.target.files[i].size / 1024)); 
      if( (size /1024) > 10 ){
        this.reviewComplianceRequestForm.setErrors({ 'invalid': true });
      }else{
        this.fileUploadList.push(event.target.files[i]);
      }
    }
  }


}
