import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { startWith, map } from 'rxjs/operators';
import { environment } from 'projects/user-portal/src/environments/environment';
import { RequireMatchForByLaw } from '../../utils/requireMatchForByLaw';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-mr-by-bm',
  templateUrl: './add-mr-by-bm.component.html',
  styleUrls: ['./add-mr-by-bm.component.css'],
  providers: [NgxSpinnerService]
})
export class AddMrByBmComponent implements OnInit {

  maintenanceRequestForm: FormGroup;
  fileUploadList: string[] = [];
  resData: AppResponse;
  resDataForCate: AppResponse;
  submitted = false;
  byLaws: any[] = [];
  byLawsListTopic: any[] = [];
  flag: string="true";
  show :boolean;
  externaluser:AppResponse;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  private maintenanceRequests = environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUESTS_GU;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private tokenService: TokenStorageService, private spinner: NgxSpinnerService) { }
  filteredOptions: Observable<String[]>;
  myControl = new FormControl();
  byLawsTopic = new FormControl('');
  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
  
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_MR).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.resDataForCate = new AppResponse(res);
      // console.log(JSON.stringify(this.resDataForCate.data.occupations));

      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }
    });

    this.show=false;
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.BY_LAWS).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.resData = new AppResponse(res);
      // console.log(JSON.stringify(this.resData.data));
      this.byLaws = this.resData.data;

      for (var i = 0; i < this.byLaws.length; i++) {
        this.byLawsListTopic.push(this.byLaws[i].byLawsTopic);
      }
      // console.log("btopic:" + this.byLawsListTopic)

      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }
    });



    this.maintenanceRequestForm = this.formBuilder.group({
      "description": ['', [Validators.required]],
      "moreInfo": [''],
      "urgent": [''],
      "safetyThreat": [''],
      "externaluseroccupationId": ['', [Validators.required]],
      "assignedTo": ['', [Validators.required]],
      "images": [''],
      "byLawsTopic": new FormControl(''),
      "byLawsDesc": ['']
    });

    this.filteredOptions = this.byLawsTopic.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );
  }


  // convenience getter for easy access to form fields
  get maintenance() { return this.maintenanceRequestForm.controls; }

  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }

  onKeyUp(event: any) {
    // console.log("ashish1"+event.target.value);
    if (event.target.value.length <= 0) {
      this.byLawsTopic.setErrors(null);
      this.maintenanceRequestForm.setErrors(null);
    } else {
      for (var i = 0; i < this.byLaws.length; i++) {
        if (this.byLaws[i].byLawsTopic == event.target.value) {
          this.maintenanceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
          this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Type:-" + this.byLaws[i].description);
          this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Description:-" + this.byLaws[i].description);
          this.byLawsTopic.setErrors(null);
          break;
        } else {
          this.maintenanceRequestForm.controls['byLawsTopic'].setValue(null);
          this.maintenanceRequestForm.controls['byLawsDesc'].setValue(null);
          this.byLawsTopic.setErrors({ invalid: true });
          this.maintenanceRequestForm.setErrors({ 'invalid': true });
        }
      }
    }
  };


  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    // console.log("data"+JSON.stringify(this.maintenanceRequestForm.value));
    if (this.maintenanceRequestForm.invalid) {
      return;
    } else {
      var formData = new FormData();

      // Add multiple file in formData
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("images", this.fileUploadList[i]);
      }

      if ($("#urgent").prop("checked") == true) {
        this.maintenanceRequestForm.value.urgent = true;
      } else {
        this.maintenanceRequestForm.value.urgent = false;
      }

      if ($("#safetyThreat").prop("checked") == true) {
        this.maintenanceRequestForm.value.safetyThreat = true;
      } else {
        this.maintenanceRequestForm.value.safetyThreat = false;
      }

      formData.append("description", this.maintenanceRequestForm.controls['description'].value);
      formData.append("moreInfo", this.maintenanceRequestForm.controls['moreInfo'].value);
      formData.append("urgent", this.maintenanceRequestForm.value.urgent);
      formData.append("safetyThreat", this.maintenanceRequestForm.value.safetyThreat);
      formData.append("externaluseroccupationId", this.maintenanceRequestForm.controls['externaluseroccupationId'].value);
      formData.append("assignedTo", this.maintenanceRequestForm.controls['assignedTo'].value);
      formData.append("byLawsTopic", this.byLawsTopic.value);
      formData.append("flag", this.flag);
	    formData.append("uploadedBy", "BM");

      // console.log("data341"+formData);

      this.httpService.save(formData, this.maintenanceRequests)
        .subscribe((res) => {
          if (res != undefined) {
            this.spinner.hide();
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.maintenanceRequestForm.reset();
              $("#externaluseroccupation").prop('selectedIndex', 0);
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['maintenance-listbyBM']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }


  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.byLawsListTopic.filter(option => option.toLowerCase().includes(filterValue));
  }

  getPosts(bylawtopic) {
    // console.log("MAIN:" + bylawtopic)
    for (var i = 0; i < this.byLaws.length; i++) {
      if (this.byLaws[i].byLawsTopic == bylawtopic) {
        // console.log("final:" + this.byLaws[i].description);
        $("#desc").val(this.byLaws[i].description);
        this.maintenanceRequestForm.controls["byLawsDesc"].setValue(this.byLaws[i].description);
        this.maintenanceRequestForm.controls["byLawsTopic"].setValue(this.byLaws[i].byLawsTopic);

      }
    }
  }

  selectByLawChangeHandler(selectedByLaw) {
    for (var i = 0; i < this.byLaws.length; i++) {
      if (this.byLaws[i].byLawsTopic == selectedByLaw) {
        this.maintenanceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
        // this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Type:-" + this.byLaws[i].description);
        this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Description:-" + this.byLaws[i].description);
      }
    }
  }
  getOccupation(countryId) {
    this.show=true;
    // console.log("country" + countryId);
    // this.states = this.Countries.find(cntry => cntry.name == country).states;
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_EXTERNAL_USER_BY_OCCUPATION + '/' + countryId).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.externaluser = res;
      // console.log(JSON.stringify(this.externaluser));
      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }

    });

  }

}
