import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from '../../utils/common-service';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgxSpinnerService } from 'ngx-spinner';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-request-report-view',
  templateUrl: './request-report-view-0f-cr.component.html',
  styleUrls: ['./request-report-view-0f-cr.component.css'],
  providers: [NgxSpinnerService]
})
export class RequestReportViewOfCrComponent implements OnInit {

  complianceRequestId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  responsedata: AppResponse;
  // added by goapl for HB-611 end

  constructor(private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService,
    private commonService: CommonService,
    private router: Router,
    private tokenService: TokenStorageService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // added by gopal for HB-611 start on 10/12/2021
    if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
    // added by goapl for HB-611 end
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.complianceRequestId = params.id;
    });

// added bygopal for HB-643 on 03/01/22
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_ACTIVE_CR_TEMPLATE)
      .subscribe((res) => {


        if (res != undefined) {
          this.responsedata = new AppResponse(res);


          if (res.status == 200) {

            // console.log(this.responsedata);


          } else if (res.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else {
            this.spinner.hide();

            // this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
          }
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });
    // tslint:disable-next-line: max-line-length
    // console.log("complianceRequestId -> " + this.complianceRequestId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS + "/" + this.complianceRequestId).subscribe(data => {
      // console.log(JSON.stringify(data));
      this.spinner.hide();

      if (data.status === 200) {
        this.appResponse = new AppResponse(data);
      } else if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        //this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        // this.router.navigate(['home']);
      }
    });
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));

        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  generatePdf() {
    if (this.appResponse.status === 200) {
      pdfMake.createPdf(this.exportToPdf()).open();
    }
  }
  // added by gopal for HB-562 start on 22/12/2021
  exportToPdf() {

    return {
      content: [

        {
          text: 'HybridHOA Complaince Violations Forms',
          bold: true,
          fontSize: 17,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },

        {
          text: this.responsedata.data[0].top + '\n',

        },
        {
          text: 'Claimant \n',
          style: 'header'

        },
        this.getCrViewObject(),

        {
          text: 'Action taken thus far to resolve the matter',
          style: 'header'
        },
        this.getCrReviwTableObject(),
        {
          text: '\n\n',

        },
        // {
        //   text: 'Linked Compliance Request',
        //   style: 'header'
        // },
        // this.getLinkedCrObject(),
        this.getCrSign(),

        {
          text: '\n\n' + this.responsedata.data[0].bottom,

        },
      ],
      info: {
        title: 'HybridHOA Complaince Violations Forms',
        author: 'Report',
        subject: 'Report',
        keywords: 'Request Report',
      },
      styles: {
        header: {
          fontSize: 15,
          bold: true,
          margin: [0, 20, 0, 10]
        },
        name: {
          fontSize: 16,
          bold: true
        },
        tableHeader: {
          bold: true,
        },
        ulborder: {
          decorationStyle: 'none'
        }

      }
    };
  }

  getCrViewObject() {
    if (this.appResponse.data.complianceRequest != null) {
      return {
        columns: [
          {
            ul: [
              'Name : ' + this.appResponse.data.complianceRequest.createdbyName + '\n \n' +
              'Address For Communication : ' + this.appResponse.data.complianceRequest.addressForCommunication + '\n \n' +
              'ByLaws Topic : ' + this.appResponse.data.complianceRequest.byLawsTopic + '\n \n' +
              'Address  of Violation: ' + this.appResponse.data.complianceRequest.createdToaddress + '\n \n' +
              'Violation : ' + this.appResponse.data.complianceRequest.description + '\n \n'
              // 'Category : ' + this.appResponse.data.complianceRequest.category + '\n \n' +
              // 'Audit Date Time : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.auditDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
              // 'Created For : ' + this.appResponse.data.complianceRequest.createdToName + '\n \n' +

              // 'Fine Amount : '+ this.appResponse.data.complianceRequest.fineAmount
            ],
            style: 'ulborder'
          },
          {
            ul: [
              'Date/Time : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.createdDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n'

              // 'Status : ' + this.appResponse.data.complianceRequest.status + '\n \n' +

              // 'Document Name : ' + this.appResponse.data.complianceRequest.listRequestDocument.map(dc => {
              //   return [ dc.documentname.substr(14) ];
              // }) + '\n \n' +
              // 'Dute Date : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.dueDate, 'MM/dd/yyyy')
            ],
            style: 'ulborder'
          }
        ],
      }
    }
  }

  getCrSign() {
    if (this.appResponse.data.complianceRequest != null) {
      return {
        columns: [
          {
            ul: [
              'Signature : '
              // 'Address : ' + this.appResponse.data.complianceRequest.createdToaddress + '\n \n' +
              // 'ByLaws Topic : ' + this.appResponse.data.complianceRequest.byLawsTopic + '\n \n' +
              // 'Address  of Violation: '+this.appResponse.data.complianceRequest.createdToaddress+'\n \n'+
              // 'Violation : ' + this.appResponse.data.complianceRequest.description + '\n \n' 
              // 'Category : ' + this.appResponse.data.complianceRequest.category + '\n \n' +
              // 'Audit Date Time : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.auditDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
              // 'Created For : ' + this.appResponse.data.complianceRequest.createdToName + '\n \n' +

              // 'Fine Amount : '+ this.appResponse.data.complianceRequest.fineAmount
            ],
            style: 'ulborder'
          },
          {
            ul: [
              'Date/Time : '

              // 'Status : ' + this.appResponse.data.complianceRequest.status + '\n \n' +

              // 'Document Name : ' + this.appResponse.data.complianceRequest.listRequestDocument.map(dc => {
              //   return [ dc.documentname.substr(14) ];
              // }) + '\n \n' +
              // 'Dute Date : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.dueDate, 'MM/dd/yyyy')
            ],
            style: 'ulborder'
          }
        ],
      }
    }
  }

  getCrReviwTableObject() {
    if (this.appResponse.data.reviewCrList != null) {
      return {
        table: {
          widths: ['*', '*', '*', '*', '*'],
          body: [
            [{
              text: 'Assigned Datetime',
              style: 'tableHeader'
            },
            {
              text: 'Assigned To',
              style: 'tableHeader'
            },
            {
              text: 'Reviewoutcome',
              style: 'tableHeader'
            },
            {
              text: 'Review Datetime',
              style: 'tableHeader'
            },
            {
              text: 'Review Comments',
              style: 'tableHeader'
            }
            ],
            ...this.appResponse.data.reviewCrList.map(cr => {
              return [this.datePipe.transform(cr.assignedDateTime, 'MM/dd/yyyy hh:mm:ss a'), cr.fullName,
              cr.reviewOutCome, this.datePipe.transform(cr.reviewDateTime, 'MM/dd/yyyy hh:mm:ss a\n\n'), cr.reviewComments];
            })
          ]
        }
      };
    }
  }

  getLinkedCrObject() {
    if (this.appResponse.data.linkCompliance != null) {
      return {
        columns: [
          {
            ul: [
              'Created By : ' + this.appResponse.data.linkCompliance.createdbyName + '\n \n' +
              'Category : ' + this.appResponse.data.linkCompliance.category + '\n \n' +
              'Audit Date Time : ' + this.datePipe.transform(this.appResponse.data.linkCompliance.auditDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
              'Created For : ' + this.appResponse.data.linkCompliance.createdToName + '\n \n' +
              'Description : ' + this.appResponse.data.linkCompliance.description + '\n \n' +
              'Is Fine Applicable : ' + this.appResponse.data.linkCompliance.fineApplicable + '\n \n' +
              'Fine Amount : ' + this.appResponse.data.linkCompliance.fineAmount
            ]
          },
          {
            ul: [
              'Created Date Time : ' + this.datePipe.transform(this.appResponse.data.linkCompliance.createdDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
              'ByLaws Topic : ' + this.appResponse.data.linkCompliance.byLawsTopic + '\n \n' +
              'Status : ' + this.appResponse.data.linkCompliance.status + '\n \n' +
              'Address : ' + this.appResponse.data.linkCompliance.createdToaddress + '\n \n' +
              'Document Name : ' + this.appResponse.data.linkCompliance.listRequestDocument.map(dc => {
                return [dc.documentname.substr(14)];
              }) + '\n \n' +
              'Dute Date : ' + this.datePipe.transform(this.appResponse.data.linkCompliance.dueDate, 'MM/dd/yyyy')

            ]
          }
        ],
      }
    }
  }

  // added by gopal for HB-562 end


}
