import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ApplicationConstants } from 'src/app/utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import 'datatables.net';
import 'datatables.net-bs4';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRegExConstants } from '../../utils/AppRegExConstants';

@Component({
  selector: 'app-edit-cr',
  templateUrl: './edit-cr.component.html',
  styleUrls: ['./edit-cr.component.css'],
  providers: [NgxSpinnerService]
})
export class EditCrComponent implements OnInit {

  flag: boolean;
  rongHo: boolean;
  complianceRequestId: String;
  editComplianceRequestForm: FormGroup;
  fileUploadList: string[] = [];
  resData: AppResponse;
  submitted = false;
  responseData: AppResponse;
  appResponse: AppResponse;
  map = new Map();
  originDoc: any;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private tokenService: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ByLawsList: any[] = [];
  homeAddressList = [];

  filteredOptions: Observable<String[]>;
  filteredHomes: Observable<String[]>;
  fileNameContentType: string;

  byLawsTopic = new FormControl('');
  homeAddress = new FormControl('', Validators.required);

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end

    this.spinner.show();
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_CR).subscribe(res => {
      this.responseData = new AppResponse(res);
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        if (res.status == 200) {
          // this.fillTextfeildData();
          // console.log(JSON.stringify(this.responseData.data.homeOwnerList));
          // console.log(JSON.stringify(this.responseData.data.bylawsList));

          this.ByLawsList = this.responseData.data.bylawsList;
          for (let index = 0; index < this.responseData.data.homeOwnerList.length; index++) {
            if (this.tokenService.getuserProfileId() != this.responseData.data.homeOwnerList[index].userProfileInfoId) {
              this.homeAddressList.push(this.responseData.data.homeOwnerList[index]);
            }
          }
         // this.homeAddressList = this.responseData.data.homeOwnerList;
        } else if (res.status === 401) {
          //401 token related issue
          this.router.navigate(['login']);
        } else if (res.status == 403) {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
          this.router.navigate(['home']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
        }

      }
    });

    this.editComplianceRequestForm = this.formBuilder.group({
      'description': ['', [Validators.required]],
      'images': ['',Validators.pattern(AppRegExConstants.DOCUMENT_PATTERN_FOR_COMPLIANCE)],
      'categoryId': ['', [Validators.required]],
      'byLowDescription': [''],
      'HoDetails': [''],
      'homeProfileId': ['', [Validators.required]],
      'byLaws': [''],
      'compliancerequestnumber':['',[Validators.required]]
    });

    this.filteredOptions = this.byLawsTopic.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );

    this.filteredHomes = this.homeAddress.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filterForHomes(value) : [])
      );

    this.gerRecord();

  }

  gerRecord() {

    this.activatedRoute.params.subscribe(params => {
      this.complianceRequestId = params.id;
      // console.log("complianceRequestId" + this.complianceRequestId)
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS + '/' + this.complianceRequestId).subscribe(data => {

      this.appResponse = new AppResponse(data);
      // console.log("complianceRequestId" + this.complianceRequestId)
      // console.log(JSON.stringify(this.appResponse.data));
      // this.fillTextfeildData();

      if (data.status == 200) {

        this.editComplianceRequestForm = this.formBuilder.group({
          "description": [this.appResponse.data.complianceRequest.description, Validators.required],
          "HoDetails": [''],
          "byLowDescription": [''],
          "categoryId": [this.appResponse.data.complianceRequest.categoryId, [Validators.required]],
          'images': ['',Validators.pattern(AppRegExConstants.DOCUMENT_PATTERN_FOR_COMPLIANCE)],
          "byLawsTopic": [''],
          'homeProfileId': [this.appResponse.data.complianceRequest.homeProfileId, [Validators.required]],
          "complianceRequestId": [this.appResponse.data.complianceRequest.complianceRequestId],
          "compliancerequestnumber":[this.appResponse.data.complianceRequest.compliancerequestnumber,[Validators.required]]
        });

        for (let index = 0; index < this.homeAddressList.length; index++) {
          if (this.homeAddressList[index].homeprofileid == this.appResponse.data.complianceRequest.homeProfileId) {
            this.homeAddress.setValue(this.homeAddressList[index]);
            // console.log("insideloop" + JSON.stringify(this.homeAddressList[index].firstname + " " +
            //   this.homeAddressList[index].lastname));
            this.editComplianceRequestForm.controls['HoDetails'].setValue(this.homeAddressList[index].firstname + " " +
              this.homeAddressList[index].lastname);

          }
        }
        // console.log("InsideByLawsList" + this.ByLawsList);
        for (let index = 0; index < this.ByLawsList.length; index++) {
          if (this.ByLawsList[index].byLawsTopic == this.appResponse.data.complianceRequest.byLawsTopic) {
            this.byLawsTopic.setValue(this.ByLawsList[index].byLawsTopic);
            this.editComplianceRequestForm.controls['byLawsTopic'].setValue(this.ByLawsList[index].byLawsTopic);
            this.editComplianceRequestForm.controls['byLowDescription'].setValue(
              this.ByLawsList[index].description);

          }
        }
        this.spinner.hide();
      }

      if (data.status === 401) {
        //401 token related issue
        this.spinner.hide();
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${data.errors}`);
      }
    });

  }

  getDocument(documentName: string) {
    // console.log("data" + documentName);
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);

        //Start the code for Base64 to byte[]
        const byteCharacters = atob(this.resData.data);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(this.resData.data);

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);

        FileSaver.saveAs(blob, documentName.substr(14));
      });
  }


  selectHoAddressChangeHandler(selectedHome) {
    // console.log(selectedHome)
    for (var i = 0; i < this.homeAddressList.length; i++) {
      if (this.homeAddressList[i].homeprofileid == selectedHome.homeprofileid) {
        this.editComplianceRequestForm.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
        this.editComplianceRequestForm.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
          this.homeAddressList[i].lastname);
      }
    }
  }

  selectByLawChangeHandler(selectedByLaw) {
    for (var i = 0; i < this.ByLawsList.length; i++) {
      if (this.ByLawsList[i].byLawsTopic == selectedByLaw) {
        this.editComplianceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
        this.editComplianceRequestForm.controls['byLowDescription'].setValue(this.ByLawsList[i].description);
      }
    }
  }

  // convenience getter for easy access to form fields
  get compliance() { return this.editComplianceRequestForm.controls; }

  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    this.editComplianceRequestForm.setErrors(null);
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      var size = Math.round((event.target.files[i].size / 1024)); 
      if( (size /1024) > 10 ){
        this.editComplianceRequestForm.setErrors({ 'invalid': true });
      }else{
        this.fileUploadList.push(event.target.files[i]);
      }
    }
  }

  
  onKeyUpForHomeAddress(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.rongHo = false;
      this.homeAddress.setErrors({ 'required': true });
    } else {
      this.rongHo = false;
      for (var i = 0; i < this.homeAddressList.length; i++) {
        if (this.homeAddressList[i].addressline1 == event.target.value) {
          this.editComplianceRequestForm.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
          this.editComplianceRequestForm.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
            this.homeAddressList[i].lastname);
          this.homeAddress.setErrors(null);
          this.rongHo = false;
          break;
        } else {
          this.editComplianceRequestForm.controls['homeProfileId'].setValue(null);
          this.editComplianceRequestForm.controls['HoDetails'].setValue(null);
          this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;

        }
      }
    }
  };

  onKeyUp(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.byLawsTopic.setErrors(null);
      this.editComplianceRequestForm.setErrors(null);
    } else {
      for (var i = 0; i < this.ByLawsList.length; i++) {
        if (this.ByLawsList[i].byLawsTopic == event.target.value) {
          this.editComplianceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
          this.editComplianceRequestForm.controls['byLowDescription'].setValue(this.ByLawsList[i].description);
          this.byLawsTopic.setErrors(null);
          break;
        } else {
          this.editComplianceRequestForm.controls['byLawsTopic'].setValue(null);
          this.editComplianceRequestForm.controls['byLowDescription'].setValue(null);
          this.byLawsTopic.setErrors({ invalid: true });
          this.editComplianceRequestForm.setErrors({ 'invalid': true });
        }
      }
    }
  }

  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    // console.log("onsubmit" + JSON.stringify(this.editComplianceRequestForm.value) + "byLawsTopic" + this.editComplianceRequestForm.controls['byLawsTopic'].value);

    if (this.editComplianceRequestForm.invalid) {
      console.log("invalid")

    } else {
      // console.log("afterElse" + this.editComplianceRequestForm.value);

      console.log("valid")
      var formData = new FormData();
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append('images', this.fileUploadList[i]);
      }
      formData.append('description', this.editComplianceRequestForm.controls['description'].value);
      formData.append('homeProfileId', this.editComplianceRequestForm.controls['homeProfileId'].value);
      formData.append('categoryId', this.editComplianceRequestForm.controls['categoryId'].value);
      formData.append('byLawsTopic', this.editComplianceRequestForm.controls['byLawsTopic'].value);
      formData.append('complianceRequestId', this.appResponse.data.complianceRequest.complianceRequestId);
      formData.append('compliancerequestnumber',this.appResponse.data.complianceRequest.compliancerequestnumber);

      this.httpService.update(formData, environment.BASE_URL + ApplicationURIConstants.EDIT_COMPLIANCE_REQUESTS_BY_HO)
        .subscribe((res) => {
          this.spinner.hide();
          this.resData = new AppResponse(res);
          // console.log(this.resData)

          if (res.status == 200) {
            this.submitted = false;
            // this.complianceRequestForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['cr-list-byho']);
            }, 3000);  //3s
          } else if (res.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }

  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.ByLawsList.filter(option => option.byLawsTopic.toLowerCase().includes(filterValue));
  }

  private _filterForHomes(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.homeAddressList.filter(option => option.addressline1.toLowerCase().includes(filterValue));
  }

  displayFn(home: any): string {
    // console.log("disply ->> " + home.addressline1)
    if (home.homeprofileid == undefined) {
      return;
    } else {
      return home.addressline1;
    }
  }

}



