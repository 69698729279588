
import { ApplicationURIConstants as BaseApplicationURIConstants } from 'src/app/utils/ApplicationURIConstants';

export class ApplicationURIConstants extends BaseApplicationURIConstants {

  // Base Url
  static readonly BASE_URL = 'http://localhost:1010/user-portal';

  // Router Authentication
  static readonly WHITE_LISTED_DOMAINS = 'http://localhost:1010/user-portal';
  static readonly BLACK_LISTED_ROUTERS = 'http://localhost:1010/user-portal/signin';

  static readonly GET_USER_PROFILE = "/get_user_profile";
  static readonly EDIT_USER_PROFILE = "/edit-profile";
  static readonly GET_REQUIRED_DATA_OF_ADD_CR = "/getrequireddata";

  // notification
  static readonly NOTIFICATIONS = '/notifications';
  static readonly INVOICE_LIST_BY_BM = '/invoice-list-by-bm';
  static readonly INVOICE_LIST_VIEW = '/invoice-list-view';
  static readonly REQUEST_FOR_ACCESS = '/requestForAccess';
  static readonly CHANGE_PASSWORD = '/changePassword/';
  static readonly FORGET_PASSWORD = "/forgetPassword"
  static readonly TOKEN = '/tokens/';
  static readonly RESET_PASSWORD = '/resetPassword';
  static readonly COMPLIANCE_REQUESTS_BY_HO = "/addcompliancerequestbyho";
  static readonly ADD_COMPLIANCE_REQUEST_BY_BM = "/addcompliancerequestbybm";
  static readonly ADD_COMPLIANCE_REQUEST_BY_MC = "/addcompliancerequestbymc";
  static readonly ADD_COMPLIANCE_REQUEST = "/addcompliancerequest";
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_label = "/getlistofcompliancerequest";
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_BY_GROUP = ApplicationURIConstants.GET_LIST_OF_COMPLIANCE_REQUEST_label + "?includeGroup=true";
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_BY_ARC = "/getlistofcompliancerequestbyarc"
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_RAISED_BY_ARC = "/getlistofcompliancerequestbyarc?raisedByArc=true";
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_AGAINST_ARC = "/getlistofcompliancerequestbyarc?raisedByArc=false";

  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_RAISED_BY_HO = "/getlistofcompliancerequestho?raisedByHo=true";
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_AGAINST_HO = "/getlistofcompliancerequestho?raisedByHo=false";
  //	static readonly GET_LIST_OF_COMPLIANCE_REQUEST_BY_HO ="/getlistofcompliancerequestho";

  static readonly MAINTENANCE_REQUESTS = '/maintenanceRequests/';
  static readonly MR_LIST = '/maintenanceRequests';
  static readonly BY_LAWS = '/byLaws';
  static readonly VIEW_COMPLIANCE_REQUESTS = '/viewcompliancerequest';
  static readonly VIEW_COMPLIANCE_REQUESTS_ARC = '/viewcompliancerequestarc';
  static readonly ADD_CR_REVIEW = "/addcrreview";
  static readonly ADD_CR_REVIEW_BY_HO = "/addcrreviewbyho";
  static readonly MAINTENANCE_REQUEST = '/maintenanceRequest/';
  static readonly INVOICE_REVIEW = '/invoice-review/';
  static readonly UPDATE_CR_REVIEW_BY_HO='/updatecrreviewbyho';

  static readonly GET_FILE = '/getFile';
  static readonly GET_INVOICE_FILE = '/getInvoiceFile';
  static readonly REVIEW_INVOICE = '/review-invoice';


  static readonly GET_SD_FILE = '/getSDFile';

  static readonly ADD_GENERAL_COMMENT = "/addgeneralcomment";
  static readonly GET_GENERAL_COMMENT = "/getgeneralcomment";
  static readonly REVIEW_UPDATE = "/reviewUpdate";
  static readonly LIST_MR_REVIEW = "/listMRReview/"
  static readonly REVIEW_BY_BM = "/maintenanceRequestsReviewByBm";

  /*Added for Shared document module */
  static readonly GET_LIST_OF_DOCUMENT_BY_HO = "/getlistofdocumentbyho";
  static readonly GET_LIST_OF_DOCUMENT_BY_BM = "/getlistofdocumentofbm?isArchived=false";
  static readonly GET_LIST_OF_DOCUMENT_BY_ARC = "/getlistofdocumentofarc?isArchived=false";
  static readonly GET_LIST_OF_DOCUMENT_BY_MC = "/getlistofdocumentofmc?isArchived=false";
  static readonly GET_LIST_OF_DOCUMENT_BY_GROUP_CURRENT_DATA = "/getlistofdocumentbygroup?isArchived=false";
  static readonly GET_LIST_OF_DOCUMENT_BY_GROUP_OLD_DATA = "/getlistofdocumentbygroup?isArchived=true";
  static readonly GET_LIST_OF_DOCUMENT_BY_USER_CURRENT_DATA = "/getlistofdocumentbyho?isArchived=false";
  static readonly GET_LIST_OF_DOCUMENT_BY_USER_OLD_DATA = "/getlistofdocumentbyho?isArchived=true";
  static readonly VIEW_SHARED_DOCUMENT = "/viewshareddocument/";
  static readonly REVIEW_SHARED_DOCUMENT = "/reviewshareddocument/";
  static readonly HIDE_SHARED_DOCUMENT = "/hideshareddocument";

  /*Added for renter, cr and mr module */
  static readonly RENTER_INFORMATION_LIST = '/renterInformation-list';
  static readonly RENTER_INFORMATION_VIEW = '/renterInformation';
  static readonly RENTER_INFORMATION_ADD = '/renterInformation';
  static readonly RENTER_INFORMATION_EDIT = '/renterInformation-edit';
  static readonly DELETE_RENTER_INFO = '/deleteRenterInfo';
  static readonly MAINTENANCE_REQUESTS_EDIT = '/maintenanceRequest-edit';
  static readonly EDIT_COMPLIANCE_REQUESTS_BY_HO = "/editcompliancerequestbyho";
  static readonly EDIT_COMPLIANCE_REQUESTS_BY_ARC = "/editcompliancerequestbyarc";
  static readonly ADD_SHARED_DOCUMENT = "/addSharedDocument";
  static readonly ACCOUNTEMAIL = "/accountemail";
  static readonly UPLOAD_INVOICE_BY_BM= "/upload_invoice_by_bm";

  static readonly EDIT_SHARED_DOC = "/editSharedDocument";

  static readonly HO_DASHBOARD = "/ho-home";
  static readonly UN_HIDE_SD = "/unHideSharedDoc";
  static readonly REVIEW_SD = "/reviewSharedDoc";

  static readonly ARC_DASHBOARD = "/arc-home";

  static readonly GET_REQUEST_REPORT = "/getrequestreport";
  static readonly GET_HOME_ACTIVITY_REPORT = "/gethomeactivityreport";
  static readonly GET_DUE_REPORT = "/getduereport";
  static readonly GET_DUE_REPORT_BY_ID = "/getsingleduereport";


  static readonly GET_ALL_HOME_LIST_BY_GROUP = "/getallhomelistbygroup";

  static readonly FAQ = "/viewFaq";
  static readonly BM_DASHBOARD = "/bm-home";
  static readonly GET_NOTIFICATION_LIST_BY_BM = "/notificationlistbybm";
  static readonly GET_NOTIFICATION_LIST_BY_ARC = "/notificationlistbyarc";
  static readonly GET_NOTIFICATION_LIST_BY_HO = "/notificationlistbyho";
  static readonly ADD_NOTIFICATION_BY_BM = "/addnotification";
  static readonly ADD_NOTIFICATION_BY_ARC = "/addnotificationbyarc";
  static readonly ADD_NOTIFICATION_BY_MC = "/addnotificationbymc";
  static readonly VIEW_NOTIFICATION = "/viewnotification"
  static readonly EDIT_NOTIFICATION_BY_BM = "/editnotificationlistbybm";
  static readonly DELETE_NOTIFICATION_BY_BM = "/deletenotificationbybm";
  static readonly EDIT_NOTIFICATION_BY_ARC = "/editnotificationlistbyarc";
  static readonly EDIT_NOTIFICATION_BY_MC = "/editnotificationlistbyarc";
  static readonly DELETE_NOTIFICATION_BY_ARC = "/deletenotificationbyarc";
  static readonly NOTIFICATION_LIST_BY_BM = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_BM + "?notificationListForBM=false";
  static readonly ARCHIVED_NOTIFICATION_LIST_BY_BM = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_BM + "?notificationListForBM=true";
  static readonly NOTIFICATION_REVIEW_UPDATE_BY_BM = "/notificationReviewUpdateByBm";

  // static readonly NOTIFICATION_LIST_BY_ARC = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_ARC + "?notificationListForBM=false";
  // static readonly ARCHIVED_NOTIFICATION_LIST_BY_ARC = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_ARC + "?notificationListForBM=true";


  static readonly NOTIFICATION_REVIEW_UPDATE_BY_ARC = "/notificationReviewUpdateByArc";

  static readonly NOTIFICATION_LIST_BY_HO = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_HO + "?notificationListForBM=false";
  static readonly ARCHIVED_NOTIFICATION_LIST_BY_HO = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_HO + "?notificationListForBM=true";
  static readonly HIDE_NOTIFICATION_BY_BM = "/hidenotificationbybm";
  static readonly UNHIDE_NOTIFICATION_BY_BM = "/unhidenotificationbybm";


  static readonly HIDE_NOTIFICATION_BY_ARC = "/hidenotificationbyarc";
  static readonly UNHIDE_NOTIFICATION_BY_ARC = "/unhidenotificationbyarc";

  static readonly MC_DASHBOARD = "/mc-home";

  static readonly ADD_EVENT_BY_BM = "/addevent";
  static readonly ADD_EVENT_BY_ARC = "/addeventbyarc";
  static readonly GET_EVENT_LIST = "/eventlist";
  static readonly GET_EVENT_LIST_BY_HO = ApplicationURIConstants.GET_EVENT_LIST + "?eventlist=ho"
  static readonly GET_VIEW_EVENT_BY_BM = "/viewevent";
  static readonly GET_VIEW_EVENT_BY_ARC = "/vieweventbyarc";
  static readonly GET_VIEW_EVENT_BY_HO ="/vieweventbyho";
  static readonly EVENT_LIST_BY_BM = ApplicationURIConstants.GET_EVENT_LIST + "?eventlist=false";
  static readonly EVENT_LIST_BY_ARC = ApplicationURIConstants.GET_EVENT_LIST + "?eventlist=true";

  static readonly EVENT_REVIEW_UPDATE_BY_BM = "/eventReviewUpdateByBmAndARC";
  static readonly EVENT_REVIEW_UPDATE_BY_ARC = "/eventReviewUpdateByBmAndARC";
  static readonly EDIT_EVENT_BY_BM = "/editeventbybmandarc";
  static readonly EDIT_EVENT_BY_ARC = "/editeventbybmandarc";
  static readonly DELETE_EVENT_BY_BM = "/deleteeventbybmandarc";
  static readonly DELETE_EVENT_BY_ARC = "/deleteeventbybmandarc";
  static readonly CANCEL_EVENT_BY_BM = "/canceleventbybmandarc";
  static readonly CANCEL_EVENT_BY_ARC = "/canceleventbybmandarc";
  static readonly GET_LOGO = '/getLogo';
  static readonly GET_GU_USER_PROFILE = "/get_external_user_profile";
  static readonly GET_EXTERNAL_USER_BY_OCCUPATION = "/get_external_user_by_occupation";
  static readonly MR_LIST_OF_EXTERNAL_USER = "/maintenanceRequestListOfExternalUser"
  static readonly MAINTENANCE_REQUEST_VIEW_BY_BM_AND_EXTERNAL_USER = '/maintenanceRequestExternalUserView/';
  static readonly NOTIFICATION_LIST_FOR_GU = "/notificationlist";
  static readonly REVIEW_UPDATE_BY_GU = "/reviewUpdateByGU";
  static readonly GU_DASHBOARD = "/gu-home";

  static readonly GET_ALL_CURRENT_DUE_OF_HO = "/getallcurrentduedfho";

  static readonly ADD_CR_FINE = "/addCRFine";

  static readonly GET_TOKEN_BY_ROLE = "/getTokenByRole";
  static readonly ADD_EXTERNAL_BM = "/addExternalBM";
  static readonly VIEW_MAINTENANCE_REQUESTS_REVIEW = '/viewmaintenanceRequestsReview';
  static readonly REVIEW_UPDATE_BY_BM = "/reviewUpdateByBM";
  static readonly GET_REQUIRED_DATA_OF_ADD_MR = '/getrequireddataForMR';
  static readonly MAINTENANCE_REQUESTS_GU = '/maintenanceRequestsGU';

  static readonly REIMBURSEMENT_DUES_USER = '/reimbursedDuesForUser';
  static readonly UPDATE_GENERAL_COMMENT = "/updateGeneralComment";
  static readonly DELETE_GENERAL_COMMENT = "/deleteGeneralComment";

  static readonly CR_REVIEW_BY_ARC = "/crreviewByARC";
  // added bygopal for HB-643 on 03/01/22
  static readonly CR_TEMPLATE = "/cr_template";
  static readonly GET_CR_TEMPLATE = "/get_cr_template";
  static readonly GET_ACTIVE_CR_TEMPLATE = "/get_active_cr_template";
  static readonly GET_CR_TEMPLATE_BY_ID = "/get_cr_template_by_id";
  static readonly EDIT_CR_TEMPLATE = "/edit_cr_template";

  static readonly DELETE_CR_TEMPLATE = "/delete_cr_template";

  static readonly DOPAYMENT = "/dopayment";

  ///added by aishwaryaa for hb-421 27/12/2021
  static readonly NOTIFICATION_LIST_BY_ARC = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_ARC + "?notificationListForArc=false";
  static readonly NOTIFICATION_LIST_BY_MC = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_ARC + "?notificationListForArc=mc";
  static readonly ARCHIVED_NOTIFICATION_LIST_BY_ARC = ApplicationURIConstants.GET_NOTIFICATION_LIST_BY_ARC + "?notificationListForArc=true";
  static readonly ARCHIVED_NOTIFICATION_LIST_BY_MC = "/getarchivedmcnotificationformc";
  static  readonly SAVEUSERPAYMENT= "/saveuserpayment";

  static readonly DYNAMIC_PUBLISH_KEY="/dynamicpublishkey";

  static readonly GET_ALL_DUE_HISTORY_OF_HO = "/getallduehistoryofho";


  
  static readonly USER_ADD_PAYMENT_CARDS = "/useraddpaymentcard";
  static readonly SAVE_USER_PAYMENT_CARDS = "/saveuserpaymentcard";
  static readonly DELETE_USER_CARD = "/deleteusercard";
  static readonly UPDATE_USER_CARD_DETAILS = "/updateusercarddetails";


  static readonly GET_USER_DUE_REPORT_BY_ID = "/getuserduereportbyid";
  static readonly SAVEUSERPAYMENTOFFLINE ="/saveuserpaymentoffline"
 
  static readonly ADD_DISCUSSION_COMMENT = "/addDiscussionComments";
  static readonly GET_DISCUSSION_COMMENT = "/getDiscussionComments";
  static readonly REPLY_DISCUSSION_COMMENT = "/replyDiscussionComments";
  static readonly GET_REPLY_DISCUSSION ="/getReplyDiscussion";
  static readonly UPDATE_DISCUSSION_COMMENT = "/updateDiscussionComments";
  static readonly DELETE_DISCUSSSION_COMMENTS = "/deleteDiscusiionComment";



static readonly GET_BM_DISCUSSION_COMMENT = "/getbmDiscussionComments";
static readonly GET_ARC_DISCUSSION_COMMENT = "/getarcDiscussionComments";
static readonly GET_MC_DISCUSSION_COMMENT = "/getmcDiscussionComments";
static readonly GET_CHILD_DISCUSSION_BY_PARENTID = "/getchilddiscussionbyparentid";

  static readonly VIEW_EMAIL_TOKEN_GA ='/verifytokens/'


}

