import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonService } from "projects/system-portal/src/app/utils/common-service";
import { Router } from "@angular/router";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { AppResponse } from "src/app/model/AppResponse";
import { ApplicationConstants } from "src/app/utils/ApplicationConstants";
import { environment } from "projects/user-portal/src/environments/environment";
import { AppRegExConstants } from "../../utils/AppRegExConstants";

declare var $: any;
var formData = new FormData();
@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.css"],
})
export class EditProfileComponent implements OnInit {
  responseData: AppResponse;
  requestData: AppResponse;
  userEditForm: FormGroup;

  isFlag: string;

  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  tokenData: String[] = [];
  fullName: string;
  show: boolean;
  showNavbar: boolean;
  enableAddress: boolean;
  isExternalUser: Boolean = false;
  intlObj: any;
  contactNum: any;
  isError: boolean = true;
  countryCode: any;
  countryCodeLength: any;
  flag: boolean = false;
  resetFlag: boolean = false;
  disablefield: boolean; //HB-426 Hevisa Patel 28-01-2021
  editProfile: boolean = false;
  contactshow: boolean = true;

  addresschecked: boolean;

  // @ViewChild("phoneInpt3",{static : false}) phoneInpt3: ElementRef;
  @ViewChild("phoneInpt2", { static: false }) phoneInpt2: ElementRef;
  @ViewChild("myInput", { static: true }) myInput: ElementRef;

  constructor(
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    if (localStorage.getItem("isGULoggedIn") == "true") {
      this.isGULoggedIn = "true";
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      this.isBMLoggedIn = "true";
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      this.isArcLoggedIn = "true";
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      this.isHOLoggedIn = "true";
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      this.isMCLoggedIn = "true";
    }

    this.enableAddress = false;
    this.userEditForm = this.formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      addressForCommunication: [""],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      gender: ["", Validators.required],
      addressLine1: ["", Validators.required],
      city: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      state: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      zipCode: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.DIGITS),
          Validators.minLength(5),
          Validators.maxLength(6),
        ],
      ],
      email: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      userProfileInfoId: [""],
      isFlag: [""],
      isCheck: [""],
    });

    // added by goapl for HB-604 start on 16/12/2021
    this.userEditForm.controls["firstName"].disable();
    this.userEditForm.controls["addressForCommunication"].disable();
    this.userEditForm.controls["lastName"].disable();
    this.userEditForm.controls["gender"].disable();
    this.userEditForm.controls["city"].disable();
    this.userEditForm.controls["state"].disable();
    this.userEditForm.controls["zipCode"].disable();
    this.userEditForm.controls["email"].disable();
    this.userEditForm.controls["addressLine1"].disable();

    // added by goapl for HB-604 end

    if (
      localStorage.getItem("isGULoggedIn") == "true" ||
      localStorage.getItem("isHOLoggedIn") == "true"
    ) {
      this.showNavbar = true;
    } else {
      this.showNavbar = false;
      // console.log("=======>>> " + this.showNavbar);
    }
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.userEditForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngAfterViewInit() {
    this.httpService
      .get(environment.BASE_URL + ApplicationURIConstants.GET_USER_PROFILE)
      .subscribe((res) => {
        // console.log(JSON.stringify(res));
        this.responseData = new AppResponse(res);
        if (res.status == 200) {
          // console.log(this.responseData.data.addressLine1 == this.responseData.data.addressForCommunication);
          // added by goapl for HB-604 start on 16/12/2021
          if (
            this.responseData.data.addressLine1 ==
            this.responseData.data.addressForCommunication
          ) {
            this.addresschecked = true;
            this.userEditForm.controls["addressForCommunication"].disable();
          } else {
            this.addresschecked = false;

            this.userEditForm.controls["addressForCommunication"].enable();
          }
          // added by goapl for HB-604 end
          if (this.responseData.data.isExternalUser == 1) {
            this.isExternalUser = true;
          } else {
            this.isExternalUser = false;
          }

          // console.log(this.isGULoggedIn)
          // console.log(this.isExternalUser)
          if (this.isGULoggedIn || this.isExternalUser) {
            // console.log("===1If");
            this.show = true;

            this.httpService
              .get(
                environment.BASE_URL +
                  ApplicationURIConstants.GET_GU_USER_PROFILE
              )
              .subscribe((res) => {
                // console.log(JSON.stringify(res));
                this.responseData = new AppResponse(res);
                if (res.status == 200) {
                  this.fillTextfeildData();
                } else if (res.status === 401) {
                  //401 token related issue
                  this.router.navigate(["login"]);
                } else if (res.status == 403) {
                  this.commonService.showNotificationSuccess(
                    ApplicationConstants.MSG_BOX_LABEL,
                    ApplicationConstants.DANGER_LABLE,
                    `${res.errors}`
                  );
                  this.router.navigate(["home"]);
                } else {
                  this.commonService.showNotificationSuccess(
                    ApplicationConstants.MSG_BOX_LABEL,
                    ApplicationConstants.DANGER_LABLE,
                    `${this.responseData.errors}`
                  );
                }
              });
          } else {
            // console.log("===1Else")
            this.httpService
              .get(
                environment.BASE_URL + ApplicationURIConstants.GET_USER_PROFILE
              )
              .subscribe((res) => {
                // console.log(JSON.stringify(res));
                this.responseData = new AppResponse(res);
                if (res.status == 200) {
                  if (this.responseData.data.isExternalUser == 1) {
                    this.show = true;
                  }

                  this.fillTextfeildData();
                } else if (res.status === 401) {
                  //401 token related issue
                  this.router.navigate(["login"]);
                } else if (res.status == 403) {
                  this.commonService.showNotificationSuccess(
                    ApplicationConstants.MSG_BOX_LABEL,
                    ApplicationConstants.DANGER_LABLE,
                    `${res.errors}`
                  );
                  this.router.navigate(["home"]);
                } else {
                  this.commonService.showNotificationSuccess(
                    ApplicationConstants.MSG_BOX_LABEL,
                    ApplicationConstants.DANGER_LABLE,
                    `${this.responseData.errors}`
                  );
                }
              });
          }
        } else if (res.status === 401) {
          //401 token related issue
          this.router.navigate(["login"]);
        } else if (res.status == 403) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${res.errors}`
          );
          this.router.navigate(["home"]);
        } else {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${this.responseData.errors}`
          );
        }
      });

    // console.log("=======>>>> ngAfterViewInit <<<<==========");
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.userEditForm.controls;
  }

  submitted = false;

  private editUserProfile =
    environment.BASE_URL + ApplicationURIConstants.EDIT_USER_PROFILE;

  onSubmit() {
    console.log(this.findInvalidControls());
    // console.log(this.userEditForm);
    // console.log(this.userEditForm.invalid)

    this.submitted = true;
    // added by gopal for HB-738
    if (this.userEditForm.invalid || !this.isError) {
      console.log(!this.isError);

      console.log(JSON.stringify(this.userEditForm.value));
      console.log("----->> EditUser Invalid <<------");

      return;
    } else {
      // added by goapl for HB-604 start on 16/12/2021
      formData.delete("contact");
      formData.delete("userProfileInfoId");
      formData.delete("isFlag");
      formData.delete("zipCode");
      formData.delete("state");
      formData.delete("city");
      formData.delete("addressLine1");
      formData.delete("addressForCommunication");
      formData.delete("gender");
      formData.delete("email");
      formData.delete("lastName");
      formData.delete("firstName");
      formData.append("contact", this.intlObj.getNumber());
      formData.append(
        "userProfileInfoId",
        this.userEditForm.controls["userProfileInfoId"].value
      );
      formData.append(
        "firstName",
        this.userEditForm.controls["firstName"].value
      );
      formData.append("lastName", this.userEditForm.controls["lastName"].value);
      formData.append("email", this.userEditForm.controls["email"].value);
      formData.append("gender", this.userEditForm.controls["gender"].value);
      formData.append(
        "addressForCommunication",
        this.userEditForm.controls["addressForCommunication"].value
      );
      formData.append(
        "addressLine1",
        this.userEditForm.controls["addressLine1"].value
      );
      formData.append("city", this.userEditForm.controls["city"].value);
      formData.append("state", this.userEditForm.controls["state"].value);
      formData.append("zipCode", this.userEditForm.controls["zipCode"].value);
      formData.append("isFlag", this.userEditForm.controls["isFlag"].value);
      // added by goapl for HB-604 end
      // console.log("----->> EditUser Valid <<------")
      // console.log(formData.get("firstname"));
      // console.log("----->> EditUser Valid <<------")
      if (
        (this.isGULoggedIn = localStorage.getItem("isGULoggedIn")) ||
        this.isExternalUser
      ) {
        // console.log(JSON.stringify(this.userEditForm.value));
        // console.log("===2If")
        // console.log(formData);
        // this.userEditForm.controls['isExternalUser'].setValue(1)
        this.httpService
          .save(formData, this.editUserProfile)
          .subscribe((resp) => {
            this.requestData = new AppResponse(resp);
            if (this.requestData.status == 200) {
              this.tokenData = JSON.parse(
                localStorage.getItem(ApplicationConstants.USER_PORTAL)
              );
              var saPortal = {
                session: [],
              };
              saPortal.session.push({
                [ApplicationConstants.TOKEN_KEY]:
                  this.tokenData[0][ApplicationConstants.TOKEN_KEY],
                [ApplicationConstants.USER_PROFILE_INFO_ID_KEY]:
                  this.tokenData[0][
                    ApplicationConstants.USER_PROFILE_INFO_ID_KEY
                  ],
                [ApplicationConstants.EMAIL_KEY]:
                  this.tokenData[0][ApplicationConstants.EMAIL_KEY],
                [ApplicationConstants.FULL_NAME_KEY]:
                  this.userEditForm.controls["firstName"].value +
                  " " +
                  this.userEditForm.controls["lastName"].value,
                [ApplicationConstants.GROUP_ID_KEY]:
                  this.tokenData[0][ApplicationConstants.GROUP_ID_KEY],
                [ApplicationConstants.URL_TOKENS_LIST_KEY]:
                  this.tokenData[0][ApplicationConstants.URL_TOKENS_LIST_KEY],
                [ApplicationConstants.ROLES]:
                  this.tokenData[0][ApplicationConstants.ROLES],
                [ApplicationConstants.GROUP_NAME]:
                  this.tokenData[0][ApplicationConstants.GROUP_NAME],
                [ApplicationConstants.ADDRESS]:
                  this.tokenData[0][ApplicationConstants.ADDRESS],
                [ApplicationConstants.GROUP_CONFIGS_KEY]:
                  this.tokenData[0][ApplicationConstants.GROUP_CONFIGS_KEY],
              });
              window.localStorage.setItem(
                ApplicationConstants.USER_PORTAL,
                JSON.stringify(saPortal.session)
              );

              this.submitted = false;
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                this.requestData.message
              );
              setTimeout(() => {
                // console.log("<<>>>" + (this.isGULoggedIn == localStorage.getItem("isGULoggedIn")));
                // console.log("<<>>>" + (this.isGULoggedIn != localStorage.getItem("isGULoggedIn")));
                if (
                  !(this.isGULoggedIn = localStorage.getItem("isGULoggedIn")) &&
                  this.isExternalUser
                ) {
                  this.router.navigate([
                    localStorage.getItem(
                      ApplicationConstants.DASHBOARD_URL_LABEL
                    ),
                  ]);
                } else {
                  this.router.navigate([
                    localStorage.getItem(
                      ApplicationConstants.DASHBOARD_URL_LABEL
                    ),
                  ]);
                }
              }, 5000);
            } else {
              // console.log(this.requestData.errors)
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.requestData.errors}`
              );
            }
          });
      } else {
        // console.log("===2Else")
        // console.log("isGULoggedIn" + this.isGULoggedIn)
        // console.log(formData)
        this.httpService
          .save(formData, this.editUserProfile)
          .subscribe((resp) => {
            this.requestData = new AppResponse(resp);
            if (this.requestData.status == 200) {
              this.tokenData = JSON.parse(
                localStorage.getItem(ApplicationConstants.USER_PORTAL)
              );
              var saPortal = {
                session: [],
              };
              saPortal.session.push({
                [ApplicationConstants.TOKEN_KEY]:
                  this.tokenData[0][ApplicationConstants.TOKEN_KEY],
                [ApplicationConstants.USER_PROFILE_INFO_ID_KEY]:
                  this.tokenData[0][
                    ApplicationConstants.USER_PROFILE_INFO_ID_KEY
                  ],
                [ApplicationConstants.EMAIL_KEY]:
                  this.tokenData[0][ApplicationConstants.EMAIL_KEY],
                [ApplicationConstants.FULL_NAME_KEY]:
                  this.userEditForm.controls["firstName"].value +
                  " " +
                  this.userEditForm.controls["lastName"].value,
                [ApplicationConstants.GROUP_ID_KEY]:
                  this.tokenData[0][ApplicationConstants.GROUP_ID_KEY],
                [ApplicationConstants.URL_TOKENS_LIST_KEY]:
                  this.tokenData[0][ApplicationConstants.URL_TOKENS_LIST_KEY],
                [ApplicationConstants.ROLES]:
                  this.tokenData[0][ApplicationConstants.ROLES],
                [ApplicationConstants.GROUP_NAME]:
                  this.tokenData[0][ApplicationConstants.GROUP_NAME],
                [ApplicationConstants.ADDRESS]:
                  this.tokenData[0][ApplicationConstants.ADDRESS],
                [ApplicationConstants.GROUP_CONFIGS_KEY]:
                  this.tokenData[0][ApplicationConstants.GROUP_CONFIGS_KEY],
              });
              window.localStorage.setItem(
                ApplicationConstants.USER_PORTAL,
                JSON.stringify(saPortal.session)
              );
              this.submitted = false;
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                this.requestData.message
              );
              setTimeout(() => {
                this.router.navigate([
                  localStorage.getItem(
                    ApplicationConstants.DASHBOARD_URL_LABEL
                  ),
                ]);
              }, 5000);
            } else {
              // console.log(this.requestData.errors)
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.requestData.errors}`
              );
            }
          });
      }
    }
  }

  // added by goapl for HB-604 start on 16/12/2021
  changeFunction() {
    if ($("#isChecked").is(":checked")) {
      this.userEditForm.controls["addressForCommunication"].disable();
      this.userEditForm.controls["addressForCommunication"].setValue(
        this.userEditForm.controls["addressLine1"].value
      );
    } else {
      this.userEditForm.controls["addressForCommunication"].enable();
      this.userEditForm.controls["addressForCommunication"].setValue(
        this.responseData.data.addressForCommunication
      );
    }
  }

  resetData(): void {
    // if (this.responseData.data != null) {
    //   this.fillTextfeildData();
    //   $( "#phoneInpt2" ). trigger( "click" );
    // }
    this.userEditForm.controls["firstName"].enable();
    this.userEditForm.controls["addressForCommunication"].enable();

    this.userEditForm.controls["addressLine1"].enable();
    this.userEditForm.controls["lastName"].enable();
    this.userEditForm.controls["gender"].enable();
    this.userEditForm.controls["city"].enable();
    this.userEditForm.controls["state"].enable();
    this.userEditForm.controls["zipCode"].enable();
    this.userEditForm.controls["email"].enable();
    this.contactshow = false;
  }
  // added by goapl for HB-604 end

  fillTextfeildData() {
    // console.log("asaa" + this.isFlag);
    if (this.responseData.data != null) {
      this.intlObj.setNumber(this.responseData.data.contact);
      if (
        (this.isGULoggedIn = localStorage.getItem("isGULoggedIn")) ||
        this.isExternalUser
      ) {
        this.isFlag = "true";
      }

      var cont = this.intlObj.getNumber();
      // console.log("nummmmm" + $("#phoneInput").val());

      this.userEditForm = this.formBuilder.group({
        firstName: [
          this.responseData.data.firstName,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
          ],
        ],
        addressForCommunication: [
          this.responseData.data.addressForCommunication,
        ],
        lastName: [
          this.responseData.data.lastName,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
          ],
        ],
        gender: [this.responseData.data.gender, Validators.required,],
        // "contact":  [this.responseData.data.contact, [Validators.required ,Validators.pattern(AppRegExConstants.INTERNATIONAL_PHONE_NUMBERS)]],
        addressLine1: [
          this.responseData.data.addressLine1,
          Validators.required,
        ],
        city: [
          this.responseData.data.city,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
          ],
        ],
        state: [
          this.responseData.data.state,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
          ],
        ],
        zipCode: [
          this.responseData.data.zipCode,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.ZIPCODE),
            Validators.minLength(5),
            Validators.maxLength(6),
          ],
        ],
        email: [
          this.responseData.data.email,
          [Validators.pattern(AppRegExConstants.EMAIL)],
        ],
        userProfileInfoId: [
          this.responseData.data.userProfileInfoId,
          Validators.required,
        ],
        isFlag: [this.isFlag],
      });

      this.intlObj.setNumber(this.responseData.data.contact);
      //HB-426 Hevisa Patel 28-01-2021 Start
      $("#phoneInput").focus();
      this.myInput.nativeElement.blur();
      this.resetFlag = true;
      //HB-426 Hevisa Patel 28-01-2021 End
    }

    if (!this.isMCLoggedIn) {
      this.userEditForm.controls["addressForCommunication"].setValidators([
        Validators.required,
      ]);
      this.userEditForm.controls[
        "addressForCommunication"
      ].updateValueAndValidity();
    }
  }

  fillMobileNumber(event: any) {
    // console.log(event.target.value);
  }

  // telInputObject(obj) {
  //   this.intlObj = obj;
  //   console.log("-------"+JSON.stringify(obj));
  //   this.flag = true;
  //   this.countryCode = obj.dialCode;
  //  // obj.setNumber(this.responseData.data.contact);
  // }

  // onCountryChange(country: any) {
  //   console.log("======="+country.dialCode);
  //   this.countryCode = country.dialCode;
  //   if(this.flag)
  //   this.intlObj.setNumber('');
  //   else
  //   this.flag = true;
  // }

  telInputObject(obj) {
    this.intlObj = obj;
    // console.log("-------" + JSON.stringify(obj));
    this.flag = true;
    this.countryCode = obj.dialCode;
    // obj.setNumber(this.responseData.data.contact);
  }

  onCountryChange(country: any) {
    // console.log("=======" + country.dialCode);
    this.countryCode = country.dialCode;
    if (this.flag) this.intlObj.setNumber("");
    else this.flag = true;
  }

  hasError(obj) {
    console.log(this.isError);

    this.isError = obj;
    // console.log('hasError: ', obj);
  }

  getNumber(obj) {
    // console.log("*******" + JSON.stringify(obj));
  }

  test(event: any) {
    // console.log("----ttttt");
  }

  checkForError(obj) {
    this.resetFlag = false; //HB-426 Hevisa Patel 28-01-2021
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
        return true;
      else return false;
    }
    return true;
  }
  ///ADDED BY AISHWARYAA FOR HB-604 30/12/2021
  changePassword() {
    this.router.navigate(
      [
        "change-password",
        JSON.parse(localStorage.getItem(ApplicationConstants.USER_PORTAL))[0][
          ApplicationConstants.USER_PROFILE_INFO_ID_KEY
        ],
      ],
      { skipLocationChange: true }
    );
  }
  /////CODE END
}
