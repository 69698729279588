import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppResponse } from 'src/app/model/AppResponse';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) {
  }

  signIn(url: string, credentials: any): Observable<AppResponse> {
    return this.http.post<AppResponse>(url, credentials, );
  }

  signUp(url: string, info: any): Observable<string> {
    return this.http.post<string>(url, info);
  }
}
