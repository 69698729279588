import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TokenStorageService } from 'projects/user-portal/src/app/auth-token/services/token-storage.service';
import { Location } from '@angular/common';
import { ApplicationConstants } from 'projects/user-portal/src/app/utils/ApplicationConstants';
import { Router } from '@angular/router';
import { CommonService } from 'projects/user-portal/src/app/utils/common-service';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from 'projects/user-portal/src/app/utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
declare let $: any;

@Component({
  selector: 'app-bm-nav',
  templateUrl: './bm-nav.component.html',
  styleUrls: ['./bm-nav.component.css']
})
export class BmNavComponent implements OnInit {

  dashboardUrl : string;

  @Output() messageEvent = new EventEmitter();
  invoice: boolean;
  constructor(private commonService: CommonService, private httpService: HttpTransactionService, private location: Location, public tokenService: TokenStorageService, private router: Router) { }
    userName: string;
    isBMLoggedIn:string;
    isArcLoggedIn:string;
    isGULoggedIn:string;
    roleId : String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    resData: AppResponse;
    get_list_of_cr_by_arc: String;
    list_all_mr: String;
    mr_list_of_external_user: String;
    combine_mr_for_bm_and_ext_user= false;
    get_list_of_document_by_bm: String;
    get_list_of_document_by_group: String;
    combine_bm_shared_doc= false;
    notification_list_by_bm: String;
    event_list: String;
    view_faq_user: String;
    get_due_report: String;
    get_request_report: String;
    get_home_activity_report: String;
    combine_report = false;
    gu_home: String;
    bm_dashboard: String;
    arc_dashboard: String;
    mc_dashboard: String;
    add_external_bm : Boolean;
    initial:String;

  ///////////////////////////
  static:boolean=true;
  crstate:boolean=false;
  sdstate:boolean=false;
  nostate:boolean=false;
  nostatic:boolean=false;
  public invoiceopen:boolean=false;
  public sideNavState: boolean = false;
  public subCrOpen: boolean=false;
  public subSdOpen:boolean=false;
  public subNotOpen:boolean=false;
  public subopen:boolean=false;
  public resview:boolean=false;
  rulerSizeClose:number = 180;
  rulerSizeOpen:number= 230;
  public profileopen:boolean=false;
//////////////////////////
 nshover:boolean=false;
 mrstate:boolean=false;
 in:boolean=false;
 bmsdstate:boolean=false;
 reportstate:boolean=false;
 subMrOpen:boolean=false;
 subreportOpen:boolean=false;

  // maginSizeClose:number = 0;
  // marginSizeOpen:number= 110;
///////////////////////////
  sendTitle(title: string) {
    this.messageEvent.emit(title);
  }

//////////////////////////////////////////////
isStatic(){
  this.static=!this.static
  this.nostatic=!this.nostatic

}
togglecr(){
  this.crstate=!this.crstate
}
togglesd(){
  this.sdstate=!this.sdstate
}
toggleno(){
  this.nostate=!this.nostate
}
//////////////////////////////////////////

sinenavOpen() {
  this.sideNavState = !this.sideNavState
  // console.log(this.sideNavState)
  this.profileopen=this.profileopen
}
subComplainopen(){
  this.subCrOpen=!this.subCrOpen
}
subComplainclose(){
  this.subCrOpen=this.sideNavState
}
subreportopen(){
  this.subreportOpen=!this.subreportOpen
}
subreportclose(){
  this.subreportOpen=this.sideNavState
}
submainopen(){
  this.subMrOpen=!this.subMrOpen
}


invocieopenopen(){
  this.invoiceopen=!this.invoiceopen
}
invoiceopenclose(){
  this.invoiceopen=this.sideNavState
}





submainclose(){
  this.subMrOpen=this.sideNavState
}
subSdopen(){
  this.subSdOpen=!this.subSdOpen
  // console.log( this.subSdOpen)
}
subSdClose(){
  this.subSdOpen=this.sideNavState
  // console.log( this.subSdOpen)
}
subNotopen(){
    this.subNotOpen=!this.subNotOpen
    // console.log(this.subNotOpen)
}
subNotclose(){
  this.subNotOpen=this.sideNavState
}

toggleprofile(){
this.profileopen=!this.profileopen
// console.log(this.profileopen)
}

togglemr(){
  this.mrstate=!this.mrstate
}

togglein(){
  this.in=!this.in
}

togglebmsd(){
  this.bmsdstate=!this.bmsdstate
}
togglereport(){
this.reportstate=!this.reportstate
}


/////////////////////////////////////////

  ngOnInit() {

    this.isBMLoggedIn= localStorage.getItem("isBMLoggedIn");
    this.isArcLoggedIn= localStorage.getItem("isArcLoggedIn");
    this.isGULoggedIn= localStorage.getItem("isGULoggedIn");


    // console.log("isBMLoggedIn"+ this.isBMLoggedIn);
    // console.log("isArcLoggedIn"+ this.isArcLoggedIn);
    // console.log("isGULoggedIn"+ this.isGULoggedIn);



    // console.log(localStorage.getItem("isArcLoggedin"))

    this.dashboardUrl = localStorage.getItem(ApplicationConstants.DASHBOARD_URL_LABEL);
    // console.log("dashboard:"+this.dashboardUrl);
    this.userName = this.tokenService.getuserName();
    $(document).ready(() => {

      if ($(window).width() < 521) {
        $('.sidebar').toggleClass('toggled');
          $('.sidebar .collapse').collapse('hide');
      }


      $(window).resize(() => {
        if ($(window).width() < 521) {
          $('.sidebar').toggleClass('toggled');
          $('.sidebar .collapse').collapse('hide');
        }
      });


      $('#sidebarToggle, #sidebarToggleTop').on('click', () => {
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
        if ($('.sidebar').hasClass('toggled')) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      $(window).resize(() => {
        if ($(window).width() < 768) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      // // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
      $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
        if ($(window).width() > 768) {
          // tslint:disable-next-line: one-variable-per-declaration
          var e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail;
          this.scrollTop += (delta < 0 ? 1 : -1) * 30;
          e.preventDefault();
        }
      });

      // Scroll to top button appear
      $(document).on('scroll', function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
      });

      $('.nav-link.active-link').closest( 'li.parent' ).addClass('active');

      var route = this.router.url;
      var splitRoute = route.split('/');
      if(splitRoute[1] === 'change-password'){
        $('#cpId').addClass('active');
      }
    });
    this.getShortName()
    $(function() {
      $('#accordionSidebar > li').click(function(e) {
          e.stopPropagation();
          var $el = $('ul',this);
          $('#accordionSidebar > li > ul').not($el).slideUp();
          $el.stop(true, true).slideToggle(400);
      });
          $('#accordionSidebar > li > ul > li').click(function(e) {
          e.stopImmediatePropagation();
      });
  });
  }

  getShortName() {
    this.initial=this.userName.split(' ').map(n => n[0]).join('');
    // console.log(this.initial);
    }

  ngAfterViewInit(): void {

    if(localStorage.getItem("isGULoggedIn") == "true"){
      this.roleId = '7';
      this.isGULoggedIn = 'true';
    } else if(localStorage.getItem("isBMLoggedIn") == "true"){
      this.roleId = '3';
      this.isBMLoggedIn = 'true';
    } else if(localStorage.getItem("isArcLoggedIn") == "true"){
      this.roleId = '4';
      this.isArcLoggedIn = 'true';
    }  else if(localStorage.getItem("isHOLoggedIn") == "true" ){
      this.roleId = '6';
      this.isHOLoggedIn = 'true';
    } else if(localStorage.getItem("isMCLoggedIn") == "true" ){
      this.roleId = '5';
      this.isMCLoggedIn = 'true';
    }

     this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_TOKEN_BY_ROLE + '/' + this.roleId).subscribe(data => {
        this.resData = new AppResponse(data);
        if (this.resData.status == 200) {

          this.get_list_of_cr_by_arc = this.resData.data.includes('GET_LIST_OF_COMPLIANCE_REQUEST_BY_ARC');
          this.list_all_mr = this.resData.data.includes('LISTALL_MR');
          this.mr_list_of_external_user = this.resData.data.includes('MAINTENANC_REQUESTS_LIST_OF_EXTERNAL_USER');

          if(((this.isMCLoggedIn === 'true' && this.list_all_mr) || (this.isBMLoggedIn === 'true' && this.list_all_mr) || (this.isArcLoggedIn === 'true' && this.list_all_mr)) || (this.isBMLoggedIn && this.mr_list_of_external_user)){
		this.combine_mr_for_bm_and_ext_user = true;
          }

          this.get_list_of_document_by_bm = this.resData.data.includes('GET_LIST_OF_DOCUMENT_BY_BM');
          this.get_list_of_document_by_group = this.resData.data.includes('GET_LIST_OF_DOCUMENT_BY_GROUP');

          if(((this.isBMLoggedIn && this.get_list_of_document_by_bm) || (this.isMCLoggedIn && this.get_list_of_document_by_bm) || (this.isArcLoggedIn && this.get_list_of_document_by_bm)) || ((this.isBMLoggedIn && this.get_list_of_document_by_group) || (this.isMCLoggedIn && this.get_list_of_document_by_group) || (this.isArcLoggedIn && this.get_list_of_document_by_group))){
            this.combine_bm_shared_doc = true;
          }

          this.notification_list_by_bm = this.resData.data.includes('NOTIFICATION_LIST_BY_BM');
          this.event_list = this.resData.data.includes('EVENT_LIST');
          this.view_faq_user = this.resData.data.includes('VIEW_FAQ_USER');

          this.get_request_report = this.resData.data.includes('GET_REQUEST_REPORT');
          this.get_due_report = this.resData.data.includes('GET_DUE_REPORT');
          this.get_home_activity_report = this.resData.data.includes('GET_HOME_ACTIVITY_REPORT');

          if(((this.isBMLoggedIn && this.get_request_report) || (this.isMCLoggedIn && this.get_request_report) || (this.isArcLoggedIn && this.get_request_report)) || ((this.isBMLoggedIn && this.get_home_activity_report) || (this.isMCLoggedIn && this.get_home_activity_report) || (this.isArcLoggedIn && this.get_home_activity_report)) || ((this.isBMLoggedIn && this.get_due_report) || (this.isMCLoggedIn && this.get_due_report))){
            this.combine_report = true;
          }

          this.gu_home = this.resData.data.includes('GU_HOME');
          this.bm_dashboard = this.resData.data.includes('BM_DASHBOARD');
          this.arc_dashboard = this.resData.data.includes('ARC_DASHBOARD');
          this.mc_dashboard = this.resData.data.includes('MC_DASHBOARD');
          this.add_external_bm = this.resData.data.includes('ADD_EXTERNAL_BM');

          $(document).ready(() => {
            $('.nav-link.active-link').closest( 'li.parent' ).addClass('active');
              $('#accordionSidebar > li').click(function(e) {
                  e.stopPropagation();
                  var $el = $('ul',this);
                  $('#accordionSidebar > li > ul').not($el).slideUp();
                  $el.stop(true, true).slideToggle(400);
              });
                  $('#accordionSidebar > li > ul > li').click(function(e) {
                  e.stopImmediatePropagation();
              });
          });

        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  changePassword() {
    this.router.navigate(['change-password', this.tokenService.getuserProfileId()]);
  }

  doLogout() {
    localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
    this.tokenService.clearSession();
    this.router.navigate(['login']);
  }

  removeData(){
    localStorage.removeItem("reportFormGroup");
    localStorage.removeItem("requestReportData");
  }

}
