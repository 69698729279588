import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ModuleWithProviders } from "@angular/core";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { AuthGuard } from './auth-token/AuthGuard ';
import { httpInterceptorProviders } from './auth-token/auth-interceptor';
import { ApplicationConstants } from './utils/ApplicationConstants';
import { HeaderComponent } from './shared/pages/header/header.component';
import { FooterComponent } from './shared/pages/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { RequestForAccessComponent } from './components/request-for-access/request-for-access.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { environment } from '../environments/environment';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AddComplianceRequestComponent } from './components/add-compliance-request/add-compliance-request.component';
import { ComplianceListComponent } from './components/compliance-list/compliance-list.component';
import { AddMrComponent } from './components/add-mr/add-mr.component';
import { MrListComponent } from './components/mr-list/mr-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewComplianceRequestComponent } from './components/view-compliance-request/view-compliance-request.component';
import { MrViewComponent } from './components/mr-view/mr-view.component';
import { ReviewCrComponent } from './components/review-cr/review-cr.component';
import { MultiUserDashboardComponent } from './components/multi-user-dashboard/multi-user-dashboard.component';
import { BmDashboardComponent } from './components/bm-dashboard/bm-dashboard.component';
import { ArcDashboardComponent } from './components/arc-dashboard/arc-dashboard.component';
import { McDashboardComponent } from './components/mc-dashboard/mc-dashboard.component';
import { GroupMaintenanceComponent } from './components/group-maintenance/group-maintenance.component';
import { GroupMaintenanceViewComponent } from './components/group-maintenance-view/group-maintenance-view.component';
import { CrListByHoComponent } from './components/cr-list-by-ho/cr-list-by-ho.component';
import { AddCrByhoComponent } from './components/add-cr-byho/add-cr-byho.component';
import { GeneralCommentMrComponent } from './components/general-comment-mr/general-comment-mr.component';
import { CrlistAgainstHoComponent } from './components/crlist-against-ho/crlist-against-ho.component';
import { CrreviewByhoComponent } from './components/crreview-byho/crreview-byho.component';
import { CrlistByARCComponent } from './components/crlist-by-arc/crlist-by-arc.component';
import { CrviewByARCComponent } from './components/crview-by-arc/crview-by-arc.component';
import { AddRenterInformationComponent } from './components/add-renter-information/add-renter-information.component';
import { EditRenterInformationComponent } from './components/edit-renter-information/edit-renter-information.component';
import { RenterInfoViewComponent } from './components/renter-info-view/renter-info-view.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RenterInformationComponent } from './components/renter-information-list/renter-information.component';
import { EditCrComponent } from './components/edit-cr/edit-cr.component';
import { EditCrByArcComponent } from './components/edit-cr-by-arc/edit-cr-by-arc.component';
import { EditMRComponent } from './components/edit-mr/edit-mr.component';
import { GeneralCommentOfSDComponent } from './components/general-comment-of-sd/general-comment-of-sd.component';
import { SdViewComponent } from './components/sd-view/sd-view.component';
import { AddSharedDocumentComponent } from './components/add-shared-document/add-shared-document.component';
import { ListSharedDocumentComponent } from './components/list-shared-document/list-shared-document.component';
import { ReviewSdComponent } from './components/review-sd/review-sd.component';
import { ListSharedDocumentArchivedComponent } from './components/list-shared-document-archived/list-shared-document-archived.component';
import { ListSharedDocumentUserComponent } from './components/list-shared-document-user/list-shared-document-user.component';
import { ListSharedDocumentArchivedUserComponent } from './components/list-shared-document-archived-user/list-shared-document-archived-user.component';
import { UnhideSharedDocumentComponent } from './components/unhide-shared-document/unhide-shared-document.component';
import { EditSdComponent } from './components/edit-sd/edit-sd.component';
import { AddNotificationByBmComponent } from './components/add-notification-by-bm/add-notification-by-bm.component';
import { BmNotificationListComponent } from './components/bm-notification-list/bm-notification-list.component';
import { BmArcheivedNotificatioListComponent } from './components/bm-archeived-notificatio-list/bm-archeived-notificatio-list.component';
import { EditNotificationByBmComponent } from './components/edit-notification-by-bm/edit-notification-by-bm.component';
import { ViewNotificationComponent } from './components/view-notification/view-notification.component';
import { ViewArchivedNotificationComponent } from './components/view-archived-notification/view-archived-notification.component';

import { AddNotificationByArcComponent } from './components/add-notification-by-arc/add-notification-by-arc.component';
import { ArcNotificationListComponent } from './components/arc-notification-list/arc-notification-list.component';
import { ArcArcheivedNotificatioListComponent } from './components/arc-archeived-notificatio-list/arc-archeived-notificatio-list.component';
import { EditNotificationByArcComponent } from './components/edit-notification-by-arc/edit-notification-by-arc.component';

import { HoNotificationListComponent } from './components/ho-notification-list/ho-notification-list.component';
import { HoArchivedNotificationListComponent } from './components/ho-archived-notification-list/ho-archived-notification-list.component';
import { UnhideNotificationByBmComponent } from './components/unhide-notification-by-bm/unhide-notification-by-bm.component';
import { FaqViewUserComponent } from './components/faq-view-user/faq-view-user.component';
import { RequestReportComponent } from './components/request-report/request-report.component';
import { AddEventByBmComponent } from './components/add-event-by-bm/add-event-by-bm.component';
import { EventListForBmComponent } from './components/event-list-for-bm/event-list-for-bm.component';
import { AddEventByArcComponent } from './components/add-event-by-arc/add-event-by-arc.component';
import { EventListForArcComponent } from './components/event-list-for-arc/event-list-for-arc.component';
import { ViewEventByArcComponent } from './components/view-event-by-arc/view-event-by-arc.component';
import { ViewEventByBMComponent } from './components/view-event-by-bm/view-event-by-bm.component';
import { HomeActivityReportComponent } from './components/home-activity-report/home-activity-report.component';
import { EditEventByBmComponent } from './components/edit-event-by-bm/edit-event-by-bm.component';
import { EditEventByArcComponent } from './components/edit-event-by-arc/edit-event-by-arc.component';
import { DueReportComponent } from './components/due-report/due-report.component';
import { GuDashboardComponent } from './components/gu-dashboard/gu-dashboard.component';
import { AddMrByBmComponent } from './components/add-mr-by-bm/add-mr-by-bm.component';
import { MrListCreatedByBmComponent } from './components/mr-list-created-by-bm/mr-list-created-by-bm.component';
import { MrViewByBmAndGuComponent } from './components/mr-view-by-bm-and-gu/mr-view-by-bm-and-gu.component';
import { MrEditByBmComponent } from './components/mr-edit-by-bm/mr-edit-by-bm.component';
import { MrViewByGUComponent } from './components/mr-view-by-gu/mr-view-by-gu.component';
import { MrListForGUComponent } from './components/mr-list-for-gu/mr-list-for-gu.component';
import { NotificationListForGUComponent } from './components/notification-list-for-gu/notification-list-for-gu.component';
import { UserDueComponent } from './components/user-due/user-due.component';
import { RequestReportViewOfCrComponent } from './components/request-report-view-0f-cr/request-report-view-0f-cr.component';
import { RequestReportViewOfMrComponent } from './components/request-report-view-of-mr/request-report-view-of-mr.component';
import { AddExternalBMComponent } from './components/add-external-bm/add-external-bm.component';
import { GeneralCommentForMrByBmComponent } from './components/general-comment-for-mr-by-bm/general-comment-for-mr-by-bm.component';
import { GeneralCommentForMrByExternalUserComponent } from './components/general-comment-for-mr-by-external-user/general-comment-for-mr-by-external-user.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ListReimburseDuesUserComponent } from './components/list-reimburse-dues-user/list-reimburse-dues-user.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { DebounceDirective } from './debounce.directive';
import { HomeActivityReportViewCrComponent } from './components/home-activity-report-view-cr/home-activity-report-view-cr.component';
import { HomeActivityReportViewMrComponent } from './components/home-activity-report-view-mr/home-activity-report-view-mr.component';
import { ReviewArchivedSdComponent } from './components/review-archived-sd/review-archived-sd.component';
import { HoNavComponent } from './shared/pages/nav-bars/ho-nav/ho-nav.component';
import { ArcNavComponent } from './shared/pages/nav-bars/arc-nav/arc-nav.component';
import { BmNavComponent } from './shared/pages/nav-bars/bm-nav/bm-nav.component';
import { McNavComponent } from './shared/pages/nav-bars/mc-nav/mc-nav.component';
import { GuNavComponent } from './shared/pages/nav-bars/gu-nav/gu-nav.component';
// Added by Hema shanker for HB-590 on 13/12/2021 Start
import { SdArchivedViewComponent } from './components/sd-archived-view/sd-archived-view.component';
// Added by Hema shanker for HB-590 on 13/12/2021 End
// Added by Hema shanker for HB-552 on 16/12/2021 Start
import { EditProfileGuComponent } from './components/edit-profile-gu/edit-profile-gu.component';
// added by gopal for HB-584 on 27/12/2021 start
import { DueReportViewComponent } from './components/due-report-view/due-report-view.component';
// Added by Hema shanker for HB-552 on 16/12/2021 End
//added by aishwaryaa for hb-673 01/01/2022
import { CrTemplateBmComponent } from './components/cr-template-bm/cr-template-bm.component';
import { CrTemplateBmViewComponent } from './components/cr-template-bm-view/cr-template-bm-view.component';
import { ReviewCrByBmComponent } from './components/review-cr-by-bm/review-cr-by-bm.component';
import { ReviewCrByMcComponent } from './components/review-cr-by-mc/review-cr-by-mc.component';
import { ComplianceListBmComponent } from './components/compliance-list-bm/compliance-list-bm.component';
import { ComplianceListMcComponent } from './components/compliance-list-mc/compliance-list-mc.component';
import { EventListHoComponent } from './components/event-list-ho/event-list-ho.component';
import { EventViewHoComponent } from './components/event-view-ho/event-view-ho.component';
import { McNotificationListComponent } from './components/mc-notification-list/mc-notification-list.component';
import { AddMcNotificationComponent } from './components/add-mc-notification/add-mc-notification.component';
import { ArchivedMcNotificationListComponent } from './components/archived-mc-notification-list/archived-mc-notification-list.component';
import { EditNotificationByMcComponent } from './components/edit-notification-by-mc/edit-notification-by-mc.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
 
import { UploadInvoiceByBmComponent } from './components/upload-invoice-by-bm/upload-invoice-by-bm.component';
import { InvoiceListByBmComponent } from './components/invoice-list-by-bm/invoice-list-by-bm.component';
import { InvoiceViewByBmComponent } from './components/invoice-view-by-bm/invoice-view-by-bm.component';

// added by Hema shanker for HB-988
import { BmSdListComponent } from './components/bm-sd-list/bm-sd-list.component';
import { McSdListComponent } from './components/mc-sd-list/mc-sd-list.component';
import { AddSharedDocumentbybmComponent } from './components/add-shared-documentbybm/add-shared-documentbybm.component';
import { AddSharedDocumentbymcComponent } from './components/add-shared-documentbymc/add-shared-documentbymc.component';
import { ReviewsdbymcComponent } from './components/reviewsdbymc/reviewsdbymc.component';
import { ReviewsdbybmComponent } from './components/reviewsdbybm/reviewsdbybm.component';
import { UnHideSdbybmComponent } from './components/un-hide-sdbybm/un-hide-sdbybm.component';
import { UnHideSdbymcComponent } from './components/un-hide-sdbymc/un-hide-sdbymc.component';
import { EditsdbymcComponent } from './components/editsdbymc/editsdbymc.component';
import { EditsdbybmComponent } from './components/editsdbybm/editsdbybm.component';
import { UnhideNotificationByMcComponent } from './components/unhide-notification-by-mc/unhide-notification-by-mc.component';
import { UnhideNotificationByArcComponent } from './components/unhide-notification-by-arc/unhide-notification-by-arc.component';

import { InvoiceViewComponent } from './components/invoice-view/invoice-view.component';
import { PositveNumberPipe } from './utils/positve-number.pipe';
import { BMauthGuard } from './auth-token/BMauthGuard';
import { PaymentHistoryComponent } from './components/payment-history/payment-history.component';
import { OfflinePaymentComponent } from './components/offline-payment/offline-payment.component';
import { DiscussionBoardComponent } from './components/discussion-board/discussion-board.component';
import { UserEmailVerificationComponent } from './components/user-email-verification/user-email-verification.component';

//code end

const providers = [
  HttpTransactionService,
  AuthGuard,
  BMauthGuard,
  httpInterceptorProviders,
  { provide: APP_BASE_HREF, useValue: ApplicationConstants.APP_BASE_HREF_USER_PORTAL }
]

export function tokenGetter() {
  return JSON.parse(localStorage.getItem(ApplicationConstants.USER_PORTAL))[0][ApplicationConstants.TOKEN_KEY]
}

@NgModule({
  declarations: [
    AppComponent,

    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    NotificationListComponent,
    RequestForAccessComponent,
    ChangePasswordComponent,
    EditProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AddComplianceRequestComponent,
    ComplianceListComponent,
    AddMrComponent,
    MrListComponent,
    MultiUserDashboardComponent,
    BmDashboardComponent,
    ArcDashboardComponent,
    McDashboardComponent,

    GroupMaintenanceComponent,
    GroupMaintenanceViewComponent,
    ViewComplianceRequestComponent,
    MrViewComponent,
    ReviewCrComponent,
    CrListByHoComponent,
    AddCrByhoComponent,
    GeneralCommentMrComponent,
    CrlistAgainstHoComponent,
    CrreviewByhoComponent,
    CrlistByARCComponent,
    CrviewByARCComponent,
    AddRenterInformationComponent,
    EditRenterInformationComponent,
    RenterInfoViewComponent,
    RenterInformationComponent,
    EditCrComponent,
    EditCrByArcComponent,
    EditMRComponent,
    GeneralCommentOfSDComponent,
    SdViewComponent,
    AddSharedDocumentComponent,
    ListSharedDocumentComponent,
    ReviewSdComponent,
    ListSharedDocumentArchivedComponent,
    ListSharedDocumentUserComponent,
    ListSharedDocumentArchivedUserComponent,
    UnhideSharedDocumentComponent,
    EditSdComponent,
    FaqViewUserComponent,
    RequestReportComponent,
    AddNotificationByBmComponent,
    BmNotificationListComponent,
    EditNotificationByBmComponent,
    BmArcheivedNotificatioListComponent,
    ViewNotificationComponent,
    ViewArchivedNotificationComponent,

    AddNotificationByArcComponent,
    ArcNotificationListComponent,
    EditNotificationByArcComponent,
    ArcArcheivedNotificatioListComponent,

    HoNotificationListComponent,
    HoArchivedNotificationListComponent,
    UnhideNotificationByBmComponent,
    FaqViewUserComponent,
    AddEventByBmComponent,
    EventListForBmComponent,
    AddEventByArcComponent,
    EventListForArcComponent,
    ViewEventByArcComponent,
    ViewEventByBMComponent,
    HomeActivityReportComponent,
    EditEventByBmComponent,
    EditEventByArcComponent,
    DueReportComponent,
    GuDashboardComponent,
    AddMrByBmComponent,
    MrListCreatedByBmComponent,
    MrViewByBmAndGuComponent,
    MrEditByBmComponent,
    MrViewByGUComponent,
    MrListForGUComponent,
    NotificationListForGUComponent,
    UserDueComponent,
    RequestReportViewOfCrComponent,
    RequestReportViewOfMrComponent,
    AddExternalBMComponent,
    GeneralCommentForMrByBmComponent,
    GeneralCommentForMrByExternalUserComponent,
    ListReimburseDuesUserComponent,
    DebounceDirective,
    HomeActivityReportViewCrComponent,
    HomeActivityReportViewMrComponent,
    ReviewArchivedSdComponent,
    HoNavComponent,
    ArcNavComponent,
    BmNavComponent,
    McNavComponent,
    GuNavComponent,
    // Added by Hema shanker for HB-590 on 13/12/2021 Start
    SdArchivedViewComponent,
    // this for B-552
    EditProfileGuComponent,
    // added by gopal for HB-584 on 27/12/2021 start
    DueReportViewComponent,
    // Added by Hema shanker for HB-590 on 13/12/2021 End
    //added by aishwaryaa for hb-673 01/01/2022
    CrTemplateBmComponent,
    CrTemplateBmViewComponent,
    ReviewCrByBmComponent,
    ReviewCrByMcComponent,
    ComplianceListBmComponent,
    ComplianceListMcComponent,
    EventListHoComponent,
    EventViewHoComponent,
    McNotificationListComponent,
    AddMcNotificationComponent,
    ArchivedMcNotificationListComponent,
    EditNotificationByMcComponent,
     
    UploadInvoiceByBmComponent,
    InvoiceListByBmComponent,
    InvoiceViewByBmComponent,

    // added by Hema shanker for HB-988
    BmSdListComponent,
    McSdListComponent,
    AddSharedDocumentbybmComponent,
    AddSharedDocumentbymcComponent,
    ReviewsdbymcComponent,
    ReviewsdbybmComponent,
    UnHideSdbybmComponent,
    UnHideSdbymcComponent,
    EditsdbymcComponent,
    EditsdbybmComponent,
    UnhideNotificationByMcComponent,
    UnhideNotificationByArcComponent,

    InvoiceViewComponent,

    PositveNumberPipe,

    PaymentHistoryComponent,

    OfflinePaymentComponent,

    DiscussionBoardComponent,

    UserEmailVerificationComponent,

    //code end 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.WHITE_LISTED_DOMAINS],
        blacklistedRoutes: [environment.BLACK_LISTED_ROUTERS]
      }
    }),
    MatAutocompleteModule,
    MatInputModule,
    NgMultiSelectDropDownModule,
    ChartsModule,
    NgbModule,
    CKEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxSpinnerModule,
    Ng2TelInputModule


  ],

  providers: [providers, DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }

@NgModule({})
export class UserPortalModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}
