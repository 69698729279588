import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { AppResponse } from "src/app/model/AppResponse";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { HttpClient } from "@angular/common/http";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";
import { environment } from "projects/user-portal/src/environments/environment";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import * as FileSaver from "file-saver";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";

import * as $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { CommonService } from "../../utils/common-service";
import { NgxSpinnerService } from "ngx-spinner";
import { JwtHelperService } from "@auth0/angular-jwt";
const helper = new JwtHelperService();

@Component({
  selector: "app-invoice-view",
  templateUrl: "./invoice-view.component.html",
  styleUrls: ["./invoice-view.component.css"],
})
export class InvoiceViewComponent implements OnInit {
  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end
  invoiceid: any;
  resData: AppResponse;
  resDatas: AppResponse;

  reviewInvoice: FormGroup;
  showsuccess: boolean = false;
  showdanger: boolean = false;
  responsemesage: string;
  hide: boolean;
  private maintenanceRequestsUpdate =
    environment.BASE_URL + ApplicationURIConstants.REVIEW_UPDATE;
  private maintenanceReview =
    environment.BASE_URL + ApplicationURIConstants.MR_LIST;
  private reviewByBM =
    environment.BASE_URL + ApplicationURIConstants.REVIEW_BY_BM;
  detailedOriginalDoc: any;
  map: any;
  fileNameContentType: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenStorageService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    datePipe: DatePipe
  ) {}

  ngOnInit() {
    // this.spinner.show();
    // added by gopal for HB-611 start on 10/12/2021
    if (localStorage.getItem("isGULoggedIn") == "true") {
      this.isGULoggedIn = "true";
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      this.isBMLoggedIn = "true";
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      this.isArcLoggedIn = "true";
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      this.isHOLoggedIn = "true";
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      this.isMCLoggedIn = "true";
    }
    // added by goapl for HB-611 end

    // console.log("ARC:"+this.isArcLoggedIn);
    // console.log("BM"+this.isBMLoggedIn);

    this.activatedRoute.params.subscribe((params) => {
      this.invoiceid = params.id;
    });

    this.reviewInvoice = this.formBuilder.group({
      invoiceId: [this.invoiceid],
      status: ["", Validators.required],
      description: ["", Validators.required],
    });

    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.INVOICE_REVIEW +
          this.invoiceid
      )
      .subscribe((data) => {
        console.log(data);

        this.resData = new AppResponse(data);
        console.log(this.resData.data.status);

        setTimeout(() => {
          $("#notificationListDatatable").DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            order: [],
            language: {
              zeroRecords: "No records found.",
            },
            lengthMenu: [
              [5, 10, 15, 20, -1],
              [5, 10, 15, 20, "All"],
            ],
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [
              {
                targets: 3,
                orderable: false,
              },
              {
                targets: 1,
                width: "30%",
              },
            ],
          });
        }, 200);
        // if(this.resData.data.status == 'PENDING'){
        //   this.reviewInvoice.

        // }

        this.reviewInvoice = this.formBuilder.group({
          invoiceId: [this.invoiceid],
          status: [this.resData.data[0].status, [Validators.required]],
          description: ["", Validators.required],
        });

        //  for(var i=0; i<this.resData.data.documentName.length; i++){
        //    this.map.set(this.resData.data.documentName[i].substr(14),this.resData.data.documentName[i]);
        //  }
        //  if(this.resData.data.originalDoc){

        //     for(let i=0; i<this.resData.data.originalDoc.length; i++) {
        //       this.detailedOriginalDoc.push({
        //         "docUploadedBy": this.resData.data.docUploadedBy[i],
        //         "originalDoc": this.resData.data.originalDoc[i],
        //         "createdOn" : this.resData.data.createdOn[i]
        //       });
        //     }
        //   }
      });
  }

  // statuschange(){
  //   console.log(this.reviewInvoice.controls['status'].value)
  //   if(this.reviewInvoice.controls['status'].value == "REJECTED"){
  //     this.reviewInvoice.controls['reviewComment'].setValidators(Validators.required)
  //   }else {
  //     this.reviewInvoice.controls['reviewComment'].clearValidators();
  //   }
  // }
  onSubmit() {
    this.spinner.show();
    console.log(this.reviewInvoice.value);

    this.httpService
      .save(
        this.reviewInvoice.value,
        environment.BASE_URL + ApplicationURIConstants.REVIEW_INVOICE
      )
      .subscribe((res) => {
        console.log(res);

        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          if (res.status == 200) {
            console.log("successsssss");

            this.responsemesage = res.message;
            this.showsuccess = true;
            this.spinner.hide();
          } else if (res.status === 401) {
            //401 token related issue
            this.spinner.hide();
            this.router.navigate(["login"]);
          } else if (res.status == 403) {
            this.spinner.hide();
            this.responsemesage = res.errors;
            this.showdanger = true;
            //this.router.navigate(['home']);
          } else {
            this.spinner.hide();
            this.responsemesage = res.errors;
            this.showdanger = true;
          }
        }
      });
  }

  getDocument(documentName: string, originalname: string) {
    console.log(documentName);
    console.log(
      environment.BASE_URL +
        ApplicationURIConstants.GET_INVOICE_FILE +
        "?documentName=" +
        documentName
    );

    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.GET_INVOICE_FILE +
          "?documentName=" +
          documentName
      )
      .subscribe((data) => {
        console.log(data);

        this.resDatas = new AppResponse(data);

        //Start the code for Base64 to byte[]
        const byteCharacters = atob(this.resDatas.data);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(this.resDatas.data);

        const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(this.resDatas.data, this.fileNameContentType);
        //End the code for Base64 to byte[]

        //Save the file
        FileSaver.saveAs(blob, originalname);
      });
  }

  //  ngAfterViewInit(): void {
  //   // tslint:disable-next-line: max-line-length
  //   this.httpService.save(this.reviewInvoice.value,environment.BASE_URL+ApplicationURIConstants.REVIEW_INVOICE).subscribe(res=>{
  //     console.log(res);

  //     this.resData[1] = new AppResponse(res);

  //     if (res.status === 401) {
  //       //401 token related issue
  //       this.tokenService.clearSession();
  //       this.router.navigate(['login']);
  //     } else if (res.status === 403) {
  //       //403 URL not accessible
  //       this.router.navigate(['home']);
  //     }
  //   });

  // }
}
