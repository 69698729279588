import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { DatePipe, formatDate } from '@angular/common';


@Component({
  selector: 'app-event-list-for-arc',
  templateUrl: './event-list-for-arc.component.html',
  styleUrls: ['./event-list-for-arc.component.css']
})
export class EventListForArcComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  userProfileInfoId:string;
  currentDateAndTime= new Date();
  curDate:string;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
    // added by aishwaryaa for HB-457 start
  eventDateAndTime:any;
  presentDate:any;
  errors:any="unable to cancel after the event ";
  //end

  // tslint:disable-next-line: max-line-length
  constructor(private datePipe: DatePipe,private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private formBuilder: FormBuilder, private commonService: CommonService) {
   
  }

  ngOnInit() {
    
       // added by gopal for HB-611 start on 10/12/2021
       if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end
    this.userProfileInfoId = this.tokenService.getuserProfileId();
    // console.log("userProfileInfoId" + this.userProfileInfoId);
    this.curDate=this.datePipe.transform(this.currentDateAndTime,"yyyy-MM-dd");
    // console.log("this.curDate"+this.curDate);
  }
  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    $('#eventListDataTableForARC').dataTable().fnDestroy(); 

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.EVENT_LIST_BY_ARC).subscribe(res => {
      this.resData = new AppResponse(res);
      setTimeout(() => {
        $('#eventListDataTableForARC').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
           // Added by Hema shanker HB-675 on 25/11/2021 Start
           stateSave: true,
           // Added by Hema shanker HB-675 on 25/11/2021 End
          // retrieve: true,
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
         // Modified by Hema shanker HB-675 on 25/11/2021 Start
         lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
         // Modified by Hema shanker HB-675 on 25/11/2021 End
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 5,
            orderable: false
          },
        ]
        });
      }, 200);
      if (res.status === 200) {
        // console.log("jdfnjdfnj");
        // console.log("resdata:"+JSON.stringify(this.resData));

      }
        else if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['dashboardarc']);
      }
    });
  }

  private deleteEvent = environment.BASE_URL + ApplicationURIConstants.DELETE_EVENT_BY_ARC;
  public deleteEventByARC(data: any) {
    // console.log("DATA:" + data);
    if (confirm("Are you sure you want to delete this event ?")) {

      this.httpService.update(data, this.deleteEvent + '/' + data)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          if (this.resData.status == 200) {
            //this.submitted = false;
            $('.close').click();
            // console.log("MSG:" + this.resData.message)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.resData.message);

            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);

          } else {
            // console.log(this.resData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
  }

  private cancelEvent = environment.BASE_URL + ApplicationURIConstants.CANCEL_EVENT_BY_ARC;
  public cancelEventByARC(data: any, dateandtime:any) {
    // console.log("DATA:" + data);
/////////////////////////////////////// Code added by Aishwaryaa HB-457 start ///////////////////////////////
    this.eventDateAndTime =formatDate(dateandtime, 'MM-dd-yyy hh:mm:ss:a', 'en_US');
    this.presentDate = formatDate(this.currentDateAndTime, 'MM-dd-yyy hh:mm:ss:a', 'en_US');

    if(this.eventDateAndTime>this.presentDate){
      if (confirm("Are you sure you want to cancel this event ?")) {

        this.httpService.update(data, this.cancelEvent + '/' + data)
          .subscribe((res) => {
            this.resData = new AppResponse(res);
            if (this.resData.status == 200) {
              //this.submitted = false;
              $('.close').click();
              // console.log("MSG:" + this.resData.message)
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.resData.message);
  
              setTimeout(() => {
                this.ngAfterViewInit();
              }, 3000);
  
            } else {
              // console.log(this.resData.errors)
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          });
      }
     

    }
    else{
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.errors}`);
    }

    ////////////////////////////////////////////////////////// code end //////////////////////////////////////
   
  }

}