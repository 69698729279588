import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/user-portal/src/environments/environment';

@Component({
  selector: 'app-invoice-list-by-bm',
  templateUrl: './invoice-list-by-bm.component.html',
  styleUrls: ['./invoice-list-by-bm.component.css']
})
export class InvoiceListByBmComponent implements OnInit {

   dataTable: any;
  resData: AppResponse;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  // tslint:disable-next-line: max-line-length
  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) {
  }

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {

            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {

            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {

            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {

            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
  }
  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.INVOICE_LIST_BY_BM).subscribe(res => {
      console.log(res);

      this.resData = new AppResponse(res);
      setTimeout(() => {
        $('#notificationListDatatable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 2,
            orderable: false
          },
          {
            targets: 1,
            width: '30%'
          }
        ]
        });
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      }
      else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

  }


}
