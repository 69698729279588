import { Component, OnInit ,ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/user-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from 'projects/user-portal/src/app/auth-token/services/token-storage.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
// import { table } from 'console';
var formData = new FormData();



@Component({
  selector: 'app-discussion-board',
  templateUrl: './discussion-board.component.html',
  styleUrls: ['./discussion-board.component.css']
})
export class DiscussionBoardComponent implements OnInit {
 
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;

  generalCommentForm: FormGroup;
  resData: AppResponse;
  responseData:AppResponse;
  generalCommentList: any[] = [];
  parentcomment:any[]=[];
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  parentCommentId : any;
  updategeneralCommentForm: FormGroup;
  updateDiscussionForm: FormGroup;
  initial:String;
  userName: string;
  showReplyBox: boolean = false;

  token: any;
  comid: any;
  childcomment: any[]=[];
  isCommentsShown: boolean = false;
  isCardShown: boolean =false;
  isDivShow : boolean = false;
  isEditShow : boolean = false;
  spaceerr: boolean=false;
  hideedit: boolean=true;
  edithide: boolean;
  BMLoggedIn: any;
  role: string;
  nextpagelength:number=0;
  nextpagedata: any;
  nextcount: any;

  pagenumber:number=1;
  previousdisable: boolean;
  nextdisable: boolean;
  userid: any;
  childdata: string;
  userprofileinfoid: any;
  showreply: boolean;
  topic: any;
  description: any;
  isedited: any;
  child: any;
  disablearrow: boolean=true;
  fullName: any;
  lastData: any;
  

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private commonService: CommonService, private httpService: HttpTransactionService, private router: Router,private activatedRoute: ActivatedRoute,public tokenService: TokenStorageService,) { }

  ngOnInit() {

    const commentCard = $('#comment-card');
const commentsSection = $('#comments-section');
const showCommentsBtn = $('#show-comments-btn');
const commentSection = $('#comment-section');

commentCard.on('click', () => {
    commentsSection.toggle();
    commentSection.toggle();
});


 
  
        

  function getRandomValue() {
    return Math.floor(Math.random() * 256);
  }


    $(document).ready(function() {
      $('.fas fa-chevron-circle-right').click(function() {
        $(this).toggleClass('fas fa-chevron-circle-right fas fa-chevron-circle-left');
      });
    });
    

    // $(".show-comments-btn").click(function() {
    //   $(this).parent().removeClass("col-lg-12").addClass("col-lg-6");
    // });
    
  

    this.token = JSON.parse(localStorage.getItem(ApplicationConstants.USER_PORTAL));
    this.userName = this.tokenService.getuserName();
    localStorage.setItem("discussion","0")

    this.userprofileinfoid=this.token[0].AuthUserProfileId
    console.log(this.userprofileinfoid);
    
    // console.log(this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY]);

    if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
    this.activatedRoute.params.subscribe((params) => {
      this.parentCommentId = params.id;
    });


    if(this.isBMLoggedIn=="true"){
      this.role="BM"
    }else if(this.isMCLoggedIn=="true"){
      this.role="MC"
    }else if(this.isArcLoggedIn=="true"){
      this.role="ARC"
    }
       
    this.getShortName()
  
    this.generalCommentForm = this.formBuilder.group({
      topic: ["",[Validators.required]],
      description: ["",[Validators.required]],
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      parentcommentId: [],
      description: ["",[Validators.required,this.trimWhitespace]],
      role:[this.role],
    });
    

    this.updateDiscussionForm = this.formBuilder.group({
      discussionId: [],
      description: ["",[Validators.required]],
      topic: ["",[Validators.required]],
      isedited:[""]
    });

  



this.pagenumber=1;
    // console.log(localStorage.getItem("USER_PORTAL"))

    const userPortalData = localStorage.getItem("USER_PORTAL");


    const userPortalObject = JSON.parse(userPortalData);

    console.log(userPortalObject);
    

    // Now you can access specific values using their keys
    this.userid = userPortalObject[0].AuthUserProfileId;

    console.log(this.userid);

if(this.isBMLoggedIn=='true'){





  console.log("inside bm login ..................");

  
  this.httpService
  .get(
  
    environment.BASE_URL + ApplicationURIConstants.GET_BM_DISCUSSION_COMMENT+ "/" + this.nextpagelength
  )
  .subscribe((res) => {
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      // console.log(JSON.stringify(res));

      if (res.status == 200) {
        this.resData = new AppResponse(res);
        this.generalCommentList = this.resData.data;
        console.log(this.resData.data.length);
          
        if(this.resData.data.length == 0){
          this.isDivShow = true
          console.log(this.isDivShow);
          
        }
        else{
          this.isDivShow = false
          console.log(this.isDivShow);

        }
        console.table( this.generalCommentList[0]);
        this.showcomment(this.generalCommentList[0]);
        this.scrollbottom();



       
        console.log(this.resData.data + "this is resdata data");
        console.log(this.resData.data.length + "this is resData");
        console.log(this.resData.data.parentCommentId + "testing parentid");

      console.log(this.generalCommentList);
      
       
      console.log(localStorage.getItem("isBMLoggedIn"));
      
      this.BMLoggedIn=localStorage.getItem("isBMLoggedIn");
      console.log(this.BMLoggedIn);
      
      if(this.pagenumber>=2){
        this.previousdisable=true;
        console.log(this.previousdisable);
        
      }else{
        this.previousdisable=false;
        console.log(this.previousdisable);

      }

      if(this.resData.data<1){
        this.nextdisable=true;
        console.log(this.nextdisable);
      }else{
        this.nextdisable=false;
        console.log(this.nextdisable);
      }

        if(this.resData.data.length == 0){
          this.isDivShow = true
        }
        else{
          this.isDivShow = false
        }





        
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
  if(this.isBMLoggedIn=="true"){
    this.role="BM"
  }else if(this.isMCLoggedIn=="true"){
    this.role="MC"
  }else if(this.isArcLoggedIn=="true"){
    this.role="ARC"
  }
          
          this.generalCommentForm = this.formBuilder.group({
            topic: [""],
            description: [""],
            role:[this.role],
            isedited:["0"],
          });
      
       
          $(document).ready(function() {
            $('#accordion').on('shown.bs.collapse', function () {
              console.log('An accordion is opened');
            });
          
            $('#accordion').on('hidden.bs.collapse', function () {
              console.log('An accordion is closed');
            });
          });
     
  
 
}else if(this.isArcLoggedIn=='true'){
  
  console.log("inside ARc login ..................");
  
  
  this.httpService
  .get(
  
    environment.BASE_URL + ApplicationURIConstants.GET_ARC_DISCUSSION_COMMENT+ "/" + this.nextpagelength
  )
  .subscribe((res) => {
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      console.log(JSON.stringify(res));

      if (res.status == 200) {
        this.resData = new AppResponse(res);
        this.generalCommentList = this.resData.data;

        if(this.resData.data.length == 0){
          this.isDivShow = true
          console.log(this.isDivShow);
          
        }
        else{
          this.isDivShow = false
          console.log(this.isDivShow);

        }
        this.showcomment(this.generalCommentList[0]);

        this.scrollbottom();

         
        if(this.resData.data.length == 0){
          this.isDivShow = true
        }
        else{
          this.isDivShow = false
        }

        // console.table( this.resData.data);
      //   console.log(this.resData.data + "this is resdata data");
      //   console.log(this.resData.data.length + "this is resData");
      //   console.log(this.resData.data.parentCommentId + "testing parentid");nextpage
  
      // console.log(this.resData.data.createdBy);
      // console.log(JSON.stringify( this.generalCommentList));
       
      // console.log(localStorage.getItem("isBMLoggedIn"));

      // const arcdata = JSON.parse(res);

      // console.log(arcdata);

      var jsonString = JSON.stringify(res);

      var parsedObject = JSON.parse(jsonString);
      console.log(parsedObject);
      
      // var specificValue = parsedObject.key2;
      
      // console.log(specificValue);




      this.BMLoggedIn=localStorage.getItem("isBMLoggedIn");
      console.log(this.BMLoggedIn);
      
      if(this.pagenumber>=2){
        this.previousdisable=true;
        console.log(this.previousdisable);
        
      }else{
        this.previousdisable=false;
        console.log(this.previousdisable);

      }

      if(this.resData.data<1){
        this.nextdisable=true;
        console.log(this.nextdisable);
      }else{
        this.nextdisable=false;
        console.log(this.nextdisable);
      }

      
      this.resData.data.forEach(element => {
        if ( element.parentCommentId  == undefined || element.parentCommentId == null){
          console.log("inside child");
      
          this.isEditShow = true
          }
          else{
            this.isEditShow = false
          }
        
      });
        
      
        if(this.resData.data.length == 0){
          this.isDivShow = true
        }
        else{
          this.isDivShow = false
        }


        // $('#MyTable tbody').empty();
        this.parentcomment=[]
        this.childcomment=[]
        this.resData.data.forEach(element => {
        // console.table(element);
        var commentId = element.discussionId;
        this.comid=element.discussionId;
        
        if(element.parentCommentId==null){
          console.log("inside parent");
          
          this.parentcomment.push(element);
        }
        
      this.resData.data.forEach(element => {
        if ( element.parentCommentId == commentId){
          console.log("inside child");
          this.childcomment.push(element);
        }
        
      });
       
        console.log(commentId + "  " + element.parentCommentId);
        
       
        });


        console.table(this.parentcomment);
        console.table(this.childcomment);
    console.log(this.childcomment[0].parentCommentId);

    
    this.childcomment.forEach((child, index) => {
      console.log(index + " index");
      
      if (child.parentCommentId) {
        const correspondingParent = this.parentcomment.find(
          parent => parent.discussionId === child.parentCommentId
        );
        
        if (correspondingParent) {
          console.log("inside forEach");
          console.log("Parent Comment:", correspondingParent);
          console.log("Child Comment:", child);
    
          // Perform your hideedit flag comparison here
          if (correspondingParent.discussionId === child.parentCommentId) {
            this.hideedit = true;
            console.log(this.hideedit);
          } else {
            this.hideedit = false;
            console.log(this.hideedit);
          }
        }
      }
    });
    



        
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
  if(this.isBMLoggedIn=="true"){
    this.role="BM"
  }else if(this.isMCLoggedIn=="true"){
    this.role="MC"
  }else if(this.isArcLoggedIn=="true"){
    this.role="ARC"
  }
          
          this.generalCommentForm = this.formBuilder.group({
            topic: [""],
            description: [""],
            role:[this.role],
            isedited:["0"],
          });
      
       
          $(document).ready(function() {
            $('#accordion').on('shown.bs.collapse', function () {
              console.log('An accordion is opened');
            });
          
            $('#accordion').on('hidden.bs.collapse', function () {
              console.log('An accordion is closed');
            });
          });
     
  
 
}else if(this.isMCLoggedIn=='true'){
  
  console.log("inside mc login ..................");
  
  
  this.httpService
  .get(
  
    environment.BASE_URL + ApplicationURIConstants.GET_MC_DISCUSSION_COMMENT+ "/" + this.nextpagelength
  )
  .subscribe((res) => {
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      // console.log(JSON.stringify(res));

      if (res.status == 200) {
        this.resData = new AppResponse(res);
        this.generalCommentList = this.resData.data;

        if(this.resData.data.length == 0){
          this.isDivShow = true
          console.log(this.isDivShow);
          
        }
        else{
          this.isDivShow = false
          console.log(this.isDivShow);

        }
        console.log(this.generalCommentList);
        this.showcomment(this.generalCommentList[0]);


        this.scrollbottom();
         
        if(this.resData.data.length == 0){
          this.isDivShow = true
        }
        else{
          this.isDivShow = false
        }

        console.log(this.resData.data + "this is resdata data");
        console.log(this.resData.data.length + "this is resData");
        console.log(this.resData.data.parentCommentId + "testing parentid");
      
      console.log(this.resData.data.createdBy);
      
       
      console.log(localStorage.getItem("isBMLoggedIn"));
      
      this.BMLoggedIn=localStorage.getItem("isBMLoggedIn");
      console.log(this.BMLoggedIn);
      
      if(this.pagenumber>=2){
        this.previousdisable=true;
        console.log(this.previousdisable);
        
      }else{
        this.previousdisable=false;
        console.log(this.previousdisable);

      }

      if(this.resData.data<1){
        this.nextdisable=true;
        console.log(this.nextdisable);
      }else{
        this.nextdisable=false;
        console.log(this.nextdisable);
      }

      
      this.resData.data.forEach(element => {
        if ( element.parentCommentId  == undefined || element.parentCommentId == null){
          console.log("inside child");
      
          this.isEditShow = true
          }
          else{
            this.isEditShow = false
          }
        
      });
        
        
        if(this.resData.data.length == 0){
          this.isDivShow = true
        }
        else{
          this.isDivShow = false
        }


        // $('#MyTable tbody').empty();
        this.parentcomment=[]
        this.childcomment=[]
        this.resData.data.forEach(element => {
        // console.table(element);
        var commentId = element.discussionId;
        this.comid=element.discussionId;
        
        if(element.parentCommentId==null){
          console.log("inside parent");
          
          this.parentcomment.push(element);
        }
        
      this.resData.data.forEach(element => {
        if ( element.parentCommentId == commentId){
          console.log("inside child");
          this.childcomment.push(element);
        }
        
      });
       
        console.log(commentId + "  " + element.parentCommentId);
       
        
        });


        console.table(this.parentcomment);
        console.table(this.childcomment);
    console.log(this.childcomment[0].parentCommentId);

   
    
    this.childcomment.forEach((child, index) => {
      console.log(index + " index");
      
      if (child.parentCommentId) {
        const correspondingParent = this.parentcomment.find(
          parent => parent.discussionId === child.parentCommentId
        );
        
        if (correspondingParent) {
          console.log("inside forEach");
          console.log("Parent Comment:", correspondingParent);
          console.log("Child Comment:", child);
    
          // Perform your hideedit flag comparison here
          if (correspondingParent.discussionId === child.parentCommentId) {
            this.hideedit = true;
            console.log(this.hideedit);
          } else {
            this.hideedit = false;
            console.log(this.hideedit);
          }
        }
      }
    });
    



        
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
 
  if(this.isBMLoggedIn=="true"){
    this.role="BM"
  }else if(this.isMCLoggedIn=="true"){
    this.role="MC"
  }else if(this.isArcLoggedIn=="true"){
    this.role="ARC"
  }
          
          this.generalCommentForm = this.formBuilder.group({
            topic: [""],
            description: [""],
            role:[this.role],
            isedited:["0"],
          });
      
       
          $(document).ready(function() {
            $('#accordion').on('shown.bs.collapse', function () {
              console.log('An accordion is opened');
            });
          
            $('#accordion').on('hidden.bs.collapse', function () {
              console.log('An accordion is closed');
            });
          });
     
  
}
    
      
       

if(this.isBMLoggedIn=="true"){
  this.role="BM"
}else if(this.isMCLoggedIn=="true"){
  this.role="MC"
}else if(this.isArcLoggedIn=="true"){
  this.role="ARC"
}
        
        this.generalCommentForm = this.formBuilder.group({
          topic: [""],
          description: [""],
          role:[this.role],
          isedited:["0"]
        });
    
     
        $(document).ready(function() {
          $('#accordion').on('shown.bs.collapse', function () {
            console.log('An accordion is opened');
          });
        
          $('#accordion').on('hidden.bs.collapse', function () {
            console.log('An accordion is closed');
          });
        });
   


  }
  trimWhitespace(control) {
    if (control.value && control.value.trim() === '') {
      return { whitespace: true };
    }
    return null;
  }
  



scrollbottom(){
  // var scrollingDiv = $("#scroll");

  // // Scroll the div to the bottom
  // scrollingDiv.scrollTop(scrollingDiv[0].scrollHeight);
  if (this.scrollContainer) {
    const container = this.scrollContainer.nativeElement;
    container.scrollTop = container.scrollHeight;
  }
}

  showcomment(name){
   


    
this.topic=name.topic
this.description=name.description
this.parentCommentId=name.discussionId
this.isedited=name.isedited
this.fullName=name.fullName

this.updateDiscussionForm = this.formBuilder.group({
  "topic": [name.topic],
  "description": [name.description],
   "discussionId": [name.discussionId],
   "isedited":[""]

});
this.scrollbottom();

// var scrollingDiv = $("#scroll");

// // Scroll the div to the bottom
// scrollingDiv.scrollTop(scrollingDiv[0].scrollHeight);

console.log(this.parentCommentId);

    const parentData = JSON.stringify(name.discussionId);
    console.log("show comment"+parentData);
    this.httpService
  .get(
  
    environment.BASE_URL + ApplicationURIConstants.GET_CHILD_DISCUSSION_BY_PARENTID+ "/" +parentData
  )
  .subscribe((res) => {
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      // console.log(JSON.stringify(res));

      if (res.status == 200) {
        this.resData = new AppResponse(res);
        this.child = this.resData.data;
        this.scrollbottom();
        console.table( this.resData.data);
        this.lastData = this.child[this.child.length - 1];
        console.log(this.lastData);
       
      

        // this.scrollbottom();

        
 this.showreply=true;
   
          
     
      
      
    



        
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
    
  }

  // search() {
  //   this.topic = this.generalCommentList.filter(item =>
  //     item.description.toLowerCase().includes(this.searchQuery.toLowerCase())
  //   );
  // }
  
  getShortName() {
    this.initial=this.userName.split(' ').map(n => n[0]).join('');
    // console.log(this.initial);
    }


  replycomment(name:any){
    console.log(name);

    this.updategeneralCommentForm.controls['parentcommentId'].setValue(name.discussionId);
  }

  toggleComments() {
    this.isCommentsShown = !this.isCommentsShown;
  }

  gettingComments(name){
    console.log(name);
    
  }
  get f() {
    return this.generalCommentForm.controls;
  }
  get g (){
    return this.updateDiscussionForm.controls;
  }
  get h (){
    return this.updategeneralCommentForm.controls;
  }
  onSubmit() {


    console.log("inside on submit");
    
  //   if(this.generalCommentForm.controls['topic'].value.trim()=='' || this.generalCommentForm.controls['description'].value.trim()==''){
  //     this.spaceerr=true;
  //     this.commonService.showNotificationSuccess(
  //       ApplicationConstants.MSG_BOX_LABEL,
  //       ApplicationConstants.DANGER_LABLE,
  //       "Please provide Valid Topic/Description data"
  //     );
  // return;
  //     }else{
  //       this.spaceerr=false;
        
  //     }
      console.log("inside on 1232312submit");
    if(this.generalCommentForm.valid){
      console.log("inside on asdasdasdsubmit");
      this.httpService
      .save(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.ADD_DISCUSSION_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));
          console.log("inside on submit");
          if (res.status == 200) {
            console.log("statussssssssssssssssssssssssssssssssssssssssssss" + res.status);
            console.log(this.generalCommentForm.value);
            
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.SUCCESS_LABLE,
              `${this.resData.message}`
            );
            // this.resData = new AppResponse(res);
            console.log("success Inside AppResponseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
            
            // this.getComment();
            this.ngOnInit()
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });

    }
    // console.log(JSON.stringify(this.generalCommentForm.value));
   
      // console.log(JSON.stringify(this.generalCommentForm.value));

     
    
  }

  
  
  

  onUpdate() {
console.log("testttttttttttttttttttttttt");
console.log(this.updategeneralCommentForm.value);
this.updategeneralCommentForm.controls["parentcommentId"].setValue(this.parentCommentId);

console.log(this.updategeneralCommentForm.value+'..................................');


    this.httpService
    .update(
      this.updategeneralCommentForm.value,
      environment.BASE_URL + ApplicationURIConstants.REPLY_DISCUSSION_COMMENT
    )
    .subscribe((res) => {
      setTimeout(() => {
        console.log("inside delete settime");
        
        this.ngOnInit()
        
      }, 2000);
      if (res == undefined) {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          ApplicationConstants.SERVER_CONNECTION_PROBLEM
        );
      } else {
        // console.log(JSON.stringify(res));
  
        if (res.status == 200) {
          this.resData = new AppResponse(res);
          
          console.log("aishuuuuuuuuuuuuuuuuuuuuuuuuuuuuu");
  
          // this.getComment();
        } else if (res.status == 401) {
          this.router.navigate(["logout"]);
        } else {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${this.resData.errors}`
          );
        }
      }
    });

  }


  OnUpdateTopic(discussions){
    this.updateDiscussionForm.controls['topic'].value;
    this.updateDiscussionForm.controls['description'].value;
    this.updateDiscussionForm.controls['isedited'].setValue('1');

    
    // this.updateDiscussionForm.controls['discussionId'].setValue[discussions.discussionId];
console.log("inside a onUpdateTopic");
console.log("updateeeeeeeeeeeeeeeeee" + this.updateDiscussionForm.controls['topic'].value);
console.log(this.updateDiscussionForm.value);

this.httpService
    .update(
      this.updateDiscussionForm.value,
      environment.BASE_URL + ApplicationURIConstants.UPDATE_DISCUSSION_COMMENT
    )
    .subscribe((res) => {
      if (res == undefined) {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          ApplicationConstants.SERVER_CONNECTION_PROBLEM
        );
      } else {
        // console.log(JSON.stringify(res));

        if (res.status == 200) {
          // this.resData = new AppResponse(res);
          // this.getComment();

          this.ngOnInit()
          console.log(res + "testttttttttttttttttttttttttttttttttttt");
          
        } else if (res.status == 401) {
          this.router.navigate(["logout"]);
        } else {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${this.resData.errors}`
          );
        }
      }
    });


  }

  nextpage(){
    if(this.isArcLoggedIn=="true"){
      console.log("next page");
    this.nextcount=Number(localStorage.getItem("discussion"))+10

    localStorage.setItem("discussion",this.nextcount)
    this.generalCommentList=null;
    this.httpService
    .get(
      environment.BASE_URL + ApplicationURIConstants.GET_ARC_DISCUSSION_COMMENT + "/" + this.nextcount
    )
    .subscribe((res) => {
      
      setTimeout(() => {
      console.log("inside next page settime");
      
    }, 2000);
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      // console.log(JSON.stringify(res));

      if (res.status == 200) {
      console.log("INside next page");
      this.pagenumber=this.pagenumber+1;
      this.resData = new AppResponse(res);
      this.generalCommentList = this.resData.data;
      console.log(this.resData);
      
      if(this.pagenumber>=2){
        this.previousdisable=true;
        console.log(this.previousdisable);
        
      }else{
        this.previousdisable=false;
        console.log(this.previousdisable);

      }

      if(this.resData.data<1){
        this.nextdisable=true
        console.log(this.nextdisable);
      }else{
        this.nextdisable=false;
        console.log(this.nextdisable);
      }

      if(this.resData.data.length == 0){
        this.isDivShow = true
      }
      else{
        this.isDivShow = false
      }


      // $('#MyTable tbody').empty();
      this.parentcomment=[]
      this.childcomment=[]
      this.resData.data.forEach(element => {
      // console.table(element);
      var commentId = element.discussionId;
      this.comid=element.discussionId;
      
      if(element.parentCommentId==null){
        console.log("inside parent");
        
        this.parentcomment.push(element);
      }
      
    this.resData.data.forEach(element => {
      if ( element.parentCommentId == commentId){
        console.log("inside child");
        this.childcomment.push(element);
      }
      
    });
     
      console.log(commentId + "  " + element.parentCommentId);
      
      });


      console.table(this.parentcomment);
      console.table(this.childcomment);
  console.log(this.childcomment[0].parentCommentId);

  
  this.childcomment.forEach((element,index)=>{ 
    console.log(index+"index");
    
    if(element.parentCommentId ){
      console.log("insdie parentid"+element.parentCommentId);
      if(element.parentCommentId >= 1){
        console.log("insdie for each");
        
        this.hideedit=true;
        console.log(this.hideedit);
      }else{
        this.hideedit=false;
        console.log(this.hideedit);
      }
    }
  })
        // this.getComment();   
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
    }else if(this.isBMLoggedIn=="true"){
      console.log("next page");
      this.nextcount=Number(localStorage.getItem("discussion"))+10
  
      localStorage.setItem("discussion",this.nextcount)
      this.generalCommentList=null;
      this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.GET_BM_DISCUSSION_COMMENT + "/" + this.nextcount
      )
      .subscribe((res) => {
        
        setTimeout(() => {
        console.log("inside next page settime");
        
      }, 2000);
      if (res == undefined) {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          ApplicationConstants.SERVER_CONNECTION_PROBLEM
        );
      } else {
        // console.log(JSON.stringify(res));
  
        if (res.status == 200) {
        console.log("INside next page");
        this.pagenumber=this.pagenumber+1;
        this.resData = new AppResponse(res);
        this.generalCommentList = this.resData.data;
        console.log(this.resData);
        
        if(this.pagenumber>=2){
          this.previousdisable=true;
          console.log(this.previousdisable);
          
        }else{
          this.previousdisable=false;
          console.log(this.previousdisable);
  
        }
  
        if(this.resData.data<1){
          this.nextdisable=true
          console.log(this.nextdisable);
        }else{
          this.nextdisable=false;
          console.log(this.nextdisable);
        }
  
        if(this.resData.data.length == 0){
          this.isDivShow = true
        }
        else{
          this.isDivShow = false
        }
  
  
        // $('#MyTable tbody').empty();
        this.parentcomment=[]
        this.childcomment=[]
        this.resData.data.forEach(element => {
        // console.table(element);
        var commentId = element.discussionId;
        this.comid=element.discussionId;
        
        if(element.parentCommentId==null){
          console.log("inside parent");
          
          this.parentcomment.push(element);
        }
        
      this.resData.data.forEach(element => {
        if ( element.parentCommentId == commentId){
          console.log("inside child");
          this.childcomment.push(element);
        }
        
      });
       
        console.log(commentId + "  " + element.parentCommentId);
        
        });
  
  
        console.table(this.parentcomment);
        console.table(this.childcomment);
    console.log(this.childcomment[0].parentCommentId);
  
    
    this.childcomment.forEach((element,index)=>{ 
      console.log(index+"index");
      
      if(element.parentCommentId ){
        console.log("insdie parentid"+element.parentCommentId);
        if(element.parentCommentId >= 1){
          console.log("insdie for each");
          
          this.hideedit=true;
          console.log(this.hideedit);
        }else{
          this.hideedit=false;
          console.log(this.hideedit);
        }
      }
    })
          // this.getComment();   
        } else if (res.status == 401) {
          this.router.navigate(["logout"]);
        } else {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${this.resData.errors}`
          );
        }
      }
    });
    }else if(this.isMCLoggedIn=="true"){
      console.log("next page");
      this.nextcount=Number(localStorage.getItem("discussion"))+10
  
      localStorage.setItem("discussion",this.nextcount)
      this.generalCommentList=null;
      this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.GET_MC_DISCUSSION_COMMENT + "/" + this.nextcount
      )
      .subscribe((res) => {
        
        setTimeout(() => {
        console.log("inside next page settime");
        
      }, 2000);
      if (res == undefined) {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          ApplicationConstants.SERVER_CONNECTION_PROBLEM
        );
      } else {
        // console.log(JSON.stringify(res));
  
        if (res.status == 200) {
        console.log("INside next page");
        this.pagenumber=this.pagenumber+1;
        this.resData = new AppResponse(res);
        this.generalCommentList = this.resData.data;
        console.log(this.resData);
        
        if(this.pagenumber>=2){
          this.previousdisable=true;
          console.log(this.previousdisable);
          
        }else{
          this.previousdisable=false;
          console.log(this.previousdisable);
  
        }
  
        if(this.resData.data<1){
          this.nextdisable=true
          console.log(this.nextdisable);
        }else{
          this.nextdisable=false;
          console.log(this.nextdisable);
        }
  
        if(this.resData.data.length == 0){
          this.isDivShow = true
        }
        else{
          this.isDivShow = false
        }
  
  
        // $('#MyTable tbody').empty();
        this.parentcomment=[]
        this.childcomment=[]
        this.resData.data.forEach(element => {
        // console.table(element);
        var commentId = element.discussionId;
        this.comid=element.discussionId;
        
        if(element.parentCommentId==null){
          console.log("inside parent");
          
          this.parentcomment.push(element);
        }
        
      this.resData.data.forEach(element => {
        if ( element.parentCommentId == commentId){
          console.log("inside child");
          this.childcomment.push(element);
        }
        
      });
       
        console.log(commentId + "  " + element.parentCommentId);
        
        });
  
  
        console.table(this.parentcomment);
        console.table(this.childcomment);
    console.log(this.childcomment[0].parentCommentId);
  
    
    this.childcomment.forEach((element,index)=>{ 
      console.log(index+"index");
      
      if(element.parentCommentId ){
        console.log("insdie parentid"+element.parentCommentId);
        if(element.parentCommentId >= 1){
          console.log("insdie for each");
          
          this.hideedit=true;
          console.log(this.hideedit);
        }else{
          this.hideedit=false;
          console.log(this.hideedit);
        }
      }
    })
          // this.getComment();   
        } else if (res.status == 401) {
          this.router.navigate(["logout"]);
        } else {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${this.resData.errors}`
          );
        }
      }
    });
    }
    
    
  }

  previouspage(){
    
  if(this.isArcLoggedIn=="true"){
    console.log("next page");
    this.nextcount=Number(localStorage.getItem("discussion"))-10;

    localStorage.setItem("discussion",this.nextcount)
    this.generalCommentList=null;
    this.nextcount=this.nextcount<0?0:this.nextcount
    this.httpService
    .get(
      environment.BASE_URL + ApplicationURIConstants.GET_ARC_DISCUSSION_COMMENT + "/" + this.nextcount
    )
    .subscribe((res) => {
      
      setTimeout(() => {
      console.log("inside next page settime");
      
    }, 2000);
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      // console.log(JSON.stringify(res));

      if (res.status == 200) {
      console.log("INside next page");
      console.log(this.pagenumber);
      
      this.pagenumber=this.pagenumber-1;

      console.log(this.pagenumber);
      if(this.pagenumber>=2){
        this.previousdisable=true;
        console.log(this.previousdisable);
        
      }else{
        this.previousdisable=false;
        console.log(this.previousdisable);

      }
      this.resData = new AppResponse(res);

      console.log(this.resData);
      
      this.nextpagedata = this.resData.data;
      console.log(this.nextpagedata);

      if(this.resData.data<1){
        this.nextdisable=true;
        console.log(this.nextdisable);
      }else{
        this.nextdisable=false;
        console.log(this.nextdisable);
      }
      if(this.resData.data.length <= 1){
        this.isDivShow = true
      }
      else{
        this.isDivShow = false
      }


      // $('#MyTable tbody').empty();
      this.parentcomment=[]
      this.childcomment=[]
      this.resData.data.forEach(element => {
      // console.table(element);
      var commentId = element.discussionId;
      this.comid=element.discussionId;
      
      if(element.parentCommentId==null){
        console.log("inside parent");
        
        this.parentcomment.push(element);
      }
      
    this.resData.data.forEach(element => {
      if ( element.parentCommentId == commentId){
        console.log("inside child");
        this.childcomment.push(element);
      }
      
    });
     
      console.log(commentId + "  " + element.parentCommentId);
      
     
      
      });


      console.table(this.parentcomment);
      console.table(this.childcomment);
  console.log(this.childcomment[0].parentCommentId);

  
  this.childcomment.forEach((element,index)=>{ 
    console.log(index+"index");
    
    if(element.parentCommentId ){
      console.log("insdie parentid"+element.parentCommentId);
      if(element.parentCommentId >= 1){
        console.log("insdie for each");
        
        this.hideedit=true;
        console.log(this.hideedit);
      }else{
        this.hideedit=false;
        console.log(this.hideedit);
      }
    }
  })
        // this.getComment();   
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
  }else if(this.isBMLoggedIn=="true"){
    console.log("next page");
    this.nextcount=Number(localStorage.getItem("discussion"))-10;

    localStorage.setItem("discussion",this.nextcount)
    this.generalCommentList=null;
    this.nextcount=this.nextcount<0?0:this.nextcount
    this.httpService
    .get(
      environment.BASE_URL + ApplicationURIConstants.GET_BM_DISCUSSION_COMMENT + "/" + this.nextcount
    )
    .subscribe((res) => {
      
      setTimeout(() => {
      console.log("inside next page settime");
      
    }, 2000);
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      // console.log(JSON.stringify(res));

      if (res.status == 200) {
      console.log("INside next page");
      console.log(this.pagenumber);
      
      this.pagenumber=this.pagenumber-1;

      console.log(this.pagenumber);
      if(this.pagenumber>=2){
        this.previousdisable=true;
        console.log(this.previousdisable);
        
      }else{
        this.previousdisable=false;
        console.log(this.previousdisable);

      }
      this.resData = new AppResponse(res);

      console.log(this.resData);
      
      this.nextpagedata = this.resData.data;
      console.log(this.nextpagedata);

      if(this.resData.data<1){
        this.nextdisable=true;
        console.log(this.nextdisable);
      }else{
        this.nextdisable=false;
        console.log(this.nextdisable);
      }
      if(this.resData.data.length <= 1){
        this.isDivShow = true
      }
      else{
        this.isDivShow = false
      }


      // $('#MyTable tbody').empty();
      this.parentcomment=[]
      this.childcomment=[]
      this.resData.data.forEach(element => {
      // console.table(element);
      var commentId = element.discussionId;
      this.comid=element.discussionId;
      
      if(element.parentCommentId==null){
        console.log("inside parent");
        
        this.parentcomment.push(element);
      }
      
    this.resData.data.forEach(element => {
      if ( element.parentCommentId == commentId){
        console.log("inside child");
        this.childcomment.push(element);
      }
      
    });
     
      console.log(commentId + "  " + element.parentCommentId);
      
     
      
      });


      console.table(this.parentcomment);
      console.table(this.childcomment);
  console.log(this.childcomment[0].parentCommentId);

  
  this.childcomment.forEach((element,index)=>{ 
    console.log(index+"index");
    
    if(element.parentCommentId ){
      console.log("insdie parentid"+element.parentCommentId);
      if(element.parentCommentId >= 1){
        console.log("insdie for each");
        
        this.hideedit=true;
        console.log(this.hideedit);
      }else{
        this.hideedit=false;
        console.log(this.hideedit);
      }
    }
  })
        // this.getComment();   
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
  }else if(this.isMCLoggedIn=="true"){
    console.log("next page");
    this.nextcount=Number(localStorage.getItem("discussion"))-10;

    localStorage.setItem("discussion",this.nextcount)
    this.generalCommentList=null;
    this.nextcount=this.nextcount<0?0:this.nextcount
    this.httpService
    .get(
      environment.BASE_URL + ApplicationURIConstants.GET_MC_DISCUSSION_COMMENT + "/" + this.nextcount
    )
    .subscribe((res) => {
      
      setTimeout(() => {
      console.log("inside next page settime");
      
    }, 2000);
    if (res == undefined) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        ApplicationConstants.SERVER_CONNECTION_PROBLEM
      );
    } else {
      // console.log(JSON.stringify(res));

      if (res.status == 200) {
      console.log("INside next page");
      console.log(this.pagenumber);
      
      this.pagenumber=this.pagenumber-1;

      console.log(this.pagenumber);
      if(this.pagenumber>=2){
        this.previousdisable=true;
        console.log(this.previousdisable);
        
      }else{
        this.previousdisable=false;
        console.log(this.previousdisable);

      }
      this.resData = new AppResponse(res);

      console.log(this.resData);
      
      this.nextpagedata = this.resData.data;
      console.log(this.nextpagedata);

      if(this.resData.data<1){
        this.nextdisable=true;
        console.log(this.nextdisable);
      }else{
        this.nextdisable=false;
        console.log(this.nextdisable);
      }
      if(this.resData.data.length <= 1){
        this.isDivShow = true
      }
      else{
        this.isDivShow = false
      }


      // $('#MyTable tbody').empty();
      this.parentcomment=[]
      this.childcomment=[]
      this.resData.data.forEach(element => {
      // console.table(element);
      var commentId = element.discussionId;
      this.comid=element.discussionId;
      
      if(element.parentCommentId==null){
        console.log("inside parent");
        
        this.parentcomment.push(element);
      }
      
    this.resData.data.forEach(element => {
      if ( element.parentCommentId == commentId){
        console.log("inside child");
        this.childcomment.push(element);
      }
      
    });
     
      console.log(commentId + "  " + element.parentCommentId);
      
     
      
      });


      console.table(this.parentcomment);
      console.table(this.childcomment);
  console.log(this.childcomment[0].parentCommentId);

  
  this.childcomment.forEach((element,index)=>{ 
    console.log(index+"index");
    
    if(element.parentCommentId ){
      console.log("insdie parentid"+element.parentCommentId);
      if(element.parentCommentId >= 1){
        console.log("insdie for each");
        
        this.hideedit=true;
        console.log(this.hideedit);
      }else{
        this.hideedit=false;
        console.log(this.hideedit);
      }
    }
  })
        // this.getComment();   
      } else if (res.status == 401) {
        this.router.navigate(["logout"]);
      } else {
        this.commonService.showNotificationSuccess(
          ApplicationConstants.MSG_BOX_LABEL,
          ApplicationConstants.DANGER_LABLE,
          `${this.resData.errors}`
        );
      }
    }
  });
  }
    
  }

  updatediscuss(name) {
    console.log(name);
    
console.log("insdie on update discuss");


    // this.updateDiscussionForm = this.formBuilder.group({
    //   "topic": [name.topic],
    //   "description": [name.description],
    //    "discussionId": [name.discussionId],
    //    "isedited":[""]

    // });
    console.log(this.updateDiscussionForm.value);

  }

  delete(){
    console.log("inside log");
    
  }


  emptybox($event) {
    console.log("inside emptybox");
  
    const trimmedValue = $event.target.value.trim(); // Remove leading and trailing whitespace
  
    if (trimmedValue === "") {
      this.disablearrow = true;
      console.log(this.disablearrow);
    } else {
      this.disablearrow = false;
      console.log(this.disablearrow);
    }
  }
  isActive: boolean = false;

  toggleActive() {
    this.isActive = !this.isActive;
  }
    
  selectedItemIndex: number | null = null; // Initialize to null

  selectItem(index: number): void {
    this.selectedItemIndex = index;
  }
  



  deleteDiscussion(name){


    console.log("inside delete : " +name.discussionId );
    
    this.updateDiscussionForm.controls['discussionId'].setValue(name.discussionId);

    if(confirm("Are you sure you want to delete this topic")){
  
      this.spinner.show();
      this.httpService
      .update(
        this.updateDiscussionForm.value,
        environment.BASE_URL + ApplicationURIConstants.DELETE_DISCUSSSION_COMMENTS
      )
      .subscribe((res) => {
       

        console.log(JSON.stringify(res));
        this.spinner.hide();
        setTimeout(() => {
          console.log("inside delete settime");
          
          this.ngOnInit()
          
        }, 2000);
        if(this.resData.data.length == 0){
          location.reload()
        }
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          console.log(JSON.stringify(res));

          if (res.status == 200) {
            // this.resData = new AppResponse(res);
            // this.getComment();
            setTimeout(() => {
              console.log("inside delete settime");
              
              this.ngOnInit()
            }, 2000);
            
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });

    }
  }

  
 

}
