import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as FileSaver from 'file-saver';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from '../../utils/common-service';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import * as $ from 'jquery';
@Component({
  selector: 'app-crview-by-arc',
  templateUrl: './crview-by-arc.component.html',
  styleUrls: ['./crview-by-arc.component.css']
})
export class CrviewByARCComponent implements OnInit {

  complianceRequestId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  dueRequestForm: FormGroup;
  submitted = false;
  // isArcLoggedIn: boolean;
  isFineApply: boolean;
  isEditable : boolean = false;
  statusSelected: string;

  reviewComplianceRequestForm: FormGroup;
  isStatusSelected: boolean;
  // btnUpdateDisabled: boolean;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
    detailedOriginalDoc : any = [];


  private addGroupDue = environment.BASE_URL + ApplicationURIConstants.ADD_CR_FINE;


  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private tokenService: TokenStorageService) { }

  ngOnInit() {

       // added by gopal for HB-611 start on 10/12/2021
       if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.complianceRequestId = params.id;
    });

    this.dueRequestForm = this.formBuilder.group({
      'homeProfileId': ['', [Validators.required]],
      "userProfileInfoId": ['', [Validators.required]],
      "complianceRequestId": [this.complianceRequestId]
    });

    this.reviewComplianceRequestForm = this.formBuilder.group({
      'reviewOutCome': ['' ,[Validators.required]],
      'reviewComments': [''],               
      'byLawsTopic': ['', [Validators.required]],              
      'complianceRequestId': [this.complianceRequestId, [Validators.required]],
      'assignedTo' : ['',[Validators.required]]            
    });        
  

    // tslint:disable-next-line: max-line-length
    // console.log("complianceRequestId -> " + this.complianceRequestId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS_ARC + "/" + this.complianceRequestId).subscribe(data => {
      // console.log(JSON.stringify(data));
      this.appResponse = new AppResponse(data);
      console.log(this.appResponse)
      // console.log("======" + this.appResponse.data.complianceRequest.userProfileInfoId);
      // console.log("======" + this.appResponse.data.complianceRequest.homeProfileId);
      this.dueRequestForm.controls['homeProfileId'].setValue(this.appResponse.data.complianceRequest.homeProfileId);
      this.dueRequestForm.controls['userProfileInfoId'].setValue(this.appResponse.data.complianceRequest.userProfileInfoId);
      this.statusSelected  = this.appResponse.data.complianceRequest.status;
      // this.dueRequestForm.controls['userProfileInfoId'].setValue(this.appResponse.data.complianceRequest.userProfileInfoId);
      if((this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_RESOLVED 
        || this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_PENDING
        || this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_ACKNOWLEDGED
        || this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_NOT_RESOLVED) && this.appResponse.data.complianceRequest.createdBy == this.tokenService.getuserProfileId()){
        this.isEditable = true;
      }
      if (this.isArcLoggedIn && this.appResponse.data.complianceRequest.fineApplicable) {
        this.isFineApply = true;
      }

       //HB-423 Hevisa Patel 28-01-2021 -- Start     
      setTimeout(() => {
        $('#reviewCommentTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 4,
            orderable: false
          },
          ]
        });
      }, 2000);  //2s
      //HB-423 Hevisa Patel 28-01-2021 -- End     
      this.reviewComplianceRequestForm = this.formBuilder.group({
        'reviewOutCome': [this.appResponse.data.complianceRequest.status, [Validators.required]],
        'reviewComments': ['', [Validators.required]],               
        'byLawsTopic': [this.appResponse.data.complianceRequest.byLawsTopic, [Validators.required]],              
        'complianceRequestId': [this.complianceRequestId, [Validators.required]],
        'assignedTo' : [this.appResponse.data.complianceRequest.userProfileInfoId,[Validators.required]]            
      });
      // code added b aishwaryaa for hb -977

      if(this.appResponse.data.complianceRequest.listRequestDocument){
         
        for(let i=0; i<this.appResponse.data.complianceRequest.listRequestDocument.length; i++) {
            
          this.detailedOriginalDoc.push({
            "docUploadedBy": (this.appResponse.data.complianceRequest.listRequestDocument[i].uploadedBy !=null)?("Document uploaded by "+this.appResponse.data.complianceRequest.listRequestDocument[i].uploadedBy+" - ") : ' ',
            "originalDoc": this.appResponse.data.complianceRequest.listRequestDocument[i].documentname,
            "createdOn" : this.appResponse.data.complianceRequest.listRequestDocument[i].createdDateTime
          });
        }
      }
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }

  get compliance() { return this.reviewComplianceRequestForm.controls; }

  get dues() { return this.dueRequestForm.controls; }
  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }


  onSubmit() {

    this.submitted = true;
    // console.log("FORM:" + JSON.stringify(this.dueRequestForm.value));
    if (this.dueRequestForm.invalid) {
      return;
    } else {
      // http service method call
      this.httpService.save(this.dueRequestForm.value, this.addGroupDue)
        .subscribe((res) => {

          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log(this.resData);
            if (res.status == 200) {
              this.submitted = false;

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);

              setTimeout(() => {
                $('.close').click();
                this.router.navigate(['cr-list-byarc']);
              }, 5000);
            } else if (res.status == 401) {
              this.router.navigate(['login']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }


  }

  changeStatus(e) {
    this.statusSelected = e.target.value;
    if (e.target.value == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_PENDING || e.target.value == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_CLOSED || e.target.value == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_NOT_RESOLVED) {
      this.isStatusSelected = true;
    } else {
      this.isStatusSelected = false;
    }  
    // if (e.target.value == this.appResponse.data.complianceRequest.status) {
    //   this.btnUpdateDisabled = true;
    //  //$('#btnUpdate').prop('disabled', true);
    // }else{
    //   this.btnUpdateDisabled = false;
    //   //$('#btnUpdate').prop('disabled', false);
    // }
  }

  reviewByARC(){
    
    // console.log(JSON.stringify(this.reviewComplianceRequestForm.value));
    if (this.reviewComplianceRequestForm.invalid) {
      console.log("invalid")
     
      return;
    } else {
      // console.log("===="+this.reviewComplianceRequestForm.value);
      this.httpService.save(this.reviewComplianceRequestForm.value, environment.BASE_URL + ApplicationURIConstants.CR_REVIEW_BY_ARC)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            
            // console.log("Response ->>" + JSON.stringify(res));
            if (res.status == 200) {

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              $('#reviewCommentTable').dataTable().fnDestroy(); 
              setTimeout(() => {
                this.router.navigate(['cr-list-byarc']);
              }, 2000);  //3s

            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }
  } 

}
