import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();
@Component({
  selector: 'app-add-notification-by-bm',
  templateUrl: './add-notification-by-bm.component.html',
  styleUrls: ['./add-notification-by-bm.component.css']
})
export class AddNotificationByBmComponent implements OnInit {
  notificationForm: FormGroup;
  public Editor = ClassicEditor;
  resData: AppResponse;
  IsDirectApproval:string;
  IsBMApprovalRequired:string;
  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  constructor(private tokenService:TokenStorageService,private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router) { 
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          // '|',
          // 'imageUpload',
          '|',
          'undo',
          'redo'
        ]
      },
      // image: {
      //   toolbar: [
      //     'imageStyle:full',
      //     'imageStyle:side',
      //     '|',
      //     'imageTextAlternative'
      //   ]
      // },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'en'
    };
  }
 

  ngAfterViewInit(): void {
    this.IsDirectApproval = this.tokenService.getGroupConfigByKey('Publish To Board');
    this.IsBMApprovalRequired = this.tokenService.getGroupConfigByKey('Publish To Home Owners');
    if(this.IsBMApprovalRequired =='No' && this.IsDirectApproval =='No'){
      this.IsDirectApproval="No";
      this.IsBMApprovalRequired="No";

    }
  }
  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
            var date = new Date();
            var datetemp = new Date(date.getFullYear(),date.getMonth(),date.getDate());
            
            var datetempmax = new Date(date.getFullYear()+1,date.getMonth(),date.getDate());
            
            this.max = datetempmax;
            this.min = datetemp;
    // console.log("Publish To Home Owners"+this.IsBMApprovalRequired);
    // console.log("IsDirectApproval"+this.IsDirectApproval);
    this.notificationForm = this.formBuilder.group({
      'subject': ['', [Validators.required, Validators.minLength(10)]],
      // "publishedDate": ['', [Validators.required]],
      'description': ['', [Validators.required]],
      'publishedBy': ['']

    });

  }



  get notification() {
    return this.notificationForm.controls;
  }
  clearPublishedDateValue(){
    this.notificationForm.controls['publishedDate'].setValue(null);
  }

  submitted = false;
  private notificationURL = environment.BASE_URL + ApplicationURIConstants.ADD_NOTIFICATION_BY_BM;

  onSubmit() {
    this.submitted = true;
    if (this.notificationForm.invalid) {
      console.error("invalid request")
      return;
    } else {
      if(this.IsBMApprovalRequired == 'No' && this.IsDirectApproval == 'No'){
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Notification Type is Not Configured Please Contact Group Admin");
        return;} 
        else{
          if(this.isBMLoggedIn) {
            this.notificationForm.value.userrole="BM";
          }
    
          // console.log("valid request"+JSON.stringify(this.notificationForm.value));
          this.httpService.save(this.notificationForm.value, this.notificationURL)
            .subscribe((res) => {
              // console.log("INSIDE THE FIRST");
              if (res != undefined) {
                this.resData = new AppResponse(res);
                // console.log("RESPONSE:" + JSON.stringify(this.resData));
                if (res.status == 200) {
                  this.submitted = false;
                  this.notificationForm.reset();
                  this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
                  setTimeout(() => {
                    this.router.navigate(['bmnotification-list']);
                  }, 3000);  //15s
                } else if (res.status == 401) {
                  this.router.navigate(['logout']);
                } else {
                  this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
                }
              } else {
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
              }
            });

        }
   


    }

  }

  

}
