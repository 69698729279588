import { Component, OnInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartType, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Router } from '@angular/router';
import { CommonService } from '../../utils/common-service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Component({
  selector: 'app-arc-dashboard',
  templateUrl: './arc-dashboard.component.html',
  styleUrls: ['./arc-dashboard.component.css']
})
export class ArcDashboardComponent implements OnInit {

  public appResponse: AppResponse;
  public totalMr: any;
  public totalCr: any;
  emptyMR: boolean = false;
  emptyCR: boolean = false;

  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end
  footer : String;
  shownewnotify: boolean;
  sdresdata: AppResponse;
  sdcount: any;
  displayText: string;

  constructor(private httpService: HttpTransactionService, private tokenService: TokenStorageService, private router: Router, private commonService: CommonService) { }

  isArcLoggedin: boolean;

  ngOnInit() {
    this.shownewnotify=localStorage.getItem('notificationviewed')=="true"?false:true;

    if(localStorage.getItem("greeting") == "true"){
      this.displayText = 'Welcome to Owner Portal';
    }else {
      this.displayText = '';
    }
    

    setTimeout(() => {
      this.displayText = '';
    }, 5000);
    // console.log("---------------ARC--------");
    this.isArcLoggedin = true;
    window.localStorage.setItem("isArcLoggedIn", "true");
    window.localStorage.setItem("isBMLoggedIn", "false");
    window.localStorage.setItem("isHOLoggedIn", "false");
    window.localStorage.setItem(ApplicationConstants.DASHBOARD_URL_LABEL, ApplicationConstants.ARC_DASHBOARD_URL);

    // added by gopal for HB-611 start on 10/12/2021
    if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
      setTimeout(() => {
        this.displayText = '';
        localStorage.setItem("greeting","false")
      }, 5000);
     
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
    // added by goapl for HB-611 end

    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.ARC_DASHBOARD).subscribe(res => {

      this.appResponse = new AppResponse(res);
      console.log(this.appResponse)

      if (res.status === 401) {
        // 401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        // 403 URL not accessible
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.appResponse.errors}`);
      } else if (res.status === 200) {

        this.mrPieChartData = [this.appResponse.data.apprvdMr, this.appResponse.data.pendingMr, this.appResponse.data.ackMr, this.appResponse.data.pendAppMr, this.appResponse.data.rejMr];
        this.crPieChartData = [this.appResponse.data.pendCr, this.appResponse.data.ackCr, this.appResponse.data.reslvCr, this.appResponse.data.clsCr];

        this.totalMr = Math.abs(this.appResponse.data.totalMr);
        this.totalCr = Math.abs(this.appResponse.data.totalCr);

        if (this.totalMr == 0)
          this.emptyMR = true;
        if (this.totalCr == 0)
          this.emptyCR = true;
      }
    });

  }

 
  // Pie
  // Maintenance request Start
  ngAfterViewInit(): void {
    this.footer = this.tokenService.getGroupConfigByKey('footer');
    // HB-1096 by vignesh on 6/10/2022 start

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_LIST_OF_DOCUMENT_BY_ARC).subscribe(res => {

      // added by gopal for HB-685 end
      // console.log("2");

      //console.log(JSON.stringify(res));
      if (res == undefined) {
        // this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {

        // console.log(JSON.stringify(res));
        this.sdresdata = new AppResponse(res);
        console.log(this.sdresdata);
        this.sdcount = this.sdresdata.data.length;
        
        
        if (res.status === 401) {
          // this.spinner.hide();
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          // this.spinner.hide();
          //403 URL not accessible
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.sdresdata.errors}`);
          //this.router.navigate(['home']);
        }
      }
    });
     // HB-1096 by vignesh on 6/10/2022 end
  }

  public mrPieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: () => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          const label = null;
          return label;
        },
      },
    }
  };

  public mrPieChartLabels: Label[] = ['Approved', 'Pending', 'Acknowledged ', 'Pending Approval ', 'Rejected'];
  public mrPieChartData: number[] = [0, 0];//=  [this.appResponse.data.acceptedMr, this.appResponse.data.rejectedMr];
  public mrPieChartType: ChartType = 'pie';
  public mrPieChartLegend = true;
  public mrPieChartPlugins = [pluginDataLabels];
  public mrPieChartColors = [
    {
      // tslint:disable-next-line: max-line-length
      backgroundColor: ['rgba(196, 154, 199, 1)', 'rgba(239, 168, 200, 1)', 'rgba(244, 234, 143, 1)', 'rgba(182, 226, 132, 1)', 'rgba(108, 217, 169, 1)', 'rgba(93, 185, 207, 1)'],
    },
  ];
  // Maintenance request End

  // Compliance request Start
  // Pie
  public crPieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: () => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          const label = null;
          return label;
        },
      },
    }
  };

  public crPieChartLabels: Label[] = ['Pending', 'Acknowledged', 'Resolved', 'Closed'];
  public crPieChartData: number[] = [0, 0];//= [this.appResponse.data.acceptedMr, this.appResponse.data.resolvedCr];
  public crPieChartType: ChartType = 'pie';
  public crPieChartLegend = true;
  public crPieChartPlugins = [pluginDataLabels];
  public crPieChartColors = [
    {
      backgroundColor: ['rgba(230, 246, 157, 1)', 'rgba(170, 222, 167, 1)', 'rgba(100, 194, 166, 1)', 'rgba(45, 135, 187, 1)'],
    },
  ];
  // Compliance request End

}



