import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { NgxSpinnerService } from 'ngx-spinner';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
const helper = new JwtHelperService();
@Component({
  selector: 'app-add-shared-document',
  templateUrl: './add-shared-document.component.html',
  styleUrls: ['./add-shared-document.component.css'],
  providers: [NgxSpinnerService]
})
export class AddSharedDocumentComponent implements OnInit {

  sharedDocumentForm: FormGroup;
  submitted = false;
  resData: AppResponse;
  fileUploadList :string [] = [];
  
  // isBMLoggedIn:boolean = false;

  uploadedBy : string ='';  
  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  
  errorFlag : boolean = true;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
    showoptions:boolean=false;

  private addSharedDoc = environment.BASE_URL + ApplicationURIConstants.ADD_SHARED_DOCUMENT;

  constructor(private httpService: HttpTransactionService,
     private formBuilder: FormBuilder, 
     private commonService: CommonService, 
     private router: Router,
     private spinner: NgxSpinnerService,
     private tokenService:TokenStorageService) {
   }

  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    //this.min.setDate(this.min.getDate()-1);
    var date = new Date();
    var datetemp = new Date(date.getFullYear(),date.getMonth(),date.getDate());
    
    var datetempmax = new Date(date.getFullYear()+1,date.getMonth(),date.getDate());
    
    this.max = datetempmax;
    this.min = datetemp;
    if(localStorage.getItem("isHOLoggedIn")== "true"){
      this.uploadedBy = "HO";
    }
    else if(localStorage.getItem("isArcLoggedIn")== "true"){
      this.uploadedBy = "ARC";
    }
    else if(localStorage.getItem("isBMLoggedIn")== "true"){
      this.uploadedBy = "BM";
    }
    else if(localStorage.getItem("isMCLoggedIn")== "true"){
      this.uploadedBy = "MC";
    }
    else{
      this.uploadedBy = "GU";
    }

 
    this.sharedDocumentForm = this.formBuilder.group({
      "category": ['', [Validators.required]],
      "description": [''],
      "publishedDate": ['', [Validators.required]],
      "file": ['',[Validators.required]],
      "publishedBy": ['',[Validators.required]]
    });

  }

  clearPublishedDateValue() {
    this.sharedDocumentForm.controls['publishedDate'].setValue(null);
  }
    // convenience getter for easy access to form fields
    get sharedDocument() { return this.sharedDocumentForm.controls; }

    onSubmit(){
       this.spinner.show();
      if (this.sharedDocumentForm.invalid) {
        this.spinner.hide();
        return;
      } else {
        var formData = new FormData();
        formData.append("description",  this.sharedDocumentForm.controls['description'].value);
        formData.append("category",this.sharedDocumentForm.controls['category'].value);
        formData.append("publishedDate",this.sharedDocumentForm.controls['publishedDate'].value);
        formData.append("publishedBy",this.sharedDocumentForm.controls['publishedBy'].value);
        formData.append("uploadedBy", this.uploadedBy);
        formData.append("userrole", this.uploadedBy);
        formData.append("file",this.fileUploadList[0]);
        this.httpService.save(formData, this.addSharedDoc)
        .subscribe((res) => {
          this.spinner.hide();
          if(res != undefined){
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.sharedDocumentForm.reset();
              $("#category").prop('selectedIndex', 0);
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['sharedDocuments']);
              }, 5000);  //15s
            } else if(res.status == 401){
              this.router.navigate(['logout']);
            }else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          }else{
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
      }


    }

    onFileSelect (event) {
      this.fileUploadList = [];
      this.fileUploadList.push(event.target.files[0]);     
    }
    onOptionSelect (event) {
    //  console.log(event) 
    //  console.log(event.target.value)
     if(event.target.value == 'Public')
     {
      this.showoptions=true;
      this.sharedDocumentForm.controls['publishedBy'].setValidators([Validators.required]);
      this.sharedDocumentForm.controls['publishedBy'].updateValueAndValidity();

     }
     else{
      this.showoptions=false;
      this.sharedDocumentForm.controls['publishedBy'].setValidators([]);
      this.sharedDocumentForm.controls['publishedBy'].updateValueAndValidity();
     }
    }
    onChange(data){
      if(data !== null){
        this.errorFlag = false;
      }else{
        this.errorFlag = true;
      }
    }

    test(){
      this.errorFlag = false;
    }
    
}
