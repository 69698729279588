import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from 'src/app/utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

import * as $ from 'jquery';


@Component({
  selector: 'app-edit-renter-information',
  templateUrl: './edit-renter-information.component.html',
  styleUrls: ['./edit-renter-information.component.css'],
  providers: [NgxSpinnerService]

})
export class EditRenterInformationComponent implements OnInit {

  renterInfoId: string;
  responseData: AppResponse;
  requestData: AppResponse;
  errorss: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  resData: AppResponse;
  submitted = false;
  public show = false;
  editRenterInformationForm: FormGroup;
  documentname: string;
  fileNameContentType: string;
  fileNameExtension: string;
  fileUploadList: string[] = [];
  map = new Map();
  editable: boolean;
  public leaseStartDate: Date;
  public leaseEndDate: Date;
  public dateOfBirth: Date;

  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  ///added by aishwaryaa for HB-460 21/12/2021
  public minDateOfBirth = new Date(1925, 12, 1, 0, 0);
  public maxDateOfBirth = new Date(2005, 12, 1, 0, 0);
  ///code end 
  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0);
  public minLeaseEndDateTemp ;
  isError : boolean = true;
  countryCode : any;
  countryCodeLength : any;
  flag : boolean = false;
  intlObj : any;
  errorFlagLeaseEndDate : boolean = true;
  errorFlag : boolean = true;
  checkEmptyFlag : boolean = true;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  private renterInformationEdit = environment.BASE_URL + ApplicationURIConstants.RENTER_INFORMATION_EDIT;

  constructor(private spinner: NgxSpinnerService,private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router
  ) {
    this.editRenterInformationForm = this.formBuilder.group({
      'leaseStartDate': ['', [Validators.required]],
      'leaseEndDate': ['', [Validators.required]],
      'firstName': ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      'email': ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      'lastName': ['', [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      //'contact': ['', [Validators.pattern(AppRegExConstants.TEN_DIGIT_CONTACT_NUMBER)]],
      'dateOfBirth': ['',],
      'importantdocuments': [''],
    });
  }



  ngOnInit() {

      // added by gopal for HB-611 start on 10/12/2021
      if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end
    this.spinner.show();

    // console.log("=======>>>> ngOnInit <<<<==========");
    this.activatedRoute.params.subscribe(params => {
      this.renterInfoId = params.id;
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.RENTER_INFORMATION_VIEW + "/" + this.renterInfoId).subscribe(res => {
      // console.log(JSON.stringify(res));
      this.responseData = new AppResponse(res);

      if (res.status == 200) {
        // console.log(JSON.stringify("TEST" + JSON.stringify(this.responseData.data)));
        this.fillTextfeildData();
        this.spinner.hide();

      } else if (res.status === 401) {
        //401 token related issue
        this.spinner.hide();
        this.router.navigate(['login']);
      } else if (res.status == 403) {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
        this.router.navigate(['home']);
      } else {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
      }

    });
    var d = new Date();
    d.setFullYear(d.getFullYear() - 18);
    this.maxDateOfBirth = d;

    var minDateVal = new Date();
    minDateVal.setFullYear(minDateVal.getFullYear() - 2);
    this.min = minDateVal;

  }


  get renterInfo() { return this.editRenterInformationForm.controls; }

  onSubmit() {
    this.isValidDate = false;
    this.submitted = true;
    if (this.editRenterInformationForm.invalid || (!this.isError && !this.checkEmptyFlag)) {
      //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.editRenterInformationForm.value))

      return;
    }
    else {

      this.isValidDate = this.validateDates(this.editRenterInformationForm.controls['leaseStartDate'].value, this.editRenterInformationForm.controls['leaseEndDate'].value);
      // console.log("RENTERINFO:" + JSON.stringify(formData));
      var formData = new FormData();

      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("importantdocuments", this.fileUploadList[i]);
      }
      formData.append("leaseStartDate", this.editRenterInformationForm.controls['leaseStartDate'].value);
      formData.append("leaseEndDate", this.editRenterInformationForm.controls['leaseEndDate'].value);
      formData.append("firstName", this.editRenterInformationForm.controls['firstName'].value);
      formData.append("lastName", this.editRenterInformationForm.controls['lastName'].value);
      formData.append("dateOfBirth", this.editRenterInformationForm.controls['dateOfBirth'].value);
      formData.append("contact", this.intlObj.getNumber());
      formData.append("email", this.editRenterInformationForm.controls['email'].value);
      formData.append("renterInfoId", this.responseData.data.renterInfoId);

      // console.log("RENTERINFOINSI:" + JSON.stringify(formData));
      if (this.isValidDate) {
        this.httpService.update(formData, this.renterInformationEdit)
          .subscribe((res) => {
            if (res != undefined) {
              this.resData = new AppResponse(res);
              // console.log("RENTERINFOINSI:" + JSON.stringify(formData));

              if (res.status == 200) {
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
                setTimeout(() => {
                  this.router.navigate(['renterInformations-list']);
                }, 5000);  //15s
              } else if (res.status == 401) {
                this.router.navigate(['logout']);
              }
              else {
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
              }
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
            }
          });
      }
    }

   

  }

  onFileSelect(event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }

  clearleaseStartDateValue() {
    this.editRenterInformationForm.controls['leaseStartDate'].setValue(null);
  }
  clearleaseEndDateValue() {
    this.editRenterInformationForm.controls['leaseEndDate'].setValue(null);
  }
  cleardateOfBirthValue() {
    this.editRenterInformationForm.controls['dateOfBirth'].setValue(null);
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.show = true;
      this.errorss = { isError: true, errorMessage: 'Lease End Date should be greater then Lease Start Date.' };
      setTimeout(function () {
        this.show = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    }
    else
      if ((sDate != null && eDate != null) && (sDate) > (eDate)) {
        this.show = true;
        this.errorss = { isError: true, errorMessage: 'Lease Start Date should be less then Lease End Date.' };
        setTimeout(function () {
          this.show = false;
        }.bind(this), 6000);
        this.isValidDate = false;
      }
    return this.isValidDate;
  }

  fillTextfeildData() {

    // console.log("inside fillTextfeildData" + JSON.stringify(this.responseData.data));
    if (this.responseData.data != null) {
      this.intlObj.setNumber(this.responseData.data.contact);
      this.leaseStartDate = new Date(this.responseData.data.leaseStartDate);
      this.leaseEndDate = new Date(this.responseData.data.leaseEndDate);
      if (this.responseData.data.dateOfBirth != null) {
        this.dateOfBirth = new Date(this.responseData.data.dateOfBirth);
      }
      this.editRenterInformationForm = this.formBuilder.group({

        "leaseStartDate": [this.leaseStartDate, Validators.required],
        "leaseEndDate": [this.leaseEndDate, Validators.required],
        "firstName": [this.responseData.data.firstName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
        "lastName": [this.responseData.data.lastName, [Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
        "dateOfBirth": [this.dateOfBirth],
        "email": [this.responseData.data.email, [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
        //"contact": [this.responseData.data.contact, [Validators.pattern(AppRegExConstants.TEN_DIGIT_CONTACT_NUMBER)]],
        "importantdocuments": [this.responseData.data.originalDoc],
        "renterInfoId": [this.responseData.data.renterInfoId],


      });
      this.intlObj.setNumber(this.responseData.data.contact);



    }
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);

        //Start the code for Base64 to byte[]
        const byteCharacters = atob(this.resData.data);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(this.resData.data);

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);

        FileSaver.saveAs(blob, documentName.substr(14));
      });
  }


  onChange(data) {
    if(data !== null){
      this.editable = true;
      this.errorFlag = false;
      this.minLeaseEndDateTemp = data;
      var datetemp = new Date(this.minLeaseEndDateTemp.getFullYear(),this.minLeaseEndDateTemp.getMonth(),this.minLeaseEndDateTemp.getDate()+1);
      // console.log("////////"+datetemp);
      this.minLeaseEndDate = datetemp;
      this.editRenterInformationForm.controls['leaseEndDate'].setValue(null);
      // console.log("---"+JSON.stringify(data));
   }else{
      this.errorFlag = true;
      this.editRenterInformationForm.controls['leaseEndDate'].setValue(null);
      this.editable = false;
    }
  }

  telInputObject(obj) {
    this.intlObj = obj;
    // console.log("-------"+JSON.stringify(obj));
    this.flag = true;
    this.countryCode = obj.dialCode;
   // obj.setNumber(this.responseData.data.contact);
  }

  onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;
    if(this.flag)
    this.intlObj.setNumber('');
    else
    this.flag = true;
  }


  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}

getNumber(obj){
  // console.log("*******"+JSON.stringify(obj));
}

onChangeLeaseEnd(data){
  if(data !== null){
   this.errorFlagLeaseEndDate = false;
  }else{
   this.errorFlagLeaseEndDate = true;
  }
}


testLend(){
  this.errorFlagLeaseEndDate = false;
}

test(){
  this.errorFlag = false;
}
  
checkForError(obj){
  // console.log("---"+this.intlObj.getNumber());
  if(this.intlObj.getNumber() == '')
     this.checkEmptyFlag = true;
  else
     this.checkEmptyFlag = false;   
  this.myInput.nativeElement.blur();
  $("#phoneInput").focus();
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
       return true;
    else   
       return false;
  }
  return true;

}
}
