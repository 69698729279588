import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';


@Component({
  selector: 'app-list-shared-document-archived-user',
  templateUrl: './list-shared-document-archived-user.component.html',
  styleUrls: ['./list-shared-document-archived-user.component.css']
})
export class ListSharedDocumentArchivedUserComponent implements OnInit {

 // isBMLoggedIn: boolean;
 resData: AppResponse;
 // added by gopal for HB-611 start on 10/12/2021
 isGULoggedIn: string;
 isBMLoggedIn: String;
 isArcLoggedIn: String;
 isHOLoggedIn: String;
 isMCLoggedIn: String;
 // added by goapl for HB-611 end

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router,
    private commonService: CommonService, private tokenService: TokenStorageService) { }

  ngOnInit() {

     // added by gopal for HB-611 start on 10/12/2021
         if (localStorage.getItem("isGULoggedIn") == "true") {

          this.isGULoggedIn = 'true';
        } else if (localStorage.getItem("isBMLoggedIn") == "true") {
    
          this.isBMLoggedIn = 'true';
        } else if (localStorage.getItem("isArcLoggedIn") == "true") {
    
          this.isArcLoggedIn = 'true';
        } else if (localStorage.getItem("isHOLoggedIn") == "true") {
    
          this.isHOLoggedIn = 'true';
        } else if (localStorage.getItem("isMCLoggedIn") == "true") {
    
          this.isMCLoggedIn = 'true';
        }
          // added by goapl for HB-611 end
  }

  ngAfterViewInit(): void {
    // console.log("1");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_LIST_OF_DOCUMENT_BY_USER_OLD_DATA).subscribe(res => {
      // console.log("2");
      //console.log(JSON.stringify(res));
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
      
        // console.log(JSON.stringify(res));
        this.resData = new AppResponse(res);
        setTimeout(() => {
          $('#sdListDataTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            // Added by Hema shanker HB-675 on 25/11/2021 Start
          stateSave: true,
          // Added by Hema shanker HB-675 on 25/11/2021 End
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
           // Modified by Hema shanker HB-675 on 25/11/2021 Start
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
          // Modified by Hema shanker HB-675 on 25/11/2021 End
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 6,
              orderable: false
            },
          ]
          });
        }, 200);
        if (res.status === 401) {
          // console.log("401");
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          //403 URL not accessible
          // console.log("403");
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          this.router.navigate(['home']);
        }
      }
    });

  }

}
