import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as FileSaver from 'file-saver';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from '../../utils/common-service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-review-archived-sd',
  templateUrl: './review-archived-sd.component.html',
  styleUrls: ['./review-archived-sd.component.css'],
  providers: [NgxSpinnerService]
})
export class ReviewArchivedSdComponent implements OnInit { sharedDocumentId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  // isBMLoggedIn: boolean;
  reviewSharedDocumentForm: FormGroup;
  submitted = false;
  assigneeList: any[] = [];
  isApproveEnable : Boolean;
  isRejectEnable : Boolean;
  detailedOriginalDoc : any = [];
  reqData: any;
  generalCommentForm: FormGroup;
  resDatas: AppResponse;
  refId :any;
  userprofileInfoId : any;
  generalCommentList: any[] = [];
  updategeneralCommentForm: FormGroup;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder, 
    private commonService: CommonService,
    private router: Router, 
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
         // added by gopal for HB-611 start on 10/12/2021
         if (localStorage.getItem("isGULoggedIn") == "true") {

          this.isGULoggedIn = 'true';
        } else if (localStorage.getItem("isBMLoggedIn") == "true") {
    
          this.isBMLoggedIn = 'true';
        } else if (localStorage.getItem("isArcLoggedIn") == "true") {
    
          this.isArcLoggedIn = 'true';
        } else if (localStorage.getItem("isHOLoggedIn") == "true") {
    
          this.isHOLoggedIn = 'true';
        } else if (localStorage.getItem("isMCLoggedIn") == "true") {
    
          this.isMCLoggedIn = 'true';
        }
          // added by goapl for HB-611 end

    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.sharedDocumentId = params.id;
    });

    // tslint:disable-next-line: max-line-length
    // console.log("sharedDocumentId -> " + this.sharedDocumentId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.REVIEW_SHARED_DOCUMENT + this.sharedDocumentId).subscribe(data => {
      // console.log(JSON.stringify(data));
      this.appResponse = new AppResponse(data);

      if (data.status === 200) {

        setTimeout(() => {
          if (this.appResponse != undefined) {

            $('#reviewCommentTable').DataTable({
              responsive: true,
              autoWidth: true,
              searching: true,
              /*"ordering": true,*/
              order: [],
              language: {
                zeroRecords: 'No records found.',
              },
              lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
              pageLength: 5,
              pagingType: $(window).width() < 768 ? "full" : "full_numbers",
              processing: true,
              columnDefs: [{
                targets: 4,
                orderable: false
              },
              ]
            });
          }
          this.spinner.hide();
        }, 2000);//2s

        for(var i=0; i < this.appResponse.data.sdReviewList.length; i++){
          this.assigneeList.push(this.appResponse.data.sdReviewList[i]);
        }
        if(this.appResponse.data.sharedDocument.listRequestDocument){
         
          for(let i=0; i<this.appResponse.data.sharedDocument.listRequestDocument.length; i++) {
              
            this.detailedOriginalDoc.push({
              "docUploadedBy": (this.appResponse.data.sharedDocument.listRequestDocument[i].uploadedBy !=null)?("Document uploaded by "+this.appResponse.data.sharedDocument.listRequestDocument[i].uploadedBy+" - ") : ' ',
              "originalDoc": this.appResponse.data.sharedDocument.listRequestDocument[i].documentname,
              "createdOn" : this.appResponse.data.sharedDocument.listRequestDocument[i].createddatetime
            });
          }
        }

        for (var i = 0; i < this.assigneeList.length; i++) {
          if(this.isBMLoggedIn && this.assigneeList[i].assignedTo == this.tokenService.getuserProfileId() &&
              this.assigneeList[i].reviewOutCome == 'PENDING'){
              this.isApproveEnable = true;
              this.isRejectEnable = true;
            }
         }

      } else if (data.status === 401) {
        //401 token related issue
        this.spinner.hide();
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.spinner.hide();
        this.router.navigate(['logout']);
      }
    });

    this.reviewSharedDocumentForm = this.formBuilder.group({
      'reviewOutCome': ['', [Validators.required]],
      'reviewComments': ['', [Validators.required]],
      'sharedDocumentId': [this.sharedDocumentId,[Validators.required]]
    });
    this.userprofileInfoId = this.tokenService.getuserProfileId();
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
        this.refId = params.id;
    });

    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['SD', [Validators.required]],
      'comment': ['', [Validators.required]],
      });
      this.getComment();
  }

   // convenience getter for easy access to form fields
   get sharedDocument() { return this.reviewSharedDocumentForm.controls; }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  approveFunction(){
    this.spinner.show();
    this.reviewSharedDocumentForm.controls['reviewOutCome'].setValue("APPROVED");
    // console.log("test::::"+JSON.stringify(this.reviewSharedDocumentForm.value));
    this.submitted = true;

    if (this.reviewSharedDocumentForm.invalid) {
      this.spinner.hide();
      return;
    }else{
      if(this.isBMLoggedIn){
        this.httpService.update(this.reviewSharedDocumentForm.value, environment.BASE_URL + ApplicationURIConstants.REVIEW_SD)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              this.spinner.hide();
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['sharedDocuments']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              this.spinner.hide();
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              this.spinner.hide();
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
      }else{
        this.spinner.hide();
      }
    }

  }

  rejectFunction(){
    this.spinner.show();
    this.reviewSharedDocumentForm.controls['reviewOutCome'].setValue("REJECTED");
    // console.log("test::::"+JSON.stringify(this.reviewSharedDocumentForm.value));
    this.submitted = true;

    if (this.reviewSharedDocumentForm.invalid) {
      this.spinner.hide();
      return;
    }else{
      if(this.isBMLoggedIn){
        this.httpService.update(this.reviewSharedDocumentForm.value, environment.BASE_URL + ApplicationURIConstants.REVIEW_SD)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              this.spinner.hide();
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['sharedDocuments']);
              }, 7000);  //15s
            } else if (res.status == 401) {
              this.spinner.hide();
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              this.spinner.hide();
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
      }else{
        this.spinner.hide();
      }
    }
  }
  getComment(){
    this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.GET_GENERAL_COMMENT).subscribe(res => {

      if (res == undefined) {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        // console.log(JSON.stringify(res));
       
        if (res.status == 200) {
          this.spinner.hide();
          this.resDatas = new AppResponse(res); 
          this.generalCommentList = this.resDatas.data;
         } else if (res.status == 401) {
          this.spinner.hide();
          this.router.navigate(['logout']);
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resDatas.errors}`);
        }
      }
    });
    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['SD', [Validators.required]],
      'comment': ['', [Validators.required]],
      'generalCommentId':["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() { return this.generalCommentForm.controls; }

  onSubmit() {
    this.spinner.show();
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.generalCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.generalCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.generalCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.generalCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.generalCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.generalCommentForm.value));
     
      this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.ADD_GENERAL_COMMENT).subscribe(res => {
        if (res == undefined) {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
        } else {
          // console.log(JSON.stringify(res));
         
          if (res.status == 200) {
           // this.resData = new AppResponse(res);
           this.getComment();
          } else if (res.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resDatas.errors}`);
          }
        }
      });
    }

  }

  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editcomment = 1;
      }
    }

  }


  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      console.log(this.updategeneralCommentForm.value);
      if(this.isArcLoggedIn) {
        this.updategeneralCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.updategeneralCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.updategeneralCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.updategeneralCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.updategeneralCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resDatas.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){
    
      this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
      // console.log(JSON.stringify(this.generalCommentForm.value));
      if(confirm("Are you sure you want to delete comment")){
        this.httpService
        .update(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.DELETE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resDatas.errors}`
              );
            }
          }
        });

      }
  }
}
