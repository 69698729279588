import { Component, OnInit } from "@angular/core";
import { AppResponse } from "src/app/model/AppResponse";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "../../utils/common-service";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";
import { environment } from "projects/user-portal/src/environments/environment";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { AppRegExConstants } from "../../utils/AppRegExConstants";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

declare var jQuery: any;
declare var Stripe: any;
var formData = new FormData();
var groupdueids = new FormData();
let pdata;
var elements;
var formDatahalf = new FormData();

@Component({
  selector: "app-user-due",
  templateUrl: "./user-due.component.html",
  styleUrls: ["./user-due.component.css"],
  providers: [NgxSpinnerService],
})
export class UserDueComponent implements OnInit {
  appResponses: AppResponse;
  clientSecret: string;
  stripe: any;
  payingamount: any;
  card: any;
  pubkey: String;
  checkcount: number = 0;
  checkedamount: any = [];
  paymentSubmit: boolean = false;
  payment: any;
  initial: string;
  email: string;
  userName: string;
  userPayForm: FormGroup;
  appResponse: AppResponse;
  resData: AppResponse;
  //modified by aishwaryaa for HB-712 05/01/2021
  minimumAmountOfTotalAmount: number;
  //code end
  specialAssesmentCount: number = 0;
  requireAmount: boolean;
  firstDueId: any;
  map = new Map();
  mapOfBack = new Map();
  invalidMaxAmt: boolean;
  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end
  paymentMessage: any;
  showpaymentsuccessmessage: boolean = false;
  showpaymentdangermessage: boolean = false;

  client_secret: any;
  paymentstatus: any;
  halfcard: FormGroup;
  requirederror: boolean;
  todaydate: Date;
  yearerror: boolean;
  montherror: boolean;
  amountdisable: boolean = false;

  constructor(
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private tokenService: TokenStorageService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private aroute: ActivatedRoute
  ) {}

  ngOnInit() {
    // Swal.fire('Payment Successfull')
    // Swal.fire({
    //   title: 'Error!',
    //   text: 'Do you want to continue',
    //   icon: 'error',
    //   confirmButtonText: 'Cool'
    // })
    this.aroute.queryParams.subscribe((params) => {
      this.paymentstatus = params["payment"];
      console.log(params);
    });
    console.log(this.paymentstatus);

    if (this.paymentstatus == "success") {
      this.savepaymentid();
    }
    // console.log(this.checkedamount);

    this.halfcard = this.formBuilder.group({
      month: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
      year: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
    });

    this.userName = this.tokenService.getuserName();
    this.email = this.tokenService.getEmail();

    // added by gopal for HB-611 start on 10/12/2021
    if (localStorage.getItem("isGULoggedIn") == "true") {
      this.isGULoggedIn = "true";
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      this.isBMLoggedIn = "true";
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      this.isArcLoggedIn = "true";
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      this.isHOLoggedIn = "true";
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      this.isMCLoggedIn = "true";
    }
    // added by goapl for HB-611 end
    this.userPayForm = this.formBuilder.group({
      amount: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.AMOUNT)],
      ],
    });

    this.spinner.show();

    if (this.totalAmount <= 0) {
      this.userPayForm.controls["amount"].setValue("");
      this.userPayForm.controls["amount"].disable();
    } else {
      this.userPayForm.controls["amount"].enable();
    }

    this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.GET_ALL_CURRENT_DUE_OF_HO
      )
      .subscribe((data) => {
        console.log(data);

        if (data.status === 401) {
          //401 token related issue
          this.spinner.hide();
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          //this.router.navigate(['home']);
          this.spinner.hide();
        } else if (data.status === 200) {
          this.spinner.hide();
          this.appResponse = new AppResponse(data);
          // console.log(this.appResponse);

          for (
            let index = 0;
            index < this.appResponse.data[0].length;
            index++
          ) {
            if (index == this.appResponse.data[0].length - 1) {
              this.map.set(
                this.appResponse.data[0][index].groupUserDueId,
                this.appResponse.data[0][index].groupUserDueId
              );
              if (index > 0) {
                this.mapOfBack.set(
                  this.appResponse.data[0][index].groupUserDueId,
                  this.appResponse.data[0][index - 1].groupUserDueId
                );
              } else {
                this.mapOfBack.set(
                  this.appResponse.data[0][index].groupUserDueId,
                  this.appResponse.data[0][index].groupUserDueId
                );
              }
            } else {
              if (index > 0) {
                this.mapOfBack.set(
                  this.appResponse.data[0][index].groupUserDueId,
                  this.appResponse.data[0][index - 1].groupUserDueId
                );
              }

              this.map.set(
                this.appResponse.data[0][index].groupUserDueId,
                this.appResponse.data[0][index + 1].groupUserDueId
              );
            }
          }

          if (this.appResponse.data[0].length > 0) {
            this.firstDueId = this.appResponse.data[0][0].groupUserDueId;
            setTimeout(() => {
              $("#" + this.firstDueId).prop("disabled", false);
              this.spinner.hide();
              // console.log(" =======>>> " + this.firstDueId)
            }, 2000);
          } else {
            this.spinner.hide();
          }
          this.spinner.hide();
        }
      });

    // this.userPayForm.controls['amount'].disable();
  }

  ngAfterViewInit(): void {}

  get f() {
    return this.userPayForm.controls;
  }
  lenghtdisable() {
    if (this.checkedamount.length >= 2) {
      // console.log(this.checkedamount.length);
      this.amountdisable = true;
      this.userPayForm.controls["amount"].disable();
      // console.log(this.amountdisable);
    } else {
      this.amountdisable = false;
      // console.log(this.amountdisable);
    }
  }

  monthchanged($event) {
    this.requirederror = false;
    console.log($event.target.value);

    if ($event.target.value > 12) {
      this.montherror = true;
    } else {
      this.montherror = false;
    }
    if ($event.target.value == "") {
      this.montherror = false;
    }

    if ($event.target.value == 0) {
      this.montherror = true;
    }
  }

  onCardhalfSubmit() {
    if (this.montherror || this.yearerror) {
      return;
    }
    if (
      this.halfcard.controls["month"].value == "" ||
      this.halfcard.controls["year"].value == ""
    ) {
      this.requirederror = true;
      return;
    } else {
      this.requirederror = false;
    }

    formDatahalf.delete("month");
    formDatahalf.delete("year");

    formDatahalf.append("month", this.halfcard.controls["month"].value);
    formDatahalf.append("year", this.halfcard.controls["year"].value);

    console.log(this.halfcard.controls["month"].value);
    console.log(this.halfcard.controls["year"].value);

    this.httpService
      .save(
        formDatahalf,
        environment.BASE_URL + ApplicationURIConstants.UPDATE_USER_CARD_DETAILS
      )
      .subscribe((data) => {
        console.log(data);
        if (data.status == 200) {
          this.paymentMessage = data.message;
          this.showpaymentsuccessmessage = true;
          this.showpaymentdangermessage = false;
          setTimeout(() => {
            this.showpaymentsuccessmessage = false;
            this.showpaymentdangermessage = false;
          }, 2000);
          this.ngOnInit();
          // window.location.reload();
        } else {
          this.paymentMessage = data.message;
          this.showpaymentsuccessmessage = false;
          this.showpaymentdangermessage = true;
          setTimeout(() => {
            this.showpaymentsuccessmessage = false;
            this.showpaymentdangermessage = false;
          }, 2000);
          this.ngOnInit();
          //  window.location.reload();
        }
      });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  yearchanged($event) {
    this.requirederror = false;
    console.log($event.target.value);

    this.todaydate = new Date();

    console.log(this.todaydate.getFullYear() + 10);
    if (
      this.todaydate.getFullYear() <= $event.target.value &&
      $event.target.value < this.todaydate.getFullYear() + 10
    ) {
      if (this.halfcard.controls["month"].value < this.todaydate.getMonth()) {
        this.yearerror = true;
      } else {
        this.yearerror = false;
      }
      this.yearerror = false;
    } else {
      this.yearerror = true;
    }
    if ($event.target.value == "") {
      this.yearerror = false;
    }

    if ($event.target.value == 0) {
      this.yearerror = true;
    }
  }

  deletecard() {
    if (
      confirm(
        "Are you sure you want to delete this card information? this will be a undone process"
      )
    ) {
      // console.log(id);
      this.httpService
        .get(environment.BASE_URL + ApplicationURIConstants.DELETE_USER_CARD)
        .subscribe((data) => {
          console.log(data);
          if (data.status == 200) {
            this.paymentMessage = "Card Deleted Successfully";
            this.showpaymentsuccessmessage = true;
            this.showpaymentdangermessage = false;
            setTimeout(() => {
              this.showpaymentsuccessmessage = false;
            }, 2000);
            this.ngOnInit();
          }
        });
    }
  }

  savepaymentid() {
    this.spinner.show();
    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.SAVE_USER_PAYMENT_CARDS +
          "?payment=" +
          this.paymentstatus
      )
      .subscribe((data) => {
        console.log(data);

        if (data.status === 401) {
          //401 token related issue
          this.spinner.hide();
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          //this.router.navigate(['home']);
          this.spinner.hide();
        } else if (data.status === 200) {
          this.spinner.hide();
          this.paymentMessage = "Card Details Added Successfully";
          this.showpaymentsuccessmessage = true;
          this.showpaymentdangermessage = false;

          setTimeout(() => {
            this.showpaymentsuccessmessage = false;
          }, 2000);
          this.router.navigate(["userdue"]);
        }
      });
  }
  createelement() {
    const { error } = this.stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element

      elements,
      confirmParams: {
        return_url: environment.refreshurl,
      },
    });

    // console.log(response + "gopal");

    console.log(error == undefined);

    if (error || error == undefined) {
      const messageContainer = document.querySelector("#error-message");
      messageContainer.textContent = error.message;
    } else {
      console.log("success");
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.paymentMessage = "Card Details Added Successfully";
      this.showpaymentsuccessmessage = true;
    }
  }
  addpaymentcard() {
    console.log("payment add");

    this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.USER_ADD_PAYMENT_CARDS
      )
      .subscribe((data) => {
        console.log(data);

        if (data.status == 401) {
          this.spinner.hide();
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        }
        if (data.status == 400) {
          this.spinner.hide();
          this.paymentMessage =
            "Your payment configuration is not subscribed to your HOA. Please contact the group admin or a board member.";
          this.showpaymentsuccessmessage = false;
          this.showpaymentdangermessage = true;
          setTimeout(() => {
            this.showpaymentsuccessmessage = false;
            this.showpaymentdangermessage = false;
          }, 3000);
          return;
        }
        if (data.status == 200) {
          this.client_secret = data.data.client_secret;
          this.stripe = new Stripe(data.data.groupstripekey);

          const options = {
            clientSecret: this.client_secret,
          };

          elements = this.stripe.elements(options);

          const paymentElement = elements.create("payment");
          paymentElement.mount("#payment-element");
        }
      });
  }

  submitted = false;

  totalAmount: number = 0;

  checkBoxClickEvent(due: any) {
    this.userPayForm.setErrors(null);
    this.requireAmount = false;
    this.invalidMaxAmt = false;

    if ($("#" + due.groupUserDueId).prop("checked") == true) {
      this.checkcount++;

      this.checkedamount.push(due);
      console.log("length of added " + this.checkedamount.length);
      if (this.checkedamount.length > 2) {
        console.log("inside length");

        this.userPayForm.controls["amount"].disable();
        this.userPayForm.controls["amount"].disable();
        this.userPayForm.controls["amount"].disable();
      } else {
        this.userPayForm.controls["amount"].enable();
      }

      if (this.appResponse.data[0].length > 1) {
        $("#" + this.map.get(due.groupUserDueId)).prop("disabled", false);
        $("#" + this.mapOfBack.get(due.groupUserDueId)).prop("disabled", true);
      }

      this.totalAmount = Number(this.totalAmount + due.amount);
      this.userPayForm.controls["amount"].setValue(this.totalAmount);

      if (due.dueType == ApplicationConstants.SPECIALASSESMENT_LABEL) {
        this.specialAssesmentCount = this.specialAssesmentCount + 1;

        this.calculateMinimumAmount();
      } else if (due.amount > 0) {
        this.calculateMinimumAmount();
      }

      if (due.amount > 0 && this.specialAssesmentCount == 0) {
        this.userPayForm.controls["amount"].enable();
      }
    } else if ($("#" + due.groupUserDueId).prop("checked") == false) {
      this.checkedamount.pop();
      this.checkcount--;
      if (this.checkedamount.length > 1) {
        this.userPayForm.controls["amount"].disable();
      } else {
        this.userPayForm.controls["amount"].enable();
      }

      groupdueids.delete("groupuserdueid");

      if (this.appResponse.data[0].length > 1) {
        $("#" + this.mapOfBack.get(due.groupUserDueId)).prop("disabled", false);
        if (this.map.get(due.groupUserDueId) != due.groupUserDueId) {
          $("#" + this.map.get(due.groupUserDueId)).prop("disabled", true);
          $("#" + this.map.get(due.groupUserDueId)).prop("checked", false);
        }
      }

      this.totalAmount = Number(this.totalAmount - due.amount);
      if (this.totalAmount > 0) {
        this.userPayForm.controls["amount"].setValue(this.totalAmount);
        // this.userPayForm.controls['amount'].enable();//added by gopal
        //added by aishwaryaa for HB-712 05/01/2021
        this.calculateMinimumAmount();
      } else {
        this.userPayForm.controls["amount"].setValue("");
        this.userPayForm.controls["amount"].disable();
      }

      if (due.dueType == ApplicationConstants.SPECIALASSESMENT_LABEL) {
        this.specialAssesmentCount = this.specialAssesmentCount - 1;
      } else if (due.amount > 0) {
        this.calculateMinimumAmount();
      }

      if (this.specialAssesmentCount < 1 && this.totalAmount > 0) {
        this.userPayForm.controls["amount"].enable();

        this.calculateMinimumAmount();
      }
    } else {
      this.userPayForm.controls["amount"].setValue("");
      this.userPayForm.controls["amount"].disable();
    }
  }

  minimumAmountPer: number;
  calculateMinimumAmount() {
    this.minimumAmountPer = Number(
      this.tokenService.getGroupConfigByKey("MinimumAmountInPercentage")
    );
    this.minimumAmountOfTotalAmount =
      this.totalAmount * (this.minimumAmountPer / 100);
    // console.log(this.minimumAmountOfTotalAmount)
  }

  //HB-424 Hevisa Patel (Added second else if condition for checking max amount) 29-01-2021
  onKeyUpForAmount(event: any) {
    if (
      event.target.value < this.minimumAmountOfTotalAmount ||
      isNaN(this.minimumAmountOfTotalAmount)
    ) {
      this.userPayForm.setErrors({ invalid: true });
      this.requireAmount = true;
      this.invalidMaxAmt = false;
    } else if (event.target.value > this.totalAmount) {
      this.userPayForm.setErrors({ invalid: true });
      this.invalidMaxAmt = true;
    } else {
      this.userPayForm.setErrors(null);
      this.requireAmount = false;
      this.invalidMaxAmt = false;
    }
  }

  // paymentSubmit(result){
  //   console.log("result "+ result);
  //   console.log("pay"+this.checkedamount);

  // }

  showmessage() {
    this.spinner.hide();
    this.paymentMessage = "Payment Card is not added to the account";
    this.showpaymentsuccessmessage = false;
    this.showpaymentdangermessage = true;
    setTimeout(() => {
      this.showpaymentdangermessage = false;
      this.showpaymentsuccessmessage = false;
    }, 2000);
  }

  onSubmit() {
    this.spinner.show();
    if (this.userPayForm.controls["amount"].value == "") {
      this.spinner.hide();
      this.paymentMessage =
        "Please select atleast one due to continue payment process";
      this.showpaymentsuccessmessage = false;
      this.showpaymentdangermessage = true;
      setTimeout(() => {
        this.showpaymentdangermessage = false;
        this.showpaymentsuccessmessage = false;
      }, 2000);
      return;
    }

    if (!this.appResponse.data[1]) {
      this.spinner.hide();
      this.paymentMessage = "Payment Card is not added to the account";
      this.showpaymentsuccessmessage = false;
      this.showpaymentdangermessage = true;
      setTimeout(() => {
        this.showpaymentdangermessage = false;
        this.showpaymentsuccessmessage = false;
      }, 2000);
      return;
    }

    this.submitted = true;
    if (this.userPayForm.invalid) {
      console.log(JSON.stringify(this.userPayForm.value));
      console.log("----->> EditUser Invalid <<------");
      this.spinner.hide();
      return;
    } else {
      console.log("gopal");
      if (
        confirm(
          "2.9% + 30 cents in additional charges will be added for the payment processing."
        )
      ) {
        console.log("true");
        for (var key in this.checkedamount) {
          // console.log(typeof(key));
          // console.log(typeof(this.checkedamount.length));

          // console.log(Number(key)==this.checkedamount.length-1);
          console.log("before loop");

          if (Number(key) == this.checkedamount.length - 1) {
            console.log("end loop");
            this.spinner.hide();

            Swal.fire("Payment Successfull");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }

          this.spinner.show();
          formData.delete("amount");
          formData.delete("dueType");
          formData.delete("groupDueId");
          formData.delete("groupUserDueId");
          formData.delete("status");
          formData.delete("wavedOffAmount");
          formData.delete("stripe");
          formData.delete("isminimum");
          formData.append("amount", this.userPayForm.controls["amount"].value);
          formData.append("dueType", this.checkedamount[key].dueType);
          formData.append("groupDueId", this.checkedamount[key].groupDueId);
          formData.append(
            "groupUserDueId",
            this.checkedamount[key].groupUserDueId
          );
          formData.append("status", this.checkedamount[key].status);
          formData.append(
            "wavedOffAmount",
            this.checkedamount[key].wavedOffAmount
          );
          formData.append("stripe", JSON.stringify(this.checkedamount));
          if (this.checkedamount.length > 1) {
            formData.append("isminimum", "0");
          } else {
            formData.append("isminimum", "1");
          }
          this.httpService
            .save(
              formData,
              environment.BASE_URL + ApplicationURIConstants.SAVEUSERPAYMENT
            )
            .subscribe((data) => {
              console.log(data);
              if (data.status == 200) {
                // this.paymentMessage = "Payment Successfull";
                // this.showpaymentsuccessmessage = true;
                // this.showpaymentdangermessage = false;
                // this.totalAmount = 0;
                // setTimeout(() => {
                //   this.showpaymentsuccessmessage = false;
                // this.showpaymentdangermessage = false;
                // }, 2000);
                this.ngOnInit();
              } else {
                // this.paymentMessage = "Payment Successfull";
                // this.showpaymentsuccessmessage = false;
                // this.showpaymentdangermessage = true;
                // this.totalAmount = 0;
                // setTimeout(() => {
                //   this.showpaymentsuccessmessage = false;
                // this.showpaymentdangermessage = false;
                // }, 2000);
                this.ngOnInit();
              }
            });
        }
      } else {
      }
    }
  }
}
