import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { startWith, map } from 'rxjs/operators';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as FileSaver from 'file-saver';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';

@Component({
  selector: 'app-edit-mr',
  templateUrl: './edit-mr.component.html',
  styleUrls: ['./edit-mr.component.css']
})
export class EditMRComponent implements OnInit {

  maintenanceRequestId: string;
  editMaintenanceRequestForm: FormGroup;
  fileUploadList: string[] = [];
  appResponse: AppResponse;
  resData: AppResponse;
  documentname: string;
  fileNameContentType: string;
  fileNameExtension: string;
  submitted = false;
  byLaws: any[] = [];
  byLawsListTopic: any[] = [];
  flag: boolean;
  map = new Map();
  category:any[] = [];
  categoryId : any;
  uploadedBy : string;
  // Added By Hema shanker for HB-577 on 21/12/2021 Start
  fix: boolean;
  request: boolean;
  requesttype: string=null;
  // Added By Hema shanker for HB-577 on 21/12/2021 End

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  private maintenanceRequestsEdit = environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUESTS_EDIT;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private tokenService: TokenStorageService) { }
  filteredOptions: Observable<String[]>;
  byLawsDesc = new FormControl('');
  byLawsTopic = new FormControl('');
  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    this.activatedRoute.params.subscribe(params => {
      this.maintenanceRequestId = params.id;
      // console.log("maintenanceRequestId" + this.maintenanceRequestId)
    });
    this.editMaintenanceRequestForm = this.formBuilder.group({
      "description": ['', [Validators.required]],
      "moreInfo": [''],
      "urgent": [''],
      "safetyThreat": [''],
      "byLowDescription": [''],
      "category": ['', [Validators.required]],
      "images": [''],
      "byLawsTopic": [''],
      // Added By Hema shanker for HB-577 on 21/12/2021 Start
      "requesttype":['',[Validators.required]],
      "maintenancerequestnumber":["",[Validators.required]]
      // Added By Hema shanker for HB-577 on 21/12/2021 End
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUEST + this.maintenanceRequestId).subscribe(data => {
      this.appResponse = new AppResponse(data);
console.log(this.appResponse);

      // Added By Hema shanker for HB-577 on 21/12/2021 Start
      if (this.appResponse.data.fix == true) {
        this.appResponse.data.requesttype = "fix";
      } else if(this.appResponse.data.request == true){
      this.appResponse.data.requesttype = "request";
      }
      // Added By Hema shanker for HB-577 on 21/12/2021 End
      for (var i = 0; i < this.appResponse.data.documentName.length; i++) {
        // console.log("docName:" + this.appResponse.data.documentName[i])
        this.map.set(this.appResponse.data.documentName[i].substr(14), this.appResponse.data.documentName[i]);
      }
      if(data.status ===200){
        this.fillTextfeildData();
      }
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
    // this.httpService.get(environment.BASE_URL + ApplicationURIConstants.BY_LAWS).subscribe(res => {
    //   console.log("2");
    //   console.log(JSON.stringify(res));
    //   this.resData = new AppResponse(res);
    //   console.log(JSON.stringify(this.resData.data));
    //   this.byLaws = this.resData.data;

    //   for (var i = 0; i < this.byLaws.length; i++) {
    //     this.byLawsListTopic.push(this.byLaws[i].byLawsTopic);
    //   }
    //   console.log("btopic:" + this.byLawsListTopic)
    //   this.fillTextfeildData();

    //   if (res.status === 401) {
    //     console.log("401");
    //     //401 token related issue
    //     this.tokenService.clearSession();
    //     this.router.navigate(['login']);
    //   } else if (res.status === 403) {
    //     //403 URL not accessible
    //     console.log("403");
    //     this.router.navigate(['home']);
    //   }
    // });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_MR).subscribe(res => {

      this.resData = new AppResponse(res);
      this.byLaws = this.resData.data.bylawsList;
      this.category = this.resData.data.categoryMR;

      for (var i = 0; i < this.byLaws.length; i++) {
        this.byLawsListTopic.push(this.byLaws[i].byLawsTopic);
      }
      this.fillTextfeildData();
      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });


    this.filteredOptions = this.byLawsTopic.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );
  }

  // convenience getter for easy access to form fields
  get maintenance() { return this.editMaintenanceRequestForm.controls; }
  onFileSelect(event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }

  onKeyUp(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.byLawsTopic.setErrors(null);
      this.editMaintenanceRequestForm.setErrors(null);
    } else {
      for (var i = 0; i < this.byLaws.length; i++) {
        // Modified By Hema shanker for HB-577 on 21/12/2021 Start
        if ((this.byLaws[i].byLawsTopic == event.target.value) && (this.editMaintenanceRequestForm.controls['requesttype'].value != null)) {
          // Modified By Hema shanker for HB-577 on 21/12/2021 End
          this.editMaintenanceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
          // this.editMaintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Type:-" + this.byLaws[i].description);
          this.editMaintenanceRequestForm.controls['byLowDescription'].setValue("ByLaws Description:-" + this.byLaws[i].description);
          this.byLawsTopic.setErrors(null);
          break;
        } else {
          this.editMaintenanceRequestForm.controls['byLawsTopic'].setValue(null);
          this.editMaintenanceRequestForm.controls['byLowDescription'].setValue(null);
          this.byLawsTopic.setErrors({ invalid: true });
          this.editMaintenanceRequestForm.setErrors({ 'invalid': true });
        }
      }
    }
  };
  onSubmit() {
    this.submitted = true;
    // console.log("onsubmit" + JSON.stringify(this.editMaintenanceRequestForm.value) + "byLawsTopic" + this.editMaintenanceRequestForm.controls['byLawsTopic'].value);
    if (this.editMaintenanceRequestForm.invalid) {
      return;
    } else {

      if(localStorage.getItem("isHOLoggedIn")){
        this.uploadedBy = "HO";
      }else if(localStorage.getItem("isArcLoggedIn")){
        this.uploadedBy = "ARC";
      }else if(localStorage.getItem("isBMLoggedIn")){
        this.uploadedBy = "BM";
      }else if(localStorage.getItem("isHOLoggedIn")){
        this.uploadedBy = "MC";
      } else{
        this.uploadedBy = "GU";
      }
      var formData = new FormData();

      // Add multiple file in formData
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("images", this.fileUploadList[i]);
      }

      if ($("#urgent").prop("checked") == true) {
        this.editMaintenanceRequestForm.value.urgent = true;
      } else {
        this.editMaintenanceRequestForm.value.urgent = false;
      }

      if ($("#safetyThreat").prop("checked") == true) {
        this.editMaintenanceRequestForm.value.safetyThreat = true;
      } else {
        this.editMaintenanceRequestForm.value.safetyThreat = false;
      }
      // Added By Hema shanker for HB-577 on 21/12/2021 Start
      if (this.editMaintenanceRequestForm.controls['requesttype'].value == "fix") {
        this.editMaintenanceRequestForm.value.fix = true;
      } else {
        this.editMaintenanceRequestForm.value.fix = false;
      }
      if (this.editMaintenanceRequestForm.controls['requesttype'].value == "request") {
        this.editMaintenanceRequestForm.value.request = true;
      } else {
        this.editMaintenanceRequestForm.value.request = false;
       }
       // Added By Hema shanker for HB-577 on 21/12/2021 End

      formData.append("description", this.editMaintenanceRequestForm.controls['description'].value);
      formData.append("moreInfo", this.editMaintenanceRequestForm.controls['moreInfo'].value);
      formData.append("urgent", this.editMaintenanceRequestForm.value.urgent);
      formData.append("safetyThreat", this.editMaintenanceRequestForm.value.safetyThreat);
      formData.append("category", this.editMaintenanceRequestForm.controls['category'].value);
      formData.append("byLawsTopic", this.byLawsTopic.value);
      formData.append("maintenanceRequestId", this.maintenanceRequestId);
	    formData.append("uploadedBy", this.uploadedBy);
	    formData.append("maintenancerequestnumber", this.editMaintenanceRequestForm.controls['maintenancerequestnumber'].value);

      // Added By Hema shanker for HB-577 on 21/12/2021 Start
      formData.append("fix", this.editMaintenanceRequestForm.value.fix);
      formData.append("request", this.editMaintenanceRequestForm.value.request);
      // Added By Hema shanker for HB-577 on 21/12/2021 End
      this.httpService.update(formData, this.maintenanceRequestsEdit)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.editMaintenanceRequestForm.reset();
              $("#category").prop('selectedIndex', 0);
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['maintenanceRequests']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }


  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.byLawsListTopic.filter(option => option.toLowerCase().includes(filterValue));
  }

  getPosts(bylawtopic) {
    // console.log("MAIN:" + bylawtopic)
    for (var i = 0; i < this.byLaws.length; i++) {
      if (this.byLaws[i].byLawsTopic == bylawtopic) {
        // console.log("final:" + this.byLaws[i].description);
        $("#desc").val(this.byLaws[i].description);
        this.editMaintenanceRequestForm.controls["byLowDescription"].setValue(this.byLaws[i].description);
        this.editMaintenanceRequestForm.controls["byLawsTopic"].setValue(this.byLaws[i].byLawsTopic);

      }
    }
  }

  selectByLawChangeHandler(selectedByLaw) {
    for (var i = 0; i < this.byLaws.length; i++) {
      if (this.byLaws[i].byLawsTopic == selectedByLaw) {
        this.editMaintenanceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
        this.editMaintenanceRequestForm.controls['byLowDescription'].setValue(this.byLaws[i].description);
      }
    }
  }
  fillTextfeildData() {

    // console.log("inside fillTextfeildData" + JSON.stringify(this.appResponse.data));

    if (this.appResponse.data != null) {
      for(var i=0; i<this.category.length; i++){
        if(this.appResponse.data.category == this.category[i].category){
          this.categoryId = this.category[i].categoryId;

        }
      }


      this.editMaintenanceRequestForm = this.formBuilder.group({
        "description": [this.appResponse.data.description, Validators.required],
        "moreInfo": [this.appResponse.data.moreInfo],
        "urgent": [this.appResponse.data.urgent],
        "safetyThreat": [this.appResponse.data.safetyThreat, Validators.required],
        "category": [this.categoryId, [Validators.required]],
        "images": [this.appResponse.data.images],
        "byLawsTopic": [''],
         "byLowDescription": [''],
         // Added By Hema shanker for HB-577 on 21/12/2021 Start
         "fix":[this.appResponse.data.fix,[Validators.required]],
         "request":[this.appResponse.data.request,[Validators.required]],
         "requesttype":[this.appResponse.data.requesttype,[Validators.required]],
         "maintenancerequestnumber":[this.appResponse.data.maintenancerequestnumber,[Validators.required]]
         // Added By Hema shanker for HB-577 on 21/12/2021 End
      });


      // console.log("InsideByLawsList" + JSON.stringify(this.byLaws));
      for (let index = 0; index < this.byLaws.length; index++) {
        if (this.byLaws[index].byLawsTopic == this.appResponse.data.byLawsTopic) {
          this.byLawsTopic.setValue(this.byLaws[index].byLawsTopic);
          this.editMaintenanceRequestForm.controls['byLawsTopic'].setValue(this.byLaws[index].byLawsTopic);
          this.editMaintenanceRequestForm.controls['byLowDescription'].setValue(this.byLaws[index].description);
        }
      }
    }
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
        this.resData = new AppResponse(data);
        const byteCharacters = atob(this.resData.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(this.resData.data);
        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
        FileSaver.saveAs(blob, documentName);
      });
  }

}
