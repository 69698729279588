import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from '../../utils/common-service'; 
import * as $ from 'jquery';
import { JwtHelperService } from "@auth0/angular-jwt";
const helper = new JwtHelperService();


@Component({
  selector: 'app-mr-view-by-bm-and-gu',
  templateUrl: './mr-view-by-bm-and-gu.component.html',
  styleUrls: ['./mr-view-by-bm-and-gu.component.css']
})
export class MrViewByBmAndGuComponent implements OnInit {

  reviewMaintenanceRequestForm: FormGroup;
  fileUploadList: string[] = [];
  maintenanceRequestId: string;
  appResponse: AppResponse;
  mrlist: AppResponse;
  resData: AppResponse;
  requestDocumentId: string;
  documentName: string;
  fileNameContentType: string;
  fileNameExtension: string;
  rdAppResponse: AppResponse;
  map = new Map();
  submitted = false;
  userProfileInfoId: any;
  assignedTo: string;
  show: boolean;
  status: string;
  hide: boolean;
  createdby:number;
  assinedto:number;
  detailedOriginalDoc:any = [];
  reviewComplianceRequestForm: FormGroup;
  showMsg : Boolean;
  

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  //general comment
  reqData: any;
  generalCommentForm: FormGroup;
  refId: any;
  updategeneralCommentForm: FormGroup;
  viewOnly : boolean;
  generalCommentList: any[] = [];
  // private activatedRoute: ActivatedRoute, private commonService: CommonService, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private tokenService: TokenStorageService
  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder, private httpService: HttpTransactionService, private http: HttpClient, private router: Router, private tokenService: TokenStorageService, private commonService: CommonService) { }

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    this.show = false;
    this.hide = true;
    this.showMsg=false;
    this.userProfileInfoId = this.tokenService.getuserProfileId();
    this.activatedRoute.params.subscribe(params => {
      this.maintenanceRequestId = params.id;
      this.refId = params.id;
    });

   

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUEST_VIEW_BY_BM_AND_EXTERNAL_USER + this.maintenanceRequestId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      console.log("appResponseeeee:" + JSON.stringify(this.appResponse.data));
    // console.log(this.userProfileInfoId);
      
//       console.log("appResponseeeee:" + JSON.stringify(this.appResponse.data.status));
// console.log(this.hide);

      if(this.appResponse.data.status == "CLOSED")
      {
        this.hide =false;
      }
// console.log(this.hide);

      if(this.appResponse.data.originalDoc){
       
        for(let i=0; i<this.appResponse.data.originalDoc.length; i++) {
          this.detailedOriginalDoc.push({
            "docUploadedBy": this.appResponse.data.docUploadedBy[i],
            "originalDoc": this.appResponse.data.originalDoc[i],
            "createdOn" : this.appResponse.data.createdOn[i]
          });

        }
      }
      this.assignedTo = this.appResponse.data.assignedTo;
      for (var i = 0; i < this.appResponse.data.documentName.length; i++) {
        // console.log("docName:" + this.appResponse.data.documentName[i])
        this.map.set(this.appResponse.data.documentName[i].substr(14), this.appResponse.data.documentName[i]);
      }

      if (this.appResponse != undefined) {
        this.reviewMaintenanceRequestForm = this.formBuilder.group({
          'status': [this.appResponse.data.status, [Validators.required]],
          'maintenanceRequestId': [this.maintenanceRequestId, [Validators.required]],
          'images': [''],
          'createdby':[this.userProfileInfoId,[Validators.required]],
          'assignedto':[this.appResponse.data.assignedTo,[Validators.required]],
          'commentedby': ['BOARD MEMBER',Validators.required],
          "reviewComments":['',Validators.required],
        });
      }
    
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['dashboardbm']);
      }
    });

    //general comment
    this.generalCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
    });
    this.getComment();

  }
  ngAfterViewInit() {
    // console.log("it is in tyhe after in it");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_MAINTENANCE_REQUESTS_REVIEW + "/" + this.maintenanceRequestId).subscribe(data => {
      // console.log(JSON.stringify(data));
      this.mrlist = new AppResponse(data);

       //HB-423 Hevisa Patel 28-01-2021 -- Start     
      setTimeout(() => {
        $('#reviewCommentTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 4,
            orderable: false
          },
          ]
        });
      }, 2000);  //2s
      //HB-423 Hevisa Patel 28-01-2021 -- End     
      this.reviewComplianceRequestForm = this.formBuilder.group({
        'reviewOutCome': [this.appResponse.data.complianceRequest.status, [Validators.required]],
        'reviewComments': ['', [Validators.required]],               
        'byLawsTopic': [this.appResponse.data.complianceRequest.byLawsTopic, [Validators.required]],              
        'complianceRequestId': [this.maintenanceRequestId, [Validators.required]],
        'assignedTo' : [this.appResponse.data.complianceRequest.userProfileInfoId,[Validators.required]]            
      });
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
    
  }

  get maintenance() { return this.reviewMaintenanceRequestForm.controls; }

  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }

  private maintenanceRequestsUpdate = environment.BASE_URL + ApplicationURIConstants.REVIEW_UPDATE_BY_BM;


  reviewUpdate() {
    this.submitted = true;
    if (this.maintenance.status.invalid) {
      // console.log("invalid:" + this.maintenance.status.invalid)
      return;
    }
    else {
      // console.log("in else"+JSON.stringify(this.reviewMaintenanceRequestForm.controls));
      var formData = new FormData();
      // Add multiple file in formData
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("images", this.fileUploadList[i]);
      }
      formData.append("status", this.reviewMaintenanceRequestForm.controls['status'].value);
      // formData.append("externalusercomment", this.reviewMaintenanceRequestForm.controls['externalusercomment'].value);
      formData.append("maintenanceRequestId", this.maintenanceRequestId);
      formData.append("commentedBy",this.reviewMaintenanceRequestForm.controls['commentedby'].value);
      formData.append("createdby",this.reviewMaintenanceRequestForm.controls['createdby'].value);
      formData.append("assignedTo",this.reviewMaintenanceRequestForm.controls['assignedto'].value);
      formData.append("reviewComments",this.reviewMaintenanceRequestForm.controls['reviewComments'].value);
      // console.log("form:" + JSON.stringify(this.reviewMaintenanceRequestForm.value));
      // console.log(formData);
      
      this.httpService.update(formData, this.maintenanceRequestsUpdate)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              // console.log("hari") this.submitted = false;
              // this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL,ApplicationConstants.DANGER_LABLE,
                // `${this.resData.errors}`
              // );
              this.showMsg=true;
              setTimeout(() => {
                this.showMsg=false;
             }, 3000); 
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL,ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                 this.router.navigate(['maintenance-listbyBM']);
              }, 3000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
          
            
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

    }
  }
  getStatus(status) {
    // console.log("status" + status)
    if (status == 'CLOSED') {
      this.show = true;

    }
    else {
      this.show = false;
    }

  }


   // get the document of byte array.
   getDocument(documentName: string) {
    // console.log("docName1:" + documentName)

    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
        this.resData = new AppResponse(data);
        
        //Start the code for Base64 to byte[]
        const byteCharacters = atob(this.resData.data);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(this.resData.data);

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
        //End the code for Base64 to byte[]

        //Save the file
        FileSaver.saveAs(blob, documentName);
      });
  }

  //general comment
  getComment() {
    this.httpService
      .save(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.GET_GENERAL_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            this.resData = new AppResponse(res);
            this.generalCommentList = this.resData.data;
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });
      this.generalCommentForm = this.formBuilder.group({
        refId: [this.refId, [Validators.required]],
        refTable: ["MR", [Validators.required]],
        comment: ["", [Validators.required]],
        editComment:["",],
        generalCommentId:["",]
      });
  
      this.updategeneralCommentForm = this.formBuilder.group({
        refId: [this.refId, [Validators.required]],
        refTable: ["MR", [Validators.required]],
        comment: ["", [Validators.required]],
        editComment:["",],
        generalCommentId:["",]
      });
  }

  get f() {
    return this.generalCommentForm.controls;
  }

  onSubmit() {
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.generalCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.generalCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.generalCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.generalCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.generalCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.generalCommentForm.value));

      this.httpService
        .save(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.ADD_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editcomment = 1;
      }
    }

  }


  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else { console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.updategeneralCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.updategeneralCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.updategeneralCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.updategeneralCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.updategeneralCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){


      this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
      // console.log(JSON.stringify(this.generalCommentForm.value));
      if(confirm("Are you sure you want to delete comment")){
        this.httpService
        .update(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.DELETE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });

      }
  }


  //General comment


}
