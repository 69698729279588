import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as XLSX from 'xlsx';

import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-home-activity-report',
  templateUrl: './home-activity-report.component.html',
  styleUrls: ['./home-activity-report.component.css'],
  providers: [NgxSpinnerService]
 
})
export class HomeActivityReportComponent implements OnInit {
  @ViewChild('TABLE',{static:false}) table:ElementRef;
  reportFormGroup: FormGroup;
  resData: any;
  responseData : any;
  rongHo: boolean;
  homeAddressList = [];
  filteredHomes: Observable<String[]>;
  homeAddress = new FormControl('', [Validators.required]);
  homeaddresslenght:any;
   // added by gopal for HB-611 start on 10/12/2021
   isGULoggedIn: string;
   isBMLoggedIn: String;
   isArcLoggedIn: String;
   isHOLoggedIn: String;
   isMCLoggedIn: String;
   // added by goapl for HB-611 end


  constructor(private httpService: HttpTransactionService,
    private formBuilder: FormBuilder, 
    private commonService: CommonService,
    private router: Router, 
    private spinner: NgxSpinnerService) {  
  
  }

  ngOnInit() {
      // added by gopal for HB-611 start on 10/12/2021
      if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end
    
    this.spinner.show();
     
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_ALL_HOME_LIST_BY_GROUP).subscribe(res => {
     
      this.responseData = new AppResponse(res);
      console.log(this.responseData);
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        if (res.status == 200) {
         
          this.homeAddressList = this.responseData.data;
          this.homeaddresslenght=this.homeAddressList.length;
          // this.restoredata(this.homeaddresslenght);
          this.spinner.hide();
        } else if (res.status === 401) {
          //401 token related issue
          this.spinner.hide();
          this.router.navigate(['login']);
        } else if (res.status == 403) {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
          //this.router.navigate(['home']);
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
        }
      }

    


    });
    this.reportFormGroup = this.formBuilder.group({     
      'homeProfileId': ['', [Validators.required]],
      'type': ['ALL', [Validators.required]],
      'HoDetails': ['']
    });
      //added by gopal
      if (localStorage.getItem("homeactivityreportdata") != null) {      
        this.resData = new AppResponse(JSON.parse( localStorage.getItem("homeactivityreportdata")) );      
     } 
      //added by gopal

    this.filteredHomes = this.homeAddress.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filterForHomes(value) : [])
    );


  }

  ngAfterViewInit(): void {
   
    this.TableInitialise();
  }

  // convenience getter for easy access to form fields
  get report() { return this.reportFormGroup.controls; }

  onSubmit() {
    $("#messageBox").empty();
    this.spinner.show();
   // console.log(JSON.stringify(this.reportFormGroup.value));
    
    if (this.reportFormGroup.invalid) {
      this.spinner.hide();
     //console.log("invalid")
      return;
    } else {
    
      //console.log(this.reportFormGroup.value);
      this.httpService.save(this.reportFormGroup.value, environment.BASE_URL + ApplicationURIConstants.GET_HOME_ACTIVITY_REPORT)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            //console.log("Response ->>" + JSON.stringify(res));
            console.log(this.resData)
        
            if (res.status == 200) {
              // console.log(res);//added by gopal
              $('#reportTable').dataTable().fnDestroy();
              setTimeout(() => {
                
                this.TableInitialise();
                this.spinner.hide();
              }, 2000);  //3s

            } else if (res.status == 401) {
             // console.log(res);//added by gopal
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              $('#reportTable').dataTable().fnDestroy();
              this.spinner.hide();
              //this.TableInitialise();
              console.log("inside red")
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
              console.log(res.message);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }
  }

  onSubmitExport() {
    $("#messageBox").empty();
    this.spinner.show();
   // console.log(JSON.stringify(this.reportFormGroup.value));
    
    if (this.reportFormGroup.invalid) {
      this.spinner.hide();
     //console.log("invalid")
      return;
    } else {
    
      //console.log(this.reportFormGroup.value);
      this.httpService.save(this.reportFormGroup.value, environment.BASE_URL + ApplicationURIConstants.GET_HOME_ACTIVITY_REPORT)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            //console.log("Response ->>" + JSON.stringify(res));
        
            if (res.status == 200) {
              // console.log(res);//added by gopal
              $('#reportTable').dataTable().fnDestroy();
              setTimeout(() => {
                
                this.TableInitialise();
                this.exportexcel();
                // this.spinner.hide();
              }, 2000);  //3s

            } else if (res.status == 401) {
             // console.log(res);//added by gopal
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              $('#reportTable').dataTable().fnDestroy();
              this.spinner.hide();
              //this.TableInitialise();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }
  }

  TableInitialise(){
    $('#reportTable').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
      // Added by Hema shanker HB-675 on 25/11/2021 Start
      stateSave: true,
      // Added by Hema shanker HB-675 on 25/11/2021 End
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      // Modified by Hema shanker HB-675 on 25/11/2021 Start
      lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
      // Modified by Hema shanker HB-675 on 25/11/2021 End
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }
  TableInitialiseexport(){
    $('#reportTable').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'HomeActivityReportExcelSheet.xlsx';
  exportexcel(): void {
    /* table id is passed over here */
    // let element = document.getElementById('reportTable');
    $('#reportTable').dataTable().fnDestroy();
    setTimeout(() => {
      
      this.TableInitialiseexport();
      this.spinner.hide();
    }, 2000);  //3s
      let element = document.getElementById('reportTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      ws['!cols'] = [];
      ws['!cols'][6] = { hidden: true };
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      //console.log(wb);
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
  
 
  
  //   var createXLSLFormatObj = [];
  //   var xlsHeader = ["Created By", "Type","Status","Created Date","Audit Date"];
  //   var xlsRows=this.resData.data;
  //   createXLSLFormatObj.push(xlsHeader);
  //   $.each(xlsRows, function(index, value) {
  //     var innerRowData = [];
  //    innerRowData.push(value)

  //     // $("tbody").append('<tr><td>' + value.createdByName + '</td><td>' + value.type + '</td><td>' + value.status + '</td><td>' + value.createdDateTime + '</td><td>' + value.auditDateTime + '</td></tr>');
  //     // $.each(value, function(ind,val) {

  //     //     for(var i=0;ind.length>i;ind--){
  //     //       innerRowData.push(val);
  //     //     }
  //     // });
  //     createXLSLFormatObj.push(innerRowData);
  //     console.log(createXLSLFormatObj)
      
  // });

  // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
  // const wb: XLSX.WorkBook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  // XLSX.writeFile(wb, this.fileName);



   

  
  }

  selectHoAddressChangeHandler(selectedHome) {
    // console.log(selectedHome)
    for (var i = 0; i < this.homeAddressList.length; i++) {
      
      if (this.homeAddressList[i].homeprofileid == selectedHome.homeprofileid) {
        this.reportFormGroup.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
        this.reportFormGroup.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
        this.homeAddressList[i].lastname);
      }
    }
  }

  onKeyUpForHomeAddress(event: any) {
    // console.log(event.target.value);
    if( event.target.value.length <= 0 ){
      this.rongHo = false;
      this.homeAddress.setErrors({ 'required': true });
    }else{
      this.rongHo = false;
      for (var i = 0; i < this.homeAddressList.length; i++) {
        if (this.homeAddressList[i].addressline1 == event.target.value) {
          
          this.reportFormGroup.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
          this.reportFormGroup.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
          this.homeAddressList[i].lastname);
          this.homeAddress.setErrors(null);
          this.rongHo = false;
        } else {
          this.reportFormGroup.controls['homeProfileId'].setValue(null);
          this.reportFormGroup.controls['HoDetails'].setValue(null);
          this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;
         
        }
      }
    }
  };

  

  private _filterForHomes(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.homeAddressList.filter(option => option.addressline1.toLowerCase().includes(filterValue));
  }

  displayFn(home: any): string {
    if (home.homeprofileid == undefined) {
      //this.homeAddress.setErrors({ invalid: true })
      return;
    } else {
      return home.addressline1;
    }
  }

  saveData(){
    if (this.resData.status == 200) {
      localStorage.setItem("homeactivityreport", JSON.stringify(this.reportFormGroup.value));
      localStorage.setItem("homeactivityreportdata", JSON.stringify(this.resData));  
    }   
  }
  
}
