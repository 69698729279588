import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from '../../utils/common-service';
import { NgxSpinnerService } from 'ngx-spinner'; 
import { JwtHelperService } from "@auth0/angular-jwt";
const helper = new JwtHelperService();

@Component({
  selector: 'app-group-maintenance-view',
  templateUrl: './group-maintenance-view.component.html',
  styleUrls: ['./group-maintenance-view.component.css'],
  providers: [NgxSpinnerService, DatePipe]
})
export class GroupMaintenanceViewComponent implements OnInit {

  maintenanceRequestId: string;
  // maintenanceRequest: MaintenanceRequest;
  appResponse: AppResponse;
  resData: AppResponse;
  requestDocumentId: string;
  documentName: string;
  fileNameContentType: string;
  fileNameExtension: string;
  rdAppResponse: AppResponse;
  reviewMaintenanceRequestForm: FormGroup;
  approveAndRejectForm: FormGroup;
  map = new Map();
  submitted = false;
  byLawsList: any[] = [];
  reviewResData : AppResponse;
  assigneeData : AppResponse;
  isUpdateEnable : Boolean;
  isApproveEnable : Boolean;
  isRejectEnable : Boolean;
  isAssignToBMEnable : Boolean;
  isStatusEnable : Boolean;
  assigneeList: any[] = [];
  userProfileInfoId : any;
  byLawsDisable : boolean;
  filteredOptions: Observable<String[]>;
  byLawsTopicMat = new FormControl('');
  detailedOriginalDoc:any = [];
  //general comment
  reqData: any;
  generalCommentForm: FormGroup;
  updategeneralCommentForm: FormGroup;
  refId: any;
  viewOnly : boolean;
  generalCommentList: any[] = []; 

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  private maintenanceRequestsUpdate = environment.BASE_URL + ApplicationURIConstants.REVIEW_UPDATE;
  private maintenanceReview = environment.BASE_URL + ApplicationURIConstants.MR_LIST;
  private reviewByBM = environment.BASE_URL + ApplicationURIConstants.REVIEW_BY_BM;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private tokenService: TokenStorageService, private commonService: CommonService, private spinner: NgxSpinnerService, datePipe : DatePipe) { }

  ngOnInit() {    
    
    this.spinner.show();
       // added by gopal for HB-611 start on 10/12/2021
       if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end

    // console.log("ARC:"+this.isArcLoggedIn);
    // console.log("BM"+this.isBMLoggedIn);

    this.activatedRoute.params.subscribe(params => {
      this.maintenanceRequestId = params.id;
      this.refId = params.id;
    });


    this.reviewMaintenanceRequestForm = this.formBuilder.group({
      'reviewOutCome': ['', [Validators.required]],
      'reviewComments': ['', [Validators.required]],
      'byLowDescription': [''],
      'maintenanceRequestId': [this.maintenanceRequestId,[Validators.required]],
      'byLawTopic': [''],
      'assignedToBm':['']
    });

    this.approveAndRejectForm = this.formBuilder.group({
      'reviewOutCome': ['', [Validators.required]],
      'reviewComments': ['',[Validators.required]],
      'maintenanceRequestId': ['',[Validators.required]],
      'byLawTopic': ['',[Validators.required]],
      'assignedToBm':[''],
      "maintenancerequestnumber":['',Validators.required],
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.BY_LAWS).subscribe(res => {
      this.resData = new AppResponse(res);
      for (var i = 0; i < this.resData.data.length; i++) {
        this.byLawsList.push(this.resData.data[i]);
      }

    this.filteredOptions = this.byLawsTopicMat.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );

      if (res.status === 401) {
        //  console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.LIST_MR_REVIEW + this.maintenanceRequestId +'?getAssignee=false').subscribe(res => {
      this.reviewResData = new AppResponse(res);
      setTimeout(() => {
        $('#reviewCommentTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 4,
          },
          ]
        });
      }, 200);
      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUEST + this.maintenanceRequestId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      // console.log("data:"+JSON.stringify(this.appResponse.data));
      this.spinner.hide();
      this.userProfileInfoId = this.tokenService.getuserProfileId();
     for(var i=0; i<this.appResponse.data.documentName.length; i++){
       this.map.set(this.appResponse.data.documentName[i].substr(14),this.appResponse.data.documentName[i]);
     }
     if(this.appResponse.data.originalDoc){
       
        for(let i=0; i<this.appResponse.data.originalDoc.length; i++) {
          this.detailedOriginalDoc.push({
            "docUploadedBy": this.appResponse.data.docUploadedBy[i],
            "originalDoc": this.appResponse.data.originalDoc[i],
            "createdOn" : this.appResponse.data.createdOn[i]
          });
        }
      }
      // console.log("detailedOriginalDoc:"+JSON.stringify(this.detailedOriginalDoc));
       for(var i=0; i<this.appResponse.data.reviewList.length; i++){
         this.assigneeList.push(this.appResponse.data.reviewList[i]);
       }
      //  console.log("LISTASSIGNEE:"+this.assigneeList);
      if(this.isArcLoggedIn && this.appResponse.data.status == 'PENDING'){
        this.isUpdateEnable = true;
        this.isAssignToBMEnable = true;
      }

      if(this.isArcLoggedIn && this.appResponse.data.status == 'PENDING'){
       // this.isStatusEnable = true;
        $('#status').prop('disabled', false);
      }else{
        $('#status').prop('disabled', true);
      }

      for (var i = 0; i < this.assigneeList.length; i++) {
        if((this.isArcLoggedIn && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'ACKNOWLEDGE' && this.appResponse.data.status == 'ACKNOWLEDGE')){

           this.isApproveEnable = true;
            this.isRejectEnable = true;
            this.isUpdateEnable = true;
            this.isAssignToBMEnable = true;
            // Modified by Hema shanker for HB-448 on 14/12/2021 Start 
        }
        else if(this.isBMLoggedIn && this.assigneeList[i].assignedTo == this.userProfileInfoId && (this.appResponse.data.status =='APPROVED' || this.appResponse.data.status == 'REJECTED')){
           this.isApproveEnable = false;
           this.isRejectEnable = false;
        }
        else if(this.isBMLoggedIn && this.assigneeList[i].assignedTo == this.userProfileInfoId && (this.assigneeList[i].reviewOutCome == 'APPROVED' || this.assigneeList[i].reviewOutCome == 'REJECTED')){

           this.isApproveEnable = true;
           this.isRejectEnable = true;
             // Modified by Hema shanker for HB-448 on 14/12/2021 End
        }else if(this.isBMLoggedIn && this.appResponse.data.status == 'PENDINGAPPROVAL' && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'PENDING' ){

           this.isApproveEnable = true;
           this.isRejectEnable = true;
        }
       }
       if((this.isArcLoggedIn && this.appResponse.data.status == 'PENDING')){
        this.isApproveEnable = true;
        this.isRejectEnable = true;
       }

        if((this.isArcLoggedIn && (this.appResponse.data.status == 'PENDINGAPPROVAL' || this.appResponse.data.status == 'APPROVED' || this.appResponse.data.status == 'REJECTED')) || (this.isBMLoggedIn && ( this.appResponse.data.status == 'APPROVED' || this.appResponse.data.status == 'REJECTED'))){
          $('#byLaw').prop('disabled', true);
        }

      //  console.log("disabled:"+this.byLawsDisable)
      if (this.appResponse != undefined) {
        this.reviewMaintenanceRequestForm = this.formBuilder.group({
          'reviewOutCome': [this.appResponse.data.status, [Validators.required]],
          'reviewComments': [''],
          'byLowDescription': [''],
          'maintenanceRequestId': [this.maintenanceRequestId,[Validators.required]],
          'byLawTopic': [this.appResponse.data.byLawsTopic,[Validators.required]],
          'assignedToBm':['']
        });

        this.approveAndRejectForm = this.formBuilder.group({
          'reviewOutCome': [this.appResponse.data.status, [Validators.required]],
          'reviewComments': ['',[Validators.required]],
          'maintenanceRequestId': [this.maintenanceRequestId,[Validators.required]],
          'byLawTopic': [this.appResponse.data.byLawsTopic,[Validators.required]],
          'assignedToBm':[''],
          "maintenancerequestnumber":[this.appResponse.data.maintenancerequestnumber,Validators.required],
        });


        if(this.appResponse.data.byLawsTopic){
          this.byLawsTopicMat.setValue(this.appResponse.data.byLawsTopic);
          for (var i = 0; i < this.byLawsList.length; i++) {
            if (this.byLawsList[i].byLawsTopic == this.appResponse.data.byLawsTopic) {
              this.approveAndRejectForm.controls['byLawTopic'].setValue(this.appResponse.data.byLawsTopic);
              this.reviewMaintenanceRequestForm.controls['byLowDescription'].setValue(this.byLawsList[i].description);
              $('#approve').prop('disabled', false);
              $('#reject').prop('disabled', false);
              this.approveAndRejectForm.setErrors(null);
            }
          }
        }

        for (var i = 0; i < this.byLawsList.length; i++) {
          if (this.byLawsList[i].byLawsTopic == this.appResponse.data.byLawsTopic ) {
            this.byLawsTopicMat.setValue(this.appResponse.data.byLawsTopic);
            this.reviewMaintenanceRequestForm.controls['byLawTopic'].setValue(this.byLawsList[i].byLawsTopic);
            this.reviewMaintenanceRequestForm.controls['byLowDescription'].setValue(this.byLawsList[i].description);

            this.approveAndRejectForm.controls['byLawTopic'].setValue(this.byLawsList[i].byLawsTopic);
          }
        }

      }

      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

    //general comment
    this.generalCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
    });
    this.getComment();
  
  
  }

    // convenience getter for easy access to form fields
    get maintenance() { return this.reviewMaintenanceRequestForm.controls; }

    // convenience getter for easy access to form fields
    get approveAndReject() { return this.approveAndRejectForm.controls; }
   // get the document of byte array.
  getDocument(documentName: string) {
       this.httpService.get(environment.BASE_URL +
       ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
         this.resData = new AppResponse(data);

         //Start the code for Base64 to byte[]
         const byteCharacters = atob(this.resData.data);

         const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
           byteNumbers[i] = byteCharacters.charCodeAt(i);
         }

        let byteArray = new Uint8Array(this.resData.data);

         const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
           const byteCharacters = atob(b64Data);
           const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
             const slice = byteCharacters.slice(offset, offset + sliceSize);

             const byteNumbers = new Array(slice.length);
             for (let i = 0; i < slice.length; i++) {
               byteNumbers[i] = slice.charCodeAt(i);
             }

            const byteArray = new Uint8Array(byteNumbers);
             byteArrays.push(byteArray);
           }

           const blob = new Blob(byteArrays, { type: contentType });
           return blob;
         };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
         //End the code for Base64 to byte[]

        //Save the file
         FileSaver.saveAs(blob, documentName);
       });
   }


   onKeyUp(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.byLawsTopicMat.setErrors({ invalid: true });
      this.reviewMaintenanceRequestForm.setErrors(null);
      $('#approve').prop('disabled', true);
      $('#reject').prop('disabled', true);
      $('#update').prop('disabled', true);
      $("#assignedToBm"). prop("checked", false);
    } else {
      for (var i = 0; i < this.byLawsList.length; i++) {
        if (this.byLawsList[i].byLawsTopic == event.target.value) {
          this.reviewMaintenanceRequestForm.controls['byLawTopic'].setValue(this.byLawsTopicMat.value);
          this.approveAndRejectForm.controls['byLawTopic'].setValue(this.byLawsTopicMat.value);
          this.reviewMaintenanceRequestForm.controls['byLowDescription'].setValue(this.byLawsList[i].description);
          this.byLawsTopicMat.setErrors(null);
          $('#approve').prop('disabled', false);
          $('#reject').prop('disabled', false);
          $('#update').prop('disabled', false);
          $("#assignedToBm"). prop("checked", false);
          break;
        } else {
          this.reviewMaintenanceRequestForm.controls['byLawTopic'].setValue(null);
          this.approveAndRejectForm.controls['byLawTopic'].setValue(this.byLawsTopicMat.value);
          this.reviewMaintenanceRequestForm.controls['byLowDescription'].setValue(null);
          this.byLawsTopicMat.setErrors({ invalid: true });
          this.reviewMaintenanceRequestForm.setErrors({ 'invalid': true });
          $('#approve').prop('disabled', true);
          $('#reject').prop('disabled', true);
          $('#update').prop('disabled', true);
          $("#assignedToBm"). prop("checked", false);
        }
      }
    }
  };

  selectByLawChangeHandler(selectedByLaw) {
    for (var i = 0; i < this.byLawsList.length; i++) {
      if (this.byLawsList[i].byLawsTopic == selectedByLaw) {
        this.reviewMaintenanceRequestForm.controls['byLawTopic'].setValue(this.byLawsTopicMat.value);
        this.approveAndRejectForm.controls['byLawTopic'].setValue(this.byLawsTopicMat.value);
        this.reviewMaintenanceRequestForm.controls['byLowDescription'].setValue(this.byLawsList[i].description);
        $('#approve').prop('disabled', false);
        $('#reject').prop('disabled', false);
        $('#update').prop('disabled', false);
        $("#assignedToBm"). prop("checked", false);
        // console.log( this.reviewMaintenanceRequestForm.value )
      }
    }
  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.byLawsList.filter(option => option.byLawsTopic.toLowerCase().includes(filterValue));
  }

  approveFunction(){
    this.approveAndRejectForm.controls['reviewOutCome'].setValue("APPROVED");
    this.submitted = true;
    if (this.approveAndRejectForm.invalid) {
      return;
    }else{
      if(this.isArcLoggedIn){
        this.httpService.update(this.approveAndRejectForm.value, this.maintenanceReview)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['maintenance-list']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }else {
       // this.approveAndRejectForm.controls['assignedToBm'].setValue(true);
        // console.log("=====>"+this.approveAndRejectForm.value)
        this.httpService.update(this.approveAndRejectForm.value, this.reviewByBM)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['maintenance-list']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }

    }

  }

  rejectFunction(){
    this.approveAndRejectForm.controls['reviewOutCome'].setValue("REJECTED");
    this.submitted = true;
    if (this.approveAndRejectForm.invalid) {
      return;
    }else{
      if(this.isArcLoggedIn){
        this.httpService.update(this.approveAndRejectForm.value, this.maintenanceReview)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['maintenance-list']);
              }, 7000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }else{
        this.httpService.update(this.approveAndRejectForm.value, this.reviewByBM)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['maintenance-list']);
              }, 7000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }
    }
  }

  changeFunction(){
    if($("#assignedToBm").is(":checked")){
      if(confirm("Are you sure you want to assign this to BM ?")){
        this.reviewMaintenanceRequestForm.controls['assignedToBm'].setValue(true);
        //this.approveAndRejectForm.controls['assignedToBm'].setValue(true);
        $('#approve').prop('disabled', true);
        $('#reject').prop('disabled', true);
      }else{
        this.reviewMaintenanceRequestForm.controls['assignedToBm'].setValue(false); //HB-433 Hevisa Patel 29-01-2021 
        $("#assignedToBm"). prop("checked", false);
        if(this.byLawsTopicMat.value == ''||this.byLawsTopicMat.invalid){
          $('#update').prop('disabled', true);
          $('#approve').prop('disabled', true);
          $('#reject').prop('disabled', true);
        }else{
  
          $('#approve').prop('disabled', false);
          $('#reject').prop('disabled', false);
        }
      }
    }else{
      this.reviewMaintenanceRequestForm.controls['assignedToBm'].setValue(false);
      //this.approveAndRejectForm.controls['assignedToBm'].setValue(false);
      
      if(this.byLawsTopicMat.value == ''||this.byLawsTopicMat.invalid){
        
        $('#approve').prop('disabled', true);
        $('#reject').prop('disabled', true);
        $('#update').prop('disabled', true);     
      }else{
        
        $('#approve').prop('disabled', false);
        $('#reject').prop('disabled', false);
      }
    }
  }


  reviewUpdate(){

    this.submitted = true;
    // console.log("invalid:"+this.maintenance.reviewOutCome.invalid)
    // console.log("in"+this.byLawsTopicMat.invalid)
    if (this.maintenance.reviewOutCome.invalid) {
      return;
    }else{
      // console.log("form:"+JSON.stringify(this.reviewMaintenanceRequestForm.value));
      this.httpService.update(this.reviewMaintenanceRequestForm.value, this.maintenanceRequestsUpdate)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                // this.router.navigate(['maintenance-list']);
              }, 2000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });


    }
  }
  //general comment
  getComment() {
    this.httpService
      .save(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.GET_GENERAL_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            this.resData = new AppResponse(res);
            // console.log("======="+this.resData.data);
            this.generalCommentList = this.resData.data;
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });
    this.generalCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() {
    return this.generalCommentForm.controls;
  }

  onSubmit() {
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.generalCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.generalCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.generalCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.generalCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.generalCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.generalCommentForm.value));

      this.httpService
        .save(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.ADD_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editcomment = 1;
      }
    }

  }


  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.updategeneralCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.updategeneralCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.updategeneralCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.updategeneralCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.updategeneralCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){


      this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
      // console.log(JSON.stringify(this.generalCommentForm.value));
      if(confirm("Are you sure you want to delete comment")){
        this.httpService
        .update(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.DELETE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });

      }
  }


}
