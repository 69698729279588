import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'projects/user-portal/src/app/components/login/login.component';
import { AuthGuard } from './auth-token/AuthGuard ';
import { DashboardComponent } from 'projects/user-portal/src/app/components/dashboard/dashboard.component';
import { RequestForAccessComponent } from 'projects/user-portal/src/app/components/request-for-access/request-for-access.component';
import { ChangePasswordComponent } from 'projects/user-portal/src/app/components/change-password/change-password.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AddComplianceRequestComponent } from './components/add-compliance-request/add-compliance-request.component';
import { ComplianceListComponent } from './components/compliance-list/compliance-list.component';
import { AddMrComponent } from './components/add-mr/add-mr.component';
import { MrListComponent } from './components/mr-list/mr-list.component';
import { ViewComplianceRequestComponent } from './components/view-compliance-request/view-compliance-request.component';
import { MrViewComponent } from './components/mr-view/mr-view.component';
import { ReviewCrComponent } from './components/review-cr/review-cr.component';
import { MultiUserDashboardComponent } from './components/multi-user-dashboard/multi-user-dashboard.component';
import { McDashboardComponent } from './components/mc-dashboard/mc-dashboard.component';
import { BmDashboardComponent } from './components/bm-dashboard/bm-dashboard.component';
import { ArcDashboardComponent } from './components/arc-dashboard/arc-dashboard.component';
import { GroupMaintenanceComponent } from './components/group-maintenance/group-maintenance.component';
import { GroupMaintenanceViewComponent } from './components/group-maintenance-view/group-maintenance-view.component';
import { CrListByHoComponent } from './components/cr-list-by-ho/cr-list-by-ho.component';
import { AddCrByhoComponent } from './components/add-cr-byho/add-cr-byho.component';
import { GeneralCommentMrComponent } from './components/general-comment-mr/general-comment-mr.component';
import { RenterInformationComponent } from './components/renter-information-list/renter-information.component';
import { AddRenterInformationComponent } from './components/add-renter-information/add-renter-information.component';
import { RenterInfoViewComponent } from './components/renter-info-view/renter-info-view.component';
import { EditRenterInformationComponent } from './components/edit-renter-information/edit-renter-information.component';
import { EditCrByArcComponent } from './components/edit-cr-by-arc/edit-cr-by-arc.component';
import { EditCrComponent } from './components/edit-cr/edit-cr.component';
import { EditMRComponent } from './components/edit-mr/edit-mr.component';
import { GeneralCommentOfSDComponent } from './components/general-comment-of-sd/general-comment-of-sd.component';
import { SdViewComponent } from './components/sd-view/sd-view.component';
import { CrlistByARCComponent } from './components/crlist-by-arc/crlist-by-arc.component';
import { CrlistAgainstHoComponent } from './components/crlist-against-ho/crlist-against-ho.component';
import { CrreviewByhoComponent } from './components/crreview-byho/crreview-byho.component';
import { CrviewByARCComponent } from './components/crview-by-arc/crview-by-arc.component';
import { ListSharedDocumentComponent } from './components/list-shared-document/list-shared-document.component';
import { AddSharedDocumentComponent } from './components/add-shared-document/add-shared-document.component';
import { ReviewSdComponent } from './components/review-sd/review-sd.component';
import { ListSharedDocumentArchivedComponent } from './components/list-shared-document-archived/list-shared-document-archived.component';
import { ListSharedDocumentUserComponent } from './components/list-shared-document-user/list-shared-document-user.component';
import { ListSharedDocumentArchivedUserComponent } from './components/list-shared-document-archived-user/list-shared-document-archived-user.component';
import { UnhideSharedDocumentComponent } from './components/unhide-shared-document/unhide-shared-document.component';
import { EditSdComponent } from './components/edit-sd/edit-sd.component';
import { ViewArchivedNotificationComponent } from './components/view-archived-notification/view-archived-notification.component';
import { ViewNotificationComponent } from './components/view-notification/view-notification.component';


import { EditNotificationByArcComponent } from './components/edit-notification-by-arc/edit-notification-by-arc.component';
import { AddNotificationByArcComponent } from './components/add-notification-by-arc/add-notification-by-arc.component';
import { ArcNotificationListComponent } from './components/arc-notification-list/arc-notification-list.component';
import { ArcArcheivedNotificatioListComponent } from './components/arc-archeived-notificatio-list/arc-archeived-notificatio-list.component';

import { EditNotificationByBmComponent } from './components/edit-notification-by-bm/edit-notification-by-bm.component';
import { AddNotificationByBmComponent } from './components/add-notification-by-bm/add-notification-by-bm.component';
import { BmNotificationListComponent } from './components/bm-notification-list/bm-notification-list.component';
import { HoNotificationListComponent } from './components/ho-notification-list/ho-notification-list.component';
import { BmArcheivedNotificatioListComponent } from './components/bm-archeived-notificatio-list/bm-archeived-notificatio-list.component';
import { HoArchivedNotificationListComponent } from './components/ho-archived-notification-list/ho-archived-notification-list.component';
import { UnhideNotificationByBmComponent } from './components/unhide-notification-by-bm/unhide-notification-by-bm.component';
import { FaqViewUserComponent } from './components/faq-view-user/faq-view-user.component';
import { RequestReportComponent } from './components/request-report/request-report.component';
import { EventListForBmComponent } from './components/event-list-for-bm/event-list-for-bm.component';
import { AddEventByBmComponent } from './components/add-event-by-bm/add-event-by-bm.component';
import { EventListForArcComponent } from './components/event-list-for-arc/event-list-for-arc.component';
import { AddEventByArcComponent } from './components/add-event-by-arc/add-event-by-arc.component';
import { ViewEventByBMComponent } from './components/view-event-by-bm/view-event-by-bm.component';
import { ViewEventByArcComponent } from './components/view-event-by-arc/view-event-by-arc.component';
import { HomeActivityReportComponent } from './components/home-activity-report/home-activity-report.component';
import { EditEventByBmComponent } from './components/edit-event-by-bm/edit-event-by-bm.component';
import { EditEventByArcComponent } from './components/edit-event-by-arc/edit-event-by-arc.component';
import { DueReportComponent } from './components/due-report/due-report.component';
import { GuDashboardComponent } from './components/gu-dashboard/gu-dashboard.component';
import { MrListCreatedByBmComponent } from './components/mr-list-created-by-bm/mr-list-created-by-bm.component';
import { AddMrByBmComponent } from './components/add-mr-by-bm/add-mr-by-bm.component';
import { MrViewByBmAndGuComponent } from './components/mr-view-by-bm-and-gu/mr-view-by-bm-and-gu.component';
import { MrEditByBmComponent } from './components/mr-edit-by-bm/mr-edit-by-bm.component';
import { MrViewByGUComponent } from './components/mr-view-by-gu/mr-view-by-gu.component';
import { MrListForGUComponent } from './components/mr-list-for-gu/mr-list-for-gu.component';
import { NotificationListForGUComponent } from './components/notification-list-for-gu/notification-list-for-gu.component';
import { UserDueComponent } from './components/user-due/user-due.component';
import { RequestReportViewOfCrComponent } from './components/request-report-view-0f-cr/request-report-view-0f-cr.component';
import { RequestReportViewOfMrComponent } from './components/request-report-view-of-mr/request-report-view-of-mr.component';
import { AddExternalBMComponent } from './components/add-external-bm/add-external-bm.component';
import { GeneralCommentForMrByBmComponent } from './components/general-comment-for-mr-by-bm/general-comment-for-mr-by-bm.component';
import { GeneralCommentForMrByExternalUserComponent } from './components/general-comment-for-mr-by-external-user/general-comment-for-mr-by-external-user.component';
import { ListReimburseDuesUserComponent } from './components/list-reimburse-dues-user/list-reimburse-dues-user.component';
import { HomeActivityReportViewCrComponent } from './components/home-activity-report-view-cr/home-activity-report-view-cr.component';
import { HomeActivityReportViewMrComponent } from './components/home-activity-report-view-mr/home-activity-report-view-mr.component';
//added by Hema shanker
import { ReviewArchivedSdComponent } from './components/review-archived-sd/review-archived-sd.component';
// Added by Hema shanker for HB-590 on 13/12/2021 Start
import { SdArchivedViewComponent } from './components/sd-archived-view/sd-archived-view.component';
// Added by Hema shanker for HB-590 on 13/12/2021 End
// Added by Hema shanker for HB-552 on 16/12/2021 Start
import { EditProfileGuComponent } from './components/edit-profile-gu/edit-profile-gu.component';
// added by gopal for HB-584 on 27/12/2021 start
import { DueReportViewComponent } from './components/due-report-view/due-report-view.component';
// added by gopal for HB-584 on 27/12/2021 end
// Added by Hema shanker for HB-552 on 16/12/2021 End
//added by aishwaryaa for HB-673 01/01.2022
import { CrTemplateBmComponent } from './components/cr-template-bm/cr-template-bm.component';
import { CrTemplateBmViewComponent } from './components/cr-template-bm-view/cr-template-bm-view.component';
// added By Hema shanker for HB-916 Start
import { ReviewCrByBmComponent } from './components/review-cr-by-bm/review-cr-by-bm.component';
import { ReviewCrByMcComponent } from './components/review-cr-by-mc/review-cr-by-mc.component';
import { ComplianceListBmComponent } from './components/compliance-list-bm/compliance-list-bm.component';
import { ComplianceListMcComponent } from './components/compliance-list-mc/compliance-list-mc.component';
import { EventListHoComponent } from './components/event-list-ho/event-list-ho.component';
import { EventViewHoComponent } from './components/event-view-ho/event-view-ho.component';
import { McNotificationListComponent } from './components/mc-notification-list/mc-notification-list.component';
import { AddMcNotificationComponent } from './components/add-mc-notification/add-mc-notification.component';
import { ArchivedMcNotificationListComponent } from './components/archived-mc-notification-list/archived-mc-notification-list.component';
import { EditNotificationByMcComponent } from './components/edit-notification-by-mc/edit-notification-by-mc.component';
 
import { UploadInvoiceByBmComponent } from './components/upload-invoice-by-bm/upload-invoice-by-bm.component';
import { InvoiceListByBmComponent } from './components/invoice-list-by-bm/invoice-list-by-bm.component';
import { InvoiceViewByBmComponent } from './components/invoice-view-by-bm/invoice-view-by-bm.component';
import { InvoiceViewComponent } from './components/invoice-view/invoice-view.component';
// added By Hema shanker for HB-916 End
// added by Hema shanker for HB-988
import { BmSdListComponent } from './components/bm-sd-list/bm-sd-list.component';
import { McSdListComponent } from './components/mc-sd-list/mc-sd-list.component';
import { AddSharedDocumentbybmComponent } from './components/add-shared-documentbybm/add-shared-documentbybm.component';
import { AddSharedDocumentbymcComponent } from './components/add-shared-documentbymc/add-shared-documentbymc.component';
import { ReviewsdbymcComponent } from './components/reviewsdbymc/reviewsdbymc.component';
import { ReviewsdbybmComponent } from './components/reviewsdbybm/reviewsdbybm.component';
import { UnHideSdbybmComponent } from './components/un-hide-sdbybm/un-hide-sdbybm.component';
import { UnHideSdbymcComponent } from './components/un-hide-sdbymc/un-hide-sdbymc.component';
import { EditsdbymcComponent } from './components/editsdbymc/editsdbymc.component';
import { EditsdbybmComponent } from './components/editsdbybm/editsdbybm.component';
import { UnhideNotificationByMcComponent } from './components/unhide-notification-by-mc/unhide-notification-by-mc.component';
import { UnhideNotificationByArcComponent } from './components/unhide-notification-by-arc/unhide-notification-by-arc.component';
import { BMauthGuard } from './auth-token/BMauthGuard';
import { PaymentHistoryComponent } from './components/payment-history/payment-history.component';
import { OfflinePaymentComponent } from './components/offline-payment/offline-payment.component';
import { DiscussionBoardComponent } from './components/discussion-board/discussion-board.component';
//code end
import { UserEmailVerificationComponent } from './components/user-email-verification/user-email-verification.component';


const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'home', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'request-for-access', component: RequestForAccessComponent },
  { path: 'change-password/:id', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'tokens/:token', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'compliance-list', component: ComplianceListComponent },
  { path: 'cr-list-byarc', component: CrlistByARCComponent },
  { path: 'cr-list-byho', component: CrListByHoComponent },
  { path: 'cr-list-againstho', component: CrlistAgainstHoComponent },
  { path: 'cr-review-byho/:id', component: CrreviewByhoComponent },
  { path: 'crview-byarc/:id', component: CrviewByARCComponent },
  { path: 'add-mr', component: AddMrComponent },
  { path: 'maintenanceRequests', component: MrListComponent },
  { path: 'viewcompliance/:id', component: ViewComplianceRequestComponent },
  { path: 'review-cr/:id', component: ReviewCrComponent },
  { path: 'generalcommentofsd/:id', component: GeneralCommentOfSDComponent },
  { path: 'maintenanceRequest/:id', component: MrViewComponent },
  { path: 'add-cr', component: AddComplianceRequestComponent },
  { path: 'add-cr-byho', component: AddCrByhoComponent },
  { path: 'dashboard', component: MultiUserDashboardComponent },
  { path: 'dashboardmc', component: McDashboardComponent },
  { path: 'dashboardarc', component: ArcDashboardComponent },
  { path: 'dashboardbm', component: BmDashboardComponent },
  { path: 'dashboardgu', component: GuDashboardComponent },
  { path: 'maintenance-list', component: GroupMaintenanceComponent },
  { path: 'maintenanceRequests/:id', component: GroupMaintenanceViewComponent },
  { path: 'generalcomments/:id', component: GeneralCommentMrComponent },
  //Added by Ashish Kumar Singh HB-126,HB-219.HB-128,HB-131,HB-130
  { path: 'renterInformations-list', component: RenterInformationComponent },
  { path: 'add-ri', component: AddRenterInformationComponent },
  { path: 'renterInformation/:id', component: RenterInfoViewComponent },
  { path: "renterInformation-edit/:id", component: EditRenterInformationComponent },
  { path: "compliancerequest-editbyarc/:id", component: EditCrByArcComponent },
  { path: "compliancerequest-edit/:id", component: EditCrComponent },
  { path: "maintenanceRequest-edit/:id", component: EditMRComponent },
  { path: 'viewshareddocument/:id', component: SdViewComponent },
  { path: 'reviewsd/:id', component: ReviewSdComponent },
  { path: "sharedDocuments", component: ListSharedDocumentComponent },
  { path: "addSharedDocument", component: AddSharedDocumentComponent },
  { path: "archivedSharedDocuments", component: ListSharedDocumentArchivedComponent },
  { path: "shared-document-list", component: ListSharedDocumentUserComponent },
  { path: "shared-document-archived-list", component: ListSharedDocumentArchivedUserComponent },
  { path: "unHideSd/:id", component: UnhideSharedDocumentComponent },
  { path: 'editsd/:id', component: EditSdComponent },
  { path: 'faq', component: FaqViewUserComponent },
  { path: 'requestreport', component: RequestReportComponent },
 

  { path: "notification-edit/:id", component: EditNotificationByArcComponent },
  { path: "addNotificationByARC", component: AddNotificationByArcComponent },
  { path: "arcnotification-list", component: ArcNotificationListComponent },
  { path: "arcarchievednotification-list", component: ArcArcheivedNotificatioListComponent },

  { path: "archived-notification-view/:id", component: ViewArchivedNotificationComponent },
  { path: "notification-view/:id", component: ViewNotificationComponent },
  //modified by  aishwaryaa for HB-421 27/12/2021
  { path: "notification-edit-bm/:id", component: EditNotificationByBmComponent },
  { path: "addNotificationByBM", component: AddNotificationByBmComponent },
  { path: "bmnotification-list", component: BmNotificationListComponent },
  { path: "bmarchievednotification-list", component: BmArcheivedNotificatioListComponent },
  { path: "honotification-list", component: HoNotificationListComponent },
  { path: "hoarchivednotification-list", component: HoArchivedNotificationListComponent },
  { path: "unHideNotification/:id", component: UnhideNotificationByBmComponent },
  { path: "event-list", component: EventListForBmComponent },
  { path: "event-listbyarc", component: EventListForArcComponent },
  { path: "addEventByBM", component: AddEventByBmComponent },
  { path: "addEventByARC", component: AddEventByArcComponent },
  { path: "eventlistforho", component: EventListHoComponent },
  { path: "eventviewbybm/:id", component: ViewEventByBMComponent },
  { path: "eventviewbyarc/:id", component: ViewEventByArcComponent },
  { path: "eventviewbyho/:id", component: EventViewHoComponent },
  { path: 'homeactivityreport', component: HomeActivityReportComponent },
  { path: 'mcnotificationlist', component: McNotificationListComponent },
  { path: 'addmcnotification', component: AddMcNotificationComponent },
  { path: 'archivedmcnotificationlist', component: ArchivedMcNotificationListComponent },
  //added by gopal
  { path: "homeactivityreportview-cr/:id", component: HomeActivityReportViewCrComponent },
  { path: "homeactivityreportview-mr/:id", component: HomeActivityReportViewMrComponent },
  { path: "editnotificationbymc/:id", component: EditNotificationByMcComponent },

  { path: "event-editbybm/:id", component: EditEventByBmComponent },
  { path: "event-editbyarc/:id", component: EditEventByArcComponent },
  { path: 'duereport', component: DueReportComponent },
  { path: 'maintenance-listbyBM', component: MrListCreatedByBmComponent },
  { path: "addMRByBM", component: AddMrByBmComponent },
  { path: "maintenanceRequestBMView/:id", component: MrViewByBmAndGuComponent },
  { path: "maintenanceRequest-editbyBM/:id", component: MrEditByBmComponent },
  { path: "maintenanceRequestForExternalUserView/:id", component: MrViewByGUComponent },
  { path: 'maintenance-listforGU', component: MrListForGUComponent },
  { path: 'gunotification-list', component: NotificationListForGUComponent },
  { path: 'userdue', component: UserDueComponent },
  { path: 'requestreportviewofcr/:id', component: RequestReportViewOfCrComponent },
  { path: 'requestreportviewofmr/:id', component: RequestReportViewOfMrComponent },
  { path: 'add-external-bm', component: AddExternalBMComponent },
  { path: 'generalcommentsbybm/:id', component: GeneralCommentForMrByBmComponent },
  { path: 'generalcommentsbygu/:id', component: GeneralCommentForMrByExternalUserComponent },
  { path: 'reimburseDues', component: ListReimburseDuesUserComponent },
  { path: 'reviewarchivedsd/:id', component: ReviewArchivedSdComponent },
  // added by gopal for HB-584 on 27/12/2021 start
  { path: 'duereportview/:id', component: DueReportViewComponent },
  // added by gopal for HB-584 on 27/12/2021 end
  // Added by Hema shanker for HB-590 on 13/12/2021 Start
  { path: 'viewarchivedshareddocument/:id', component: SdArchivedViewComponent },
  // Added by Hema shanker for HB-590 on 13/12/2021 End
  // Added by Hema shanker for HB-552 on 16/12/2021 Start
  { path: 'edit-profile-gu', component: EditProfileGuComponent },
  // Added by Hema shanker for HB-552 on 16/12/2021 End
  // {path:'duereportview/:id',component:DueReportViewComponent},
  //added by aishwaryaa for hb-673 01/01/2022
  {path: 'crtemplaebm', component:CrTemplateBmComponent},
  // added bygopal for HB-643 on 03/01/22
  {path: 'crtemplatebmview/:id', component:CrTemplateBmViewComponent},
  {path:'review-cr-bymc/:id', component:ReviewCrByMcComponent},
  {path:'review-cr-bybm/:id', component:ReviewCrByBmComponent},
  {path:'mc-compliance-list',component:ComplianceListMcComponent},
  {path:'bm-compliance-list',component:ComplianceListBmComponent},
  {path:'archived-mc-notification-list',component:ArchivedMcNotificationListComponent},
  {path:'upload-invoice-by-bm',component:UploadInvoiceByBmComponent},
  {path:'invoice-list-by-bm',component:InvoiceListByBmComponent},
  {path:'invoice-view-by-bm/:id',component:InvoiceViewByBmComponent},

  // added by Hema shanker for HB-988
  {path:'bm-sd-list',component:BmSdListComponent,canActivate: [BMauthGuard]},
  {path:'mc-sd-list',component:McSdListComponent},
  {path:'addSharedDocumentbybm',component:AddSharedDocumentbybmComponent},
  {path:'addSharedDocumentbymc',component:AddSharedDocumentbymcComponent},
  {path:'reviewsdbybm/:id',component:ReviewsdbybmComponent},
  {path:'reviewsdbymc/:id',component:ReviewsdbymcComponent},
  {path:'unHideSdbybm/:id',component:UnHideSdbybmComponent},
  {path:'unHideSdbymc/:id',component:UnHideSdbymcComponent},
  {path:'editsdbybm/:id',component:EditsdbybmComponent},
  {path:'editsdbymc/:id',component:EditsdbymcComponent},
  { path: "unHideNotificationbyarc/:id", component: UnhideNotificationByArcComponent },
  { path: "unHideNotificationbymc/:id", component: UnhideNotificationByMcComponent },

  {path:'invoice-view/:id',component:InvoiceViewComponent},
  {path:'payment-history',component:PaymentHistoryComponent},
  {path:'offline-payment',component:OfflinePaymentComponent},
  {path:'discussion-board',component:DiscussionBoardComponent},
  {path:'verifytokens/:token',component:UserEmailVerificationComponent},


  //code end
  { path: '**', component: LoginComponent, canActivate: [AuthGuard] },


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
