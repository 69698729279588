import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as FileSaver from 'file-saver';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from '../../utils/common-service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
// Modified By Hema shanker for HB-714 on 07/01/2022 Start
import { AppRegExConstants } from '../../utils/AppRegExConstants';
// Modified By Hema shanker for HB-714 on 07/01/2022 End

@Component({
  selector: 'app-view-compliance-request',
  templateUrl: './view-compliance-request.component.html',
  styleUrls: ['./view-compliance-request.component.css'],
  providers: [NgxSpinnerService]
})
export class ViewComplianceRequestComponent implements OnInit {


  complianceRequestId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
 
  isEditable : boolean = false;
  statusSelected: string;

  reviewComplianceRequestForm: FormGroup;
  isStatusSelected: boolean;
  btnUpdateDisabled: boolean;
// Modified By Hema shanker for HB-714 on 07/01/2022 Start
  fileUploadList: string[] = [];
// Modified By Hema shanker for HB-714 on 07/01/2022 End
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
    reqData: any;
    generalCommentForm: FormGroup;
    // resData: AppResponse;
    refId: any;
    userprofileInfoId : any;
    updategeneralCommentForm: FormGroup;
    generalCommentList: any[] = [];
    detailedOriginalDoc : any = [];
    resdata: AppResponse;
  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService, 
    private commonService: CommonService,
    private router: Router,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.complianceRequestId = params.id;
    });
    this.reviewComplianceRequestForm = this.formBuilder.group({
      'reviewOutCome': ['' ,[Validators.required]],
      'reviewComments': ['',[Validators.required]],               
      'byLawsTopic': ['',],              
      'complianceRequestId': [this.complianceRequestId, [Validators.required]],
      // Modified By Hema shanker for HB-714 on 07/01/2022 Start
      'createdBy':[''],
      'images': ['', Validators.pattern(AppRegExConstants.DOCUMENT_PATTERN_FOR_COMPLIANCE)], 
      'compliancerequestnumber':['',[Validators.required]]   
      // Modified By Hema shanker for HB-714 on 07/01/2022 End        
    });
    this.userprofileInfoId = this.tokenService.getuserProfileId();
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      this.refId = params.id;
    });

    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['CR', [Validators.required]],
      'comment': ['', [Validators.required]],
    });
    this.getComment();
  }  

  ngAfterViewInit(){ 
    // tslint:disable-next-line: max-line-length
    // console.log("complianceRequestId -> " + this.complianceRequestId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS + "/" + this.complianceRequestId).subscribe(data => {

      // console.log(JSON.stringify(data));
      this.spinner.hide();
      this.appResponse = new AppResponse(data);
      console.log(this.appResponse)
       if (data.status === 200) {
        // this.spinner.hide();

        this.statusSelected = this.appResponse.data.complianceRequest.status;
        if(this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_ACKNOWLEDGED        
          ||this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_RESOLVED
          || this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_CLOSED
          || this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_NOT_RESOLVED){
          this.isEditable = true;
        }else{
            if (this.appResponse.data.reviewCrList != null) {
              for (let index = 0; index < this.appResponse.data.reviewCrList.length; index++) {
                if (this.appResponse.data.reviewCrList[index].assignedTo == this.tokenService.getuserProfileId()) {
                    this.isEditable = true;
                }
              }

            }
        }
        
        setTimeout(() => {
          $('#reviewCommentTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
            lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 4,
              orderable: false
            },
            ]
          });

          this.spinner.hide();
        }, 2000);  //2s

        this.reviewComplianceRequestForm = this.formBuilder.group({
          'reviewOutCome': [this.appResponse.data.complianceRequest.status, [Validators.required]],
          'reviewComments': ['',[Validators.required]],               
          'byLawsTopic': [this.appResponse.data.complianceRequest.byLawsTopic],              
          'complianceRequestId': [this.complianceRequestId, [Validators.required]],
          // Modified By Hema shanker for HB-714 on 07/01/2022 Start
          'createdBy': [this.appResponse.data.complianceRequest.createdBy] ,
          'images' : [''],
          'compliancerequestnumber':[this.appResponse.data.complianceRequest.compliancerequestnumber,[Validators.required]]    
          // Modified By Hema shanker for HB-714 on 07/01/2022 End      
        });
        this.btnUpdateDisabled = true;
        // code added by aishwaryaa for hb-977
        if(this.appResponse.data.complianceRequest.listRequestDocument){
         
          for(let i=0; i<this.appResponse.data.complianceRequest.listRequestDocument.length; i++) {
              
            this.detailedOriginalDoc.push({
              "docUploadedBy": (this.appResponse.data.complianceRequest.listRequestDocument[i].uploadedBy !=null)?("Document uploaded by "+this.appResponse.data.complianceRequest.listRequestDocument[i].uploadedBy+" - ") : ' ',
              "originalDoc": this.appResponse.data.complianceRequest.listRequestDocument[i].documentname,
              "createdOn" : this.appResponse.data.complianceRequest.listRequestDocument[i].createdDateTime
            });
          }
        }
        
      } else if(data.status === 401) {
        //401 token related issue
       this.tokenService.clearSession();
       this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
         this.router.navigate(['home']);
      }
    });
  }

   // convenience getter for easy access to form fields
   get compliance() { return this.reviewComplianceRequestForm.controls; }

   onSubmit() {
    // this.spinner.show();
    // console.log(JSON.stringify(this.reviewComplianceRequestForm.value));
    if (this.reviewComplianceRequestForm.invalid) {
      console.log("invalid")
      // this.spinner.hide();
      return;
    } else {

      // added by aishwaryaa for HB-714 05/01/2022

      var formData = new FormData();
      // Modified By Hema shanker for HB-714 on 07/01/2022 Start
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append('images', this.fileUploadList[i]);
      }
      // Modified By Hema shanker for HB-714 on 07/01/2022 End
      formData.append('reviewOutCome', this.reviewComplianceRequestForm.controls['reviewOutCome'].value);
      formData.append('reviewComments', this.reviewComplianceRequestForm.controls['reviewComments'].value);
      formData.append('byLawsTopic', this.reviewComplianceRequestForm.controls['byLawsTopic'].value);
      formData.append('complianceRequestId', this.reviewComplianceRequestForm.controls['complianceRequestId'].value);
      formData.append('createdBy', this.reviewComplianceRequestForm.controls['createdBy'].value);

      // code end 

      this.httpService.save(formData, environment.BASE_URL + ApplicationURIConstants.ADD_CR_REVIEW_BY_HO)

        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            this.spinner.hide();
            // console.log("Response ->>" + JSON.stringify(res));
            if (res.status == 200) {
              this.spinner.hide();

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              $('#reviewCommentTable').dataTable().fnDestroy(); 
              setTimeout(() => {
                this.ngAfterViewInit();
              }, 2000);  //3s

            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));

        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  changeStatus(e) {
   
    if (e.target.value == "RESOLVED") {
      this.isStatusSelected = true;
    }else if (e.target.value == "ACKNOWLEDGED") {
      this.isStatusSelected = true;
    } else {
      this.isStatusSelected = false;
    }  
    if (e.target.value == this.appResponse.data.complianceRequest.status) {
      this.btnUpdateDisabled = true;
     //$('#btnUpdate').prop('disabled', true);
    }else{
      this.btnUpdateDisabled = false;
      //$('#btnUpdate').prop('disabled', false);
    }
  }
  getComment() {
    this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.GET_GENERAL_COMMENT).subscribe(res => {
      this.spinner.hide();
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        // console.log(JSON.stringify(res));

        if (res.status == 200) {
          this.resData = new AppResponse(res);
          this.generalCommentList = this.resData.data;
        } else if (res.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      }
    });
    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['CR', [Validators.required]],
      'comment': ['', [Validators.required]],
      'generalCommentId':["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["CR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() { return this.generalCommentForm.controls; }


  onSubmitcomment() {
    this.spinner.show();
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid")
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.generalCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.generalCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.generalCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.generalCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.generalCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.generalCommentForm.value));
      this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.ADD_GENERAL_COMMENT).subscribe(res => {
        this.spinner.hide();
        if (res == undefined) {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            // this.resData = new AppResponse(res);
            this.getComment();
          } else if (res.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }
      });
    }

  }

  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editcomment = 1;
      }
    }

  }

  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else { console.log(this.updategeneralCommentForm.value);
      if(this.isArcLoggedIn) {
        this.updategeneralCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.updategeneralCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.updategeneralCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.updategeneralCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.updategeneralCommentForm.value.userrole="GU"
      }
      console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){


      this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
      // console.log(JSON.stringify(this.generalCommentForm.value));
      if(confirm("Are you sure you want to delete comment")){
        this.httpService
        .update(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.DELETE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });

      }
    }
}
