import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';

@Component({
  selector: 'app-add-event-by-bm',
  templateUrl: './add-event-by-bm.component.html',
  styleUrls: ['./add-event-by-bm.component.css']
})
export class AddEventByBmComponent implements OnInit {

  eventForm: FormGroup;
  resData: AppResponse;
  submitted = false;
  public show=false; 
  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  errorFlag : boolean = true;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  private eventUrl = environment.BASE_URL +ApplicationURIConstants.ADD_EVENT_BY_BM;
  validationerror: boolean;

  constructor(private tokenService: TokenStorageService,private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router) { }

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
   // this.min.setDate(this.min.getDate()-1);
   // this.min.setTime(this.min.getTime());
   var date = new Date();
    var datetemp = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
    
    var datetempmax = new Date(date.getFullYear()+1,date.getMonth(),date.getDate());
    
    this.max = datetempmax;
    this.min = datetemp;

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_MR).subscribe(res => {
      if (res.status === 200) {
        this.resData = new AppResponse(res);
        // console.log(this.resData);
      }else if (res.status === 200) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }
    });
    this.eventForm = this.formBuilder.group({
      'categoryId': ['', Validators.required],
      'eventTitle': ['', Validators.required],
      'description': ['', [Validators.required,Validators.maxLength(5000)]],
      'dateAndTime': ['',Validators.required],
      'showForHo':[false]

    });

  }
  get event() {
     return this.eventForm.controls;
  }

  clearDateAndTimeValue() {
    this.eventForm.controls['dateAndTime'].setValue(null);
  }

  onSubmit() {
    this.eventForm.controls['description'].setValue(this.eventForm.controls['description'].value.trim());
    this.submitted = true;
    // console.log("RESPONSE:" + JSON.stringify(this.eventForm.value));

    if (this.eventForm.invalid) {
      console.error("invalid request")
      return;
    } else {

      console.log("valid request");
      this.httpService.save(this.eventForm.value, this.eventUrl)
      .subscribe((res) => {
        // console.log("INSIDE THE FIRST");
        if (res != undefined) {
          this.resData = new AppResponse(res);
          // console.log("RESPONSE:" + JSON.stringify(this.resData));
          if (res.status == 200) {
            this.submitted = false;
            // this.eventForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['event-list']);
            }, 3000);  //15s
          } else if (res.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });


    }

  }


  lenghtvalidation($event){
    console.log("inside valisde");
    console.log($event.target.value.length);
    
    if($event.target.value.length>5000){
      this.validationerror=true;
      console.log(this.validationerror);
      
    }else{
      this.validationerror=false;
      console.log(this.validationerror);

    }
  }

  onChange(data){
    // console.log("-----11");
    if(data !== null){
      // console.log("-----");
      this.errorFlag = false;
    }else{
      this.errorFlag = true;
    }
  }

  test(){
    this.errorFlag = false;
  }

}









