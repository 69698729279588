import { Component, OnInit, HostListener } from "@angular/core";
import { AppResponse } from "src/app/model/AppResponse";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "../../utils/common-service";
import { Router } from "@angular/router";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";
import { environment } from "projects/user-portal/src/environments/environment";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { AppRegExConstants } from "../../utils/AppRegExConstants";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { NgxSpinnerService } from "ngx-spinner";
import pdfMake from "pdfmake/build/pdfmake";
import { DatePipe } from "@angular/common";
import { ToWords } from "to-words";
import { setUTCOffset } from "ngx-bootstrap/chronos/units/offset";

declare var Razorpay: any;
declare var jQuery: any;
declare var Stripe: any;
var formData = new FormData();
var groupdueids = new FormData();
let pdata;

const toWords = new ToWords({
  localeCode: "en-US",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Dollar",
      plural: "Dollars",
      symbol: "$",
      fractionalUnit: {
        name: "cent",
        plural: "cents",
        symbol: "",
      },
    },
  },
});
let checkedvalue = [];
let payamount;
let paymentfunction = [];
// let responsefunction:Function;

@Component({
  selector: "app-payment-history",
  templateUrl: "./payment-history.component.html",
  styleUrls: ["./payment-history.component.css"],
})
export class PaymentHistoryComponent implements OnInit {
  appResponses: AppResponse;
  clientSecret: string;
  stripe: any;
  payingamount: any;
  card: any;
  pubkey: String;
  checkcount: number = -1;
  checkedamount: any = [];
  paymentSubmit: boolean = false;
  payment: any;
  initial: string;
  email: string;
  name: string;
  userName: string;
  userPayForm: FormGroup;
  appResponse: AppResponse;
  resData: AppResponse;
  minimumAmountOfTotalAmount: number;
  specialAssesmentCount: number = 0;
  requireAmount: boolean;
  firstDueId: any;
  map = new Map();
  mapOfBack = new Map();
  invalidMaxAmt: boolean;
  customerid: any;
  contact: number;
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;

  paymentMessage: boolean = false;
  showpaymentsuccessmessage: boolean = false;
  showpaymentdangermessage: boolean = false;
  onlinepay: any;
  stripevalue: any;
  date: number;
  month: number;
  year: number;
  duedate: number;
  duemonth: number;
  dueyear: number;
  amount: number;
  imageToShow: string;
  price: any;
  total: number;
  finalamount: number;
  totalAmount: any;
  city: any = "";
  state: any = "";
  zipcode: any = "";
  address: any = "";

  constructor(
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private tokenService: TokenStorageService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    // console.log(this.checkedamount);

    this.userName = this.tokenService.getuserName();
    this.email = this.tokenService.getEmail();
    this.name = this.tokenService.getuserName();
    // added by gopal for HB-611 start on 10/12/2021
    if (localStorage.getItem("isGULoggedIn") == "true") {
      this.isGULoggedIn = "true";
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      this.isBMLoggedIn = "true";
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      this.isArcLoggedIn = "true";
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      this.isHOLoggedIn = "true";
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      this.isMCLoggedIn = "true";
    }

    this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.GET_ALL_DUE_HISTORY_OF_HO
      )
      .subscribe((data) => {
        this.spinner.hide();
        console.log(data);

        if (data.status === 401) {
          //401 token related issue
          this.spinner.hide();
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          //this.router.navigate(['home']);
          this.spinner.hide();
        } else if (data.status === 200) {
          this.spinner.hide();

          this.appResponse = new AppResponse(data);
          console.log(this.appResponse);
          // console.log(this.appResponse.data[0]);

          // console.log(this.appResponse.data[24].paymentDate)

          this.httpService
            .get(
              environment.BASE_URL +
                ApplicationURIConstants.GET_LOGO +
                "?logoName=" +
                this.tokenService.getGroupConfigByKey("logo")
            )
            .subscribe((data) => {
              this.resData = new AppResponse(data);
              if (this.resData.status == 200) {
                //Start the code for Base64 to byte[]
                // console.log(this.resData)
                this.imageToShow = "data:image/png;base64," + this.resData.data;
              } else if (this.resData.status == 400) {
                this.imageToShow = null;
              } else if (this.resData.status == 401) {
                this.router.navigate(["logout"]);
              } else {
                this.commonService.showNotificationSuccess(
                  ApplicationConstants.MSG_BOX_LABEL,
                  ApplicationConstants.DANGER_LABLE,
                  `${this.resData.errors}`
                );
              }
            });

          for (let index = 0; index < this.appResponse.data.length; index++) {
            if (index == this.appResponse.data.length - 1) {
              this.map.set(
                this.appResponse.data[index].groupUserDueId,
                this.appResponse.data[index].groupUserDueId
              );
              if (index > 0) {
                this.mapOfBack.set(
                  this.appResponse.data[index].groupUserDueId,
                  this.appResponse.data[index - 1].groupUserDueId
                );
              } else {
                this.mapOfBack.set(
                  this.appResponse.data[index].groupUserDueId,
                  this.appResponse.data[index].groupUserDueId
                );
              }
            } else {
              if (index > 0) {
                this.mapOfBack.set(
                  this.appResponse.data[index].groupUserDueId,
                  this.appResponse.data[index - 1].groupUserDueId
                );
              }

              this.map.set(
                this.appResponse.data[index].groupUserDueId,
                this.appResponse.data[index + 1].groupUserDueId
              );
            }
          }

          if (this.appResponse.data.length > 0) {
            this.firstDueId = this.appResponse.data[0].groupUserDueId;
            setTimeout(() => {
              $("#" + this.firstDueId).prop("disabled", false);
              this.spinner.hide();
              // console.log(" =======>>> " + this.firstDueId)
            }, 2000);
          } else {
            this.spinner.hide();
          }
        }
      });

    // this.userPayForm.controls['amount'].disable();
  }

  ngAfterViewInit(): void {
    this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.GET_ALL_DUE_HISTORY_OF_HO
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          this.resData = new AppResponse(res);
          console.log(this.resData);

          setTimeout(() => {
            $("#userDueTable").DataTable({
              responsive: true,
              autoWidth: true,
              searching: true,
              stateSave: true,
              order: [],
              language: {
                zeroRecords: "No records found.",
              },
              lengthMenu: [
                [5, 10, 15, 20],
                [5, 10, 15, 20],
              ],
              pageLength: 5,
              pagingType: $(window).width() < 768 ? "full" : "full_numbers",
              processing: true,
              columnDefs: [
                {
                  targets: 4,
                  orderable: false,
                },
              ],
            });
          }, 200);
          if (res.status === 401) {
            this.tokenService.clearSession();
            this.router.navigate(["login"]);
          } else if (res.status === 403) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
            this.router.navigate(["home"]);
          }
        }
      });
  }

  get f() {
    return this.userPayForm.controls;
  }

  submitted = false;

  exportinvoice(due) {
    console.log(due);

    if (due) {
      pdfMake.createPdf(this.exportToPdf(due)).open();
    }
  }

  exportToPdf(due) {
    console.log("due : " + due);
    console.log("due : " + due.city);

    if (due.city) {
      this.city = due.city;
    }
    if (due.state) {
      this.state = due.state;
    }
    if (due.zipcode) {
      this.zipcode = due.zipcode;
    }
    if (due.addressLine1) {
      this.address = due.addressLine1;
    }

    var datePipe = new DatePipe("en-US");
    this.date = new Date().getDate();
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();

    this.price = due.amount;
    this.total = (Number(this.price) + 0.3) / 0.971;
    this.finalamount = this.total - this.price;

    this.totalAmount = this.price + this.finalamount;

    // this.duedate = new Date(due.paymentDate).getDate();
    // this.duemonth = new Date(due.paymentDate).getMonth()+1;
    // this.dueyear = new Date(due.paymentDate).getFullYear();

    return {
      content: [
        {
          columns: [
            {
              image: this.imageToShow,
              width: 200,
            },
            {
              text: "Invoice",
              bold: true,
              fontSize: 20,
              alignment: "right",
            },
          ],
        },
        {
          columns: [
            {
              text: this.tokenService.getGroupConfigByKey("footer"),
              fontSize: 12,
              alignment: "left",
            },
            {
              text: "\nInvoice Number : \n" + due.invoiceNo,
              bold: false,
              fontSize: 12,
              alignment: "right",
            },
          ],
        },
        {
          columns: [
            // {
            //   text: this.tokenService.getGroupConfigByKey("footer"),

            //   fontSize: 12,
            //   alignment: "left",
            // },
            {
              text:
                "\nInvoice Date : \n" +
                new DatePipe("en-US").transform(
                  new Date(due.paymentDate),
                  "MM/dd/yyyy"
                ),
              bold: false,
              fontSize: 12,
              alignment: "right",
            },
            // {
            //   text: '\nInvoice Date : \n' + formatDate(new Date(this.year + "/" + this.month + "/" + this.date), 'MM-dd-yyyy', 'en-US'),
            //   bold: false,
            //   fontSize: 12,
            //   alignment: 'right',

            // },
          ],
        },
        {
          columns: [{}],
        },
        {
          text: "\n\n",
        },

        {
          columns: [
            {
              text: "Billing To,",
              bold: true,
              fontSize: 16,
              alignment: "left",
              margin: [0, 0, 0, 20],
            },

            // {
            //   text: "Thanks For Your Payment",
            //   bold: true,
            //   fontSize: 16,
            //   style: "border",
            //   alignment: "right",
            // },
          ],
        },

        {
          text: due.firstName + "  " + due.lastName,
          bold: false,
          fontSize: 12,
          alignment: "left",
        },
        {
          text:
            this.address +
            "\n" +
            this.city +
            ", " +
            this.state +
            " " +
            this.zipcode,
          bold: false,
          fontSize: 12,
          alignment: "left",
        },

        // {
        //   text:
        //     due.addressLine1 == null
        //       ? ""
        //       : due.addressLine1 + "\n" + due.city == null
        //       ? ""
        //       : due.city + ", " + due.state == null
        //       ? ""
        //       : due.state + " " + due.zipcode == null
        //       ? ""
        //       : due.zipcode,
        //   bold: false,
        //   fontSize: 12,
        //   alignment: "left",
        // },
        // {
        //   text:  due.city + due.country==null?"":due.country + due.zipCode==null?"":due.zipCode,
        //   bold: false,
        //   fontSize: 12,
        //   alignment: 'left',

        // },
        // {
        //   text:  due.country==null?"":due.country + due.zipCode==null?"":due.zipCode,
        //   bold: false,
        //   fontSize: 12,
        //   alignment: 'left',

        // },
        // {
        //   text:  due.zipCode==null?"":due.zipCode,
        //   bold: false,
        //   fontSize: 12,
        //   alignment: 'left',

        // },
        {
          text: "\n\n",
        },
        this.billingtable(due),
        {
          text: "\n",
        },

        this.descriptiontable(due),

        // this.amount= due.amount,
        // {
        //   text:  "Total Amount : "+"$"+ this.totalamount,
        //   bold: false,
        //   fontSize: 16,
        //   alignment: 'right',
        //   margin: [0, 20, 0, 20]
        // },

        {
          text: toWords.convert(Number(this.totalAmount.toFixed(2))),
          bold: false,
          fontSize: 12,
          alignment: "left",
          margin: [0, 5, 0, 20],
        },

        {
          text: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nCredit / Debit Card Processing Fee: A 2.9% + 0.30 cents fee is applied to total amount paid via credit /debit card to cover processing charges.",
          fontSize: 8,
        },
      ],
      info: {
        title: "Invoice",
        author: "Invoice",
        subject: "Invoice",
        keywords: "Invoice",
      },
      styles: {
        header: {
          fontSize: 15,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        name: {
          fontSize: 16,
          bold: true,
        },
        tableHeader: {
          bold: true,
        },
        ulborder: {
          decorationStyle: "none",
        },
      },
    };
  }

  billingtable(due) {
    return {
      table: {
        widths: ["*", "*"],
        // headerRows: 1,
        // keepWithHeaderRows: 1,
        body: [
          [
            { text: "Due Date", style: "tableHeader" },
            {
              text: new DatePipe("en-US").transform(
                new Date(due.dueDate),
                "MM/dd/yyyy"
              ),
            },
          ],
        ],
      },
      // table: {
      //   widths: ['*', '*'],
      //   headerRows: 2,
      //   body: [
      //     [{
      //       text: 'Registration Number',
      //       style: 'tableHeader'
      //     },

      //     ],
      //     [
      //       {
      //         text: 'Due Date',
      //         style: 'tableHeader'
      //       },
      //     ]

      //     [
      //      due.groupregistrationnum],[new DatePipe('en-US').transform(new Date(due.paymentDate),'MM/dd/yyyy')
      //     ]

      //   ]
      // }
    };
  }

  descriptiontable(due) {
    // console.log(this.taxamount);

    return {
      table: {
        widths: ["*", "auto"],
        body: [
          [
            {
              text: "Description",
              style: "tableHeader",
            },

            {
              text: "Amount $",
              style: "tableHeader",
              noWrap: true,
            },
          ],

          [
            due.dueType +
              "\n\n" +
              "Card Processing Fees (2.9% + 30 cents)" +
              "\n",
            this.price.toFixed(2) + "\n\n" + this.finalamount.toFixed(2),
          ],
          ["Total Amount", this.totalAmount.toFixed(2)],
        ],
      },
    };
  }
}
