import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { startWith, map } from 'rxjs/operators';
import { environment } from 'projects/user-portal/src/environments/environment';
import { RequireMatchForByLaw } from '../../utils/requireMatchForByLaw';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as FileSaver from 'file-saver';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';

@Component({
  selector: 'app-mr-edit-by-bm',
  templateUrl: './mr-edit-by-bm.component.html',
  styleUrls: ['./mr-edit-by-bm.component.css']

})
export class MrEditByBmComponent implements OnInit {

  maintenanceRequestForm: FormGroup;
  fileUploadList: string[] = [];
  fileNameContentType: string;
  fileNameExtension: string;
  resData: AppResponse;
  appResponse: AppResponse;
  resDataForCate: AppResponse;
  submitted = false;
  byLaws: any[] = [];
  externalusers: any[] = [];
  byLawsListTopic: any[] = [];
  flag: string = "true";
  externaluser: AppResponse;
  map = new Map();
  maintenanceRequestId: string;
  uploadedBy : string = '';

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  // show: boolean;

  private maintenanceRequests = environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUESTS_GU;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private tokenService: TokenStorageService) { }
  filteredOptions: Observable<String[]>;
  myControl = new FormControl();
  byLawsTopic = new FormControl('');
  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    // this.show = false;

    this.activatedRoute.params.subscribe(params => {
      this.maintenanceRequestId = params.id;
      // console.log("maintenanceRequestId" + this.maintenanceRequestId)
    });
    
    this.maintenanceRequestForm = this.formBuilder.group({
      "description": ['', [Validators.required]],
      "moreInfo": [''],
      "urgent": [''],
      "safetyThreat": [''],
      "externaluseroccupationId": ['', [Validators.required]],
      "assignedTo": ['', [Validators.required]],
      "images": [''],
      "maintenancerequestnumber":['',Validators.required]
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUEST_VIEW_BY_BM_AND_EXTERNAL_USER + this.maintenanceRequestId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      // console.log("appResponse" + JSON.stringify(this.appResponse.data));
      for (var i = 0; i < this.appResponse.data.documentName.length; i++) {
        // console.log("docName:" + this.appResponse.data.documentName[i])
        this.map.set(this.appResponse.data.documentName[i].substr(14), this.appResponse.data.documentName[i]);
      }
      if (data.status === 200) {
        this.maintenanceRequestForm = this.formBuilder.group({
          "description": [this.appResponse.data.description, Validators.required],
          "moreInfo": [this.appResponse.data.moreInfo],
          "urgent": [this.appResponse.data.urgent],
          "safetyThreat": [this.appResponse.data.safetyThreat, Validators.required],
          "externaluseroccupationId": [this.appResponse.data.externaluseroccupationId, [Validators.required]],
          "assignedTo": [this.appResponse.data.assignedToFullName, [Validators.required]],
          "images": [this.appResponse.data.images],
          "maintenancerequestnumber":[this.appResponse.data.maintenancerequestnumber,Validators.required]
        });

      }
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['dashboardbm']);
      }
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_MR).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.resDataForCate = new AppResponse(res);
      // console.log(JSON.stringify(this.resDataForCate.data.occupations));

      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.BY_LAWS).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.resData = new AppResponse(res);
      // console.log(JSON.stringify(this.resData.data));
      this.byLaws = this.resData.data;

      for (var i = 0; i < this.byLaws.length; i++) {
        this.byLawsListTopic.push(this.byLaws[i].byLawsTopic);
      }
      // console.log("btopic:" + this.byLawsListTopic)

      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }
    });



    this.filteredOptions = this.byLawsTopic.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );
  }


  // convenience getter for easy access to form fields
  get maintenance() { return this.maintenanceRequestForm.controls; }

  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }


  onSubmit() {
    this.submitted = true;
    // console.log("ashish" + JSON.stringify(this.maintenanceRequestForm.value));
    if (this.maintenanceRequestForm.invalid) {
      return;
    } else {
      var formData = new FormData();

      // Add multiple file in formData
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("images", this.fileUploadList[i]);
      }

      if ($("#urgent").prop("checked") == true) {
        this.maintenanceRequestForm.value.urgent = true;
      } else {
        this.maintenanceRequestForm.value.urgent = false;
      }

      if ($("#safetyThreat").prop("checked") == true) {
        this.maintenanceRequestForm.value.safetyThreat = true;
      } else {
        this.maintenanceRequestForm.value.safetyThreat = false;
      }

      formData.append("description", this.maintenanceRequestForm.controls['description'].value);
      formData.append("moreInfo", this.maintenanceRequestForm.controls['moreInfo'].value);
      formData.append("urgent", this.maintenanceRequestForm.value.urgent);
      formData.append("safetyThreat", this.maintenanceRequestForm.value.safetyThreat);
      formData.append("externaluseroccupationId", this.appResponse.data.externaluseroccupationId);
      if (this.appResponse.data.assignedToFullName == this.maintenanceRequestForm.value.assignedTo) {
        formData.append("assignedTo", this.appResponse.data.assignedTo);
      }
      formData.append("assignedTo", this.maintenanceRequestForm.controls['assignedTo'].value);
	  formData.append("uploadedBy", "BM");
      // formData.append("byLawsTopic", this.byLawsTopic.value);
      formData.append("flag", this.flag);
      formData.append("maintenanceRequestId", this.maintenanceRequestId);
      formData.append("maintenancerequestnumber", this.maintenanceRequestForm.controls['maintenancerequestnumber'].value);

      this.httpService.save(formData, this.maintenanceRequests)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.maintenanceRequestForm.reset();
              $("#externaluseroccupation").prop('selectedIndex', 0);
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['maintenance-listbyBM']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }


  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.byLawsListTopic.filter(option => option.toLowerCase().includes(filterValue));
  }



  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
        this.resData = new AppResponse(data);
        const byteCharacters = atob(this.resData.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(this.resData.data);
        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
        FileSaver.saveAs(blob, documentName);
      });
  }

}
