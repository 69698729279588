import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationUtils } from "src/app/utils/ApplicationUtils";
import { AppRegExConstants } from "src/app/utils/AppRegExConstants";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { CommonService } from "../../utils/common-service";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { AppResponse } from "src/app/model/AppResponse";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import * as $ from "jquery";
import { environment } from "projects/user-portal/src/environments/environment";

@Component({
  selector: "app-request-for-access",
  templateUrl: "./request-for-access.component.html",
  styleUrls: ["./request-for-access.component.css"],
})
export class RequestForAccessComponent implements OnInit {
  @ViewChild("myInput", { static: true }) myInput: ElementRef;

  constructor(
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router
  ) {}

  requestFrom: FormGroup;
  resData: AppResponse;
  isError: boolean = true;
  countryCode: any;
  intlobj: any;
  flag: boolean = false;

  get f() {
    return this.requestFrom.controls;
  }

  ngOnInit() {
    // console.log("in request for access")

    this.requestFrom = this.formBuilder.group({
      email: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)],
      ],
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      //"contact": ['', [Validators.required]],
      // "groupName": ['']
    });
  }

  submitted = false;
  private requestForAccess =
    environment.BASE_URL + ApplicationURIConstants.REQUEST_FOR_ACCESS;

  onSubmit() {
    this.submitted = true;
    if (this.requestFrom.invalid || !this.isError) {
      console.error("invalid request");
      return;
    } else {
      console.log("valid request");
      var formData = new FormData();
      formData.append("contact", this.intlobj.getNumber());
      // formData.append("groupName",this.requestFrom.controls['groupName'].value);
      formData.append(
        "firstName",
        this.requestFrom.controls["firstName"].value
      );
      formData.append("lastName", this.requestFrom.controls["lastName"].value);
      formData.append("email", this.requestFrom.controls["email"].value);
      //this.requestFrom.controls['contact'].setValue(this.intlobj.getNumber());
      this.httpService
        .save(formData, this.requestForAccess)
        .subscribe((res) => {
          console.log("INSIDE THE FIRST" + res);
          if (res != undefined) {
            this.resData = new AppResponse(res);
            console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.requestFrom.reset();
              this.intlobj.setNumber("");
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                `${this.resData.message}`
              );
              setTimeout(() => {
                this.router.navigate(["login"]);
              }, 3000); //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(["login"]);
            } else {
              console.log("INSIDE THE FOURTH");
              if (this.resData.errors == null) {
                console.log("INSIDE THE FOURTH");
                this.commonService.showNotificationSuccess(
                  ApplicationConstants.MSG_BOX_LABEL,
                  ApplicationConstants.DANGER_LABLE,
                  `${this.resData.message}`
                );
              } else {
                this.commonService.showNotificationSuccess(
                  ApplicationConstants.MSG_BOX_LABEL,
                  ApplicationConstants.DANGER_LABLE,
                  `${this.resData.errors}`
                );
              }
            }
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        });
    }
  }

  onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;
    if (this.flag) {
    }
    // this.intlobj.setNumber('');
    else this.flag = true;
    this.hasError(false);
  }

  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
  }

  telInputObject(obj) {
    // console.log(obj);
    this.intlobj = obj;
    this.flag = true;
  }

  test1(obj) {
    this.myInput.nativeElement.blur();
    $("#contInput").focus();
    // this.hasError(obj);
    //console.log("-----"+JSON.stringify(this.isError));
    //console.log(this.intlobj.getNumber());
    //var numberObj = phoneUtil.parseAndKeepRawInput('8690489817', '+91');
    //console.log("----"+phoneUtilInstance.isValidNumber(numberObj));
  }

  checkForError(obj) {
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
        return true;
      else return false;
    }
    return true;
  }
}
