import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TokenStorageService } from '../../../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../../../utils/ApplicationConstants';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { CommonService } from '../../../../utils/common-service';

declare let $: any;

@Component({
  selector: 'app-gu-nav',
  templateUrl: './gu-nav.component.html',
  styleUrls: ['./gu-nav.component.css']
})
export class GuNavComponent implements OnInit {
  showFiller = false;
  sidesize: any;
  rulerSizeClose: number = 180;
  rulerSizeOpen: number = 230;
  activeColor: string = "'red'";
  normalColor: string = "'pink'";
  initial: String;
  ///////////////////////////
  static: boolean = true;
  crstate: boolean = false;
  sdstate: boolean = false;
  nostate: boolean = false;
  nostatic: boolean = false;
  invoice: boolean = false;
  public sideNavState: boolean = false;
  public subCrOpen: boolean = false;
  public subSdOpen: boolean = false;
  public subNotOpen: boolean = false;
  public resview: boolean = false;
 public subinvoiceopen: boolean = false;
  public profileopen: boolean = false;
  //////////////////////////
  nshover: boolean = false;
  // maginSizeClose:number = 0;
  // marginSizeOpen:number= 110;
  ///////////////////////////
  ho_dashboard: String;

  @Output() messageEvent = new EventEmitter();

  constructor(private commonService: CommonService, private httpService: HttpTransactionService, private location: Location, public tokenService: TokenStorageService, private router: Router) { }
  userName: string;
  isGULoggedIn: string;
  resData: AppResponse;
  roleId: String;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  roles = [];
  mr_list_of_ext_user: String;
  list_all_mr: String;
  renter_info_list: String;
  get_list_of_document_by_ho: String;
  view_faq: String;
  get_list_of_cr_by_ho: String;
  notification_list_by_ho: String;
  notification_list_for_gu: String;
  gu_home: String;
  reimursed_dues_user: String;
  user_due: String;


  sendTitle(title: string) {
    this.messageEvent.emit(title);
  }
  //////////////////////////////////////////////
  isStatic() {
    this.static = !this.static
    this.nostatic = !this.nostatic

  }
  togglecr() {
    this.crstate = !this.crstate
  }
  togglesd() {
    this.sdstate = !this.sdstate
  }
  toggleno() {
    this.nostate = !this.nostate
  }
  //////////////////////////////////////////

  sinenavOpen() {
    this.sideNavState = !this.sideNavState
    // console.log(this.sideNavState)
    this.profileopen = this.profileopen
  }
  subComplainopen() {
    this.subCrOpen = !this.subCrOpen
  }
  subComplainclose() {
    this.subCrOpen = this.sideNavState
  }
  subSdopen() {
    this.subSdOpen = !this.subSdOpen
    // console.log(this.subSdOpen)
  }
  subSdClose() {
    this.subSdOpen = this.sideNavState
    // console.log(this.subSdOpen)
  }
  subNotopen() {
    this.subNotOpen = !this.subNotOpen
    // console.log(this.subNotOpen)
  }
  subNotclose() {
    this.subNotOpen = this.sideNavState
  }

  toggleprofile() {
    this.profileopen = !this.profileopen
    // console.log(this.profileopen)
  }
  /////////////////////////////////////////
   toggleinvoice(){
    this.invoice= !this.invoice
   }
  ngOnInit() {

    this.isGULoggedIn = localStorage.getItem("isGULoggedIn");
    // console.log("isGULoggedIssn" + this.isGULoggedIn);


    this.userName = this.tokenService.getuserName();
    this.getShortName();
    $(document).ready(() => {


      $(window).resize(() => {
        if ($(window).width() < 521) {
          // $('.sidebar').toggleClass('toggled');
          // $('.sidebar .collapse').collapse('hide');

        } else {

        }
      });


      // $('#sidebarToggle, #sidebarToggleTop').on('click', () => {
      //   $('body').toggleClass('sidebar-toggled');
      //   $('.sidebar').toggleClass('toggled');
      //   if ($('.sidebar').hasClass('toggled')) {
      //     $('.sidebar .collapse').collapse('hide');
      //   }
      // });

      $(window).resize(() => {
        if ($(window).width() < 768) {
          // $('.sidebar .collapse').collapse('hide');

        }
        else {

        }
      });

      // // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
      $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
        if ($(window).width() > 768) {
          // tslint:disable-next-line: one-variable-per-declaration
          var e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail;
          this.scrollTop += (delta < 0 ? 1 : -1) * 30;
          e.preventDefault();
        }
      });

      // Scroll to top button appear
      $(document).on('scroll', function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
      });

      $('.nav-link.active-link').closest('li.parent').addClass('active');
      var route = this.router.url;
      var splitRoute = route.split('/');
      if (splitRoute[1] === 'change-password') {
        $('#cpId').addClass('active');
      }


    });

    $(function () {
      $('#accordionSidebar > li').click(function (e) {
        e.stopPropagation();
        var $el = $('div', this);
        $('#accordionSidebar > li > div').not($el).slideUp();
        $el.stop(true, true).slideToggle(400);
      });
      $('#accordionSidebar > li > div > li').click(function (e) {
        e.stopImmediatePropagation();
      });
    });




    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    //  function toggleSidebar() {
    //   if (this.mini) {
    //     console.log("opening sidebar");
    //     document.getElementById("mySidebar").style.width = "250px";
    //     document.getElementById("main").style.marginLeft = "250px";
    //     this.mini = false;
    //   } else {
    //     console.log("closing sidebar");
    //     document.getElementById("mySidebar").style.width = "85px";
    //     document.getElementById("main").style.marginLeft = "85px";
    //     this.mini = true;
    //   }
    // }

    ////////////////////////////////////////


  }
  getShortName() {
    this.initial = this.userName.split(' ').map(n => n[0]).join('');
    // console.log(this.initial);
  }

  ngAfterViewInit(): void {

    if (localStorage.getItem("isGULoggedIn") == "true") {
      this.roleId = '7';
      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      this.roleId = '3';
      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      this.roleId = '4';
      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      this.roleId = '6';
      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      this.roleId = '5';
      this.isMCLoggedIn = 'true';
    }

    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_TOKEN_BY_ROLE + '/' + this.roleId).subscribe(data => {
        this.resData = new AppResponse(data);
        if (this.resData.status == 200) {
          this.ho_dashboard = this.resData.data.includes('HO_DASHBOARD');
          this.mr_list_of_ext_user = this.resData.data.includes('MAINTENANC_REQUESTS_LIST_OF_EXTERNAL_USER');
          this.list_all_mr = this.resData.data.includes('LISTALL_MR');
          this.renter_info_list = this.resData.data.includes('RENTER_INFORMATION_LIST');
          this.get_list_of_document_by_ho = this.resData.data.includes('GET_LIST_OF_DOCUMENT_BY_HO');
          this.view_faq = this.resData.data.includes('VIEW_FAQ_USER');
          this.get_list_of_cr_by_ho = this.resData.data.includes('GET_LIST_OF_COMPLIANCE_REQUEST_BY_HO');
          this.notification_list_by_ho = this.resData.data.includes('NOTIFICATION_LIST_BY_HO');
          this.notification_list_for_gu = this.resData.data.includes('NOTIFICATION_LIST_FOR_GU');
          this.gu_home = this.resData.data.includes('GU_HOME');
          this.reimursed_dues_user = this.resData.data.includes('REIMBURSED_DUES_USER');
          this.user_due = this.resData.data.includes('GET_ALL_CURRENT_DUE_OF_HO');

          $(document).ready(() => {
            $('.nav-link.active-link').closest('li.parent').addClass('active');
            $('#accordionSidebar > li').click(function (e) {
              e.stopPropagation();
              var $el = $('ul', this);
              $('#accordionSidebar > li > ul').not($el).slideUp();
              $el.stop(true, true).slideToggle(400);
            });
            $('#accordionSidebar > li > ul > li').click(function (e) {
              e.stopImmediatePropagation();
            });
          });
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });

  }

  changePassword() {
    this.router.navigate(['change-password', this.tokenService.getuserProfileId()]);

  }

  doLogout() {
    localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
    this.tokenService.clearSession();
    this.router.navigate(['login']);
  }



}
