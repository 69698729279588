import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/user-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { CommonService } from '../../utils/common-service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
var formData = new FormData();
@Component({
  selector: 'app-cr-template-bm',
  templateUrl: './cr-template-bm.component.html',
  styleUrls: ['./cr-template-bm.component.css'],
  providers: [NgxSpinnerService]
})
export class CrTemplateBmComponent implements OnInit {
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  crtemplate: FormGroup;
  resData: any;
  responseData: any;
  token: any;
  spaceerr: boolean=false;
  spaceerrbottom: boolean;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private commonService: CommonService, private httpService: HttpTransactionService, private router: Router) { }

  ngOnInit() {

    this.token = JSON.parse(localStorage.getItem(ApplicationConstants.USER_PORTAL));

    // console.log(this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY]);

    if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }

    this.crtemplate = this.formBuilder.group({
      "top": ['', [Validators.required]],
      "bottom": ['', [Validators.required]],
      "createdBy": [this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY], [Validators.required]]

    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_CR_TEMPLATE)
      .subscribe((res) => {
        // console.log(res);

        if (res != undefined) {
          this.responseData = new AppResponse(res);


          if (res.status == 200) {



          } else if (res.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else {
            this.spinner.hide();

            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
          }
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });


  }
// code added by gopal for hb-984
  get f() { return this.crtemplate.controls; }





  ngAfterViewInit(): void {

    this.TableInitialise();
    this.spinner.hide();
  }

  // linecheck(){
  //   if(this.crtemplate.controls['top'].value.trim()==''){
  //     this.spaceerr=true;
  
  //     }else{
  //       this.spaceerr=false;
  //     }
  //   if(this.crtemplate.controls['bottom'].value.trim()==''){
  //     this.spaceerrbottom=true;
  
  //     }else{
  //       this.spaceerrbottom=false;
  //     }
  // }
  onSubmit() {
    // console.log(JSON.stringify(this.crtemplate.value));
    // this.spinner.show();
    if(this.crtemplate.controls['top'].value.trim()=='' || this.crtemplate.controls['bottom'].value.trim()==''){
      this.spaceerr=true;
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please provide Valid Top/Bottom data");
  return;
      }else{
        this.spaceerr=false;
        
      }

      
  
    if (this.crtemplate.invalid ) {
      // console.log("invalid")
      // this.spinner.hide();

      return;
    }
    else {


      formData.append("createdBy", this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY]);
      formData.append("top", this.crtemplate.controls['top'].value);
      formData.append("bottom", this.crtemplate.controls['bottom'].value);





      this.httpService.save(this.crtemplate.value, environment.BASE_URL + ApplicationURIConstants.CR_TEMPLATE)
        .subscribe((res) => {
          // console.log(res);

          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log(this.resData);

            if (res.status == 200) {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${res.message}`);
              this.ngOnInit();

            } else if (res.status == 401) {
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              this.spinner.hide();

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });


    }
  }

  TableInitialise() {
    $('#crtemplate').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
      // Added by Hema shanker HB-675 on 25/11/2021 Start
      stateSave: true,
      // Added by Hema shanker HB-675 on 25/11/2021 End
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      // Modified by Hema shanker HB-675 on 25/11/2021 Start
      lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
      // Modified by Hema shanker HB-675 on 25/11/2021 End
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  // fillTextfeildData() {

  //   this.crtemplate = this.formBuilder.group({

  //     "createdBy": [this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY], Validators.required],

  //   });

  // }
}



