import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../../auth-token/services/token-storage.service';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';
import { CommonService } from '../../../utils/common-service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationConstants } from '../../../utils/ApplicationConstants';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footer : String;
  resData: AppResponse;
  fileNameContentType: string;
  imageToShow: any;
  groupName : String;

  constructor(private httpService: HttpTransactionService, private commonService: CommonService, private router: Router, private tokenService: TokenStorageService) { }

  ngOnInit() {

    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_LOGO + '?logoName=' + this.tokenService.getGroupConfigByKey('logo')).subscribe(data => {
        this.resData = new AppResponse(data);
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          // console.log(this.resData)
          this.imageToShow = 'data:image/png;base64,' + this.resData.data;
          
        } else if (this.resData.status == 400) {
          this.imageToShow = null;
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  ngAfterViewInit(): void {
    this.footer = this.tokenService.getGroupConfigByKey('footer');
  }
  doLogout() {
    localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
    this.tokenService.clearSession();
    this.router.navigate(['login']);
  }

}
