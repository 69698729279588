import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { CommonService } from '../../utils/common-service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-list-shared-document',
  templateUrl: './list-shared-document.component.html',
  styleUrls: ['./list-shared-document.component.css'],
  providers: [NgxSpinnerService]
})
export class ListSharedDocumentComponent implements OnInit {

  // isBMLoggedIn: boolean;
  resData: AppResponse;
  listUrl: string;
  hideSharedDocument: FormGroup;
  userprofileId: any;
  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end


  constructor(private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.spinner.show();
    // added by gopal for HB-611 start on 10/12/2021
    if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
    // added by goapl for HB-611 end
    this.userprofileId = this.tokenService.getuserProfileId();

    this.hideSharedDocument = this.formBuilder.group({
      'sharedDocumentId': ['0', [Validators.required]]
    });
  }

  ngAfterViewInit(): void {
    // console.log("1");
    // added by gopal for HB-685 start on 16/12/2021
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_LIST_OF_DOCUMENT_BY_ARC).subscribe(res => {

      // added by gopal for HB-685 end
      // console.log("2");

      //console.log(JSON.stringify(res));
      if (res == undefined) {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {

        // console.log(JSON.stringify(res));
        this.resData = new AppResponse(res);
        setTimeout(() => {
          $('#sdListDataTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            // Added by Hema shanker HB-675 on 25/11/2021 Start
            stateSave: true,
            // Added by Hema shanker HB-675 on 25/11/2021 End
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
            // Modified by Hema shanker HB-675 on 25/11/2021 Start
            lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
            // Modified by Hema shanker HB-675 on 25/11/2021 End
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 6,
              orderable: false
            },
            ]
          });
          this.spinner.hide();
        }, 200);

        if (res.status === 401) {
          this.spinner.hide();
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          this.spinner.hide();
          //403 URL not accessible
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          //this.router.navigate(['home']);
        }
      }
    });
  }

  onSubmit() {
    // console.log(JSON.stringify(this.hideSharedDocument.value));
    this.spinner.show();
    if (this.hideSharedDocument.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      $('.close').click();
      this.httpService.save(this.hideSharedDocument.value, environment.BASE_URL + ApplicationURIConstants.HIDE_SHARED_DOCUMENT)
        .subscribe((res) => {
          if (res != undefined) {
            // this.resData = new AppResponse(res);
            // console.log("Response ->>" + JSON.stringify(res));
            if (res.status == 200) {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${res.message}`);

              $('#sdListDataTable').dataTable().fnDestroy();
              setTimeout(() => {
                this.ngAfterViewInit();
              }, 200);  //2s
            } else if (res.status == 401) {
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }
  }

  fillSharedDocumentId(id) {
    this.hideSharedDocument = this.formBuilder.group({
      'sharedDocumentId': [id, [Validators.required]]
    });
  }

}
