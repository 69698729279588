import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationUtils } from 'src/app/utils/ApplicationUtils';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { AppResponse } from 'src/app/model/AppResponse';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as $ from 'jquery';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  appResponse: AppResponse;
  resData: AppResponse;
  userProfileInfoId: String;
  submitted = false;
  private changePass = environment.BASE_URL + ApplicationURIConstants.CHANGE_PASSWORD;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private applicationUtils: ApplicationUtils, private formBuilder: FormBuilder, private commonService: CommonService) {

  }

  resetPwdForm: FormGroup;
  //  isGULoggedIn:string;
  //  isHOLoggedIn : boolean;
  //  isBMLoggedIn : boolean;
  isARCLoggedIn: boolean;
  isGuLoggedIn: boolean;
  isMcLoggedIn: boolean;

  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end


  get f() { return this.resetPwdForm.controls; }

  ngOnInit() {


    this.isGULoggedIn = localStorage.getItem("isGULoggedIn");

      // added by gopal for HB-611 start on 10/12/2021
      if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end
    this.activatedRoute.params.subscribe(params => {
      this.userProfileInfoId = params.id;
    });
    // console.log("ID:" + this.userProfileInfoId);
    this.resetPwdForm = this.formBuilder.group({
      "currentPassword": ['', [Validators.required, Validators.pattern(AppRegExConstants.PASSWORD)]],
      "newPassword": ['', [Validators.required, Validators.pattern(AppRegExConstants.NEW_PASSWORD)]],
      "confirmPassword": ['', [Validators.required, Validators.pattern(AppRegExConstants.NEW_PASSWORD)]]
    });

    $('#confirmPassword').bind("cut copy paste", function (e) {
      e.preventDefault();
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetPwdForm.invalid) {
      return;
    } else {
      // console.log("changepassform:" + JSON.stringify(this.resetPwdForm.value));
      // http service method call
      this.httpService.save(this.resetPwdForm.value, this.changePass + this.userProfileInfoId)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.resetPwdForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                window.localStorage.clear();
                this.router.navigate(['login']);
              }, 2000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }


  }

  togglePassword(id, passId) {
    var passwordInput = document.getElementById(passId);
    var passid = document.getElementById(id);

    // console.log("pass" + passwordInput);

    if (passwordInput.getAttribute('type') == 'password') {
      passwordInput.setAttribute('type', 'text');
      $(passid).toggleClass("fa-eye fa-eye-slash");
    } else {
      passwordInput.setAttribute('type', 'password');
      $(passid).toggleClass("fa-eye fa-eye-slash");

    }


  }

}
