import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Component({
  selector: 'app-renter-information',
  templateUrl: './renter-information.component.html',
  styleUrls: ['./renter-information.component.css']
})
export class RenterInformationComponent implements OnInit, AfterViewInit {
  dataTable: any;
  resData: AppResponse;
  deleteResData:any;

  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end

  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) {
  }
 
  ngOnInit() {
    
     // added by gopal for HB-611 start on 10/12/2021
     if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
      // added by goapl for HB-611 end

  }

  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    $('#riListDataTable').dataTable().fnDestroy(); 
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.RENTER_INFORMATION_LIST ).subscribe(res => {
      this.resData = new AppResponse(res);
      // console.log("resdata:"+JSON.stringify(this.resData));
      setTimeout(() => {
        $('#riListDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
           // Added by Hema shanker HB-675 on 25/11/2021 Start
           stateSave: true,
           // Added by Hema shanker HB-675 on 25/11/2021 End
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
         // Modified by Hema shanker HB-675 on 25/11/2021 Start
         lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
         // Modified by Hema shanker HB-675 on 25/11/2021 End
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 5,
            orderable: false
          },
        ]
        });
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }

  //delete renter info 
  private deleteRenterInfoURL= environment.BASE_URL + ApplicationURIConstants.DELETE_RENTER_INFO;
  public deleteRenterInfo(data: any) {
    // console.log("DATA:" + data);
    if (confirm("Are you sure you want to delete this renter info ?")) {

      this.httpService.update(data, this.deleteRenterInfoURL + '/' + data)
        .subscribe((res) => {
          this.deleteResData = new AppResponse(res);
          if (this.deleteResData.status == 200) {
            //this.submitted = false;
            $('.close').click();
            // console.log("MSG:" + this.deleteResData.message)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.deleteResData.message);

            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);

          } else {
            // console.log(this.deleteResData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.deleteResData.errors}`);
          }
        });
    }
  }

}
