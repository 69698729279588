import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { FormBuilder } from '@angular/forms';

import { Router } from '@angular/router';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
@Component({
  selector: 'app-ho-archived-notification-list',
  templateUrl: './ho-archived-notification-list.component.html',
  styleUrls: ['./ho-archived-notification-list.component.css']
})
export class HoArchivedNotificationListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  userProfileInfoId : string;
  editReqData: AppResponse;
  deleteResData: AppResponse;

  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end

  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) {
   
  }
  
 
  ngOnInit() {

     // added by gopal for HB-611 start on 10/12/2021
     if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
      // added by goapl for HB-611 end

  }

  
  ngAfterViewInit(): void {

    // console.log("Ashish"+"singhg")
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.ARCHIVED_NOTIFICATION_LIST_BY_HO).subscribe(res => {
      this.resData = new AppResponse(res);
     
      setTimeout(() => {
        $('#notificationListDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
           // Added by Hema shanker HB-675 on 25/11/2021 Start
           stateSave: true,
           // Added by Hema shanker HB-675 on 25/11/2021 End
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
         // Modified by Hema shanker HB-675 on 25/11/2021 Start
         lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
         // Modified by Hema shanker HB-675 on 25/11/2021 End
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 3,
            orderable: false
          },
        ]
        });
      }, 200);
      if (res.status === 200) {
        // console.log("jdfnjdfnj");
        // console.log("Ashish:"+JSON.stringify(this.resData));

      }
        else if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }

}
