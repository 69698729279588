import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positveNumber'
})
export class PositveNumberPipe implements PipeTransform {

  transform(value: number): number {
    console.log("Positiuve nbumbekjhbrkjhsg");
     console.log(Math.abs(value));
     
    return Math.abs(value);
  }

}
