export abstract class AppRegExConstants {
  //  Specific Field Related Regex
  static readonly EMAIL =
    "^(([\\w-]+\\.)+[\\w-]+|([a-zA-Z]{1}|[\\w-]{2,}))@" +
    "((([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\." +
    "([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])){1}|" +
    "([a-zA-Z0-9]+[\\w-]+\\.)+[a-zA-Z]{1}[a-zA-Z0-9-]{1,23})$";

  static readonly PASSWORD =
    "((?=.*[a-zA-Z])(?=.*[\\d!\"#$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~]).{8,15})";

  static readonly NEW_PASSWORD =
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\"#$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~]).{8,15})";

  static readonly GROUP_NAME = "^[A-Za-z\\ ]*$";
  static readonly GROUP_NUMBER = "^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$";
  static readonly ENTITY_NUMBER = "^[0-9\\-]*$";

  static readonly CONTACT_NUMBER = "^[+][1-9][0-9]{11}$";

  static readonly TEN_DIGIT_CONTACT_NUMBER = "^[1-9][0-9]{9,12}";

  static readonly INTERNATIONAL_PHONE_NUMBERS = "^\\+(?:[0-9]?){1,14}[0-9]$";

  static readonly ALPHABETS = "^[A-Za-z]*$";
  static readonly ADDRESS = "^[a-zA-Z0-9 ]*$";
  static readonly OCCUPATION = "^[a-zA-Z ]*$";

  static readonly ALPHABETS_WITH_SPACE = "^[a-zA-Z_ ]*$";

  static readonly ALPHABETS_DIGITS_ONLY = "^[A-Za-z0-9]*$";

  static readonly DIGITS = "^[0-9]*$";

 static readonly AMOUNT = "^\\d{0,5}(?:\\.\\d{0,2})?$";


  
  static readonly ZIPCODE = "^[1-9][0-9]{4,5}$";
  static readonly DUE_AMOUNT = "^\\d{0,5}(?:\\.\\d{0,2})?$";
  static readonly WEBSITE = "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$";


  static readonly DOCUMENT_PATTERN_FOR_COMPLIANCE =
    "^.*\\.(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF|png|PNG|BMP|bmp|jpeg|JPEG|docm|DOCM|docx|DOCX|xlsx|XLSX)$";

  static readonly LATE_FEE_CHARGES_REGEX = "[1-9][0-9][0-9]";

  static readonly PERCENTAGE_REGEX = "^(100|[1-9]?[0-9])$";

  static readonly INTERNATIONAL_CONTACT = "^+(?:[0-9] ?){6,14}[0-9])$";

  static readonly DECIMAL_INPUT =
    "^(?:[1-4]?[0-9])(?:\\.d{1,3})?$|^[5]?0-8?$|^59(.0{1,3})?$";

  static readonly GROUPS_NAME = "^[A-Za-z0-9\\-_ ]*$";

  static readonly DESCRIPTION = "^[ A-Za-z0-9_@.,*\/#&+-]*$"

  static readonly OFFLINE_AMOUNT = "^[1-9][0-9]{0,30}(?:\\.\\d{0,2})?$";
}
