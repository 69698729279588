import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as XLSX from 'xlsx';

import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
var formData = new FormData();


@Component({
  selector: 'app-offline-payment',
  templateUrl: './offline-payment.component.html',
  styleUrls: ['./offline-payment.component.css']
})
export class OfflinePaymentComponent implements OnInit {
  @ViewChild('TABLE',{static:false}) table:ElementRef;
  reportFormGroup: FormGroup;
  offlinepay: FormGroup;
  resData: any;
  responseData : any;
  rongHo: boolean;
  homeAddressList = [];
  filteredHomes: Observable<String[]>;
  homeAddress = new FormControl('', [Validators.required]);
  homeaddresslenght:any;
   // added by gopal for HB-611 start on 10/12/2021
   isGULoggedIn: string;
   isBMLoggedIn: String;
   isArcLoggedIn: String;
   isHOLoggedIn: String;
   isMCLoggedIn: String;
  fileUploadList: any[];
  duetype: any;
  groupDueId: any;
  groupUserDueId: any;
  amount: any;
  wavedoffamount: any;
  userProfileInfoId: any;
  minimumAmountOfTotalAmount: number;

  requireAmount: boolean;
  invalidMaxAmt: boolean;
  totalAmount: any;
  minimumAmountPer: number;
  tokenService: any;
  selecteddue: any[];
  partiallyselecteddue: any[];
  responselength: boolean=true;
  totalvalue: any;
  total: any;
  totalamount: number;
  showamounterr: boolean=false;
  noamount: boolean = false;
  enablebtn: boolean=true;
  errorcheck: boolean;



  constructor(private httpService: HttpTransactionService,
    private formBuilder: FormBuilder, 
    private commonService: CommonService,
    private router: Router, 
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
 
    if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }

    console.log(this.responselength + "length");
    




      // added by goapl for HB-611 end
  
  this.spinner.show();
   
  this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_ALL_HOME_LIST_BY_GROUP).subscribe(res => {
   
    this.responseData = new AppResponse(res);
    console.log(this.responseData);
    if (res == undefined) {
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
    } else {
      if (res.status == 200) {
       
        this.homeAddressList = this.responseData.data;
        this.homeaddresslenght=this.homeAddressList.length;
        // this.restoredata(this.homeaddresslenght);
        $('#reportTable').dataTable().fnDestroy();
        setTimeout(() => {
          
          this.TableInitialise();
          this.spinner.hide();
        }, 2000);

      } else if (res.status === 401) {
        //401 token related issue
        this.spinner.hide();
        this.router.navigate(['login']);
      } else if (res.status == 403) {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
        //this.router.navigate(['home']);
      } else {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
      }
    }

  


  });
  this.reportFormGroup = this.formBuilder.group({     
    'userProfileInfoId': ['', [Validators.required]],
    'HoDetails': ['']
  });

  // '^[0-9]{1,6}$'    check
  this.offlinepay = this.formBuilder.group({     
    'checkno': [''], 
    'images': [''],
    'amount':['',[Validators.required ,Validators.pattern(AppRegExConstants.OFFLINE_AMOUNT)]],
    "paymenttype":['']
  });
  


  this.filteredHomes = this.homeAddress.valueChanges
    .pipe(
      startWith(''),
      map(value => value.length >= 3 ? this._filterForHomes(value) : [])
  );

  // console.log(this.reportFormGroup.controls['homeAddress'].value);
  // console.log(this.reportFormGroup.controls['HoDetails'].value);
  

}


categorychange($event){

  console.log($event.target.value);

  if($event.target.value=="CHEQUE" || $event.target.value=="DD"){
    this.offlinepay.controls['checkno'].enable();
    this.offlinepay.controls['checkno'].setValidators([Validators.pattern('^[0-9]{1,6}$')]);
    this.offlinepay.controls['checkno'].updateValueAndValidity();


  }else if($event.target.value=="UPI"){
    this.offlinepay.controls['checkno'].enable();

    this.offlinepay.controls['checkno'].setValidators([Validators.pattern(AppRegExConstants.DIGITS)]);
    this.offlinepay.controls['checkno'].updateValueAndValidity();

  }else{
    this.offlinepay.controls['checkno'].clearValidators();
    this.offlinepay.controls['checkno'].updateValueAndValidity();

    this.offlinepay.controls['checkno'].disable();

  }
  

}

//   onBlurMethod($event){
//     console.log($event.target.length);
//     if($event.target.length==0){
//     this.enablebtn=true

//   }else
//   {
//     this.enablebtn = false
//   }
// }
pay(){


// this.reportFormGroup.controls['homeAddress'].value(null)



// this.totalvalue = this.responseData.data.amount
// console.log(this.totalvalue);
// for(let i=0;i<this.responseData.data.amount;i++){
//   this.total+= this.totalvalue.amount
//   console.log(this.total);

//   console.log(this.totalvalue);
  
  

// }

  // console.log(this.offlinepay.value);
  // console.log(this.resData.data);
  var count=this.offlinepay.controls['amount'].value;
  // console.log("count before loop : " + count);
  
  this.selecteddue=[];
  this.partiallyselecteddue=[];

  for (let index = 0; index < this.resData.data.length; index++) {
    const element = this.resData.data[index];
    
    if(count>=element.amount){
      // console.log("inseide if for  : " + element.amount);
      
      
      // console.log(typeof(element));
      this.selecteddue.push(element);
    
      
      count-=element.amount;
      // console.log("this is after loop : " + count);
      

    }else{
// console.log(count + " : balance count");
// console.log(element.amount-count + "need to p[ay dues");

this.partiallyselecteddue.push(element)


      break;
    }

   
    
    
  }
  
  console.log( this.responseData.data.amount );
  console.log("balance amount to pay : " + count);



  
   console.log("this is selected user due final : " + this.selecteddue);
    console.log("this is partially selected user due final : " + this.partiallyselecteddue);


    for(var key in this.selecteddue){
      console.log(this.selecteddue[key].amount);

      console.log(this.selecteddue[key].amount+".....................");
      
      formData.delete("amount");
      formData.delete("dueType");
      formData.delete("groupDueId");
      formData.delete("groupUserDueId");
      formData.delete("status");
      formData.delete("wavedOffAmount");
      formData.delete("stripe");
      formData.delete("isminimum");
      formData.delete("paymentType");
      formData.delete("checkNo");
      formData.delete("userProfileInfoId");
      formData.append("amount", this.selecteddue[key].amount);
      formData.append("dueType",this.selecteddue[key].dueType);
      formData.append("groupDueId",this.selecteddue[key].groupDueId);
      formData.append("groupUserDueId",this.selecteddue[key].groupUserDueId);
      formData.append("status",this.selecteddue[key].status);
      formData.append("wavedOffAmount",this.selecteddue[key].wavedOffAmount);
      formData.append("stripe",JSON.stringify(this.offlinepay.value));
      formData.append("paymentType",this.offlinepay.controls['paymenttype'].value);
      formData.append("checkNo",this.offlinepay.controls['checkno'].value);
      formData.append("userProfileInfoId",this.selecteddue[key].userProfileInfoId);

      console.log("form appended");
      
      if(this.selecteddue.length > 1){
        formData.append("isminimum","0");
      }else
      {
        formData.append("isminimum","1");
      }
      this.httpService.save(formData,environment.BASE_URL + ApplicationURIConstants.SAVEUSERPAYMENTOFFLINE).subscribe((data)=>{
        console.log(data);
        console.log("line 315");
        
        if(data.status==200){
          // console.log(this.enablebtn+"318");
          this.enablebtn=true;
          
          // this.resData=null;
          this.onSubmit();

          // this.ngOnInit();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${data.message}`);
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${data.message}`);
        }
       
  // if(data.status == 200){
  // this.paymentMessage = data.message;
  // this.showpaymentsuccessmessage  = true;
  // this.showpaymentdangermessage = false
  // this.totalAmount= 0;
  // this.ngOnInit();
  // }
  // else{
  // this.paymentMessage = data.message;
  // this.showpaymentsuccessmessage  = false;
  // this.showpaymentdangermessage = true
  // }
  
        
      });
    }
    for(var key in this.partiallyselecteddue){
      console.log(this.partiallyselecteddue[key].amount);
      
      formData.delete("amount");
      formData.delete("dueType");
      formData.delete("groupDueId");
      formData.delete("groupUserDueId");
      formData.delete("status");
      formData.delete("wavedOffAmount");
      formData.delete("stripe");
      formData.delete("isminimum");
      formData.delete("paymentType");
      formData.delete("checkNo");
      formData.delete("userProfileInfoId");
      formData.append("amount", count);
      formData.append("dueType",this.partiallyselecteddue[key].dueType);
      formData.append("groupDueId",this.partiallyselecteddue[key].groupDueId);
      formData.append("groupUserDueId",this.partiallyselecteddue[key].groupUserDueId);
      formData.append("status",this.partiallyselecteddue[key].status);
      formData.append("wavedOffAmount",this.partiallyselecteddue[key].wavedOffAmount);
      formData.append("stripe",JSON.stringify(this.offlinepay.value));
      formData.append("paymentType",this.offlinepay.controls['paymenttype'].value);
      formData.append("checkNo",this.offlinepay.controls['checkno'].value);
      formData.append("userProfileInfoId",this.partiallyselecteddue[key].userProfileInfoId);
console.log("line 368");

      if(this.partiallyselecteddue.length > 1){
        formData.append("isminimum","0");
      }else
      {
        formData.append("isminimum","1");
      }
      this.httpService.save(formData,environment.BASE_URL + ApplicationURIConstants.SAVEUSERPAYMENTOFFLINE).subscribe((data)=>{
        console.log(data);  
        if(data.status==200){
          console.log("line 379");
          console.log(this.enablebtn);
          
      setTimeout(() => {
            
        // this.resData=null;
        this.onSubmit();
        this.offlinepay.controls['amount'].setValue(null);
        this.offlinepay.controls['checkno'].setValue(null);
        this.offlinepay.controls['paymenttype'].setValue(null)
    
      }, 2000);


          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${data.message}`);
       
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${data.message}`);
         
        }
       
        
  // if(data.status == 200){
  // this.paymentMessage = data.message;
  // this.showpaymentsuccessmessage  = true;
  // this.showpaymentdangermessage = false
  // this.totalAmount= 0;
  // this.ngOnInit();
  // }
  // else{
  // this.paymentMessage = data.message;
  // this.showpaymentsuccessmessage  = false;
  // this.showpaymentdangermessage = true
  // }
  
        
      });
    }
 
  

  
}
onFileSelect(event) {
    
  this.fileUploadList = [];
  for (var i = 0; i < event.target.files.length; i++) {
    var size = Math.round((event.target.files[i].size / 1024)); 
    if( (size /1024) > 10 ){
      this.offlinepay.setErrors({ 'invalid': true });
    }else{
      this.fileUploadList.push(event.target.files[i]);
    }
  }
}
ngAfterViewInit(): void {
 
  this.TableInitialise();
}
get f() { return this.offlinepay.controls;}

// convenience getter for easy access to form fields
get report() { return this.reportFormGroup.controls; }

//   fillpaymentdata(data){
// console.log(data);
//      this.duetype=data.dueType;
//      this.groupDueId=data.groupDueId;
//      this.groupUserDueId=data.groupUserDueId;
//      this.amount=data.amount;
//      this.wavedoffamount=data.wavedOffAmount;
//      this.userProfileInfoId=data.userProfileInfoId;
//     //  this.offlinepay.controls['amount'].setValue(this.amount);
//      this.calculateMinimumAmount();
//   }

calculateMinimumAmount() {

  console.log(JSON.parse(localStorage.getItem('USER_PORTAL'))[0]    );
  var sto=JSON.parse(localStorage.getItem('USER_PORTAL'))[0];
  console.log(sto['group-configs'].MinimumAmountInPercentage);
  
  

  this.minimumAmountPer = Number(sto['group-configs'].MinimumAmountInPercentage);
  this.minimumAmountOfTotalAmount = this.amount * (this.minimumAmountPer / 100);
  console.log(this.minimumAmountOfTotalAmount)
}


// onKeyUpForAmount(event: any) {
//   if((event.target.value < this.minimumAmountOfTotalAmount) || isNaN(this.minimumAmountOfTotalAmount)) {
//     this.offlinepay.setErrors({ invalid: true });
//     this.requireAmount = true;
//     this.invalidMaxAmt = false;
//   }else if(event.target.value > this.totalAmount){
//     this.offlinepay.setErrors({ invalid: true });
//     this.invalidMaxAmt = true;
//   }else{
//     this.offlinepay.setErrors(null);
//     this.requireAmount = false;
//     this.invalidMaxAmt = false;
//   }
// }


offlinepaysubmit(){ 
          formData.append("amount", this.offlinepay.controls['amount'].value);
          formData.append("dueType",this.duetype);
          formData.append("groupDueId",this.groupDueId);
          formData.append("groupUserDueId",this.groupUserDueId);
          // formData.append("status",checkedvalue[key].status);
          formData.append("wavedOffAmount",this.wavedoffamount);
          formData.append("stripe",JSON.stringify(this.offlinepay.value));
          formData.append("userProfileInfoId",this.userProfileInfoId);
          formData.append("isminimum","1");
          // formData.append("checkno",this.ofl);

          console.log(this.duetype + this.groupDueId + this.groupUserDueId + this.amount+ this.wavedoffamount);
          
  console.log(this.offlinepay.value);

  this.httpService.save(formData,environment.BASE_URL + ApplicationURIConstants.SAVEUSERPAYMENTOFFLINE).subscribe((data)=>{
    console.log(data);
    if(data.status==200){ 
      // this.ngOnInit();
     
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${data.message}`);
      console.log("line 466");
      
      $('#reportTable').dataTable().fnDestroy();
      setTimeout(() => {
        this.onSubmit()
        // this.ngOnInit();
        this.TableInitialise();
        
      }, 2000);
    }else{
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${data.message}`);
    }
   
  });
  
}

onSubmit() {
console.log(this.reportFormGroup.value);

  $("#messageBox").empty();
  // this.spinner.show();
 // console.log(JSON.stringify(this.reportFormGroup.value));
  
  if (this.reportFormGroup.invalid) {
    // this.spinner.hide();
   //console.log("invalid")
    return;
  } else {
  
    //console.log(this.reportFormGroup.value);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_USER_DUE_REPORT_BY_ID +'/'+ this.reportFormGroup.controls['userProfileInfoId'].value)
      .subscribe((res) => {
        console.log(res);
        
        if (res != undefined) {
          this.resData = new AppResponse(res);
          //console.log("Response ->>" + JSON.stringify(res));
          console.log(this.resData)
      
          if (res.status == 200) {


            this.totalamount=0;
          this.resData.data.forEach(i => {
            this.totalamount+=i.amount;
            
          });

          // console.log(this.totalamount);
          
            // this.responselength=res.data.length;
            // console.log(this.responselength);

            if (res.data.length>0) {
              this.responselength=false;
            }else{
              this.responselength=true;
            }
         
            // console.log(res);//added by gopal
            $('#reportTable').dataTable().fnDestroy();
            setTimeout(() => {
              
              this.TableInitialise();
              this.spinner.hide();
            }, 2000);  //3s

          } else if (res.status == 401) {
           // console.log(res);//added by gopal
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else {
            $('#reportTable').dataTable().fnDestroy();
            this.spinner.hide();
            //this.TableInitialise();
            console.log("inside red")
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
            console.log(res.message);
          }
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });
  }
}


payenable(){

 this.enablebtn=true;
}

checkerror(){
  if(this.offlinepay.controls['checkno'].errors  ){
    this.errorcheck=true;
    console.log(this.errorcheck);
    
  }else{
    this.errorcheck=false;
    console.log(this.errorcheck);
    
  }
}

emptyvalue($event){
  
  if($event.target.value.length=="0" || $event.target.value<=0 ||this.offlinepay.controls['amount'].errors ){
    // console.log($event.target.value.length);
    
    this.enablebtn=true;      
  
  
  } else{
    this.enablebtn=false;
  }
}


maxprice($event){
  // console.log($event);

  // console.log(this.showamounterr);

if($event.target.value > this.totalamount){ 
    this.offlinepay.setErrors(null);
    // console.log($event.target.value);
    // console.log("inside if");
    
     
    this.showamounterr= true;
    // console.log(this.showamounterr);
    
  }else {
    this.showamounterr=false;
    // console.log($event.target.value +">" +this.totalamount);
    // console.log("inside else");
    // console.log($event.target);
    
    this.offlinepay.setErrors({ invalid: true });
    // console.log(this.showamounterr);
    
  }
// console.log(this.offlinepay.invalid);
// console.log(this.showamounterr);
// console.log(this.responselength);





}

TableInitialise(){
  $('#reportTable').DataTable({
    responsive: true,
    autoWidth: true,
    searching: true,
    // Added by Hema shanker HB-675 on 25/11/2021 Start
    stateSave: true,
    // Added by Hema shanker HB-675 on 25/11/2021 End
    order: [],
    language: {
      zeroRecords: 'No records found.',
    },
    // Modified by Hema shanker HB-675 on 25/11/2021 Start
    lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
    // Modified by Hema shanker HB-675 on 25/11/2021 End
    pageLength: 5,
    pagingType: $(window).width() < 768 ? "full" : "full_numbers",
    processing: true,
    columnDefs: [
    ]
  });
}
TableInitialiseexport(){
  $('#reportTable').DataTable({
    responsive: true,
    autoWidth: true,
    searching: true,
    order: [],
    language: {
      zeroRecords: 'No records found.',
    },
    lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
    pageLength: 5,
    pagingType: $(window).width() < 768 ? "full" : "full_numbers",
    processing: true,
    columnDefs: [
    ]
  });
}


selectHoAddressChangeHandler(selectedHome) {
  // console.log(selectedHome)
  for (var i = 0; i < this.homeAddressList.length; i++) {
    
    if (this.homeAddressList[i].homeprofileid == selectedHome.homeprofileid) {
      console.log(this.homeAddressList[i])
      this.reportFormGroup.controls['userProfileInfoId'].setValue(this.homeAddressList[i].userProfileInfoId);
      this.reportFormGroup.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
      this.homeAddressList[i].lastname);
    }
  }
}

onKeyUpForHomeAddress(event: any) {
  // console.log(event.target.value);
  if( event.target.value.length <= 0 ){
    this.rongHo = false;
    this.homeAddress.setErrors({ 'required': true });
  }else{
    this.rongHo = false;
    for (var i = 0; i < this.homeAddressList.length; i++) {
      if (this.homeAddressList[i].addressline1 == event.target.value) {
        
        this.reportFormGroup.controls['userProfileInfoId'].setValue(this.homeAddressList[i].userProfileInfoId);
        this.reportFormGroup.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
        this.homeAddressList[i].lastname);
        this.homeAddress.setErrors(null);
        this.rongHo = false;
      } else {
        this.reportFormGroup.controls['userProfileInfoId'].setValue(null);
        this.reportFormGroup.controls['HoDetails'].setValue(null);
        this.homeAddress.setErrors({ invalid: true });
        this.rongHo = true;
       
      }
    }
  }
};



private _filterForHomes(value: string): String[] {
  const filterValue = value.toLowerCase();
  return this.homeAddressList.filter(option => option.addressline1.toLowerCase().includes(filterValue));
}

displayFn(home: any): string {
  if (home.homeprofileid == undefined) {
    //this.homeAddress.setErrors({ invalid: true })
    return;
  } else {
    return home.addressline1;
  }
}



}
