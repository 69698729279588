import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { CommonService } from "../../utils/common-service";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { AppResponse } from "src/app/model/AppResponse";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { environment } from "projects/user-portal/src/environments/environment";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { NgxSpinnerService } from "ngx-spinner";
import { JwtHelperService } from "@auth0/angular-jwt";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";

const helper = new JwtHelperService();

@Component({
  selector: "app-upload-invoice-by-bm",
  templateUrl: "./upload-invoice-by-bm.component.html",
  styleUrls: ["./upload-invoice-by-bm.component.css"],
})
export class UploadInvoiceByBmComponent implements OnInit {
  uplodebillsForm: FormGroup;
  submitted = false;
  resData: AppResponse;
  fileUploadList: string[] = [];
  dataTable: any;

  // isBMLoggedIn:boolean = false;

  // uploadedBy : string ='';
  // public min = new Date(2019, 12, 1, 0, 0);
  // public max = new Date();

  errorFlag: boolean = true;

  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end

  //  email: String;
  //  Purchases:String;
  //  Sales:String;
  //  suppliers:String;
  // Expenses:String;

  private uploadInvoiceByBm =
    environment.BASE_URL + ApplicationURIConstants.UPLOAD_INVOICE_BY_BM;

  constructor(
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private tokenService: TokenStorageService
  ) {}

  ngOnInit() {
    // added by gopal for HB-611 start on 10/12/2021
    if (localStorage.getItem("isGULoggedIn") == "true") {
      this.isGULoggedIn = "true";
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      this.isBMLoggedIn = "true";
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      this.isArcLoggedIn = "true";
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      this.isHOLoggedIn = "true";
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      this.isMCLoggedIn = "true";
    }

    this.uplodebillsForm = this.formBuilder.group({
      category: ["", [Validators.required]],
      file: ["", [Validators.required]],
      description: ["", [Validators.required,Validators.maxLength(250)]],
    });
  }

  // convenience getter for easy access to form fields
  get sharedDocument() {
    return this.uplodebillsForm.controls;
  }

  onSubmit() {
    console.log(this.uplodebillsForm.value);

    //  this.spinner.show();
    if (this.uplodebillsForm.invalid) {
      // this.spinner.hide();
      return;
    } else {
      var formData = new FormData();
      formData.append(
        "category",
        this.uplodebillsForm.controls["category"].value
      );
      formData.append(
        "description",
        this.uplodebillsForm.controls["description"].value
      );
      formData.append("file", this.fileUploadList[0]);
      if (this.isBMLoggedIn) {
        formData.append("userRole", "BM");
      }
      if (this.isArcLoggedIn) {
        formData.append("userRole", "ARC");
      }
      if (this.isGULoggedIn) {
        formData.append("userRole", "GU");
      }
      if (this.isMCLoggedIn) {
        formData.append("userRole", "MC");
      }
      // console.log("FORMDATA:"+formData.get("publishedDate"));
      this.httpService
        .save(formData, this.uploadInvoiceByBm)
        .subscribe((res) => {
          console.log(res);
          // this.spinner.hide();
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;

              this.uplodebillsForm.reset();
              $("#category").prop("selectedIndex", 0);
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                `${this.resData.message}`
              );

              setTimeout(() => {
                $(".close").click();
                // this.router.navigate(["/upload-invoice-by-bm"]);

                this.ngAfterViewInit();
              }, 3000);
              //15s
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.MAXIMUM_FILE_SIZE
            );
          }
        });
    }
  }

  onFileSelect(event) {
    // console.log(event)
    this.fileUploadList = [];
    this.fileUploadList.push(event.target.files[0]);
  }
  onChange(data) {
    if (data !== null) {
      this.errorFlag = false;
    } else {
      this.errorFlag = true;
    }
  }

  test() {
    this.errorFlag = false;
  }

  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService
      .get(environment.BASE_URL + ApplicationURIConstants.INVOICE_LIST_VIEW)
      .subscribe((res) => {
        console.log(res);

        this.resData = new AppResponse(res);
        $("#notificationListDatatable").dataTable().fnDestroy();
        setTimeout(() => {
          $("#notificationListDatatable").DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            order: [],
            language: {
              zeroRecords: "No records found.",
            },
            lengthMenu: [
              [5, 10, 15, 20, -1],
              [5, 10, 15, 20, "All"],
            ],
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [
              {
                targets: 2,
                orderable: false,
              },
              {
                targets: 1,
                width: "30%",
              },
            ],
          });
        }, 200);

        if (res.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (res.status === 403) {
          //403 URL not accessible
          this.router.navigate(["home"]);
        }
      });
  }
}
