import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';

@Component({
  selector: 'app-edit-event-by-arc',
  templateUrl: './edit-event-by-arc.component.html',
  styleUrls: ['./edit-event-by-arc.component.css']
})
export class EditEventByArcComponent implements OnInit {

  eventForm: FormGroup;
  resDataForCate: AppResponse;
  resData: AppResponse;

  submitted = false;
  eventId: string;
  map = new Map();
  appResponse: AppResponse;
  public dateAndTimes: Date;
  min : any = new Date();
  errorFlag : boolean = true;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end


  private eventUrl = environment.BASE_URL + ApplicationURIConstants.EDIT_EVENT_BY_ARC;
  validationerror: boolean;

  constructor(private tokenService: TokenStorageService, private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router) { }

  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_MR).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.resDataForCate = new AppResponse(res);
      // console.log(JSON.stringify(this.resDataForCate.data.categoryForARC));

      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }
    });

    this.eventForm = this.formBuilder.group({
      'categoryId': ['', Validators.required],
      'eventTitle': ['', Validators.required],
      'description': ['', [Validators.required, Validators.maxLength(5000)]],
      'dateAndTime': ['', Validators.required],
      'showForHo':[false]

    });

    this.activatedRoute.params.subscribe(params => {
      this.eventId = params.id;
      // console.log("eventId" + this.eventId)
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_VIEW_EVENT_BY_ARC + '/' + this.eventId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      console.log("appResponse" + JSON.stringify(this.appResponse.data));
      this.dateAndTimes = new Date(this.appResponse.data.eventList.dateAndTime);
      this.eventForm = this.formBuilder.group({
        "dateAndTime": [this.dateAndTimes, [Validators.required]],
        "categoryId": [this.appResponse.data.eventList.categoryId, Validators.required],
        "eventTitle": [this.appResponse.data.eventList.eventTitle, Validators.required],
        "description": [this.appResponse.data.eventList.description, Validators.required],
        "eventId": [this.appResponse.data.eventList.eventId],
        'showForHo':[this.appResponse.data.eventList.showForHo]


      });
    



      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

  }

  get event() {
    return this.eventForm.controls;
  }

  clearDateAndTimeValue() {
    this.eventForm.controls['dateAndTime'].setValue(null);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.eventForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
            console.log(name);
            
        }
    }
    return invalid;
}
  
  onSubmit() {
    this.eventForm.controls['description'].setValue(this.eventForm.controls['description'].value.trim());

    this.findInvalidControls();


    this.submitted = true;
    // console.log("RESPONSE:" + JSON.stringify(this.eventForm.value));

    if (this.eventForm.invalid) {
      console.error("invalid request")
      return;
    } else {

      console.log("valid request");
      this.httpService.update(this.eventForm.value, this.eventUrl)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              this.submitted = false;
              // this.eventForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['event-listbyarc']);
              }, 3000);  //15s
            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });


    }

  }

  
  lenghtvalidation($event){
    console.log("inside valisde");
    console.log($event.target.value.length);
    
    if($event.target.value.length>5000){
      this.validationerror=true;
      console.log(this.validationerror);
      
    }else{
      this.validationerror=false;
      console.log(this.validationerror);

    }
  }

  onChange(data){
    // console.log("-----11");
    if(data !== null){
      // console.log("-----");
      this.errorFlag = false;
    }else{
      this.errorFlag = true;
    }
  }

  test(){
    this.errorFlag = false;
  }
}
