import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-renter-info-view',
  templateUrl: './renter-info-view.component.html',
  styleUrls: ['./renter-info-view.component.css']
})
export class RenterInfoViewComponent implements OnInit {

  renterInfoId: string;
  // maintenanceRequest: MaintenanceRequest;
  appResponse: AppResponse;
  resData: AppResponse;
  requestDocumentId: string;
  documentname: string;
  fileNameContentType: string;
  fileNameExtension: string;
  rdAppResponse: AppResponse;
  detailedOriginalDoc:any = [];
  map = new Map();

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private http: HttpClient, private router: Router, private tokenService: TokenStorageService) { }

  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    this.activatedRoute.params.subscribe(params => {
      this.renterInfoId = params.id;
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.RENTER_INFORMATION_VIEW + "/" + this.renterInfoId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      // console.log("data"+JSON.stringify(this.appResponse.data))
    
      for(var i=0; i<this.appResponse.data.documentName.length; i++){
        // console.log("docName:"+this.appResponse.data.documentName[i])
        this.map.set(this.appResponse.data.documentName[i].substr(14),this.appResponse.data.documentName[i]);
      }
      if(this.appResponse.data.originalDoc){
       
        for(let i=0; i<this.appResponse.data.originalDoc.length; i++) {
          this.detailedOriginalDoc.push({
            "docUploadedBy": this.appResponse.data.docUploadedBy[i],
            "originalDoc": this.appResponse.data.originalDoc[i],
            "createdOn" : this.appResponse.data.createdOn[i]
          });
          
        }
      }
      // console.log("detailedOriginalDoc:"+JSON.stringify(this.detailedOriginalDoc));
 

      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

  }
  // get the document of byte array.
  getDocument(documentName: string) {
       this.httpService.get(environment.BASE_URL +
       ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
         this.resData = new AppResponse(data);

         //Start the code for Base64 to byte[]
         const byteCharacters = atob(this.resData.data);

         const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
           byteNumbers[i] = byteCharacters.charCodeAt(i);
         }

        let byteArray = new Uint8Array(this.resData.data);

         const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
           const byteCharacters = atob(b64Data);
           const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
             const slice = byteCharacters.slice(offset, offset + sliceSize);

             const byteNumbers = new Array(slice.length);
             for (let i = 0; i < slice.length; i++) {
               byteNumbers[i] = slice.charCodeAt(i);
             }

            const byteArray = new Uint8Array(byteNumbers);
             byteArrays.push(byteArray);
           }

           const blob = new Blob(byteArrays, { type: contentType });
           return blob;
         };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
         //End the code for Base64 to byte[]

        //Save the file
         FileSaver.saveAs(blob, documentName);
       });
   }

}
