import { ApplicationConstants as BaseApplicationConstants } from 'src/app/utils/ApplicationConstants';

export class ApplicationConstants extends BaseApplicationConstants {

    static readonly SERVER_CONNECTION_PROBLEM ="Server connection refused"

    static readonly COMPLIANCE_REQUEST_STATUS_PENDING = "PENDING";
    static readonly COMPLIANCE_REQUEST_STATUS_RESOLVED = "RESOLVED";
    static readonly COMPLIANCE_REQUEST_STATUS_ACKNOWLEDGED = "ACKNOWLEDGED";
    static readonly COMPLIANCE_REQUEST_STATUS_CLOSED = "CLOSED";
    static readonly COMPLIANCE_REQUEST_STATUS_NOT_RESOLVED = "NOT RESOLVED";
    static readonly COMPLIANCE_REQUEST_LINKED_SUCCESS = "Compliance request has been linked successfully";

    static readonly DASHBOARD_URL_LABEL = "dashboardUrl";

    static readonly BM_DASHBOARD_URL = "/dashboardbm";
    static readonly ARC_DASHBOARD_URL = "/dashboardarc";
    static readonly MC_DASHBOARD_URL = "/dashboardmc";
    static readonly HO_DASHBOARD_URL = "/home";

    static readonly SPECIALASSESMENT_LABEL = "SPECIALASSESMENT";

    // static readonly STRIPE_API_KEY = "pk_test_51KSxn9H0DoI2v68DNWlqez8jVGwV6ybGgCp5D78V7bcOhavwgykQrX6DanWk1wLcLgMsLpGDUYyGQi77aQazokm300leJfVZp1";

    
     static readonly STRIPE_API_KEY_USER_PORTAL = "pk_test_51KY7qpJ8AenmbZPOejsOZ8PJNu11x6fpa6mmETW9oxFVJBXIAvoGIcttIdrxF6pKGRry7eQGzkHaHOLHAjWWYxVL00bg9HArYL";
    


}
