import { Component, OnInit, Input, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { TokenStorageService } from '../../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ApplicationURIConstants } from '../../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { CommonService } from '../../../utils/common-service';

  @Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
  })
  export class HeaderComponent implements OnInit{
    @Input() headerData: any;
    public groupConfiges:any;
    userprofileinfoId: any;
    isHOLogin : boolean;
    groupName : String;
    address : String;
    header : string;
    isHOLoggedIn: String;
    isBMLoggedIn:string;
    // added by Hema shanker for HB-601 on 13/12/2021 Start
    isArcLoggedIn: String;
    isARCLogin :boolean;
    isBMLogin :boolean;
    a : String;
    roles:any;
    onlyHO:boolean=false;
    resData: AppResponse;
    imageToShow: any;
    // added by Hema shanker for HB-601 on 13/12/2021 End
    

    constructor(private router: Router,private httpService: HttpTransactionService,private commonService: CommonService, private location: Location, private tokenService: TokenStorageService) {
    }

    ngOnInit() {
      this.httpService.get(environment.BASE_URL +
        ApplicationURIConstants.GET_LOGO + '?logoName=' + this.tokenService.getGroupConfigByKey('logo')).subscribe(data => {
          this.resData = new AppResponse(data);
          if (this.resData.status == 200) {
            //Start the code for Base64 to byte[]
            // console.log(this.resData)
            this.imageToShow = 'data:image/png;base64,' + this.resData.data;
            
          } else if (this.resData.status == 400) {
            this.imageToShow = null;
          } else if (this.resData.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
      // console.log(this.tokenService.getRoles());
   this.roles = this.tokenService.getRoles();
   if(this.roles.length==1){
    
       this.onlyHO=true;
    
   }
      this.header = this.tokenService.getGroupConfigByKey('header');
      if( localStorage.getItem("isHOLoggedIn") == "true" ){
        this.isHOLogin = true;
        // added by Hema shanker for HB-601 on 13/12/2021 Start
        this.a='Home Owner';
        // added by Hema shanker for HB-601 on 13/12/2021 End
      }else{
        this.isHOLogin = false;
      }
      // added by Hema shanker for HB-601 on 13/12/2021 Start
      if( localStorage.getItem("isArcLoggedIn") == "true" ){
        this.isARCLogin = true;
        this.a='ARC';
      }else{
        this.isARCLogin = false;
      }
      if( localStorage.getItem("isBMLoggedIn") == "true" ){
        this.isBMLogin = true;
        this.a='Board Member';
      }else{
        this.isBMLogin = false;
      }
      // added by Hema shanker for HB-601 on 13/12/2021 End

      this.userprofileinfoId = this.tokenService.getuserProfileId();
      this.groupName = this.tokenService.getGroupName();
      this.address = this.tokenService.getAddress();
      
      // console.log("ISD:"+this.userprofileinfoId);
      setTimeout(()=>{
        if(this.headerData === undefined || this.headerData === null ){
          this.groupConfiges= JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_CONFIGS_KEY));
        } else {
          this.groupConfiges= this.headerData;
        }
      }, 500);
      
    }

    ngAfterViewInit(): void {

      if(localStorage.getItem("isBMLoggedIn") == "true"){
        this.isBMLoggedIn = 'true';
      }
       else if(localStorage.getItem("isHOLoggedIn") == "true" ){
        this.isHOLoggedIn = 'true';
      }
      // added by Hema shanker for HB-601 on 13/12/2021 Start
      else if(localStorage.getItem("isArcLoggedIn") == "true" ){
        this.isArcLoggedIn = 'true';
      }
      // added by Hema shanker for HB-601 on 13/12/2021 End
    }

    
    doLogout() {
      localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
      this.tokenService.clearSession();
      this.router.navigate(['login']);
    }

    changePassword(){
      this.router.navigate(['change-password',this.userprofileinfoId]);
    }
  }
