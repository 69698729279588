import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { Router } from '@angular/router';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as $ from 'jquery';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
const helper = new JwtHelperService();
@Component({
  selector: 'app-add-external-bm',
  templateUrl: './add-external-bm.component.html',
  styleUrls: ['./add-external-bm.component.css']
})
export class AddExternalBMComponent implements OnInit {

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private tokenService: TokenStorageService) { }

  userRegistrationForm: FormGroup;

  groupList = [];

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end


  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end


    // $("#vehicleYear").append(options);
    // this.httpService.get(ApplicationURIConstants.BASE_URL + ApplicationURIConstants.GROUP_LIST).subscribe(res => {
    //   this.resData = new AppResponse(res);
    //   if (this.resData.status == 200) {
    //     this.groupList = this.resData.data;
    //   } else if (this.resData.status === 403) {
    //     this.router.navigate(['home']);
    //   }
    // });


    // ("#firstName").focus();
    this.userRegistrationForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "contact": ['', [Validators.required, Validators.pattern(AppRegExConstants.INTERNATIONAL_PHONE_NUMBERS)]],
      "email": ['', [ Validators.required,Validators.pattern(AppRegExConstants.EMAIL)]],
    });


  }

  // convenience getter for easy access to form fields
  get f() { return this.userRegistrationForm.controls; }

  // URL
  private users = environment.BASE_URL + ApplicationURIConstants.ADD_EXTERNAL_BM;

  resData: AppResponse;

  submitted = false;

  // onSubmit(formData: any, formDirective: FormGroupDirective): void {

  //   this.submitted = true;
  //   if (this.userRegistrationForm.invalid) {
  //     return;
  //   } else {

  //     this.httpService.save(this.userRegistrationForm.value, this.users)
  //       .subscribe((res) => {
  //         this.resData = new AppResponse(res);
  //         if (this.resData.status == 200) {
  //           if (this.resData.message == ApplicationConstants.SUCCESS_LABLE) {
  //             this.submitted = false;
  //             formDirective.resetForm();
  //             this.userRegistrationForm.reset();
  //             this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, ApplicationConstants.SUCCESS_LABLE);
  //             $('html, body').animate({ scrollTop: (0) }, 2000);
  //             setTimeout(() => {
  //               this.router.navigate(['dashboardbm']);
  //             }, 15000);  //15s
  //           }
  //         }
  //         else if (this.resData.status == 401 || this.resData.status == 403) {
  //           this.router.navigate(['home']);
  //         } else {
  //           this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
  //         }
  //       });
  //   }
  // }


  onSubmit(){

    // console.log(this.userRegistrationForm.value)
    if (this.userRegistrationForm.invalid) {
      return;
    } else {

      this.httpService.save(this.userRegistrationForm.value, this.users)
      .subscribe((res) => {
        if(res != undefined){
          this.resData = new AppResponse(res);
          if (res.status == 200) {
            this.submitted = false;
            this.userRegistrationForm.reset();

            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['dashboardbm']);
            }, 5000);  //15s
          } else if(res.status == 401){
            this.router.navigate(['logout']);
          }else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });
    }


  }

  clickForReset() {
    $('html, body').animate({ scrollTop: (0) }, 1000);
  }


}
