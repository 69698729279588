import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Component({
  selector: 'app-mc-notification-list',
  templateUrl: './mc-notification-list.component.html',
  styleUrls: ['./mc-notification-list.component.css']
})
export class McNotificationListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  userProfileInfoId: string;
  editReqData: AppResponse;
  deleteResData: AppResponse;
  hideResData: AppResponse;
  data: any;
  notificationListForARC: boolean = true;
  hide = true;
  unhide = false
  hideNotification: FormGroup;

   // added by gopal for HB-611 start on 10/12/2021
   isGULoggedIn: string;
   isBMLoggedIn: String;
   isArcLoggedIn: String;
   isHOLoggedIn: String;
   isMCLoggedIn: String;
   // added by goapl for HB-611 end
 

  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private formBuilder: FormBuilder, private commonService: CommonService) {

  }

  ngOnInit() {

    localStorage.setItem("notificationviewed","true")
    
       // added by gopal for HB-611 start on 10/12/2021
       if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
      // added by goapl for HB-611 end
    this.userProfileInfoId = this.tokenService.getuserProfileId();
    // console.log("userProfileInfoId" + this.userProfileInfoId);

    this.hideNotification = this.formBuilder.group({
      'notificationId': ['0', [Validators.required]]
    });

  }

  ngAfterViewInit(): void {
    $('#notificationListDataTable').dataTable().fnDestroy(); 
    // below line modified by aishwaryaa for hb-421  27/12/2021
    //NOTIFICATION_LIST_BY_BM
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.NOTIFICATION_LIST_BY_MC).subscribe(res => {
      this.resData = new AppResponse(res);
//code end 
      setTimeout(() => {
        $('#notificationListDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
           // Added by Hema shanker HB-675 on 25/11/2021 Start
           stateSave: true,
           // Added by Hema shanker HB-675 on 25/11/2021 End
          // retrieve: true,
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
           // Modified by Hema shanker HB-675 on 25/11/2021 Start
           lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
           // Modified by Hema shanker HB-675 on 25/11/2021 End
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 4,
            orderable: false
          },
          ]
        });
      }, 200);
      if (res.status === 200) {
        // console.log("this.resData:" + JSON.stringify(this.resData));

      }
      else if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['dashboardarc']);
      }
    });
  }

  private deleteNotification = environment.BASE_URL + ApplicationURIConstants.DELETE_NOTIFICATION_BY_BM;
  public deleteNotificationByBm(data: any) {
    // console.log("DATA:" + data);
    if (confirm("Are you sure you want to delete this notification ?")) {

      this.httpService.update(data, this.deleteNotification + '/' + data)
        .subscribe((res) => {
          this.deleteResData = new AppResponse(res);
          if (this.deleteResData.status == 200) {
            //this.submitted = false;
            $('.close').click();
            // console.log("MSG:" + this.deleteResData.message)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.deleteResData.message);

            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);

          } else {
            // console.log(this.deleteResData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.deleteResData.errors}`);
          }
        });
    }
  }


  public sendNotificationId(data: any) {
    // console.log("DATA:" + data);
    this.data = data;


  }

  private hideNotificationURL = environment.BASE_URL + ApplicationURIConstants.HIDE_NOTIFICATION_BY_BM;
  public hideNotificationByBm() {
    // console.log("DATA:" + JSON.stringify(this.hideNotification.value));
    this.httpService.update(this.data, this.hideNotificationURL + '/' + this.data)
      .subscribe((res) => {
        this.hideResData = new AppResponse(res);
        if (this.hideResData.status == 200) {
          //this.submitted = false;
          $('.close').click();
          // console.log("MSG:" + this.hideResData.message)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.hideResData.message);

          setTimeout(() => {
            this.ngAfterViewInit();
          }, 3000);

        } else {
          // console.log(this.hideResData.errors)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.hideResData.errors}`);
        }
      });
  }

}
