import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationUtils } from 'src/app/utils/ApplicationUtils';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [NgxSpinnerService]
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  //roles: [] = [];
  captchaResponse: string;
  loginCount: number = 1;
  flag: boolean = false;
  env=environment.env;
  roles: any[] = [];

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  private signInUrl = environment.BASE_URL + ApplicationURIConstants.SIGN_IN;

  constructor(private router: Router, private formBuilder: FormBuilder, private applicationUtils: ApplicationUtils, private authService: AuthService, private tokenService: TokenStorageService,
    private route: ActivatedRoute, private spinner: NgxSpinnerService) {
    if (this.tokenService.getToken()) {
      // console.log("-----1")
      if (localStorage.getItem(ApplicationConstants.DASHBOARD_URL_LABEL) == ApplicationConstants.BM_DASHBOARD_URL) {
        this.router.navigate(['dashboardbm']);
      } else if (localStorage.getItem(ApplicationConstants.DASHBOARD_URL_LABEL) == ApplicationConstants.MC_DASHBOARD_URL) {
        this.router.navigate(['dashboardmc']);
      } else if (localStorage.getItem(ApplicationConstants.DASHBOARD_URL_LABEL) == ApplicationConstants.ARC_DASHBOARD_URL) {
        this.router.navigate(['dashboardarc']);
      } else {
        this.router.navigate(['home']);
      }

    }
  }

  loginForm: FormGroup;

  ngOnInit() {
    $(document).ready(() => {
      $('body').css('background-color', '#eee');
    });
    this.loginForm = this.formBuilder.group({
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      "password": ['', [Validators.required, Validators.pattern(AppRegExConstants.PASSWORD)]],
    });
  }

  get f() { return this.loginForm.controls; }

  submitted = false;
  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    if (this.loginForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      this.spinner.hide();
      if (this.loginCount > 3 && this.captchaResponse == null) {
        return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please Fill all the details.");
      }

      if (this.flag) {
        return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Captcha has expired. Please check the checkbox again.");
      }

      this.authService.signIn(this.signInUrl, this.loginForm.value)
        .subscribe((appResponse) => {
          if (appResponse.status == 200 && appResponse.message == ApplicationConstants.SUCCESS_LABLE) {
            setTimeout(() => {
              localStorage.setItem("greeting","true");
              this.tokenService.saveUserTokenData(appResponse.data);
              // console.log("DATA:" + appResponse.data.firstTimeLogin);
              // console.log("DATA1:" + JSON.stringify(appResponse.data));
              // if(appResponse.data.firstTimeLogin){
              //   this.router.navigate(['change-password',appResponse.data.userProfileInfoId]);
              // }else{
              //   this.router.navigate(['home']);
              // }
              // console.log("roles:" + appResponse.data.roles);
              //this.roles = appResponse.data.roles;
              this.roles = this.tokenService.getRoles();
              // console.log("len:" + this.roles.length)
              // console.log("test:" + this.roles[0]);
              // console.log("GROUPNAME=========" + this.tokenService.getGroupName());
              // console.log("ADDRESS=========" + this.tokenService.getAddress());
              if (this.roles.length > 1) {
                if (appResponse.data.firstTimeLogin) {
                  this.router.navigate(['change-password', appResponse.data.userProfileInfoId]);
                }
                //added by aishwaryaa for HB-488 31/12/2021
                else {
                  if (this.roles[0] == 3)
                  this.router.navigate(['dashboardbm']);
                else if (this.roles[0] == 4) {
                  // console.log(".........ARC");
                  this.router.navigate(['dashboardarc']);
                }
                else if (this.roles[0] == 5)
                  this.router.navigate(['dashboardmc']);
                else if (this.roles[0] == 7)
                  this.router.navigate(['dashboardgu']);
                  else if (this.roles[0] ==6)
                  this.router.navigate(['dashboard'])
                }
                //code end

              } else {
                if (appResponse.data.firstTimeLogin) {
                  this.router.navigate(['change-password', appResponse.data.userProfileInfoId]);
                } else {
                  if (this.roles[0] == 3)
                    this.router.navigate(['dashboardbm']);
                  else if (this.roles[0] == 4) {
                    // console.log(".........ARC");
                    this.router.navigate(['dashboardarc']);
                  }
                  else if (this.roles[0] == 5)
                    this.router.navigate(['dashboardmc']);
                  else if (this.roles[0] == 7)
                    this.router.navigate(['dashboardgu']);
                  else {
                    // console.log("/////");
                    this.router.navigate(['home']);
                  }

                }
              }
            }, 1000);
          } else {
            this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, appResponse.errors);
            this.loginCount = this.loginCount + 1;
            if (this.loginCount === 4) {
              this.addRecaptchaScript();
            }
          }
        }, error => {
          this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.INTERNAL_SEREVR_ERROR);
          this.errorMessage = error.error.message;
          this.isLoginFailed = true;
        });
    }
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey':environment.ReCaptcha,
      'callback': (response) => {
        this.captchaResponse = response;
        $("#messageBox").empty();
        this.flag = false;
      },
      'expired-callback': () => {
        this.flag = true;
      }
    });
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  togglePassword(id, passId) {
    var passwordInput = document.getElementById(passId);
    var passid = document.getElementById(id);

    // console.log("pass" + passwordInput);

    if (passwordInput.getAttribute('type') == 'password') {
      passwordInput.setAttribute('type', 'text');
      $(passid).toggleClass("fa-eye fa-eye-slash");
    }
    else {
      passwordInput.setAttribute('type', 'password');
      $(passid).toggleClass("fa-eye fa-eye-slash");

    }


  }

}
