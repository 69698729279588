import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as XLSX from 'xlsx';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';



@Component({
  selector: 'app-report',
  templateUrl: './request-report.component.html',
  styleUrls: ['./request-report.component.css'],
  providers: [NgxSpinnerService]
})
export class RequestReportComponent implements OnInit {
  @ViewChild('fromReportDate',{static:false}) fromReportDate:ElementRef;

  reportFormGroup: FormGroup;
  resData: any;
// Added by aishwaryaa for HB-714
  maxTodate: any =  new Date();
  // code end
  public minDate = new Date(2019, 12, 1, 0, 0);
  errorss: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  public show = false;
  finaldate:Date;

     // added by gopal for HB-611 start on 10/12/2021
     isGULoggedIn: string;
     isBMLoggedIn: String;
     isArcLoggedIn: String;
     isHOLoggedIn: String;
     isMCLoggedIn: String;
  minimumdate: any;
  maximumDate: Date;
  nextdate: number;
     // added by goapl for HB-611 end
 
  constructor(private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
       // added by gopal for HB-611 start on 10/12/2021
       if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end

    this.spinner.show();
    this.maxTodate.setDate(this.maxTodate.getDate());
    this.reportFormGroup = this.formBuilder.group({
      'status': ['ALL', [Validators.required]],
      'type': ['ALL', [Validators.required]],
      'startDate': ['', [Validators.required]],
      'endDate': [, [Validators.required]],
    });

    if (localStorage.getItem("reportFormGroup") != null) {
      this.reportFormGroup.controls['status'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).status);
      this.reportFormGroup.controls['type'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).type);
      this.reportFormGroup.controls['startDate'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).startDate);
      this.reportFormGroup.controls['endDate'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).endDate);
    }
    if (localStorage.getItem("requestReportData") != null) {      
       this.resData = new AppResponse(JSON.parse( localStorage.getItem("requestReportData")) );         
      //  console.log(this.resData)
        } 

        this.newdate(event);
  }

  ngAfterViewInit(): void {

    this.TableInitialise();
    this.spinner.hide();
  }
  // convenience getter for easy access to form fields
  get report() { return this.reportFormGroup.controls; }

newdate($event){
  
  this.minimumdate = new Date($event.target.value);

  // console.log(new Date($event.target.value).getMonth()+1);
  this.nextdate=new Date($event.target.value).getMonth()+1

  this.maximumDate=new Date();

// console.log(this.maximumDate);


if(this.nextdate > 9){

  // console.log(new Date($event.target.value).getMonth()+1);
  // console.log("insdie if");
  
  this.maximumDate.setDate(this.minimumdate.getDate());
  this.maximumDate.setMonth(this.minimumdate.getMonth()+3);
  this.maximumDate.setFullYear(this.minimumdate.getFullYear()+1);
}else{
  // console.log(new Date($event.target.value).getMonth()+1);
  // console.log("inside else");
  
  this.maximumDate.setDate(this.minimumdate.getDate());
  this.maximumDate.setMonth(this.minimumdate.getMonth()+3);
  this.maximumDate.setFullYear(this.minimumdate.getFullYear());
}

  // this.maximumDate.setDate(this.minimumdate.getDate());
  // this.maximumDate.setMonth(this.minimumdate.getMonth()+3);
  // this.maximumDate.setFullYear(this.minimumdate.getFullYear());
  
}

  onSubmit() {
    $("#messageBox").empty();
    // console.log(JSON.stringify(this.reportFormGroup.value));
    this.spinner.show();
    if (this.reportFormGroup.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      // console.log(this.reportFormGroup.value);
      this.isValidDate = this.validateDates(this.reportFormGroup.controls['startDate'].value, this.reportFormGroup.controls['endDate'].value);
      if (this.isValidDate) {

        this.httpService.save(this.reportFormGroup.value, environment.BASE_URL + ApplicationURIConstants.GET_REQUEST_REPORT)
          .subscribe((res) => {
            if (res != undefined) {
              this.resData = new AppResponse(res);
              // console.log(this.resData);

              if (res.status == 200) {

              
                $('#reportTable').dataTable().fnDestroy();
                setTimeout(() => {
                  this.TableInitialise();
                  this.spinner.hide();
                }, 2000);  //2s

              } else if (res.status == 401) {
                this.spinner.hide();
                this.router.navigate(['logout']);
              } else {
                this.spinner.hide();
                $('#reportTable').dataTable().fnDestroy();
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
              }
            } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
            }
          });
      } else {
        this.spinner.hide();
      }

    }
  }

  // code added by aishwaryaa for hb-945
  onSubmitExport() {
    $("#messageBox").empty();
    // console.log(JSON.stringify(this.reportFormGroup.value));
    this.spinner.show();
    if (this.reportFormGroup.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      // console.log(this.reportFormGroup.value);
      this.isValidDate = this.validateDates(this.reportFormGroup.controls['startDate'].value, this.reportFormGroup.controls['endDate'].value);
      if (this.isValidDate) {

        this.httpService.save(this.reportFormGroup.value, environment.BASE_URL + ApplicationURIConstants.GET_REQUEST_REPORT)
          .subscribe((res) => {
            if (res != undefined) {
              this.resData = new AppResponse(res);
              // console.log(this.resData);

              if (res.status == 200) {

              
                $('#reportTable').dataTable().fnDestroy();
                setTimeout(() => {
                  
                  this.TableInitialise();
                 this.exportexcel();
                  // this.spinner.hide();
                }, 2000);  //2s

                

              } else if (res.status == 401) {
                this.spinner.hide();
                this.router.navigate(['logout']);
              } else {
                this.spinner.hide();
                $('#reportTable').dataTable().fnDestroy();
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
              }
            } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
            }
          });
      } else {
        this.spinner.hide();
      }

    }
  }

  TableInitialise() {
    $('#reportTable').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
       // Added by Hema shanker HB-675 on 25/11/2021 Start
       stateSave: true,
       // Added by Hema shanker HB-675 on 25/11/2021 End
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
     // Modified by Hema shanker HB-675 on 25/11/2021 Start
     lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
     // Modified by Hema shanker HB-675 on 25/11/2021 End
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  
  TableInitialiseexport() {
    $('#reportTable').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'RequestReportExcelSheet.xlsx';
  exportexcel(): void {
    /* table id is passed over h
    thisere */
    // this.onSubmitExport();
    $('#reportTable').dataTable().fnDestroy();
    setTimeout(() => {
      // this.onSubmitExport();
      this.TableInitialiseexport();
      this.spinner.hide();
    }, 2000);
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'][6] = { hidden: true };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //console.log(wb);
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.show = true;
      this.errorss = { isError: true, errorMessage: 'To Date should be greater than From Date.' };
      setTimeout(function () {
        this.show = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    } else if ((sDate != null && eDate != null) && (sDate) > (eDate)) {
      this.show = true;
      this.errorss = { isError: true, errorMessage: 'From Date should be less than To Date.' };
      setTimeout(function () {
        this.show = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  saveData(){
    if (this.resData.status == 200) {
      localStorage.setItem("reportFormGroup", JSON.stringify(this.reportFormGroup.value));
      localStorage.setItem("requestReportData", JSON.stringify(this.resData));  
    }   
  }
  
}
