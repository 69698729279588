import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ApplicationConstants } from './ApplicationConstants';


declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor(private logger: NGXLogger) { }

  showNotificationSuccess(msgDivId, alertType, alertMsg) {
    var alertMsgDiv = "<div class=\"alert alert-" + alertType + " alert-dismissible\" role=\"alert\">";
    var res = alertMsg.split(',');
    alertMsgDiv += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>";
    // alertMsgDiv += "" + res[0]; + "";
    res.forEach(myFunction);

    function myFunction(value, index, array) {
      if(index <=4){
        alertMsgDiv += "<p>" +value; + "<br/><p>";
      }
    }
    alertMsgDiv += "</div>";

    $(msgDivId).html(alertMsgDiv);

    $('html, body').animate({ scrollTop: (0) }, 2000);
    if(alertType == ApplicationConstants.SUCCESS_LABLE){
      window.setTimeout(function() {
        $(".alert-"+alertType+"").fadeTo(500, 0).slideUp(500, function(){
            $(this).remove();
        });
      }, 15000); // 15s
    }

  }


  handelError<T>(operation = 'Operation', result?: T) {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed : ${error.message}`);
      return of(result as T);// Return empty result to keep runnig our application.
    };
  }
   /** HB-677,HB-683 Priya Start */
  showNotificationSuccessInModal(msgDivId, alertType, alertMsg) {
    var alertMsgDiv = "<div class=\"alert alert-" + alertType + " alert-dismissible fade show \" role=\"alert\">";
    var res = alertMsg.split(',');
    alertMsgDiv += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>";
    res.forEach(myFunction);

    function myFunction(value, index, array) {
      if(index <=4){
        alertMsgDiv += "<p>" +value; + "<br/><p>";
      }
    }
    alertMsgDiv += "</div>";
    

    $(msgDivId).html(alertMsgDiv);

    if(alertType == ApplicationConstants.SUCCESS_LABLE){
      window.setTimeout(function() {
        $(".alert-"+alertType+"").fadeTo(500, 0).slideUp(500, function(){
            $(this).remove();
        });
      }, 15000); // 15s
    }

  }
  /**  HB-677,HB-683 Priya End*/

}

