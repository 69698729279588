import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { TokenStorageService } from './services/token-storage.service';
@Injectable()
export class BMauthGuard implements CanActivate {

  constructor(private router: Router, private token: TokenStorageService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(this.router.url + "bm routing");

    for (let i = 0; i < this.token.getRoles().length; i++) {
        const element = this.token.getRoles()[i];
       
        if(Number(element)==3 && this.token.getToken()){
            return true;
        }
    }


    
    
    
    if (this.token.getToken()) {
        this.router.navigate([localStorage.getItem('dashboardUrl')]);
            
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
