import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective} from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();
@Component({
  selector: 'app-add-renter-information',
  templateUrl: './add-renter-information.component.html',
  styleUrls: ['./add-renter-information.component.css']
})
export class AddRenterInformationComponent implements OnInit {
  renterInformationForm: FormGroup;
  fileUploadList :string [] = [];
  resData: AppResponse;
  submitted = false;
  errorss:any={isError:false,errorMessage:''};
  isValidDate:any;
  public show=false;
  editable: boolean;
  errorFlag: boolean = true;
  errorFlagLeaseEndDate: boolean = true;

  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  public minDateOfBirth = new Date(1925, 12, 1, 0, 0);
  public maxDateOfBirth = new Date(2005, 12, 1, 0, 0);
  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0);
  public minLeaseEndDateTemp ;
  isError : boolean = true;
  countryCode : any;
  intlobj : any;
  flag : boolean = false;
  checkEmptyFlag : boolean = true;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  
  private renterInformationUrl = environment.BASE_URL +ApplicationURIConstants.RENTER_INFORMATION_ADD;

  constructor(private httpService: HttpTransactionService, 
    private formBuilder: FormBuilder, 
    private commonService: CommonService, 
    private router: Router,
    private tokenService:TokenStorageService) { }

  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end

    this.renterInformationForm = this.formBuilder.group({
      // Validators.pattern('(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}')
      // ^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([2-9])(\d{1})|(20)([01])(\d{1})|([8901])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([2-9])(\d{1})|(20)([01])(\d{1})|([8901])(\d{1})))$
      'leaseStartDate': ['', [Validators.required]],
      'leaseEndDate': ['', [Validators.required]],
      'firstName': ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      'email': ['', [Validators.required,Validators.pattern(AppRegExConstants.EMAIL)]],
      'lastName': ['',[Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      'contact': ['',[]],
      'dateOfBirth': ['',],
      'importantdocuments': [''],

    });

    var d = new Date();
    d.setFullYear(d.getFullYear() - 18);
    this.maxDateOfBirth = d;

    var minDateVal = new Date();
    minDateVal.setFullYear(minDateVal.getFullYear() - 2);
    this.min = minDateVal;
    this.minLeaseEndDate = minDateVal;


  }
  get renterInfo() {
     return this.renterInformationForm.controls;
  }

  clearleaseStartDateValue() {
    this.renterInformationForm.controls['leaseStartDate'].setValue(null);
  }
  clearleaseEndDateValue() {
    this.renterInformationForm.controls['leaseEndDate'].setValue(null);
  }
  cleardateOfBirthValue() {
    this.renterInformationForm.controls['dateOfBirth'].setValue(null);
  }

  onSubmit() {
    this.submitted = true;
    if (this.renterInformationForm.invalid ||(!this.isError && !this.checkEmptyFlag)) {
     // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.renterInformationForm.value))
      return;
    }
     else {

      this.isValidDate = this.validateDates(this.renterInformationForm.controls['leaseStartDate'].value, this.renterInformationForm.controls['leaseEndDate'].value);
      // console.log("EditRENTERINFO:" + JSON.stringify(formData));
      var formData = new FormData();

       for (var i = 0; i < this.fileUploadList.length; i++) {
         formData.append("importantdocuments", this.fileUploadList[i]);
       }
      //  formData.append("leaseStartDate",  this.renterInformationForm.controls['leaseStartDate'].value)
      //  console.log(";;;;;"+this.minLeaseEndDateTemp);
       formData.append("leaseStartDate",  this.renterInformationForm.controls['leaseStartDate'].value);
       formData.append("leaseEndDate",this.renterInformationForm.controls['leaseEndDate'].value);
       formData.append("firstName",this.renterInformationForm.controls['firstName'].value);
       formData.append("lastName",this.renterInformationForm.controls['lastName'].value);
       formData.append("dateOfBirth",this.renterInformationForm.controls['dateOfBirth'].value);
       formData.append("contact",this.intlobj.getNumber());
       formData.append("email",this.renterInformationForm.controls['email'].value);
      //  console.log("RENTERINFOINSI:" + JSON.stringify(formData));
      if(this.isValidDate){
        // console.log("FORMDATA:"+ JSON.stringify(this.renterInformationForm.value));
      this.httpService.save(formData,this.renterInformationUrl)
      .subscribe((res) => {
        if(res != undefined){
          this.resData = new AppResponse(res);
          // console.log("RENTERINFOINSI:" + JSON.stringify(formData));

          if (res.status == 200) {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['renterInformations-list']);
            }, 5000);  //15s
          } else if(res.status == 401){
            this.router.navigate(['logout']);
          }
          else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });
      }
    }

  }

  onFileSelect(event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }

  validateDates(sDate: string, eDate: string){
    this.isValidDate = true;
    if((sDate != null && eDate !=null) && (eDate) < (sDate)){
       this.show=true;
      this.errorss={isError:true,errorMessage:'Lease End Date should be greater then Lease Start Date.'};
      setTimeout(function() {
        this.show = false;
    }.bind(this),6000);
      this.isValidDate = false;
    }
  else
    if((sDate != null && eDate !=null) && (sDate) > (eDate)){
    this.show=true;
     this.errorss={isError:true,errorMessage:'Lease Start Date should be less then Lease End Date.'};
     setTimeout(function() {
       this.show = false;
   }.bind(this),6000);
     this.isValidDate = false;
   }
    return this.isValidDate;
  }


onChange(data) {
  if(data !== null){
    this.errorFlag = false;
    this.editable = true;
    this.minLeaseEndDateTemp = data;
    var datetemp = new Date(this.minLeaseEndDateTemp.getFullYear(),this.minLeaseEndDateTemp.getMonth(),this.minLeaseEndDateTemp.getDate()+1);
    // console.log("////////"+datetemp);
    this.minLeaseEndDate = datetemp;
    this.renterInformationForm.controls['leaseEndDate'].setValue(null);
    // console.log("---"+JSON.stringify(data));
 }else{
    this.errorFlag = true;
    this.renterInformationForm.controls['leaseEndDate'].setValue(null);
    this.editable = false;
  }
   }

   onChangeLeaseEnd(data){
     if(data !== null){
      this.errorFlagLeaseEndDate = false;
     }else{
      this.errorFlagLeaseEndDate = true;
     }
   }

   test(){
     this.errorFlag = false;
   }
   
   testLend(){
     this.errorFlagLeaseEndDate =false;
   }

   onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;
    if(this.flag)
      this.intlobj.setNumber('');
     else
      this.flag = true; 
    
  }

  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}

telInputObject(obj) {
  // console.log(obj);
  this.intlobj = obj;
  this.flag = true;
}

checkForError(obj){
  if(this.intlobj.getNumber() == '')
  this.checkEmptyFlag = true;
  else
  this.checkEmptyFlag = false;   
  this.myInput.nativeElement.blur();
  $("#phoneInput").focus();
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
       return true;
    else   
       return false;
  }
  return true;

}


}

