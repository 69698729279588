import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../utils/common-service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { JsonpInterceptor } from '@angular/common/http';

@Component({
  selector: 'app-event-view-ho',
  templateUrl: './event-view-ho.component.html',
  styleUrls: ['./event-view-ho.component.css']
})
export class EventViewHoComponent implements OnInit {

  resData: AppResponse;
  eventId: string;
  appResponse: AppResponse;
  isAcceptEnable: Boolean;
  isDenieEnable: Boolean;
  isARCLoggedIn: Boolean;
  map = new Map();
  assigneeList: any[] = [];
  userProfileInfoId: any;
  reviewEventForm: FormGroup;
  submitted = false;
  // Modified by Hema shanker for HB-458 Start
  // show = false;
  // Modified by Hema shanker for HB-458 End
   // Modified By Hema shanker for HB-694 Start
  length:any=0;
  isapproved:number=0;
  isrejected:number=0;
  isnull:number=0;
  isreviewoutcome:string=null;
   // Modified By Hema shanker for HB-694 End
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  private eventReview = environment.BASE_URL + ApplicationURIConstants.EVENT_REVIEW_UPDATE_BY_ARC;

  constructor(private tokenService: TokenStorageService, private commonService: CommonService, private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end

    this.spinner.show();
    if (localStorage.getItem("isARCLoggedIn") == "true") {
      this.isARCLoggedIn = true;
      // console.log("ARC" + this.isARCLoggedIn)

    }

    this.activatedRoute.params.subscribe(params => {
      this.eventId = params.id;
      // console.log("eventId" + this.eventId)
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_VIEW_EVENT_BY_HO + '/' + this.eventId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      console.log(data);
      
      this.userProfileInfoId = this.tokenService.getuserProfileId();
      // console.log("userProfileInfoId" + this.userProfileInfoId)


      for (var i = 0; i < this.appResponse.data.reviewEventList.length; i++) {
        this.assigneeList.push(this.appResponse.data.reviewEventList[i]);
      }
      // Modified by Hema shanker for HB-458 Start
      // if (this.userProfileInfoId == this.appResponse.data.eventList.createdBy) {
      //   this.show = true
      // }
      // Modified by Hema shanker for HB-458 End
       // Modified By Hema shanker for HB-694 Start
      for (var i=0;i<this.appResponse.data.reviewEventList.length;i++){
      if (this.appResponse.data.reviewEventList[i].reviewOutCome=="ACCEPTED"){
        this.isapproved=this.isapproved+1;
      }
      else if (this.appResponse.data.reviewEventList[i].reviewOutCome=="REJECTED"){
        this.isrejected=this.isrejected+1;
      }
      else if (this.appResponse.data.reviewEventList[i].reviewOutCome== null){
        this.isnull=this.isnull+1;

      }
    } 
    this.length=this.assigneeList.length/2;
      if(this.length<this.isapproved){
        this.isreviewoutcome="APPROVED";
      }
      else if(this.length<this.isrejected){
        this.isreviewoutcome="REJECTED";
      }
      else if(this.length<this.isnull){
        this.isreviewoutcome="PENDING";
      }
     else if(this.isreviewoutcome==null){
        this.isreviewoutcome="PENDING";
      }
       // Modified By Hema shanker for HB-694 End
      for (var i = 0; i < this.assigneeList.length; i++) {
        // console.log("insideloop"+JSON.stringify(this.assigneeList));

        if (this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == null) {
          this.isAcceptEnable = true;
          this.isDenieEnable = true;
        }
         // Modified By Hema shanker for HB-694 Start
        else if(this.assigneeList[i].assignedTo == this.userProfileInfoId && this.isreviewoutcome=="PENDING"){
            this.isAcceptEnable=true;
            this.isDenieEnable=true;
        }
         // Modified By Hema shanker for HB-694 End
      }


      setTimeout(() => {
        $('#reviewCommentTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: false,
          retrieve: true,
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers"
          // pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          // processing: true,
          // columnDefs: [{
          //   targets: 4,
          //   orderable: false
          // },
          // ]
        });
      }, 200);
      if (data.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardarc']);
      }

    });

    this.reviewEventForm = this.formBuilder.group({
      'eventId': [this.eventId, [Validators.required]],
      'reviewOutCome': ['', [Validators.required]],
    });

  }
  get eventsReview() { return this.reviewEventForm.controls; }

  acceptFunction() {
    this.reviewEventForm.controls['reviewOutCome'].setValue("ACCEPTED");
    this.reviewEventForm.controls['eventId'].setValue(this.eventId);
    // console.log("test::::" + JSON.stringify(this.reviewEventForm.value));
  
        this.httpService.update(this.reviewEventForm.value, this.eventReview)
          .subscribe((res) => {
            // console.log("INSIDE THE FIRST");
            if (res != undefined) {
              this.resData = new AppResponse(res);
              // console.log("RESPONSE:" + JSON.stringify(this.resData));
              if (res.status == 200) {
                $('.close').click();
                // console.log("INSIDE THE SECOND");
                this.submitted = false;
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
                setTimeout(() => {
                  this.router.navigate(['event-listbyarc']);
                }, 200);
              } else if (res.status == 401) {
                // console.log("INSIDE THE THIRD");
                this.router.navigate(['login']);
              } else {
                // console.log("INSIDE THE FOURTH");
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
              }
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          });

    

  }

  denieFunction() {
    this.reviewEventForm.controls['reviewOutCome'].setValue("REJECTED");
    this.reviewEventForm.controls['eventId'].setValue(this.eventId);
    // console.log("test::::" + JSON.stringify(this.reviewEventForm.value));
    this.submitted = true;
    // console.log("invalid:" + this.eventsReview.reviewOutCome.invalid)
  
        this.httpService.update(this.reviewEventForm.value, this.eventReview)
          .subscribe((res) => {
            // console.log("INSIDE THE FIRST");
            if (res != undefined) {
              this.resData = new AppResponse(res);
              // console.log("RESPONSE:" + JSON.stringify(this.resData));
              if (res.status == 200) {
                $('.close').click();
                // console.log("INSIDE THE SECOND");
                this.submitted = false;
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
                setTimeout(() => {
                  this.router.navigate(['event-listbyarc']);
                }, 200);  //15s
              } else if (res.status == 401) {
                // console.log("INSIDE THE THIRD");
                this.router.navigate(['login']);
              } else {
                // console.log("INSIDE THE FOURTH");
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
              }
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          });

  }
}
