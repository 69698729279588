import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
@Component({
  selector: 'app-faq-view-user',
  templateUrl: './faq-view-user.component.html',
  styleUrls: ['./faq-view-user.component.css'],
  providers: [NgxSpinnerService]

})
export class FaqViewUserComponent implements OnInit {

  faqForm: FormGroup;
  appResponse: AppResponse;
  resData: AppResponse;
  content: any;
  discontent: any;
  // isHOLoggedIn : boolean;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  public showOverlay = true;

constructor(private router: Router,private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService,
  private tokenService: TokenStorageService ,private spinner: NgxSpinnerService) {


   this.faqForm = this.formBuilder.group({
    "content": ['']
   });
 }

  ngOnInit() {

        // added by gopal for HB-611 start on 10/12/2021
        if (localStorage.getItem("isGULoggedIn") == "true") {

          this.isGULoggedIn = 'true';
        } else if (localStorage.getItem("isBMLoggedIn") == "true") {
    
          this.isBMLoggedIn = 'true';
        } else if (localStorage.getItem("isArcLoggedIn") == "true") {
    
          this.isArcLoggedIn = 'true';
        } else if (localStorage.getItem("isHOLoggedIn") == "true") {
    
          this.isHOLoggedIn = 'true';
        } else if (localStorage.getItem("isMCLoggedIn") == "true") {
    
          this.isMCLoggedIn = 'true';
        }
          // added by goapl for HB-611 end
    this.spinner.show();

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.FAQ).subscribe(data => {
      this.appResponse = new AppResponse(data);

      // console.log(JSON.stringify(data));

      this.spinner.hide();
      if( data.status === 200 && this.appResponse.data != null ){
          this.faqForm = this.formBuilder.group({
          "content": this.appResponse.data.content
         });
      }else{
        this.faqForm = this.formBuilder.group({
          "content": ''
         });
      }

     if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });


  }
}
