import { ApplicationConstants as BaseApplicationConstants } from 'src/app/utils/ApplicationConstants';

export class ApplicationConstants extends BaseApplicationConstants {

    static readonly GRP_REG_MSG = "Group has been added successfully";
	 /** HB-677 Priya  Start */
      // Token & Session
    static readonly SYSTEM_PORTAL = 'SYSTEM_PORTAL';
    static readonly ROLE_MASTERID_SYSTEMADMIN = 1;
    static readonly TOKEN_KEY = 'AuthToken';
    static readonly TOKEN_TYPE = "Bearer ";
    static readonly AUTHORIZATION = "Authorization";
    static readonly TOKEN_HEADER_KEY = 'Authorization';
    static readonly AUTHORITIES_KEY = 'AuthAuthorities';

    static readonly USER_PROFILE_INFO_ID_KEY = 'AuthUserProfileId';
    static readonly EMAIL_KEY = "email";
    static readonly FULL_NAME_KEY = "fullName";
    static readonly GROUP_ID_KEY = "groupId";
    static readonly URL_TOKENS_LIST_KEY = "urlTokens";
    static readonly ROLES = "roles";
    static readonly GROUP_NAME = "groupName";
    static readonly ADDRESS = "address";
     // Success Or Error Label
     static readonly SUCCESS_LABLE = "success";
     static readonly ERROR_LABLE = "error";
     static readonly DANGER_LABLE = "danger";
     static readonly MSG_BOX_LABEL = "#messageBox";     
    
    static readonly MSG_BOX_LABEL_IN_MODAL = "#messageBoxInModal";
     /** HB-677 Priya  End */
	  /** HB-630 Priya Start */    
    static readonly SUBSCRIBER_LIST = '/subscriberList';
    /** HB-630 Priya End */

    /** HB-629 Priya Start */    
    static readonly GRP_UPDATE_MSG = "Group has been updated successfully";
    static readonly DEFAULT_HOUSE_COUNT = 235;
    static readonly DEFAULT_SUB_TERM = 'standard';
    static readonly DEFAULT_TOOLTIP_MSG = 'If housecount is greater than 235, a special discount and an additional price per unit will be applicable.';
    /** HB-629 Priya End */
}
