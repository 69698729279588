import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
//import { listenerCount } from 'stream';

@Component({
  selector: 'app-review-cr-by-mc',
  templateUrl: './review-cr-by-mc.component.html',
  styleUrls: ['./review-cr-by-mc.component.css']
})
export class ReviewCrByMcComponent implements OnInit {

  complianceRequestId: any;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  reviewComplianceRequestForm: FormGroup;
  linkComplianceRequestForm: FormGroup;
  isStatusSelected: boolean;
  isStatusClosed: boolean;
  isEditable: boolean;
  fileUploadList: string[] = [];
  dueRequestForm: FormGroup;
  showResolvedOption: boolean = false;
  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end
  // isArcLoggedIn: boolean;
  byLawsList: any[] = [];
  isFineApply: boolean;
  submitted = false;
  selectedStatus: string;
  reviewComplianceRequestByARCForm: FormGroup;
  isReviewStatusSelected : boolean;
  btnUpdateDisabled : boolean;
  isLinkCREditable : boolean;
  reqData: any;
  generalCommentForm: FormGroup;
  // resData: AppResponse;
  refId: any;
  userprofileInfoId : any;
  updategeneralCommentForm: FormGroup;
  generalCommentList: any[] = [];
  private addGroupDue = environment.BASE_URL + ApplicationURIConstants.ADD_CR_FINE;

  filteredOptions: Observable<String[]>;

  byLawsTopicMat = new FormControl('');

  constructor(private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.spinner.show();
         // added by gopal for HB-611 start on 10/12/2021
         if (localStorage.getItem("isGULoggedIn") == "true") {

          this.isGULoggedIn = 'true';
        } else if (localStorage.getItem("isBMLoggedIn") == "true") {
    
          this.isBMLoggedIn = 'true';
        } else if (localStorage.getItem("isArcLoggedIn") == "true") {
    
          this.isArcLoggedIn = 'true';
        } else if (localStorage.getItem("isHOLoggedIn") == "true") {
    
          this.isHOLoggedIn = 'true';
        } else if (localStorage.getItem("isMCLoggedIn") == "true") {
    
          this.isMCLoggedIn = 'true';
        }
          // added by goapl for HB-611 end
    this.isStatusClosed = false;
    this.isEditable = false;
    this.activatedRoute.params.subscribe(params => {
      this.complianceRequestId = params.id;
    });

    this.reviewComplianceRequestForm = this.formBuilder.group({
      'reviewOutCome': ['', [Validators.required]],
      'reviewComments': [''],
      'byLowDescription': [''],
      'complianceRequestId': [this.complianceRequestId, [Validators.required]],
      'byLawsTopic': ['', Validators.required],
      'createdBy':['']
    });

    this.linkComplianceRequestForm = this.formBuilder.group({
      'images': ['',Validators.pattern(AppRegExConstants.DOCUMENT_PATTERN_FOR_COMPLIANCE)],
      'description': ['', [Validators.required]]
    });

    this.reviewComplianceRequestByARCForm = this.formBuilder.group({
      'reviewOutCome': ['' ,[Validators.required]],
      'reviewComments': [''],               
      'byLawsTopic': ['', [Validators.required]],              
      'complianceRequestId': ['', [Validators.required]],
      'assignedTo' : ['',[Validators.required]]            
    });        
  


    this.getBylaw();

    this.filteredOptions = this.byLawsTopicMat.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );

    this.dueRequestForm = this.formBuilder.group({
      'homeProfileId': ['', [Validators.required]],
      "userProfileInfoId": ['', [Validators.required]],
      "complianceRequestId": ['', [Validators.required]]
    });

    this.userprofileInfoId = this.tokenService.getuserProfileId();
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      this.refId = params.id;
    });

    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['CR', [Validators.required]],
      'comment': ['', [Validators.required]],
    });
    this.getComment();


  }


  //added by gopal
  getComment() {
    this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.GET_GENERAL_COMMENT).subscribe(res => {
      this.spinner.hide();
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        // console.log(JSON.stringify(res));

        if (res.status == 200) {
          this.resData = new AppResponse(res);
          this.generalCommentList = this.resData.data;
        } else if (res.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      }
    });
    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['CR', [Validators.required]],
      'comment': ['', [Validators.required]],
      'generalCommentId':["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["CR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() { return this.generalCommentForm.controls; }


  onSubmitcomment() {

    this.spinner.show();
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid")
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.generalCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.generalCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.generalCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.generalCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.generalCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.generalCommentForm.value));
      this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.ADD_GENERAL_COMMENT).subscribe(res => {
        this.spinner.hide();
        if (res == undefined) {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            // this.resData = new AppResponse(res);
            this.getComment();
          } else if (res.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }
      });
    }

  }

  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editcomment = 1;
      }
    }

  }

  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {console.log(this.updategeneralCommentForm.value);
      if(this.isArcLoggedIn) {
        this.updategeneralCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.updategeneralCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.updategeneralCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.updategeneralCommentForm.value.userrole="HO"
      }
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){


      this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
      // console.log(JSON.stringify(this.generalCommentForm.value));
      if(confirm("Are you sure you want to delete comment")){
        this.httpService
        .update(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.DELETE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });

      }
  }

  //added by gopal

  get dues() { return this.dueRequestForm.controls; }
  // convenience getter for easy access to form fields
  get compliance() { return this.reviewComplianceRequestForm.controls; }

  // convenience getter for easy access to form fields
  get linkCr() { return this.linkComplianceRequestForm.controls; }

  get reviewCr() { return this.reviewComplianceRequestByARCForm.controls;}

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        console.log("test asdfsafsdaf"+JSON.stringify(this.resData.data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  ngAfterViewInit() {

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS_ARC + "/" + this.complianceRequestId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      // console.log("test two"+JSON.stringify(this.appResponse.data.linkCompliance.listRequestDocument));
      if (data.status === 200) {
        
        this.selectedStatus = this.appResponse.data.complianceRequest.status;
        if (this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_PENDING) {
          this.isEditable = true;
        } else {
          if (this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_CLOSED) {
            this.isStatusClosed = true;
          }
          if (!this.isStatusClosed) {
            if (this.appResponse.data.reviewCrList != null) {
              for (let index = 0; index < this.appResponse.data.reviewCrList.length; index++) {
                if (this.appResponse.data.reviewCrList[index].assignedTo == this.tokenService.getuserProfileId()) {
                  this.isEditable = true;
                }
              }
            }
          }
        }
       if(this.appResponse.data.linkCompliance != null){
        if ((this.appResponse.data.linkCompliance.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_RESOLVED
         // || this.appResponse.data.linkCompliance.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_ACKNOWLEDGED
         ) && this.appResponse.data.linkCompliance.createdBy == this.tokenService.getuserProfileId()) {
          this.isLinkCREditable = true;
        }
       }
      


        if (this.appResponse.data.linkCompliance != null) {
          this.dueRequestForm.controls['homeProfileId'].setValue(this.appResponse.data.complianceRequest.homeProfileId);
          this.dueRequestForm.controls['userProfileInfoId'].setValue(this.appResponse.data.complianceRequest.userProfileInfoId);
          this.dueRequestForm.controls['complianceRequestId'].setValue(this.appResponse.data.linkCompliance.complianceRequestId);
          if (this.isArcLoggedIn && this.appResponse.data.linkCompliance.fineApplicable) {
            this.isFineApply = true;
          }
        }
        //console.log(">>>>>>>"+this.appResponse.data.linkCompliance.userProfileInfoId);
        setTimeout(() => {
          if (this.appResponse != undefined) {
            this.reviewComplianceRequestForm = this.formBuilder.group({
              'reviewOutCome': [this.appResponse.data.complianceRequest.status, [Validators.required]],
              'reviewComments': ['', [Validators.required]],
              'byLowDescription': [''],
              'complianceRequestId': [this.complianceRequestId, [Validators.required]],
              'byLawsTopic': ['', Validators.required],
              'createdBy' :[this.appResponse.data.complianceRequest.createdBy]
            });
            
            if(this.appResponse.data.complianceRequest.status == ApplicationConstants.COMPLIANCE_REQUEST_STATUS_RESOLVED){
              this.showResolvedOption = true;
            }

            this.linkComplianceRequestForm = this.formBuilder.group({
              'images': ['',Validators.pattern(AppRegExConstants.DOCUMENT_PATTERN_FOR_COMPLIANCE)],
              'description': [this.appResponse.data.complianceRequest.description, [Validators.required]]
            });
            if (this.appResponse.data.linkCompliance != null){
              this.reviewComplianceRequestByARCForm = this.formBuilder.group({
                'reviewOutCome': [this.appResponse.data.linkCompliance.status ,[Validators.required]],
                // Modified by Hema shanker for HB-497 Start
                'reviewComments': ['',[Validators.required]], 
                // Modified by Hema shanker for HB-497 End              
                'byLawsTopic': [this.appResponse.data.linkCompliance.byLawsTopic, [Validators.required]],              
                'complianceRequestId': [this.appResponse.data.linkCompliance.complianceRequestId, [Validators.required]],
                'assignedTo' : [this.appResponse.data.linkCompliance.userProfileInfoId,[Validators.required]]            
              });  
            }
           
            
            if (this.appResponse.data.complianceRequest.byLawsTopic != null) {
              for (let i = 0; i < this.byLawsList.length; i++) {
                if (this.byLawsList[i].byLawsTopic == this.appResponse.data.complianceRequest.byLawsTopic) {
                  this.byLawsTopicMat.setValue(this.appResponse.data.complianceRequest.byLawsTopic);
                  this.reviewComplianceRequestForm.controls['byLawsTopic'].setValue(this.appResponse.data.complianceRequest.byLawsTopic);
                  this.reviewComplianceRequestForm.controls['byLowDescription'].setValue(this.byLawsList[i].description);
                }
              }
            }

            $('#reviewCommentTable').DataTable({
              responsive: true,
              autoWidth: true,
              searching: true,
              /*"ordering": true,*/
              order: [],
              language: {
                zeroRecords: 'No records found.',
              },
              lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
              pageLength: 5,
              pagingType: $(window).width() < 768 ? "full" : "full_numbers",
              processing: true,
              columnDefs: [{
                targets: 4,
                orderable: false
              },
              ]
            });

            
            //HB-423 Hevisa Patel 28-01-2021 -- Start     
            $('#reviewLinkCommentTable').DataTable({
              responsive: true,
              autoWidth: true,
              searching: true,
              /*"ordering": true,*/
              order: [],
              language: {
                zeroRecords: 'No records found.',
              },
              lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
              pageLength: 5,
              pagingType: $(window).width() < 768 ? "full" : "full_numbers",
              processing: true,
              columnDefs: [{
                targets: 4,
                orderable: false
              },
              ]
            });
             //HB-423 Hevisa Patel 28-01-2021 -- End

          }
          this.spinner.hide();
          if(this.reviewComplianceRequestForm.invalid){
           // this.commonService.showNotificationAlert(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `Please provide required fields`);
          }
        }, 2000);//2s

      } else if (data.status === 401) {
        //401 token related issue
        this.spinner.hide();
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${data.errors}`);
        //403 URL not accessible
        //this.router.navigate(['home']);
      }
    }); 

    /*const controls = this.reviewComplianceRequestForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                console.log("in/*field "+name);
            }
        }
        */
  }

  onSubmit() {
    this.spinner.show();
    // console.log(JSON.stringify(this.reviewComplianceRequestForm.value));
    if (this.reviewComplianceRequestForm.invalid) {
      const controls = this.reviewComplianceRequestForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                // console.log("in/*field "+name);
            }
        }
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      this.httpService.save(this.reviewComplianceRequestForm.value, environment.BASE_URL + ApplicationURIConstants.ADD_CR_REVIEW)
        .subscribe((res) => {
          this.resData = new AppResponse(res);

          // console.log("Response ->>" + JSON.stringify(res));
          if (res.status == 200) {

            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            $('#reviewCommentTable').dataTable().fnDestroy();
            setTimeout(() => {
              this.router.navigate(['mc-compliance-list']);
            }, 2000);  //3s

          } else if (res.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else if (res.status == 400) {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);//HB-429 Hevisa Patel 28-01-2021
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }

  }

  addLinkCr() {
    //this.submitted = true;
    // console.log(JSON.stringify(this.linkComplianceRequestForm.value));
    if (this.linkComplianceRequestForm.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {

      $('.close').click();

      console.log("valid");
      var formData = new FormData();
      // Add multiple file in formData
      
      
      for (var i = 0; i < this.fileUploadList.length; i++) {
        console.log("document"+ JSON.stringify(this.fileUploadList[i]));
        formData.append('images', this.fileUploadList[i]);
      }
      formData.append('description', this.linkComplianceRequestForm.controls['description'].value);
      formData.append('homeProfileId', this.appResponse.data.complianceRequest.homeProfileId);
      formData.append('categoryId', this.appResponse.data.complianceRequest.categoryId);
      formData.append('byLawsTopic', this.appResponse.data.complianceRequest.byLawsTopic);
      formData.append('relatedCrId', this.appResponse.data.complianceRequest.complianceRequestId);

      console.log(JSON.stringify(formData));
      console.log(formData);
      // console.log(formData);

      this.httpService.save(formData, environment.BASE_URL + ApplicationURIConstants.ADD_COMPLIANCE_REQUEST_BY_MC)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          // console.log("Response ->>" + JSON.stringify(res));
          if (res.status == 200) {
            // this.complianceRequestForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, ApplicationConstants.COMPLIANCE_REQUEST_LINKED_SUCCESS);
            $('#reviewCommentTable').dataTable().fnDestroy();
            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);  //2s
          } else if (res.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
  }

  onKeyUp(event: any) {
    // console.log(event);
    if (event.target.value.length <= 0) {
      this.byLawsTopicMat.setErrors(null);
      this.reviewComplianceRequestForm.setErrors(null);
    } else {
      for (var i = 0; i < this.byLawsList.length; i++) {
        if (this.byLawsList[i].byLawsTopic == event.target.value) {
          this.reviewComplianceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopicMat.value);
          this.reviewComplianceRequestForm.controls['byLowDescription'].setValue(this.byLawsList[i].description);
          this.byLawsTopicMat.setErrors(null);
          break;
        } else {
          this.reviewComplianceRequestForm.controls['byLawsTopic'].setValue(null);
          this.reviewComplianceRequestForm.controls['byLowDescription'].setValue(null);
          this.byLawsTopicMat.setErrors({ invalid: true });
          this.reviewComplianceRequestForm.setErrors({ 'invalid': true });
        }
      }
    }
    if (event.target.value == null) {
      this.reviewComplianceRequestForm.setErrors({ 'invalid': true });
    }
  };

  selectByLawChangeHandler(selectedByLaw) {
    for (var i = 0; i < this.byLawsList.length; i++) {
      if (this.byLawsList[i].byLawsTopic == selectedByLaw) {
        this.reviewComplianceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopicMat.value);
        this.reviewComplianceRequestForm.controls['byLowDescription'].setValue(this.byLawsList[i].description);
      }
    }
  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.byLawsList.filter(option => option.byLawsTopic.toLowerCase().includes(filterValue));
  }

  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    this.linkComplianceRequestForm.setErrors(null);
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      var size = Math.round((event.target.files[i].size / 1024)); 
      if( (size /1024) > 10 ){
        this.linkComplianceRequestForm.setErrors({ 'invalid': true });
      }else{
        this.fileUploadList.push(event.target.files[i]);
      }
    }
  }

  changeStatus(e) {
    if (e != null) {
      this.selectedStatus = e.target.value;
      this.showResolvedOption = false;
      if (e.target.value == "RESOLVED") {
        this.isStatusSelected = true;
        this.showResolvedOption = true;
      } else if (e.target.value == "ACKNOWLEDGED") {
        this.isStatusSelected = true;
      } else if (e.target.value == "CLOSED") {
        this.isStatusSelected = true;
      }else {
        //this.isStatusSelected = false;
      }
    }

  }

  getBylaw() {
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.BY_LAWS).subscribe(res => {
      //console.log("2");
      this.resData = new AppResponse(res);
      // console.log(JSON.stringify(this.resData.data));
      if (res.status === 200) {
        for (var i = 0; i < this.resData.data.length; i++) {
          this.byLawsList.push(this.resData.data[i]);
        }
      } else if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        //this.router.navigate(['home']);
      }
    });
  }



  applyFine() {
    this.spinner.show();
    this.submitted = true;
    // console.log("FORM:" + JSON.stringify(this.dueRequestForm.value));
    if (this.dueRequestForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      // http service method call
      // console.log("FORM:" + JSON.stringify(this.dueRequestForm.value));
      this.httpService.save(this.dueRequestForm.value, this.addGroupDue)
        .subscribe((res) => {

          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.spinner.hide();
              this.submitted = false;
              $('.close').click();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['mc-compliance-list']);
              }, 2000);
            } else if (res.status == 401) {
              this.spinner.hide();
              this.router.navigate(['login']);
            } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }


  }


  changereviewStatus(e) {
   
    if (e.target.value == "PENDING" || e.target.value == 'CLOSED' || e.target.value == 'RESOLVED') {
      this.isReviewStatusSelected = true;
    } else {
      this.isReviewStatusSelected = false;
    }  
    if (e.target.value == this.appResponse.data.linkCompliance.status) {
      this.btnUpdateDisabled = true;
     //$('#btnUpdate').prop('disabled', true);
    }else{
      this.btnUpdateDisabled = false;
      //$('#btnUpdate').prop('disabled', false);
    }
  }

  reviewByARC(){
    
    // console.log(JSON.stringify(this.reviewComplianceRequestByARCForm.value));
    if (this.reviewComplianceRequestByARCForm.invalid) {
      // console.log(this.reviewComplianceRequestForm.errors);
      console.log("invalid");
      return;
    } else {
  
      this.httpService.save(this.reviewComplianceRequestByARCForm.value, environment.BASE_URL + ApplicationURIConstants.CR_REVIEW_BY_ARC)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            
            // console.log("Response ->>" + JSON.stringify(res));
            if (res.status == 200) {

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              $('#reviewCommentTable').dataTable().fnDestroy(); 
              setTimeout(() => {
                this.router.navigate(['mc-compliance-list']);//HB-429 Hevisa Patel 28-01-2021
              }, 2000);  //3s

            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }
  }

}
