import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-unhide-notification-by-bm',
  templateUrl: './unhide-notification-by-bm.component.html',
  styleUrls: ['./unhide-notification-by-bm.component.css']
})
export class UnhideNotificationByBmComponent implements OnInit {

  resData: AppResponse;
  notificationId: string;
  appResponse: AppResponse;
  map = new Map();
  unHideNotificationForm: FormGroup;
  IsDirectApproval:string;
  IsBMApprovalRequired:string;
  public Editor = ClassicEditor;
  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private tokenService: TokenStorageService,private activatedRoute: ActivatedRoute,private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router) { 
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
           'insertTable',
          // '|',
          // 'imageUpload',
          '|',
          'undo',
          'redo'
        ]
      },
      // image: {
      //   toolbar: [
      //     'imageStyle:full',
      //     'imageStyle:side',
      //     '|',
      //     'imageTextAlternative'
      //   ]
      // },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'en'
    };
  }
  
  ngAfterViewInit(): void {
    this.IsDirectApproval = this.tokenService.getGroupConfigByKey('Publish To Board');
    this.IsBMApprovalRequired = this.tokenService.getGroupConfigByKey('Publish To Home Owners');
    if(this.IsBMApprovalRequired =='No' && this.IsDirectApproval =='No'){
      this.IsDirectApproval="Yes";
      this.IsBMApprovalRequired="Yes";

    }
  }

  ngOnInit(){

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
          var date = new Date();
            var datetemp = new Date(date.getFullYear(),date.getMonth(),date.getDate());
            
            var datetempmax = new Date(date.getFullYear()+1,date.getMonth(),date.getDate());
            
            this.max = datetempmax;
            this.min = datetemp;
            // added by goapl for HB-611 end
   
    this.unHideNotificationForm = this.formBuilder.group({
      'subject': ['', [Validators.required,Validators.minLength(10)]],
      // "publishedDate": ['', [Validators.required]],
      'description': ['', [Validators.required]],
      'publishedBy': [''],
      

    });
   
    this.activatedRoute.params.subscribe(params => {
      this.notificationId = params.id;
      // console.log("notificationId"+ this.notificationId)
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_NOTIFICATION +'/'+ this.notificationId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      // console.log("data"+JSON.stringify( this.appResponse.data ));

      this.unHideNotificationForm= this.formBuilder.group({
        "subject": [this.appResponse.data.notificationList.subject, [Validators.required,Validators.minLength(10)]],
        "description": [this.appResponse.data.notificationList.description, [Validators.required]],
        "notificationId": [this.appResponse.data.notificationList.notificationId],
        'publishedBy': [this.appResponse.data.notificationList.status=="PENDING"?'criteria':'direct'],
        "publishedDate": [new Date(this.appResponse.data.notificationList.publishedDate), [Validators.required]],
  
    }); 
            
      
  if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['dashboardbm']);
      }
  });

   
}



get editNotification() {
    return this.unHideNotificationForm.controls;
 }

 submitted = false;
  private notificationURL = environment.BASE_URL + ApplicationURIConstants.UNHIDE_NOTIFICATION_BY_BM;

  onSubmits(){
    // console.log("valid1 request"+JSON.stringify(this.unHideNotificationForm.value));
  }



  onSubmit() {
    this.submitted = true;
    if (this.unHideNotificationForm.invalid) {
      console.error("invalid request")
      return;
    } else {
      if(this.isBMLoggedIn) {
        this.unHideNotificationForm.value.userrole="BM";
      }
      if(this.isArcLoggedIn) {
        this.unHideNotificationForm.value.userrole="ARC";
      }
      if(this.isMCLoggedIn) {
        this.unHideNotificationForm.value.userrole="MC";
      }
      // console.log("valid request"+JSON.stringify(this.unHideNotificationForm.value));
      this.httpService.save(this.unHideNotificationForm.value, this.notificationURL)
      .subscribe((res) => {
        // console.log("INSIDE THE FIRST");
        if (res != undefined) {
          $('.close').click();
          this.resData = new AppResponse(res);
          // console.log("RESPONSE:" + JSON.stringify(this.resData));
          if (res.status == 200) {
            this.submitted = false;
            this.unHideNotificationForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            // added by aishwaryaa for hb-812 15/02/2022
            setTimeout(() => {

              if(this.isBMLoggedIn){
                this.router.navigate(['bmnotification-list']);
              }else if(this.isArcLoggedIn){
                this.router.navigate(['arcnotification-list']);
              }else{
                this.router.navigate(['mcnotificationlist']);
              }
              //code end 
             
            }, 3000);  //15s
          } else if (res.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });


    }

  }

}
