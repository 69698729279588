import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ApplicationConstants } from 'src/app/utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRegExConstants } from '../../utils/AppRegExConstants';

@Component({
  selector: 'app-add-cr-byho',
  templateUrl: './add-cr-byho.component.html',
  styleUrls: ['./add-cr-byho.component.css'],
  providers: [NgxSpinnerService]
})
export class AddCrByhoComponent implements OnInit {

  flag: boolean;
  rongHo: boolean;
  complianceRequestForm: FormGroup;
  fileUploadList: string[] = [];
  resData: AppResponse;
  submitted = false;
  responseData: AppResponse;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService) { }

  ByLawsList: any[] = [];
  homeAddressList = [];

  filteredOptions: Observable<String[]>;
  filteredHomes: Observable<String[]>;

  byLawsTopic = new FormControl('');
  homeAddress = new FormControl('', [Validators.required]);

  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_CR).subscribe(res => {
      //console.log(JSON.stringify(res));
      this.responseData = new AppResponse(res);
      // console.log(this.responseData)

      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        if (res.status == 200) {
          // this.fillTextfeildData();
          //console.log(JSON.stringify(this.responseData.data.homeOwnerList));
          //console.log(JSON.stringify(this.responseData.data.bylawsList));
          for (let index = 0; index < this.responseData.data.homeOwnerList.length; index++) {
            if (this.tokenService.getuserProfileId() != this.responseData.data.homeOwnerList[index].userProfileInfoId) {
              this.homeAddressList.push(this.responseData.data.homeOwnerList[index]);
            }
          }

          this.ByLawsList = this.responseData.data.bylawsList;
          //  this.homeAddressList = this.responseData.data.homeOwnerList;
        } else if (res.status === 401) {
          //401 token related issue
          this.router.navigate(['login']);
        } else if (res.status == 403) {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
          this.router.navigate(['home']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
        }

      }
    });

    this.complianceRequestForm = this.formBuilder.group({
      'description': ['', [Validators.required]],
      'images': ['', Validators.pattern(AppRegExConstants.DOCUMENT_PATTERN_FOR_COMPLIANCE)],
      'categoryId': ['', [Validators.required]],
      'byLowDescription': [''],
      'HoDetails': [''],
      'homeProfileId': ['', [Validators.required]],
      'bylaw': ['']
    });

    this.filteredOptions = this.byLawsTopic.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );

    this.filteredHomes = this.homeAddress.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filterForHomes(value) : [])
      );
  }

  selectHoAddressChangeHandler(selectedHome) {
    // console.log(selectedHome)
    for (var i = 0; i < this.homeAddressList.length; i++) {
      if (this.homeAddressList[i].homeprofileid == selectedHome.homeprofileid) {
        this.complianceRequestForm.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
        this.complianceRequestForm.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
          this.homeAddressList[i].lastname);
      }
    }
  }

  selectByLawChangeHandler(selectedByLaw) {
    for (var i = 0; i < this.ByLawsList.length; i++) {
      if (this.ByLawsList[i].byLawsTopic == selectedByLaw) {
        this.complianceRequestForm.controls['bylaw'].setValue(this.byLawsTopic.value);
        this.complianceRequestForm.controls['byLowDescription'].setValue(this.ByLawsList[i].description);
      }
    }
  }

  // convenience getter for easy access to form fields
  get compliance() { return this.complianceRequestForm.controls; }

  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    this.complianceRequestForm.setErrors(null);
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      var size = Math.round((event.target.files[i].size / 1024)); 
      if( (size /1024) > 10 ){
        this.complianceRequestForm.setErrors({ 'invalid': true });
      }else{
        this.fileUploadList.push(event.target.files[i]);
      }
    }
  }

  onKeyUpForHomeAddress(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.rongHo = false;
      this.homeAddress.setErrors({ 'required': true });
    } else {
      this.rongHo = false;
      for (var i = 0; i < this.homeAddressList.length; i++) {
        if (this.homeAddressList[i].addressline1 == event.target.value) {
          this.complianceRequestForm.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
          this.complianceRequestForm.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
            this.homeAddressList[i].lastname);
          this.homeAddress.setErrors(null);
          this.rongHo = false;
          break;
        } else {
          this.complianceRequestForm.controls['homeProfileId'].setValue(null);
          this.complianceRequestForm.controls['HoDetails'].setValue(null);
          this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;

        }
      }
    }
  };

  onKeyUp(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.byLawsTopic.setErrors(null);
      this.complianceRequestForm.setErrors(null);
    } else {
      for (var i = 0; i < this.ByLawsList.length; i++) {
        if (this.ByLawsList[i].byLawsTopic == event.target.value) {
          this.complianceRequestForm.controls['bylaw'].setValue(this.byLawsTopic.value);
          this.complianceRequestForm.controls['byLowDescription'].setValue(this.ByLawsList[i].description);
          this.byLawsTopic.setErrors(null);
          break;
        } else {
          this.complianceRequestForm.controls['bylaw'].setValue(null);
          this.complianceRequestForm.controls['byLowDescription'].setValue(null);
          this.byLawsTopic.setErrors({ invalid: true });
          this.complianceRequestForm.setErrors({ 'invalid': true });
        }
      }
    }
  };

  onSubmit() {
    //this.submitted = true;     
    // console.log(JSON.stringify(this.complianceRequestForm.value));
    this.spinner.show()

    if (this.complianceRequestForm.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {

      console.log("valid")
      var formData = new FormData();
      // Add multiple file in formData
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append('images', this.fileUploadList[i]);
      }
      formData.append('description', this.complianceRequestForm.controls['description'].value);
      formData.append('homeProfileId', this.complianceRequestForm.controls['homeProfileId'].value);
      formData.append('categoryId', this.complianceRequestForm.controls['categoryId'].value);
      formData.append('byLawsTopic', this.complianceRequestForm.controls['bylaw'].value);

      // console.log(JSON.stringify(formData));

      this.httpService.save(formData, environment.BASE_URL + ApplicationURIConstants.COMPLIANCE_REQUESTS_BY_HO)
        .subscribe((res) => {
          this.spinner.hide();

          this.resData = new AppResponse(res);
          // console.log("Response ->>" + JSON.stringify(res));
          if (res.status == 200) {
            this.submitted = false;
            // this.complianceRequestForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['cr-list-byho']);
            }, 3000);  //3s
          } else if (res.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }

        });
    }

  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.ByLawsList.filter(option => option.byLawsTopic.toLowerCase().includes(filterValue));
  }

  private _filterForHomes(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.homeAddressList.filter(option => option.addressline1.toLowerCase().includes(filterValue));
  }

  displayFn(home: any): string {
    //console.log("disply ->> " + home.addressline1)
    if (home.homeprofileid == undefined) {
      //this.homeAddress.setErrors({ invalid: true });
      return;
    } else {
      return home.addressline1;
    }
  }

}
