import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
const helper = new JwtHelperService();
@Component({
  selector: 'app-multi-user-dashboard',
  templateUrl: './multi-user-dashboard.component.html',
  styleUrls: ['./multi-user-dashboard.component.css']
})
export class MultiUserDashboardComponent implements OnInit {

  roles: any[] = [];
  isValidHo : boolean;
  isValidMc : boolean;
  isValidArc : boolean;
  isValidBm : boolean;

  constructor(public tokenService: TokenStorageService, private router: Router) { }

  ngOnInit() {
    
  //  console.log(this.tokenService.getRoles());
   this.roles = this.tokenService.getRoles();

   for(var i=0; i<this.roles.length; i++){
     if(this.roles[i] == 3)
       this.isValidBm = true;
     else if(this.roles[i] == 4)
       this.isValidArc = true;
     else if(this.roles[i] == 5)
      this.isValidMc = true;
     else
       this.isValidHo = true;
   }
  }

}
