// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: "",

  //Production URL
  BASE_URL: "https://api.hybridhoa.com/hhoa-user-portal/user-portal", // url changed by gopal on 13/12/2021
  refreshurl:
    "https://user.hybridhoa.com/#/user-portal/userdue?payment=success",
  // refreshurl:
  //   "http://api.hybridhoa.com/hhoa-user-portal/user-portal/userdue?payment=success",

  // Router Authentication
  WHITE_LISTED_DOMAINS:
    "https://api.hybridhoa.com/hhoa-user-portal/user-portal", // url changed by gopal on 13/12/2021
  BLACK_LISTED_ROUTERS:
    "https://api.hybridhoa.com/hhoa-user-portal/user-portal/signin", // url changed by gopal on 13/12/2021
  ReCaptcha: "6LcAkS0hAAAAAGP74Ew5fU4l1xwWkfPi8k9abiEP",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
