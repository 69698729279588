import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cr-list-by-ho',
  templateUrl: './cr-list-by-ho.component.html',
  styleUrls: ['./cr-list-by-ho.component.css'],
  providers: [NgxSpinnerService]
})
export class CrListByHoComponent implements OnInit {

  resData: AppResponse;
  createdByMe: AppResponse;
  againstByMe: AppResponse;
  userProfileInfoId:string;

  // added by gopal for HB-611 start on 10/12/2021
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  // added by goapl for HB-611 end


  constructor(private httpService: HttpTransactionService,
    private router: Router,
    private commonService: CommonService, 
    private tokenService: TokenStorageService ,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    
      // added by gopal for HB-611 start on 10/12/2021
      if (localStorage.getItem("isGULoggedIn") == "true") {

        this.isGULoggedIn = 'true';
      } else if (localStorage.getItem("isBMLoggedIn") == "true") {
  
        this.isBMLoggedIn = 'true';
      } else if (localStorage.getItem("isArcLoggedIn") == "true") {
  
        this.isArcLoggedIn = 'true';
      } else if (localStorage.getItem("isHOLoggedIn") == "true") {
  
        this.isHOLoggedIn = 'true';
      } else if (localStorage.getItem("isMCLoggedIn") == "true") {
  
        this.isMCLoggedIn = 'true';
      }
        // added by goapl for HB-611 end
    this.spinner.show();
    this.userProfileInfoId = this.tokenService.getuserProfileId();
  }

  ngAfterViewInit(): void {
    // console.log("1");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_LIST_OF_COMPLIANCE_REQUEST_RAISED_BY_HO).subscribe(res => {
      // console.log("2");
      //console.log(JSON.stringify(res));
      this.spinner.hide();
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        this.createdByMe = new AppResponse(res);
        // console.log(JSON.stringify(this.resData.data));
        this.resData = new AppResponse(this.createdByMe);
        $(document).ready(function() {
          $('#complianceRequestTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            stateSave: true,
            
            lengthMenu: [ 5,10, 15, 20],
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            order: [],
      language: {
        zeroRecords: 'No records found.',
      },
            columnDefs: [{
              targets: 5,
            
            }]
          });
    
          $('.dataTables_length select').attr('aria-label', 'Show Entries').prepend('<option value="" disabled style="display:none">5</option>');
        });
        if (res.status === 401) {
          // console.log("401");
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          //403 URL not accessible
          // console.log("403");
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          this.router.navigate(['home']);
        }
      }
    });
  }

  
}
