import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../utils/common-service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/user-portal/src/environments/environment';
import * as $ from 'jquery';
import * as FileSaver from 'file-saver';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-sd',
  templateUrl: './edit-sd.component.html',
  styleUrls: ['./edit-sd.component.css'],
  providers: [NgxSpinnerService]
})
export class EditSdComponent implements OnInit {

  sharedDocumentForm: FormGroup;
  submitted = false;
  resData: AppResponse;
  appResponse: AppResponse;
  fileUploadList :string [] = [];
  // isBMLoggedIn : Boolean;
  sharedDocumentId: string;
  dateTime: Date;
  fileNameContentType: string;
  uploadedBy : string = '';
  //min : any = new Date();  
  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  errorFlag : boolean = true;
  showoptions:boolean=false;
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  
  constructor(private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService, 
    private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private router: Router ,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end

   // this.min.setDate(this.min.getDate()-1);
   var date = new Date();
    var datetemp = new Date(date.getFullYear(),date.getMonth(),date.getDate());
    
    var datetempmax = new Date(date.getFullYear()+1,date.getMonth(),date.getDate());
    
    this.max = datetempmax;
    this.min = datetemp;
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.sharedDocumentId = params.id;
    });

    this.sharedDocumentForm = this.formBuilder.group({
      "category": ['', [Validators.required]],
      "description": [''],
      "publishedDate": ['', [Validators.required]],
      "file": [''],
      "sharedDocumentId":[this.sharedDocumentId],
      "publishedBy": ['']
    });

     this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_SHARED_DOCUMENT+ this.sharedDocumentId).subscribe(data => {
         
      
      if (data.status === 200) {
        this.appResponse = new AppResponse(data);
        this.dateTime = new Date(this.appResponse.data.sharedDocument.publishedDate);
        this.sharedDocumentForm = this.formBuilder.group({
          "category": [this.appResponse.data.sharedDocument.category, [Validators.required]],
          "description": [this.appResponse.data.sharedDocument.description],
          "publishedDate": [this.dateTime, [Validators.required]],
          "file": [''],
          "sharedDocumentId":[this.sharedDocumentId],
          "publishedBy": [this.appResponse.data.sharedDocument.publishedBy]
        });     
        this.spinner.hide();
        if(this.appResponse.data.sharedDocument.category == 'Public')
        {
         this.showoptions=true;
        }
        else{
         this.showoptions=false;
        }
      } else if (data.status === 401) {
        this.spinner.hide();
        //401 token related issue
       this.tokenService.clearSession();
      // this.router.navigate(['login']);
      } else if (data.status === 403) {
        this.spinner.hide();
        //403 URL not accessible
         //this.router.navigate(['home']);
      }
    });

 
    
  }

   // convenience getter for easy access to form fields
   get sharedDocument() { return this.sharedDocumentForm.controls; }

   clearPublishedDateValue() {
     this.errorFlag = true;
    this.sharedDocumentForm.controls['publishedDate'].setValue(null);
  }


   onSubmit(){
    this.spinner.show();
     if (this.sharedDocumentForm.invalid) {
      this.spinner.hide();
       return;
     } else {
      if(localStorage.getItem("isHOLoggedIn")== "true"){
        this.uploadedBy = "HO";
      }else if(localStorage.getItem("isArcLoggedIn")== "true"){
        this.uploadedBy = "ARC";
      }else if(localStorage.getItem("isBMLoggedIn")== "true"){
        this.uploadedBy = "BM";
      }else if(localStorage.getItem("isMCLoggedIn")== "true"){
        this.uploadedBy = "MC";
      } else{
        this.uploadedBy = "GU";
      }

       var formData = new FormData();
       formData.append("sharedDocumentId",  this.sharedDocumentForm.controls['sharedDocumentId'].value);
       formData.append("description",  this.sharedDocumentForm.controls['description'].value);    
       formData.append("category",  this.sharedDocumentForm.controls['category'].value);      
       formData.append("publishedDate",this.sharedDocumentForm.controls['publishedDate'].value);
       formData.append("publishedBy",this.sharedDocumentForm.controls['publishedBy'].value);
       formData.append("userrole", this.uploadedBy);
       formData.append("uploadedBy",this.uploadedBy);

       if(this.fileUploadList != null && this.fileUploadList[0] != null){
         formData.append("file",this.fileUploadList[0]);
        }
      
      //  console.log(JSON.stringify(formData));

       this.httpService.save(formData,  environment.BASE_URL + ApplicationURIConstants.EDIT_SHARED_DOC).subscribe((res) => {
        // console.log(JSON.stringify(res));
         
        if(res != undefined){
           this.resData = new AppResponse(res);
           if (res.status == 200) {
             this.submitted = false;
             this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
             this.spinner.hide();
             setTimeout(() => {
               this.router.navigate(['sharedDocuments']);
             }, 2000);  //2s
           } else if(res.status == 401){
             this.spinner.hide();
             this.router.navigate(['logout']);
           }else {
             this.spinner.hide();
             this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
           }
         }else{
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
         }
       });
     }

   }

   onFileSelect (event) {
    this.fileUploadList = [];
    this.fileUploadList.push(event.target.files[0]);     
  }

  onOptionSelect (event) {
     if(event.target.value == 'Public')
     {
      this.showoptions=true;
      this.sharedDocumentForm.controls['publishedBy'].setValidators([Validators.required]);
      this.sharedDocumentForm.controls['publishedBy'].updateValueAndValidity();
     }
     else{
      this.showoptions=false;
      this.sharedDocumentForm.controls['publishedBy'].setValidators([]);
      this.sharedDocumentForm.controls['publishedBy'].updateValueAndValidity();
     }
    }
  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_SD_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName);
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  onChange(data){
    // console.log("-----11");
    if(data !== null){
      // console.log("-----");
      this.errorFlag = false;
    }else{
      this.errorFlag = true;
    }
  }

  test(){
    this.errorFlag = false;
  }
  
}
