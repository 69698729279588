import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective} from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { startWith, map } from 'rxjs/operators';
import { environment } from 'projects/user-portal/src/environments/environment';
import { RequireMatchForByLaw } from '../../utils/requireMatchForByLaw';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';

@Component({
  selector: 'app-add-mr',
  templateUrl: './add-mr.component.html',
  styleUrls: ['./add-mr.component.css'],
  providers: [NgxSpinnerService]
})
export class AddMrComponent implements OnInit {

  maintenanceRequestForm: FormGroup;
  fileUploadList :string [] = [];
  resData: AppResponse;
  submitted = false;
  byLaws:any[] = [];
  byLawsListTopic:any[] = [];
  flag : boolean;
  category:any[] = [];
  uploadedBy:string;
  // Modified by Hema shanker for HB-577 on 14/12/2021 Start
  fix: boolean;
  request: boolean;
  requesttype: string;
  // Modified by Hema shanker for HB-577 on 14/12/2021 End

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end


  private maintenanceRequests = environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUESTS;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private tokenService: TokenStorageService, private spinner: NgxSpinnerService) { }
  groupName = ['Parking (arc)','Parking (Bylaw)','Design Guideline (bylaw)','Temporary Structures (covenent)','Mailboxes (bylaw)','Limitation on Size and Location of Structures (arc)'];
  filteredOptions: Observable<String[]>;
  myControl = new FormControl();
  byLawsTopic = new FormControl('');
  ngOnInit() {

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
      // added by goapl for HB-611 end

    if(localStorage.getItem("isHOLoggedIn")){
      this.uploadedBy = "HO";
    }
    else if(localStorage.getItem("isArcLoggedIn")){
      this.uploadedBy = "ARC";
    }
    else if(localStorage.getItem("isBMLoggedIn")){
      this.uploadedBy = "BM";
    }
    else if(localStorage.getItem("isHOLoggedIn")){
      this.uploadedBy = "MC";
    }
    else{
      this.uploadedBy = "GU";
    }

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_REQUIRED_DATA_OF_ADD_MR).subscribe(res => {

      this.resData = new AppResponse(res);
      this.byLaws = this.resData.data.bylawsList;
      this.category = this.resData.data.categoryMR;

      for (var i = 0; i < this.byLaws.length; i++) {
        this.byLawsListTopic.push(this.byLaws[i].byLawsTopic);
      }

      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });




    this.maintenanceRequestForm = this.formBuilder.group({
      "description": ['', [Validators.required]],
      "moreInfo": [''],
      "urgent": [''],
      "safetyThreat": [''],
      "category": ['', [Validators.required]],
      "images": [''],
      "byLawsTopic":new FormControl(''),
      // Modified by Hema shanker for HB-577 on 14/12/2021 Start
      "byLawsDesc":[''],
      "requesttype":['',[Validators.required]]
      // Modified by Hema shanker for HB-577 on 14/12/2021 End
    });

    this.filteredOptions = this.byLawsTopic.valueChanges
        .pipe(
          startWith(''),
          map(value => value.length >=3 ? this._filter(value):[])
        );
  }

  // convenience getter for easy access to form fields
  get maintenance() { return this.maintenanceRequestForm.controls; }

  // file upload data manupulated in on change evenet
  onFileSelect (event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }

  onKeyUp(event: any) {
    // console.log(event.target.value);
    if( event.target.value.length <= 0 ){
      this.byLawsTopic.setErrors(null);
      this.maintenanceRequestForm.setErrors(null);
    }else{
      for (var i = 0; i < this.byLaws.length; i++) {
        // Modified by Hema shanker for HB-577 on 14/12/2021 Start
        if ((this.byLaws[i].byLawsTopic == event.target.value) && (this.maintenanceRequestForm.controls['requesttype'].value != null)) {
          // Modified by Hema shanker for HB-577 on 14/12/2021 End
          this.maintenanceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
          this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Type:-" + this.byLaws[i].description);
          this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Description:-" + this.byLaws[i].description);
          this.byLawsTopic.setErrors(null);
          break;
        } else {
          this.maintenanceRequestForm.controls['byLawsTopic'].setValue(null);
          this.maintenanceRequestForm.controls['byLawsDesc'].setValue(null);
          this.byLawsTopic.setErrors({ invalid: true });
          this.maintenanceRequestForm.setErrors({ 'invalid': true });
        }
      }
    }
  };


  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    if (this.maintenanceRequestForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      var formData = new FormData();

      // Add multiple file in formData
       for (var i = 0; i < this.fileUploadList.length; i++) {
         formData.append("images", this.fileUploadList[i]);
       }

      if($("#urgent"). prop("checked") == true){
        this.maintenanceRequestForm.value.urgent = true;
      }else{
        this.maintenanceRequestForm.value.urgent = false;
      }

      if($("#safetyThreat"). prop("checked") == true){
        this.maintenanceRequestForm.value.safetyThreat = true;
      }else{
        this.maintenanceRequestForm.value.safetyThreat = false;
      }
      // Modified by Hema shanker for HB-577 on 14/12/2021 Start
      if (this.maintenanceRequestForm.controls['requesttype'].value == "fix") {
        this.maintenanceRequestForm.value.fix = true;
      } else {
        this.maintenanceRequestForm.value.fix = false;
      }
      if (this.maintenanceRequestForm.controls['requesttype'].value == "request") {
        this.maintenanceRequestForm.value.request = true;
      } else {
        this.maintenanceRequestForm.value.request = false;
       }
      // console.log("test",this.maintenanceRequestForm.controls['requesttype'].value);
      // console.log("fix",this.maintenanceRequestForm.value.fix);
      // console.log("request",this.maintenanceRequestForm.value.request);
      // Modified by Hema shanker for HB-577 on 14/12/2021 End

       formData.append("description",  this.maintenanceRequestForm.controls['description'].value);
       formData.append("moreInfo",this.maintenanceRequestForm.controls['moreInfo'].value);
       formData.append("urgent",this.maintenanceRequestForm.value.urgent);
       formData.append("safetyThreat",this.maintenanceRequestForm.value.safetyThreat);
       formData.append("category",this.maintenanceRequestForm.controls['category'].value);
       formData.append("byLawsTopic",this.byLawsTopic.value);
      //  Modified by Hema shanker for HB-577 on 14/12/2021 Start
      formData.append("uploadedBy", this.uploadedBy);
       formData.append("fix", this.maintenanceRequestForm.value.fix);
      formData.append("request", this.maintenanceRequestForm.value.request);
      // Modified by Hema shanker for HB-577 on 14/12/2021 End
      this.httpService.save(formData, this.maintenanceRequests)
      .subscribe((res) => {
        if(res != undefined){
          this.spinner.hide();
          this.resData = new AppResponse(res);
          if (res.status == 200) {
            this.submitted = false;
            this.maintenanceRequestForm.reset();
            $("#category").prop('selectedIndex', 0);
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['maintenanceRequests']);
            }, 5000);  //15s
          } else if(res.status == 401){
            this.router.navigate(['logout']);
          }else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });
    }


  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.byLawsListTopic.filter(option => option.toLowerCase().includes(filterValue));
  }

  getPosts(bylawtopic){
    // console.log("MAIN:"+bylawtopic)
    for (var i = 0; i < this.byLaws.length; i++) {
      if(this.byLaws[i].byLawsTopic == bylawtopic){
        // console.log("final:"+this.byLaws[i].description);
         $("#desc").val(this.byLaws[i].description);
         this.maintenanceRequestForm.controls["byLawsDesc"].setValue(this.byLaws[i].description);

      }
    }
  }

  selectByLawChangeHandler(selectedByLaw) {
    for (var i = 0; i < this.byLaws.length; i++) {
      if (this.byLaws[i].byLawsTopic == selectedByLaw) {
        this.maintenanceRequestForm.controls['byLawsTopic'].setValue(this.byLawsTopic.value);
        this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Type:-" + this.byLaws[i].description);
        this.maintenanceRequestForm.controls['byLawsDesc'].setValue("ByLaws Description:-" + this.byLaws[i].description);
      }
    }
  }

}
