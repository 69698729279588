import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../utils/common-service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.css'],
  providers: [NgxSpinnerService]

})
export class ViewNotificationComponent implements OnInit {

  resData: AppResponse;
  notificationId: string;
  appResponse: AppResponse;
  isApproveEnable : Boolean;
  isRejectEnable : Boolean;
  isUpstands : Boolean;
  // isBMLoggedIn : Boolean;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  
  // isArcLoggedIn : Boolean;
  // isHOLoggedIn : Boolean;
  // isGuLoggedIn : Boolean;
  map = new Map();
  assigneeList: any[] = [];
  userProfileInfoId : any;
  reviewNotificationForm: FormGroup;
  submitted = false;
  show=false;
  
  private notificationsReview = environment.BASE_URL + ApplicationURIConstants.NOTIFICATION_REVIEW_UPDATE_BY_BM;

  constructor(private tokenService:TokenStorageService,private commonService: CommonService,private activatedRoute: ActivatedRoute,private httpService: HttpTransactionService, private formBuilder: FormBuilder,  private router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit() {
   
    this.spinner.show();
     // added by gopal for HB-611 start on 10/12/2021
     if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }
      // added by goapl for HB-611 end

    this.activatedRoute.params.subscribe(params => {
      this.notificationId = params.id;
      // console.log("notificationId"+ this.notificationId)
    });
    // this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_NOTIFICATION +'/'+ this.notificationId).subscribe(data => {
    //   this.appResponse = new AppResponse(data);
    //   this.userProfileInfoId = this.tokenService.getuserProfileId();
    //   console.log("userProfileInfoId"+ this.userProfileInfoId)


    //   for(var i=0; i<this.appResponse.data.reviewNotificationList.length; i++){
    //     this.assigneeList.push(this.appResponse.data.reviewNotificationList[i]);
    //   }
    //   if(this.assigneeList.length >0){
    //     this.show=true
    //   }

    //   for (var i = 0; i < this.assigneeList.length; i++) {
    //     console.log("insideloop")
      
    //     if((this.isBMLoggedIn  && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'PENDING')||(this.isBMLoggedIn  && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'REJECTED')||(this.isBMLoggedIn  && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'APPROVED')){
    //     this.isApproveEnable = true;
    //     this.isRejectEnable = true;
        // console.log("insideif")
    //  }
    //  }

    // if((this.isBMLoggedIn && this.appResponse.data.notificationList.status == 'APPROVED' || this.appResponse.data.notificationList.status == 'REJECTED' )){
    //   this.isApproveEnable = false;
    //   this.isRejectEnable = false;
    //   console.log("insideif")

    //  }

    //   setTimeout(() => {
    //     $('#reviewCommentTable').DataTable({
    //       responsive: true,
    //       autoWidth: true,
    //       searching: false,
    //       /*"ordering": true,*/
    //       order: [],
    //       language: {
    //         zeroRecords: 'No records found.',
    //       },
    //       lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
    //       pageLength: 5,
    //       pagingType: $(window).width() < 768 ? "full" : "full_numbers"
    //       // pagingType: $(window).width() < 768 ? "full" : "full_numbers",
    //       // processing: true,
    //       // columnDefs: [{
    //       //   targets: 4,
    //       //   orderable: false
    //       // },
    //       // ]
    //     });
    //   }, 200);
    //   if (data.status === 401) {
    //     console.log("401");
    //     //401 token related issue
    //     this.tokenService.clearSession();
    //     this.router.navigate(['login']);
    //   } else if (data.status === 403) {
    //     //403 URL not accessible
    //     console.log("403");
    //     this.router.navigate(['dashboardbm']);
    //   }
   
    //  }); 
            
     this.reviewNotificationForm = this.formBuilder.group({
      'reviewComments': ['', [Validators.required]],
      'notificationId': [this.notificationId,[Validators.required]],
      'reviewOutCome': ['', [Validators.required]],     
    });

  }

  ngAfterViewInit(): void {
    

    $('#reviewCommentTable').dataTable().fnDestroy(); 
    
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_NOTIFICATION +'/'+ this.notificationId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      this.userProfileInfoId = this.tokenService.getuserProfileId();
      // console.log("userProfileInfoId"+ this.userProfileInfoId)


      for(var i=0; i<this.appResponse.data.reviewNotificationList.length; i++){
        this.assigneeList.push(this.appResponse.data.reviewNotificationList[i]);
      }
      if(this.assigneeList.length >0){
        this.show=true
      }
      // Added by Hema shanker for HB-585 on 14/12/2021 Start
      if(this.isBMLoggedIn && this.appResponse.data.notificationList.status == 'PENDING'){
        this.isApproveEnable = true;
        this.isRejectEnable = true;
        this.isUpstands = true;
        // console.log("insideif 2")
       }
       // Added by Hema shanker for HB-585 on 14/12/2021 End

      for (var i = 0; i < this.assigneeList.length; i++) {
        // console.log("insideloop")
      
        if((this.isBMLoggedIn  && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'PENDING')||(this.isBMLoggedIn  && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'REJECTED')||(this.isBMLoggedIn  && this.assigneeList[i].assignedTo == this.userProfileInfoId && this.assigneeList[i].reviewOutCome == 'APPROVED')){
        this.isApproveEnable = true;
        this.isRejectEnable = true;
        this.isUpstands = true;

        // console.log("insideif")
     }
     }

    if((this.isBMLoggedIn && this.appResponse.data.notificationList.status == 'APPROVED' || this.appResponse.data.notificationList.status == 'REJECTED' )){
      this.isApproveEnable = false;
      this.isRejectEnable = false;
      this.isUpstands = false;
      // console.log("insideif")

     }

      setTimeout(() => {
        $('#reviewCommentTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: false,
          // retrieve: true,
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers"
          // pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          // processing: true,
          // columnDefs: [{
          //   targets: 4,
          //   orderable: false
          // },
          // ]
        });
      }, 200);
      if (data.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['dashboardbm']);
      }
   
     }); 
        
  }


  get notificationReview() { return this.reviewNotificationForm.controls; }

  approveFunction(){
    this.reviewNotificationForm.controls['reviewOutCome'].setValue("APPROVED");
     this.reviewNotificationForm.controls['notificationId'].setValue(this.notificationId);
    // console.log("test::::"+JSON.stringify(this.reviewNotificationForm.value));
    this.submitted = true;
    if (this.reviewNotificationForm.invalid) {
      return;
    }else{
      if(this.isBMLoggedIn){
        this.httpService.update(this.reviewNotificationForm.value, this.notificationsReview)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.ngAfterViewInit();
                // this.router.navigate(['maintenance-list']);
              }, 100);  
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }
   
    }

  }

  rejectFunction(){
    
    this.reviewNotificationForm.controls['reviewOutCome'].setValue("REJECTED");
    this.reviewNotificationForm.controls['notificationId'].setValue(this.notificationId);
    // console.log("test::::"+JSON.stringify(this.reviewNotificationForm.value));
    this.submitted = true;
    // console.log("invalid:"+this.notificationReview.reviewOutCome.invalid)
    if (this.reviewNotificationForm.invalid) {
      return;
    }else{
      if(this.isBMLoggedIn){
        this.httpService.update(this.reviewNotificationForm.value, this.notificationsReview)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.ngAfterViewInit();
              }, 100);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }
    
    }
  }

  upstandsFunction(){
    this.reviewNotificationForm.controls['reviewOutCome'].setValue("UPSTANDS");
     this.reviewNotificationForm.controls['notificationId'].setValue(this.notificationId);
    // console.log("test::::"+JSON.stringify(this.reviewNotificationForm.value));
    this.submitted = true;
    if (this.reviewNotificationForm.invalid) {
      return;
    }else{
      if(this.isBMLoggedIn){
        this.httpService.update(this.reviewNotificationForm.value, this.notificationsReview)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              $('.close').click();
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.ngAfterViewInit();
                // this.router.navigate(['maintenance-list']);
              }, 100);  
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }
   
    }

  }
  /*reset review notification modal- review comment field reset on approve/reject/upstand click*/
  clearReviewNotificationForm(){
    this.reviewNotificationForm.reset();
  }

}
