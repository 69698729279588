import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from "projects/system-portal/src/app/utils/common-service";

import { ApplicationConstants } from "../../utils/ApplicationConstants";
import * as $ from 'jquery';
import { JwtHelperService } from "@auth0/angular-jwt";
const helper = new JwtHelperService();
@Component({
  selector: 'app-mr-view',
  templateUrl: './mr-view.component.html',
  styleUrls: ['./mr-view.component.css']
})
export class MrViewComponent implements OnInit {

  maintenanceRequestId: string;
  // maintenanceRequest: MaintenanceRequest;

  reqData: any;
  generalCommentForm: FormGroup;
  updategeneralCommentForm: FormGroup;
  resData: AppResponse;
  refId: any;
  viewOnly : boolean;
  generalCommentList: any[] = [];
  userprofileInfoId : any;
  appResponse: AppResponse;
  resData2: AppResponse;
  requestDocumentId: string;
  documentName: string;
  fileNameContentType: string;
  fileNameExtension: string;
  rdAppResponse: AppResponse;
  detailedOriginalDoc:any = [];
  map = new Map();
    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private http: HttpClient, private router: Router, private tokenService: TokenStorageService,private formBuilder: FormBuilder, private commonService: CommonService) { }

  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
            this.userprofileInfoId = this.tokenService.getuserProfileId();
    this.activatedRoute.params.subscribe(params => {
      this.maintenanceRequestId = params.id;
      this.refId=this.maintenanceRequestId;
    });

    // console.log("REFID:" + this.refId);
    this.generalCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
    });
    this.getComment();


    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUEST + this.maintenanceRequestId).subscribe(data => {
      this.appResponse = new AppResponse(data);

      console.log("appresponse"+JSON.stringify(this.appResponse));
      
     for(var i=0; i<this.appResponse.data.documentName.length; i++){
      //  console.log("docName:"+this.appResponse.data.documentName[i])
       this.map.set(this.appResponse.data.documentName[i].substr(14),this.appResponse.data.documentName[i]);
     }
     if(this.appResponse.data.originalDoc){

      for(let i=0; i<this.appResponse.data.originalDoc.length; i++) {

        this.detailedOriginalDoc.push({
          "docUploadedBy": this.appResponse.data.docUploadedBy[i],
          "originalDoc": this.appResponse.data.originalDoc[i],
          "createdOn" : this.appResponse.data.createdOn[i]
        });

      }
      //console.log(this.detailedOriginalDoc)
    }

      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

  }

// added by aishwaryaa for hb-807
  getComment() {
    this.httpService
      .save(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.GET_GENERAL_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            this.resData2 = new AppResponse(res);
            // console.log("======="+this.resData2.data);
            this.generalCommentList = this.resData2.data;
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData2.errors}`
            );
          }
        }
      });
    this.generalCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() {
    return this.generalCommentForm.controls;
  }

  onSubmit() {
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.generalCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.generalCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.generalCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.generalCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.generalCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.generalCommentForm.value));

      this.httpService
        .save(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.ADD_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  
  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editcomment = 1;
      }
    }

  }

  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      console.log(this.generalCommentForm.value);
      if(this.isArcLoggedIn) {
        this.updategeneralCommentForm.value.userrole="ARC";
      }else if(this.isBMLoggedIn){
        this.updategeneralCommentForm.value.userrole="BM";
      }else if(this.isMCLoggedIn){
        this.updategeneralCommentForm.value.userrole="MC";
      }else if(this.isHOLoggedIn){
        this.updategeneralCommentForm.value.userrole="HO"
      }else if(this.isGULoggedIn){
        this.updategeneralCommentForm.value.userrole="GU"
      }
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){


    this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if(confirm("Are you sure you want to delete comment")){
      this.httpService
      .update(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.DELETE_GENERAL_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            // this.resData = new AppResponse(res);
            this.getComment();
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });

    }
}

//code end 
  // get the document of byte array.
  getDocument(documentName: string) {
       this.httpService.get(environment.BASE_URL +
       ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
         this.resData = new AppResponse(data);

         //Start the code for Base64 to byte[]
         const byteCharacters = atob(this.resData.data);

         const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
           byteNumbers[i] = byteCharacters.charCodeAt(i);
         }

        let byteArray = new Uint8Array(this.resData.data);

         const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
           const byteCharacters = atob(b64Data);
           const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
             const slice = byteCharacters.slice(offset, offset + sliceSize);

             const byteNumbers = new Array(slice.length);
             for (let i = 0; i < slice.length; i++) {
               byteNumbers[i] = slice.charCodeAt(i);
             }

            const byteArray = new Uint8Array(byteNumbers);
             byteArrays.push(byteArray);
           }

           const blob = new Blob(byteArrays, { type: contentType });
           return blob;
         };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
         //End the code for Base64 to byte[]

        //Save the file
         FileSaver.saveAs(blob, documentName);
       });
   }


}
