import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/user-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { CommonService } from '../../utils/common-service';
var formData = new FormData();
declare var $: any;
@Component({
  selector: 'app-cr-template-bm-view',
  templateUrl: './cr-template-bm-view.component.html',
  styleUrls: ['./cr-template-bm-view.component.css']
})
export class CrTemplateBmViewComponent implements OnInit {
  isGULoggedIn: string;
  isBMLoggedIn: String;
  isArcLoggedIn: String;
  isHOLoggedIn: String;
  isMCLoggedIn: String;
  crtemplate: FormGroup;
  resData: any;
  token: any;
  templateid: any;
  checked: boolean;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private commonService: CommonService, private httpService: HttpTransactionService, private router: Router) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.templateid = params.id;
    });

    this.token = JSON.parse(localStorage.getItem(ApplicationConstants.USER_PORTAL));

    // console.log(this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY]);

    if (localStorage.getItem("isGULoggedIn") == "true") {

      this.isGULoggedIn = 'true';
    } else if (localStorage.getItem("isBMLoggedIn") == "true") {

      this.isBMLoggedIn = 'true';
    } else if (localStorage.getItem("isArcLoggedIn") == "true") {

      this.isArcLoggedIn = 'true';
    } else if (localStorage.getItem("isHOLoggedIn") == "true") {

      this.isHOLoggedIn = 'true';
    } else if (localStorage.getItem("isMCLoggedIn") == "true") {

      this.isMCLoggedIn = 'true';
    }

    // this.crtemplate = this.formBuilder.group({
    //   "top": ['', [Validators.required]],
    //   "bottom": ['', [Validators.required]],
    //   "createdBy": [this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY], [Validators.required]],
    //   "isActive": [true, [Validators.required]],
    // });

    this.crtemplate = this.formBuilder.group({
      "top": ['', [Validators.required]],
      "bottom": ['', [Validators.required]],
      "createdBy": [this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY], [Validators.required]],
      "isActive": [true, [Validators.required]],

    });

    this.crtemplate.controls['top'].disable()
    this.crtemplate.controls['bottom'].disable()




    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_CR_TEMPLATE_BY_ID + '/' + this.templateid)
      .subscribe((res) => {
        // console.log(res);

        if (res != undefined) {
          this.resData = new AppResponse(res);
          // console.log(this.resData);

          if (res.status == 200) {
            this.fillTextfeildData();


          } else if (res.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          } else {
            this.spinner.hide();

            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
          }
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });



    //   console.log(this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY]);



    // this.crtemplate.setValue['userProfileInfoId']=this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY];

    // this.crtemplate = this.formBuilder.group({

    //   "userProfileInfoId" : [this.token[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY],[Validators.required]]

    //   });
  }


  onSubmit() {
    if(this.crtemplate.controls['top'].value.trim()=='' || this.crtemplate.controls['bottom'].value.trim()==''){
      
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please provide Valid Top/Bottom data");
  return;
      }else{
        
        
      }

    // this.spinner.show();
    if (this.crtemplate.invalid) {
      // console.log("invalid")
      this.spinner.hide();
      return;
    }
    else {





      // console.log(this.crtemplate.value);




      this.httpService.save(this.crtemplate.value, environment.BASE_URL + ApplicationURIConstants.EDIT_CR_TEMPLATE)
        .subscribe((res) => {
          // console.log(res);

          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log(this.resData);

            if (res.status == 200) {
              setTimeout(() => {
                this.router.navigate(['crtemplaebm']);
              }, 2000);
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${res.message}`);
            


            } else if (res.status == 401) {
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              this.spinner.hide();

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });


    }
  }
  get f() { return this.crtemplate.controls; }

  DeleteTemplate() {
    // code modified by aishwaryaa for HB-737 24/01/2022
    //delete message modified by aishwaryaa for hb-838
    if (confirm("Are You Sure You want to Delete Template ?")) {
      this.httpService.get(environment.BASE_URL + ApplicationURIConstants.DELETE_CR_TEMPLATE + "/" + this.templateid)
        .subscribe((res) => {
          // console.log(res);


          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log(this.resData);

            if (res.status == 200) {

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${res.message}`);

              setTimeout(() => {
                this.router.navigate(['crtemplaebm']);
              }, 2000);

            } else if (res.status == 401) {
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              this.spinner.hide();

              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }
  }


  editTemplate() {
    this.crtemplate.controls['top'].enable()
    this.crtemplate.controls['bottom'].enable()


  }

  // changeFunction() {
  //   if ($("#isActive").is(":checked")) {

  //     this.crtemplate.controls['status'].disable()



  //   } else {

  //     this.crtemplate.controls['status'].enable()
  //     this.crtemplate.controls['status'].reset()

  //   }
  // }


  changestate() {
    if ($("#status").is(":checked")) {


      this.crtemplate.controls['isActive'].setValue(true);


    } else {

      this.crtemplate.controls['isActive'].setValue(false);

    }
  }

  fillTextfeildData() {

    if (this.resData.data.isActive) {
      this.checked = true;

    }
    else {
      this.checked = false;
    }

    this.crtemplate = this.formBuilder.group({
      "top": [this.resData.data.top, [Validators.required]],
      "bottom": [this.resData.data.bottom, [Validators.required]],
      "createdBy": [this.resData.data.createdBy, [Validators.required]],
      "isActive": [this.resData.data.isActive],
      "crTemplateId": [this.resData.data.crTemplateId]

    });





  }
}



