import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as FileSaver from 'file-saver';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-un-hide-sdbybm',
  templateUrl: './un-hide-sdbybm.component.html',
  styleUrls: ['./un-hide-sdbybm.component.css']
})
export class UnHideSdbybmComponent implements OnInit {
  sharedDocumentId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  unHideSharedDocumentForm: FormGroup;
  fileUploadList :string [] = [];
  public dateTime: Date;
  list : any[] = [];
  submitted = false;
  uploadedBy : string = '';
  showoptions:boolean=false;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
  
  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();


  private unHideSharedDoc = environment.BASE_URL + ApplicationURIConstants.UN_HIDE_SD;

  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private router: Router, 
    private tokenService: TokenStorageService ,
    private spinner: NgxSpinnerService) { }


  ngOnInit() {
          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    /*  Bug 454- commented this line- to prevent showing previous date enabled in calender */
   // this.min.setDate(this.min.getDate()-1);
   var date = new Date();
    
   
    var datetempmax = new Date(date.getFullYear()+1,date.getMonth(),date.getDate());
    /////added by aishwaryaa for HB-454 22/12/2021
    var datetemp = new Date(date.getFullYear(),date.getMonth(),date.getDate());
    this.min = datetemp;
    /////CODE END 

    this.max = datetempmax;
  
    this.spinner.show();
    this.unHideSharedDocumentForm = this.formBuilder.group({
      "category": ['', [Validators.required]],
      "description": [],
      "publishedDate": ['', [Validators.required]],
      "file": ['',[Validators.required]],
      "publishedBy": ['']
    });
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.sharedDocumentId = params.id;
    });
    // tslint:disable-next-line: max-line-length
    // console.log("sharedDocumentId -> " + this.sharedDocumentId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_SHARED_DOCUMENT+ this.sharedDocumentId).subscribe(data => {
      // console.log("+++++++"+JSON.stringify(data));
      // console.log(this.)

      this.appResponse = new AppResponse(data);
      if(this.appResponse.data.sharedDocument.category == 'Public')
    {
      // console.log("in if");
      
     this.showoptions=true;
    }
      this.list = this.appResponse.data.sharedDocument.listRequestDocument;
      // for(var i = 0; i<this.list.length; i++){
      //   console.log("======"+this.list[i]);
      // }
      // console.log(this.appResponse.data.sharedDocument.listRequestDocument);
      this.dateTime = new Date(this.appResponse.data.sharedDocument.publishedDate);
      this.unHideSharedDocumentForm = this.formBuilder.group({
        "category": [this.appResponse.data.sharedDocument.category, [Validators.required]],
        "description": [this.appResponse.data.sharedDocument.description],
        "publishedDate": [this.dateTime, [Validators.required]],
        "file": [''],
        "publishedBy": [this.appResponse.data.sharedDocument.publishedBy]
      });
      //  $( "#fromReportDate" ).datepicker({dateFormat: "DD, d MM, yy"});
      //  $( "#fromReportDate" ).datepicker("setDate", $.fromReportDate.parseDate( "yy-mm-dd", this.appResponse.data.sharedDocument.publishedDate ));
      //this.dateTime = this.appResponse.data.sharedDocument.publishedDate;
      //this.unHideSharedDocumentForm.controls['publishedDate'].setValue(this.appResponse.data.sharedDocument.publishedDate);
      
      this.spinner.hide();
      if (data.status === 401) {
        //401 token related issue
        this.spinner.hide();
       this.tokenService.clearSession();
       this.router.navigate(['login']);
      } else if (data.status === 403) {
        this.spinner.hide();
        //403 URL not accessible
        // this.router.navigate(['home']);
      }
    });
////////////////////////////// added by aishwaryaa for Hb-582/////////////////
    if( localStorage.getItem("isBMLoggedIn") == "true" ){
      this.uploadedBy = "BM";
    }else if ( localStorage.getItem("isArcLoggedIn") == "true"){
      this.uploadedBy = "ARC";
    }
    else if (localStorage.getItem("isMCLoggedIn") == "true"){
      this.uploadedBy = "MC";
    }

    //////////////////code end


  }

  clearPublishedDateValue() {
    this.unHideSharedDocumentForm.controls['publishedDate'].setValue(null);
  }
  get sharedDocument() { return this.unHideSharedDocumentForm.controls; }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_SD_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  onFileSelect (event) {
    // console.log("SELECTIOOOOOOOO");
    $("#docList").hide();
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }
  onOptionSelect (event) {
    if(event.target.value == 'Public')
    {
     this.showoptions=true;
    }
    else{
     this.showoptions=false;
    }
   }

  onSubmit(){
    this.spinner.show();
    // console.log("FORMVALUE:"+JSON.stringify(this.unHideSharedDocumentForm.value));
    if (this.unHideSharedDocumentForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      var formData = new FormData();
      formData.append("description",  this.unHideSharedDocumentForm.controls['description'].value);
      formData.append("category",this.unHideSharedDocumentForm.controls['category'].value);
      formData.append("publishedDate",this.unHideSharedDocumentForm.controls['publishedDate'].value);
      formData.append("uploadedBy", this.uploadedBy);
      formData.append("userrole", this.uploadedBy);
      formData.append("publishedBy",this.unHideSharedDocumentForm.controls['publishedBy'].value);
      if(this.fileUploadList != null && this.fileUploadList[0] != null){
        // console.log("======");
        formData.append("file",this.fileUploadList[0]);
      }

      formData.append("originalSdId",this.sharedDocumentId);
      // console.log("FORMDATA:"+formData.get("publishedDate"));
      // console.log("VALUE:"+formData.get("originalSdId"));

       this.httpService.save(formData, this.unHideSharedDoc)
       .subscribe((res) => {
         if(res != undefined){
           this.resData = new AppResponse(res);
           if (res.status == 200) {
             this.submitted = false;
             this.unHideSharedDocumentForm.reset();
             $("#category").prop('selectedIndex', 0);
             this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
             this.spinner.hide();
             setTimeout(() => {
               this.router.navigate(['bm-sd-list']);
          }, 5000);  //15s
           } else if(res.status == 401){
             this.spinner.hide();
             this.router.navigate(['logout']);
           }else {
             this.spinner.hide();
             this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
           }
         }else{
          this.spinner.hide();
           this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
         }
       });
    }
  }


}
