import { Component, OnInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartType, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Router } from '@angular/router';
import { CommonService } from '../../utils/common-service';
import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Component({
  selector: 'app-gu-dashboard',
  templateUrl: './gu-dashboard.component.html',
  styleUrls: ['./gu-dashboard.component.css']
})
export class GuDashboardComponent implements OnInit {

  // isGULoggedIn : boolean;
  public totalMr : any;
  public totalCr : any;
  public appResponse: AppResponse;
  emptyMR : boolean = false;

    // added by gopal for HB-611 start on 10/12/2021
    isGULoggedIn: string;
    isBMLoggedIn: String;
    isArcLoggedIn: String;
    isHOLoggedIn: String;
    isMCLoggedIn: String;
    // added by goapl for HB-611 end
    footer : String;
  displayText: string;


  constructor(private httpService: HttpTransactionService, private tokenService: TokenStorageService, private router: Router ,private commonService: CommonService) {}


  ngOnInit() {
    if(localStorage.getItem("greeting") == "true"){
      this.displayText = 'Welcome to Owner Portal';
    }else {
      this.displayText = '';
    }
    

    setTimeout(() => {
      this.displayText = '';
    }, 5000);
    
    window.localStorage.setItem("isGULoggedIn", "true");

          // added by gopal for HB-611 start on 10/12/2021
          if (localStorage.getItem("isGULoggedIn") == "true") {

            this.isGULoggedIn = 'true';
            setTimeout(() => {
              this.displayText = '';
              localStorage.setItem("greeting","false")
            }, 5000);
          } else if (localStorage.getItem("isBMLoggedIn") == "true") {
      
            this.isBMLoggedIn = 'true';
          } else if (localStorage.getItem("isArcLoggedIn") == "true") {
      
            this.isArcLoggedIn = 'true';
          } else if (localStorage.getItem("isHOLoggedIn") == "true") {
      
            this.isHOLoggedIn = 'true';
          } else if (localStorage.getItem("isMCLoggedIn") == "true") {
      
            this.isMCLoggedIn = 'true';
          }
            // added by goapl for HB-611 end
    // this.isArcLoggedin = true;
    // window.localStorage.setItem("isArcLoggedIn", "true");
    // window.localStorage.setItem(ApplicationConstants.DASHBOARD_URL_LABEL, ApplicationConstants.ARC_DASHBOARD_URL);

    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GU_DASHBOARD).subscribe(res => {

      this.appResponse = new AppResponse(res);
      // console.log("GUDashboard"+JSON.stringify( this.appResponse))

      if (res.status === 401) {
        // 401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        // 403 URL not accessible
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.appResponse.errors}`);
      } else if (res.status === 200) {

        this.mrPieChartData = [this.appResponse.data.completedtMr, this.appResponse.data.pendingMr, this.appResponse.data.inprogressMr];
        // this.crPieChartData = [this.appResponse.data.visitNeedCr, this.appResponse.data.pendCr, this.appResponse.data.ackCr, this.appResponse.data.reslvCr, this.appResponse.data.clsCr];
        
        this.totalMr = Math.abs(this.appResponse.data.totalMr);
        // this.totalCr = Math.abs(this.appResponse.data.totalCr);
        if(this.totalMr == 0)
        this.emptyMR = true;
      }
    });

  }


  ngAfterViewInit(): void {
    this.footer = this.tokenService.getGroupConfigByKey('footer');
  }
   // Pie
  // Maintenance request Start
  public mrPieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: () => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          const label = null;
          return label;
        },
      },
    }
  };

  public mrPieChartLabels: Label[] = ['Completed', 'Pending', 'In Progress '];
  public mrPieChartData: number[] = [0, 0];//=  [this.appResponse.data.acceptedMr, this.appResponse.data.rejectedMr];
  public mrPieChartType: ChartType = 'pie';
  public mrPieChartLegend = true;
  public mrPieChartPlugins = [pluginDataLabels];
  public mrPieChartColors = [
    {
      // tslint:disable-next-line: max-line-length
      backgroundColor: ['rgba(196, 154, 199, 1)', 'rgba(239, 168, 200, 1)', 'rgba(182, 226, 132, 1)'],
    },
  ];
  // Maintenance request End

  // Compliance request Start
  // Pie
  public crPieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: () => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          const label = null;
          return label;
        },
      },
    }
  };

}
